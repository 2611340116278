<div
  class="parser-container"
  *ngFor="let element of elements; index as i"
  [ngSwitch]="element.type"
>
  <!-- VERIFICATION STATUSES -->
  <div
    [attr.tabGroup]="element.tabGroup"
    [attr.tabKey]="element.tabKey"
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'verification_statuses'"
  >
    <!-- STATUS LIST -->
    <app-verification-statuses
      [statuses]="element.params.statuses"
      [evidenceTypes]="element.params.evidenceTypes"
      [vtype]="element.params.vtype"
      [requestKey]="element.params.requestKey"
    ></app-verification-statuses>
  </div>

  <div
    class="d-none"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'empty'"
  >
    <!-- IMAGE -->
  </div>

  <div
    class="content-absolute-link"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'absolute-link'"
  >
    <button
      [attr.clickmethod]="element.params.action"
      [attr.type]="element.params.type"
      (click)="OnButtonClick($event)"
      [attr.specKey]="element.params.specKey"
    >
      {{ element.params.content }}
    </button>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'image'"
  >
    <!-- IMAGE -->
    <img
      src="{{ element.params.src }}"
      class="result-image {{ element.params.class }}"
      [attr.width]="element.params.width"
      [attr.height]="element.params.height"
    />
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'svgImage'"
  >
    <!-- IMAGE -->
    <svg-icon
      [src]="element.params.src"
      [svgStyle]="{
        'width.px': element.params.width,
        'height.px': element.params.height
      }"
      [ngClass]="element.params.classes ? element.params.classes : ''"
    ></svg-icon>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'alert'"
  >
    <!-- ALERT -->
    <div class="alert-container {{ element.params.alert_type }} d-flex">
      <div class="alert-icon-container d-flex align-items-center">
        <div class="alert-image {{ element.params.alert_image_class }}"></div>
      </div>
      <div class="alert-content-container">
        <h1 class="alert-title {{ element.params.alert_title_class }}">
          {{ element.params.alert_title }}
        </h1>
        <p class="alert-text mb-2 alert-date" *ngIf="element.params.alert_date">
          {{ element.params.alert_date | date: "dd.MM.yyyy" }}
        </p>
        <p class="alert-text">{{ element.params.alert_content }}</p>
      </div>
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'alertLoader'"
  >
    <div class="alert-container alert-loader container-fixed-mob-height d-flex">
      <img
        src="/assets/images/spinner-gray.svg"
        width="32px"
        height="32px"
        class="mx-auto"
      />
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="
      element.class ? element.class : element.firstOfType ? 'mt-2' : ''
    "
    *ngSwitchCase="'h3'"
  >
    <!-- H3 -->
    <h3
      [ngClass]="
        element.params.classes
          ? 'result-h3 ' + element.params.classes
          : 'result-h3'
      "
    >
      {{ element.params.content }}
    </h3>
  </div>

  <div
    [attr.tabGroup]="element.tabGroup"
    [attr.tabKey]="element.tabKey"
    class="element-wrapper d-flex-low"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'title'"
  >
    <!-- TITLE -->
    <h4
      [ngClass]="element.params.titleClass ? element.params.titleClass : ''"
      class="result-inner-title d-inline-block mb-0"
      *ngIf="
        element.params.display === undefined ||
        (element.params.display !== undefined &&
          element.params.display === true)
      "
    >
      {{ element.params.content }}
    </h4>
    <app-tooltip
      *ngIf="element.params.tooltipMessage"
      [tooltipText]="element.params.tooltipMessage"
    >
    </app-tooltip>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'subtitle'"
  >
    <!-- SUBTITLE -->
    <h4 class="result-inner-subtitle">
      {{ element.params.content }}
    </h4>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'subtitle-alternate'"
  >
    <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
    <h4>
      {{ element.params.content }}
    </h4>
  </div>

  <div
    [attr.tabGroup]="element.tabGroup"
    [attr.tabKey]="element.tabKey"
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'paragraph'"
  >
    <!-- PARAGRAPH AS SUBTITLE -->
    <h4
      class="result-paragraph"
      [ngClass]="element.paragraphClass ? element.paragraphClass : ''"
      [innerHTML]="
        element.params.contentHTML && element.params.contentHTML !== ''
          ? element.params.contentHTML
          : element.params.content
      "
    >
      {{ element.params.content }}
    </h4>
  </div>

  <div
    class="element-wrapper d-flex"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'paragraphWicon'"
  >
    <!-- PARAGRAPH AS SUBTITLE -->
    <div class="paragaph-icon d-flex align-items-center">i</div>
    <h4
      class="result-paragraph"
      [ngClass]="element.paragraphClass ? element.paragraphClass : ''"
      [innerHTML]="
        element.params.contentHTML && element.params.contentHTML !== ''
          ? element.params.contentHTML
          : element.params.content
      "
    >
      {{ element.params.content }}
    </h4>
  </div>

  <div
    class="element-wrapper d-flex flex-row align-items-start notification info"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'notificationParagraph'"
  >
    <img src="assets/images/icon-information-prepared-teal.svg" width="16px" />
    <div
      class="description ml-3"
      [ngClass]="element.paragraphClass ? element.paragraphClass : ''"
      [innerHTML]="
        element.params.contentHTML && element.params.contentHTML !== ''
          ? element.params.contentHTML
          : element.params.content
      "
    >
      {{ element.params.content }}
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'paragraphIcon'"
  >
    <!-- PARAGRAPH WITH ICON -->
    <div
      class="d-flex position-relative"
      [attr.id]="element.params.id"
      [ngClass]="element.params.class ? element.params.class : ''"
      *ngIf="
        element.params.display === undefined ||
        (element.params.display !== undefined &&
          element.params.display === true)
      "
    >
      <svg-icon
        [src]="element.params.svgIcon.src"
        [svgStyle]="{
          'width.px': element.params.svgIcon.width,
          'height.px': element.params.svgIcon.height
        }"
        [ngClass]="
          element.params.svgIcon.class ? element.params.svgIcon.class : ''
        "
        *ngIf="element.params.svgIcon"
      ></svg-icon>
      <p
        *ngIf="element.params.svgIcon && element.params.svgIcon.content"
        class="result-paragraph mt-0 ml-2 mr-3"
      >
        {{ element.params.svgIcon.content }}
      </p>
      <svg-icon
        *ngIf="element.params.svgIcon2"
        [src]="element.params.svgIcon2.src"
        [svgStyle]="{
          'width.px': element.params.svgIcon2.width,
          'height.px': element.params.svgIcon2.height
        }"
        [ngClass]="
          element.params.svgIcon2.class ? element.params.svgIcon2.class : ''
        "
      ></svg-icon>
      <p
        *ngIf="element.params.svgIcon2 && element.params.svgIcon2.content"
        class="result-paragraph mt-0 ml-2"
      >
        {{ element.params.svgIcon2.content }}
      </p>
      <p
        class="result-paragraph mt-0 ml-2"
        [ngClass]="{ 'mr-6': element.displayHistory }"
      >
        {{ element.params.content }}
      </p>
      <a
        href="#"
        [attr.clickmethod]="'OnDisplayHistory'"
        class="historylink ml-3"
        (click)="OnButtonClick($event)"
        *ngIf="element.displayHistory"
        >history</a
      >
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'conditional_paragraph'"
  >
    <!-- PARAGRAPH AS SUBTITLE -->
    <h4 class="result-paragraph" *ngIf="element.params.display">
      {{ element.params.content }}
    </h4>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'most_recent_relationship'"
  >
    <!-- MOST RECENT RELATIONSHIP / SERVICE -->
    <div
      class="d-flex most-recent-relationship-container"
      *ngIf="
        (element.params.display === undefined ||
          element.params.display === true) &&
        ((element.params.startdate !== undefined &&
          element.params.startdate !== '') ||
          (element.params.enddate !== undefined &&
            element.params.enddate !== ''))
      "
    >
      <div
        class="d-flex flex-column visual-icons justify-content-center align-items-center"
      >
        <div class="start-icon" *ngIf="element.params.startdate"></div>
        <div
          class="connecting-rectangle"
          *ngIf="
            element.params.startdate !== '' && element.params.enddate !== ''
          "
        ></div>
        <div class="end-icon" *ngIf="element.params.enddate"></div>
      </div>
      <div class="service-dates d-flex flex-column">
        <h5 class="service-start-date" *ngIf="element.params.startdate">
          Service start date:
          {{ element.params.startdate | date: "dd.MM.yyyy" }}
        </h5>
        <h5 class="service-end-date mt-auto" *ngIf="element.params.enddate">
          Service end date: {{ element.params.enddate | date: "dd.MM.yyyy" }}
        </h5>
      </div>
    </div>
  </div>

  <div
    class="element-wrapper"
    [attr.tabGroup]="element.params.tabGroup"
    [attr.tabKey]="element.params.tabKey"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'tabbed_content'"
  >
    <!-- <ul  class="nav nav-tabs kvk-navigation" role="tablist">
      <li class="nav-item" *ngFor="let tab of element.params.tabs">
        <a class="nav-link {{ tab.status }}" data-toggle="tab" href="#{{ tab.id }}">{{ tab.title }}</a>
      </li>
    </ul> -->
    <div
      class="person-section-title mb-4"
      [ngClass]="
        element.params.sectionTitleClass ? element.params.sectionTitleClass : ''
      "
    >
    <div *ngIf="element?.params?.profileVerificationStatus?.length > 0"  class="mb-3">
      <div *ngFor="let status of element.params.profileVerificationStatus" class="d-flex verification-main-substatus">
        <img *ngIf="status.icon" style="width: 17px; height: auto" src="{{ status.icon }}"  class="mr-1"/>
        <div class="details" [innerHTML]="status.status"></div>
      </div>
    </div>
      <h3>{{ element.params.sectionTitle }}</h3>
    </div>
    <!-- GENERAL INFORMATION TAB -->
    <div
      class="d-flex tab-content kvk-container"
      *ngFor="let tab of element.params.tabs"
    >
      <div
        id="general_information"
        class="flex-grow-1 tab-pane {{ tab.status }}"
      >
        <div class="loader-overlay" *ngIf="tab.displayLoader"></div>
        <div
          class="kvk-element-wrapper"
          [ngClass]="[
            tabelement['type'] !== 'html' &&
            tabelement['content'] !== undefined &&
            tabelement['title'] == true
              ? 'full-width-kvk'
              : '',
            tabelement['type'] !== 'html' &&
            tabelement['content'] == undefined &&
            tabelement['title'] == undefined
              ? 'd-none'
              : ''
          ]"
          *ngFor="let tabelement of tab.content"
        >
          <div
            class="d-flex w-100"
            *ngIf="
              tabelement['type'] !== 'html' &&
              tabelement['content'] !== undefined &&
              tabelement['title'] == undefined
            "
          >
            <div class="kvk-element-wrapper-icon-title">
              <div class="kvk-label">{{ tabelement.key }}</div>
              <img *ngIf="tabelement.icon" class="kvk-icon ml-1" [src]="tabelement.icon" height="15px"/>
            </div>
            <div class="kvk-information">{{ tabelement.content }}</div>
          </div>
          <div
            class="d-flex w-100"
            *ngIf="
              tabelement['type'] !== 'html' &&
              tabelement['content'] !== undefined &&
              tabelement['title'] == true
            "
          >
            <div
              style="margin-bottom: 16px"
              class="kvk-title title-t2 mt-2 pt-2"
            >
              {{ tabelement.content }}
            </div>
          </div>
          <div
            class="d-flex w-100"
            *ngIf="
              tabelement['type'] === 'html' &&
              tabelement['content'] !== undefined
            "
          >
            <div class="kvk-label">{{ tabelement.key }}</div>
            <div class="kvk-information" [innerHTML]="tabelement.content"></div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex flex-column image-wrapper image-wrapper-no-image" *ngIf="element.params.image && element.params.image.display && element.params.image.src === 'failed-to-recover'">
        <div class="title">Picture ID</div>
        <div class="image-holder no-image h-100 d-flex align-items-center text-center" [ngClass]="element.params.image.class">
          <div class="mx-auto">No picture of ID<br />to dislpay</div>
        </div>
      </div> -->
      <div style="margin-top: -26px">
        <div
          *ngIf="
            element.params.image &&
            element.params.image.display &&
            element.params.image.src !== 'failed-to-recover'
          "
          class="person-section-title title-t2 mb-2"
        >
          <h3 class="">Evidence</h3>
        </div>
        <div
          [ngClass]=""
          class="document-inner-entry mt-3 mb-4"
          *ngIf="
            element.params.image &&
            element.params.image.display &&
            element.params.image.src !== 'failed-to-recover'
          "
          [attr.clickmethod]="'OnDisplayImageInLightbox'"
          (click)="OnButtonClick($event)"
        >
          <div
            [attr.clickmethod]="'OnDisplayImageInLightbox'"
            class="document-upload-entry-image entry-image-ID"
          >
            <div
              [attr.clickmethod]="'OnDisplayImageInLightbox'"
              [ngClass]="element.params.image.class"
              [ngStyle]="{
                'background-image': 'url(' + element.params.image.src + ')'
              }"
            ></div>
          </div>
          <div
            [attr.clickmethod]="'OnDisplayImageInLightbox'"
            class="document-upload-entry-detail"
          >
            <h5
              [attr.clickmethod]="'OnDisplayImageInLightbox'"
              class="document-name"
              style="pointer-events: none"
            >
              Identity Document
            </h5>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex flex-column image-wrapper" *ngIf="element.params.image && element.params.image.display && element.params.image.src !== 'failed-to-recover'">
        <div class="title">Picture ID</div>
        <div class="image-holder h-100" [ngStyle]="{'background-image': 'url(' + element.params.image.src + ')'}" [ngClass]="element.params.image.class" [attr.clickmethod]="'OnDisplayImageInLightbox'" (click)="OnButtonClick($event)"></div>
      </div> -->
      <!-- SHAREHOLDERS TAB -->
      <div id="shareholders" class="tab-pane fade">
        <div class="kvk-element-wrapper d-flex">
          <div class="kvk-label">Shareholders info</div>
          <div class="kvk-information">.............</div>
        </div>
      </div>
      <!-- DIRECTORS TAB -->
      <div id="directors" class="tab-pane fade">
        <div class="kvk-element-wrapper d-flex">
          <div class="kvk-label">Directors info</div>
          <div class="kvk-information">.............</div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'button_group'"
  >
    <!-- BUTTON GROUP -->
    <span
      class="wrapper"
      [ngClass]="element.displayFlex ? 'd-flex flex-row' : ''"
      *ngIf="element.display === undefined || element.display === true"
    >
      <span
        *ngFor="let button of element.params"
        [ngClass]="element.alignRight ? 'ml-auto mr-0' : ''"
      >
        <button
          [ngClass]="
            button.class
              ? 'form-button ' + button.class
              : 'form-button form-button-1'
          "
          *ngIf="button.display"
          [attr.id]="button.id"
          [attr.clickmethod]="button.action"
          [attr.type]="button.type"
          (click)="OnButtonClick($event)"
        >
          {{ button.content }}
        </button>
      </span>
    </span>
    <div
      *ngIf="!element.hideErrorField"
      [ngClass]="element.errorMessageClass ? element.errorMessageClass : ''"
      class="button-group-error-message button-error-field"
    >
      {{ element.errorMessage }}
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'button'"
  >
    <!-- BUTTON -->
    <button
    [id]="element?.params?.id ? element.params.id : ''"
      [ngClass]="
        element.params.class
          ? 'form-button ' + element.params.class
          : 'form-button form-button-1'
      "
      *ngIf="element.params.display"
      [attr.clickmethod]="element.params.action"
      [attr.type]="element.params.type"
      (click)="OnButtonClick($event)"
      [attr.specKey]="element.params.specKey"
    >
      {{ element.params.content }}
    </button>
    <div *ngIf="element.params.errorMessage" class="button-error-field">
      {{ element.params.errorMessage }}
    </div>
  </div>

  <div
    [attr.tabGroup]="element.requestKey"
    [attr.tabKey]="element.requestKey + '_details'"
    [ngClass]="element.class ? element.class : ''"
    class="element-wrapper"
    *ngSwitchCase="'update_button'"
  >
    <!-- BUTTON -->
    <button
      *ngIf="element.updateFlow"
      class="form-button form-button-4"
      [attr.clickmethod]="'updateBasicInformation'"
      (click)="OnButtonClick($event)"
    >
      <img src="/assets/images/update_white.svg" />
      <span>{{ element.content }}</span>
    </button>
    <button
      *ngIf="element.previewFlow"
      class="form-button form-button-4"
      (click)="
        documentPreviewFunction(
          element.evidenceTypes,
          element.evidence,
          element.requestKey,
          element.basicFeildsevidenceKey
        )
      "
    >
      <img src="/assets/images/update_white.svg" />
      <span>{{ element.content }}</span>
    </button>
  </div>

  <div
    [attr.tabGroup]="element.params.requestKey"
    [attr.tabKey]="element.params.requestKey + '_details'"
    class="element-wrapper"
    [ngClass]="element.class"
    *ngSwitchCase="'update_button_investor'"
  >
    <!-- BUTTON -->
    <button
      class="form-button form-button-4"
      (click)="
        onUpdateInvestorFields(
          element.params.evidenceKey,
          element.params.requestKey,
          element.params.documents,
          false,
          false,
          element.params.basicFields
        )
      "
    >
      <img src="/assets/images/update_white.svg" />
      <span>{{ "appSpecific.ViewDetails" | translate }}</span>
    </button>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'inputText'"
  >
    <span class="input-label d-flex {{ element.params.labelClass }}"
      >{{ element.params.label }}
      <app-tooltip
        *ngIf="element.params.tooltip"
        [tooltipText]="element.params.tooltip"
        [icon]="element.params.tooltipIcon"
        class="d-flex"
      ></app-tooltip>
      <sup
        *ngIf="element.params.required"
        class="sup-required sup-required-alternate"
        >*</sup
      >
    </span>
    <!-- Input Text -->
    <input
      type="text"
      class="text-input"
      id="{{ element.params.id }}"
      name="{{ element.params.name }}"
      value="{{ element.params.content }}"
      [attr.isValid]="true"
      [readonly]="element.params.readOnly"
      [attr.isRequired]="element.params.required"
      [attr.isMinLength]="element.params.minLength"
      [attr.isMaxLength]="element.params.maxLength"
      [attr.patternMatch]="element.params.patternMatch"
      [attr.enterkeyaction]="element.params.onenterkeyaction"
      [attr.enterChangeAction]="element.params.onEnterChangeAction"
      [attr.autofocus]="element.params.autofocus"
      (input)="OnChange($event)"
      (keyup)="OnInputKeyUp($event)"
      (blur)="OnInputBlur($event)"
    />
    <div
      *ngIf="element.params.additionalInfo"
      class="text-right font-italic mt-2"
    >
      {{ element.params.additionalInfo }}
    </div>
    <div
      class="error-field"
      [ngClass]="element.params.validatorMessageClass"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>
  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'inputHidden'"
  >
    <!-- Input Hidden -->
    <input
      type="hidden"
      class="text-input"
      id="{{ element.params.id }}"
      name="{{ element.params.name }}"
      value="{{ element.params.content }}"
      [attr.isValid]="true"
      [readonly]="element.params.readOnly"
      [attr.isRequired]="element.params.required"
      [attr.isMinLength]="element.params.minLength"
      [attr.isMaxLength]="element.params.maxLength"
      [attr.patternMatch]="element.params.patternMatch"
      [attr.enterkeyaction]="element.params.onenterkeyaction"
      (keyup)="OnInputKeyUp($event)"
      (blur)="OnInputBlur($event)"
    />
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>
  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'inputGoogleAutocomplete'"
  >
    <div id="mapImage" [ngClass]="!map ? '' : 'display-none'"></div>
    <div #mapWrapper id="map" [ngClass]="map ? '' : 'display-none'"></div>
    <div class="mapInfo" [ngClass]="map ? '' : 'display-none'">
      {{ "review.validation2" | translate }}
    </div>

    <span class="input-label {{ element.params.labelClass }}"
      >{{ element.params.label
      }}<sup *ngIf="element.params.required" class="sup-required">*</sup></span
    >
    <!-- Input Text -->
    <input
      type="text"
      id="googleAutoComplete"
      class="text-input google-autocomplete"
      #gautocomplete
      name="{{ element.params.name }}"
      value="{{ element.params.content }}"
      [attr.isValid]="true"
      [attr.isRequired]="element.params.required"
      [attr.isMinLength]="element.params.minLength"
      [attr.isMaxLength]="element.params.maxLength"
      [attr.patternMatch]="element.params.patternMatch"
      [attr.enterkeyaction]="element.params.onenterkeyaction"
      autocomplete="chrome-off"
      (focus)="OnInitGoogleAutocomplete($event)"
      (keyup)="OnInputKeyUp($event)"
      placeholder="{{ element.params.placeholder }}"
      (blur)="OnInputBlur($event)"
    />
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>
  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'displayGoogleMap'"
  >
    <div
      #mapWrapper
      id="map"
      [ngClass]="map ? 'mobile-map' : 'display-none'"
    ></div>
  </div>

  <div
    class="element-wrapper d-flex justify-content-between"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'inputTextDouble'"
  >
    <div [ngStyle]="{ width: element.params.width }">
      <span class="input-label {{ element.params.labelClass }}"
        >{{ element.params.label
        }}<sup *ngIf="element.params.required" class="sup-required"
          >*</sup
        ></span
      >
      <!-- Input Text -->
      <input
        type="text"
        class="text-input"
        id="{{ element.params.id }}"
        name="{{ element.params.name }}"
        value="{{ element.params.content }}"
        [attr.isValid]="true"
        [attr.isRequired]="element.params.required"
        [attr.isMinLength]="element.params.minLength"
        [attr.isMaxLength]="element.params.maxLength"
        [attr.patternMatch]="element.params.patternMatch"
        [attr.enterkeyaction]="element.params.onenterkeyaction"
        (keyup)="OnInputKeyUp($event)"
        (blur)="OnInputBlur($event)"
      />
      <div
        class="error-field"
        [attr.validatorMessage]="element.params.validatorMessage"
        [attr.validatorMessageField]="element.params.validatorMessage"
      ></div>
    </div>
    <div [ngStyle]="{ width: element.params.width2 }">
      <span class="input-label {{ element.params.labelClass2 }}"
        >{{ element.params.label2
        }}<sup *ngIf="element.params.required2" class="sup-required"
          >*</sup
        ></span
      >
      <!-- Input Text -->
      <input
        type="text"
        class="text-input"
        id="{{ element.params.id2 }}"
        name="{{ element.params.name2 }}"
        value="{{ element.params.content2 }}"
        [attr.isValid]="true"
        [attr.isRequired]="element.params.required2"
        [attr.isMinLength]="element.params.minLength2"
        [attr.isMaxLength]="element.params.maxLength2"
        [attr.patternMatch]="element.params.patternMatch2"
        [attr.enterkeyaction]="element.params.onenterkeyaction2"
        (keyup)="OnInputKeyUp($event)"
        (blur)="OnInputBlur($event)"
      />
      <div
        class="error-field"
        [attr.validatorMessage]="element.params.validatorMessage"
        [attr.validatorMessageField]="element.params.validatorMessage"
      ></div>
    </div>
  </div>

  <div
    [attr.tabGroup]="element.tabGroup"
    [attr.tabKey]="element.tabKey"
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'inputTextTitle'"
  >
    <div class="element-wrapper mt-0">
      <div
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
        class="pb-3 align-items-center d-flex position-relative input-text-button-wrapper mb-3"
      >
        <!-- Status notification -->
        <div
          style="font-size: 16px; font-weight: 700 !important; color: #105f6b"
          class="mr-3"
        >
          {{ "appSpecific.linkLabel" | translate }}
        </div>
        <div class="input-action-status"></div>
        <a
          target="_blank"
          style="color: white !important"
          href="{{ element.params.verificationLink }}"
          class="form-button form-button-1 ml-2 mr-0"
        >
          {{ "common.start" | translate }}
        </a>
        <!-- Button -->
      </div>
    </div>
    <p style="color: #808080; font-weight: 400" class="mb-1">
      {{ "appSpecific.linkLabel2" | translate }}
    </p>
  </div>

  <div
    [attr.tabGroup]="element.tabGroup"
    [attr.tabKey]="element.tabKey"
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'inputTextWithButton'"
  >
    <span class="input-label">{{ element.params.label }}</span>
    <div class="d-flex position-relative input-text-button-wrapper">
      <!-- Status notification -->
      <div class="input-action-status"></div>
      <div *ngIf="element.params.innerLabel" class="text-button-label mr-3">
        {{ element.params.innerLabel }}
      </div>
      <!-- Input Text -->
      <input
        [readonly]="element.params.readonly"
        placeholder="{{ element.params.placeholder }}"
        type="text"
        class="text-input"
        id="{{ element.params.id }}"
        name="{{ element.params.name }}"
        value="{{ element.params.content }}"
      />
      <!-- Button -->
      <button
        [attr.requestKey]="element.params.requestKey"
        [ngClass]="
          element.params.button.class
            ? 'form-button ' + element.params.button.class
            : 'form-button form-button-1'
        "
        *ngIf="element.params.button.display"
        [attr.clickmethod]="element.params.button.action"
        (click)="OnButtonClick($event)"
      >
        {{ element.params.button.content }}
      </button>
    </div>
    <div
      class="button-error-field"
      [ngClass]="element.params.errorMessageClass"
      *ngIf="element.params.errorMessage"
    >
      {{ element.params.errorMessage }}
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'checkbox_group'"
  >
    <!-- Checkbox Group -->
    <div
      class="checkbox-container"
      [id]="element.id ? element.id : ''"
      [attr.isRequired]="element.params.required"
    >
      <h4
        style="font-size: 1em; font-weight: 700"
        class="checkbox-group-title"
        *ngIf="element.params.groupTitle"
      >
        {{ element.params.groupTitle }}
      </h4>
      <div
        class="single-checkbox-wrapper"
        *ngFor="let checkbox of element.params.checkboxes"
      >
        <input
          class="custom-checkbox"
          type="checkbox"
          id="{{ checkbox.params.id }}"
          name="{{ checkbox.params.name }}"
          value="{{ checkbox.params.value }}"
          [attr.clickaction]="checkbox.params.onclick"
          [disabled]="checkbox.params.disabled"
          (click)="OnContentElementClick($event)"
          [attr.data-existingEvidenceKey]="checkbox.params.dataEvidenceKey"
          [checked]="checkbox.params.checked"
        />
        <label class="custom-checkbox-label" for="{{ checkbox.params.id }}">{{
          checkbox.params.label
        }}</label>
        <span class="float-label" *ngIf="checkbox.params.floatlabel">{{
          checkbox.params.floatlabel
        }}</span>
      </div>
    </div>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'checkbox'"
  >
    <input
      class="custom-checkbox"
      type="checkbox"
      id="{{ element.params.id }}"
      name="{{ element.params.name }}"
      value="{{ element.params.value }}"
      [attr.clickaction]="element.params.onclick"
      (click)="OnContentElementClick($event)"
      [attr.changeMethod]="element.params.changemethod"
      (change)="OnChangeSelect($event)"
      [checked]="element.params.checked ? true : false"
      *ngIf="
        element.params.display === undefined || element.params.display === true
      "
      [attr.disabled]="element.params.disabled ? element.params.disabled : null"
    />
    <label
      class="custom-checkbox-label"
      [ngClass]="element.params.class ? element.params.class : ''"
      for="{{ element.params.id }}"
      *ngIf="
        element.params.display === undefined || element.params.display === true
      "
      >{{ element.params.label }}</label
    >
  </div>

  <div
    class="element-wrapper counter-group"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'counter_group'"
  >
    <!-- Checkbox Group -->
    <div
      class="counter-container checkbox-container"
      [id]="element.id ? element.id : ''"
      [attr.isRequired]="element.params.required"
    >
      <h4
        style="font-size: 1em; font-weight: 700"
        class="checkbox-group-title w-100 mb-2 ml-1 mt-2"
        *ngIf="element.params.groupTitle"
      >
        {{ element.params.groupTitle }}
      </h4>
      <div
        class="single-checkbox-wrapper"
        *ngFor="let checkbox of element.params.checkboxes"
        [ngStyle]="{ display: checkbox?.hide ? 'none' : 'block' }"
        [ngClass]="checkbox.class ? checkbox.class + ' ' : ''"
      >
        <div
          [ngClass]="[
            checkbox.params.oneDocumentDisabled ? ' counter-disabled' : '',
            checkbox.params.class ? checkbox.params.class : ''
          ]"
          class="custom-counter"
        >
          <div
            [ngClass]="checkbox.params.counter ? 'd-none' : 'd-flex'"
            class="counter-passive"
          >
            <button
              (click)="increaseCountEmpty($event)"
              class="button-plus-empty"
            >
              <span>+</span>
            </button>
          </div>
          <div
            [ngClass]="checkbox.params.counter ? 'd-flex' : 'd-none'"
            class="counter-active"
          >
            <button
              (click)="
                increaseCountMinus(
                  $event,
                  checkbox.params.counter ? checkbox.params.counter : 0
                )
              "
              class="button-minus"
              [ngClass]="
                (checkbox.params.oneDocumentDisabled === false &&
                  checkbox.params.counter === 1) ||
                checkbox.params.notDisabled
                  ? ''
                  : 'button-inactive'
              "
            >
              <span>-</span>
            </button>
            <input
              id="{{ checkbox.params.id }}"
              [attr.baseValue]="
                checkbox.params.counter ? checkbox.params.counter : 0
              "
              type="text"
              readonly="readonly"
              class="custom-counter-count"
              value="{{
                checkbox.params.counter ? checkbox.params.counter : 0
              }}"
              [ngClass]="checkbox.params.oneDocument ? 'd-none' : ''"
              [attr.verificationDisabled]="
                checkbox.params.oneDocumentDisabled ? 'true' : ''
              "
            />
            <input
              *ngFor="let evidenceKey of checkbox.params.dataEvidenceKeys"
              [attr.data-Oftype]="checkbox.params.id"
              readonly="readonly"
              class="hidden-counter-field"
              value="{{ evidenceKey }}"
              type="hidden"
            />
            <button
              (click)="increaseCountPlus($event)"
              [ngClass]="checkbox.params.oneDocument ? 'd-none' : ''"
              class="button-plus"
            >
              <span>+</span>
            </button>
          </div>
          <div
            (click)="
              checkbox.params.oneDocument ? counterLabelClick($event) : ''
            "
            class="custom-counter-label unselectable"
          >
            {{ checkbox.params.label }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'radio'"
    [attr.isRequired]="element.params.required"
  >
    <!-- Radio Group -->
    <span>
      <input
        [attr.changeMethod]="element.params.changemethod"
        (change)="OnChangeSelect($event)"
        class="custom-radio-button"
        type="radio"
        [attr.checked]="element.params.selected"
        id="{{ element.params.id }}"
        name="{{ element.params.name }}"
        value="{{ element.params.value }}"
      />
      <label class="custom-radio-label" for="{{ element.params.id }}">{{
        element.params.label
      }}</label>
    </span>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'radiobutton_group'"
    [attr.isRequired]="element.params.required"
  >
    <!-- Radio Group -->
    <span *ngFor="let radio of element.params">
      <input
        class="custom-radio-button"
        type="radio"
        id="{{ radio.params.id }}"
        name="{{ radio.params.name }}"
        value="{{ radio.params.value }}"
        (change)="OnChangeSelect($event)"
        [attr.changemethod]="radio.params.changemethod"
        [attr.checked]="radio.params.selected"

      />
      <label class="custom-radio-label" for="{{ radio.params.id }}">{{
        radio.params.label
      }}</label>
      <div class="mt-2 pl-5" style="color: #767676;" *ngIf="radio.params.description">
        <p>
          {{radio.params.description}}
        </p>
      </div>
    </span>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'radiobutton_list'"
  >
    <!-- Radio Group -->
    <div *ngFor="let radio of element.params">
      <input
        *ngIf="radio.params.selected === true"
        class="custom-radio-button"
        type="radio"
        id="{{ radio.params.id }}"
        name="{{ radio.params.name }}"
        value="{{ radio.params.value }}"
        [attr.checked]="true"
      />
      <input
        *ngIf="
          radio.params.selected === undefined || radio.params.selected === false
        "
        class="custom-radio-button"
        type="radio"
        id="{{ radio.params.id }}"
        name="{{ radio.params.name }}"
        value="{{ radio.params.value }}"
      />
      <label class="custom-radio-label" for="{{ radio.params.id }}">{{
        radio.params.label
      }}</label>
    </div>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'radiobutton_document_list'"
  >
    <!-- Radio Group -->
    <div *ngFor="let radio of element.params">
      <input
        *ngIf="radio.params.selected === true"
        class="custom-radio-button"
        type="radio"
        id="{{ radio.params.id }}"
        name="{{ radio.params.name }}"
        value="{{ radio.params.value }}"
        [attr.checked]="true"
      />
      <input
        *ngIf="
          radio.params.selected === undefined || radio.params.selected === false
        "
        class="custom-radio-button"
        type="radio"
        id="{{ radio.params.id }}"
        name="{{ radio.params.name }}"
        value="{{ radio.params.value }}"
      />
      <label class="custom-radio-label" for="{{ radio.params.id }}">{{
        radio.params.label
      }}</label>
      <div
        [ngClass]=""
        class="document-inner-entry ml-4 mb-4"
        *ngIf="radio.params.elementType"
        (click)="
          onReviewEvidenceClick(
            radio.params.value,
            element.requestKey,
            radio.params.documents,
            true,
            true
          )
        "
      >
        <div class="document-upload-entry-image">
          <img
            [src]="
              idVerificationService.returnimgrlByType(
                radio.params.elementTypeUnparsed
              )
            "
          />
        </div>
        <div class="document-upload-entry-detail">
          <h5 class="document-name">{{ radio.params.elementType }}</h5>
        </div>
      </div>
    </div>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'input_date'"
  >
    <!-- Input Date -->
    <span
      [ngClass]="element.params.labelClass ? element.params.labelClass : ''"
      class="input-label"
    >
      {{ element.params.label }}
      <sup class="sup-required">{{
        element.params.required === undefined ||
        (element.params.required != undefined && element.params.required)
          ? "*"
          : ""
      }}</sup>
    </span>
    <app-my-date-picker
      name="{{ element.params.name }}"
      id="{{ element.params.id }}"
      startDate="{{
        element.params.startDate
          ? element.params.startDate
          : element.params.minDate
          ? element.params.minDate
          : ''
      }}"
      minDate="{{ element.params.minDate }}"
      maxDate="{{ element.params.maxDate }}"
      method="setMinEndDate"
      dateInputId="{{ element.params.dateInputId }}"
      readOnly="{{
        element.params.readOnly ? element.params.readOnly : undefined
      }}"
      isReqiured="{{
        element.params.required === undefined ||
        (element.params.required != undefined && element.params.required)
          ? 'true'
          : 'false'
      }}"
      (dateChange)="parseDateContentReturn($event)"
      value="{{ element.params.content }}"
      [(ngModel)]="element.params.name"
    ></app-my-date-picker>
    <!-- Method -->
    <div 
    *ngIf="element?.params?.button?.display"  
    [ngClass]="element.params.button.class"
    [attr.clickmethod]="element.params.button.action"
    [attr.inputId]="element.params.name"
    [attr.dob]="element.params.button.dateOfBirth"
    (click)="OnButtonClick($event)"
    >
    {{element.params.button.content}}
  </div>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
    <div
      class="error-field-wrong-format d-none"
      [attr.validatorMessage]="
        '*the date must be in the correct format: dd.mm.yyyy '
      "
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'textarea'"
  >
    <!-- Textarea (autogrow) -->
    <span
      class="input-label {{
        element.params.labelClass ? element.params.labelClass : ''
      }} "
      *ngIf="element.params.label"
    >
      {{ element.params.label }}
    </span>
    <textarea
      autosize
      class="text-input-div"
      [attr.isRequired]="element.params.required"
      name="{{ element.params.name }}"
      (focus)="OnInputFocus($event)"
      (blur)="OnInputBlur($event)"
    ></textarea>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'simple_textarea'"
  >
    <!-- Textarea (autogrow) -->
    <span class="input-label" *ngIf="element.params.label">
      {{ element.params.label }}
    </span>
    <textarea
      autosize
      [attr.isRequired]="element.params.required"
      class="text-input-div"
      name="{{ element.params.name }}"
      (focus)="OnInputFocus($event)"
      (blur)="OnInputBlur($event)"
    ></textarea>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'unordered_list'"
  >
    <div class="loader-overlay" *ngIf="element.displayLoader"></div>
    <ul class="custom-list request-list {{ element.isOrange ? 'orange' : '' }}">
      <li *ngFor="let item of element.params">
        {{ item.content }}
        <div class="text-body text-big my-1">{{ item.additional_content }}</div>
      </li>
    </ul>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'unordered_list_icons'"
  >
    <div class="loader-overlay" *ngIf="element.displayLoader"></div>
    <ul [ngClass]="element.listClass">
      <li *ngFor="let item of element.params" [ngClass]="item.li_class">
        {{ item.content }}
        <div class="text-body text-big my-1">{{ item.additional_content }}</div>
      </li>
    </ul>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'hr'"
  >
    <hr
      class="{{ element.params.class }}"
      *ngIf="
        element.params.display === undefined || element.params.display === true
      "
    />
  </div>

  <div
    [ngClass]="{
      'element-wrapper': element.display,
      'h-0': element.display === false
    }"
    *ngSwitchCase="'contentBuilder'"
  >
    <!-- FOR DISPLAY PURPOSES ONLY -->
    <app-content-builder
      [contentDOM]="element.params"
      *ngIf="element.display !== false"
    ></app-content-builder>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'reveal_on_click'"
  >
    <a
      href="#"
      [attr.toggleTarget]="element.params.id"
      class="result-a"
      (click)="OnToggleContentFields($event)"
      >{{ element.params.content }}</a
    >
    <span id="{{ element.params.id }}" class="d-none-unimportant">
      <!-- FOR DISPLAY PURPOSES ONLY -->
      <app-content-elements
        [contentDOM]="element.params.contentElements"
      ></app-content-elements>
    </span>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'readmore_content'"
  >
    <span>
      {{ element.params.excerpt }}
    </span>
    <a
      href="#"
      [attr.toggleTarget]="element.params.id"
      class="result-a"
      (click)="OnToggleExcerptFields($event)"
      >{{ element.params.readMoreContent }}</a
    >
    <span class="d-none-unimportant">
      {{ element.params.content }}
    </span>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'action_link'"
  >
    <a
      href="#"
      [attr.clickmethod]="element.params.action"
      class="end-integrity-check"
      (click)="OnButtonClick($event)"
      >{{ element.params.content }}</a
    >
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'company_selector'"
  >
    <span id="{{ element.params.id }}">
      <!-- COMPANY AUTOSUGGEST SEARCH -->
      <!-- <span class="input-label">{{ element.params.content }}</span>
      <input class="form-control" type="text" value=''> -->
      <app-company-search
        [kvkResults]="kvkResults"
        (selectedCompany)="OnSelectedCompanyChange($event)"
        (searchTerm)="onSearchTermChanged($event)"
        (displayLoader)="OnDisplayLoader($event)"
      ></app-company-search>
    </span>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'anchor'"
  >
    <a
      href="{{ element.params.url }}"
      class="{{ element.params.external ? 'external-link' : '' }}"
      [target]="element.params.open_new_tab ? '_blank' : '_self'"
      >{{ element.params.content }}</a
    >
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'actionAnchor'"
  >
    <a
      href="#"
      class="{{ element.params.external ? 'external-link' : '' }}"
      [attr.clickmethod]="element.params.clickFunction"
      (click)="OnButtonClick($event)"
    >
      {{ element.params.content }}
    </a>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'requestsHistory'"
  >
    <hr
      class="{{ element.params.hr_class }}"
      *ngIf="
        element.params.elements !== undefined &&
        element.params.elements.length > 0
      "
    />
    <h4
      class="result-inner-title"
      *ngIf="
        element.params.elements !== undefined &&
        element.params.elements.length > 0
      "
    >
      {{ element.params.title }}
    </h4>
    <div class="search-history-entries">
      <!-- ENTRY 1 NO RISK-->
      <div
        class="search-history-entry"
        *ngFor="let item of element.params.elements"
      >
        <p class="entry-date" *ngIf="item.record.finishedOn">
          {{ item.record.finishedOn | date: "dd.MM.yyyy" }}
        </p>
        <p
          class="entry-date text-uppercase font-italic"
          *ngIf="!item.record.finishedOn"
        >
          PENDING
        </p>
        <span *ngFor="let question of item.record.questions">
          <div class="entry-title">
            {{ question.questionText }}
          </div>
          <p class="entry-comment" *ngIf="question.answer">
            {{ question.answer.record }}
          </p>
          <p
            class="entry-comment font-weight-bold"
            *ngIf="
              item.record.status === 'rejected' ||
              item.record.status === 'rejected_seen'
            "
          >
            REQUEST DENIED
          </p>
        </span>
        <div
          [ngClass]="[
            'entry-marker',
            item.record.status !== 'rejected' &&
            item.record.status !== 'rejected_seen'
              ? item.record.status !== 'answered' &&
                item.record.status !== 'answered_seen'
                ? 'default-entry-marker'
                : 'no-risk-entry-marker'
              : 'risk-entry-marker'
          ]"
        ></div>
      </div>
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'select'"
  >
    <span
      class="input-label d-flex {{
        element.params.labelClass ? element.params.labelClass : ''
      }}"
    >
      {{ element.params.label }}
      <app-tooltip
        *ngIf="element.params.tooltip"
        [tooltipText]="element.params.tooltip"
        [icon]="element.params.tooltipIcon"
        class="d-flex"
      ></app-tooltip>
      <sup class="sup-required sup-required-alternate">{{
        element.params.required ? "*" : ""
      }}</sup>
    </span>
    <span class="select-wrapper">
      <select
        class="select-input"
        id="{{ element.params.id }}"
        (change)="OnChangeSelect($event)"
        [disabled]="element.params.readOnly"
        [attr.changemethod]="element.params.changemethod"
        [attr.isRequired]="element.params.required"
        [value]="
          element.params.optionselected ? element.params.optionselected : -1
        "
      >
        <option
          [selected]="element.params.optionselected === option.key"
          value="{{ option.key }}"
          *ngFor="let option of element.params.options"
        >
          {{ option.name }}
        </option>
      </select>
    </span>
    <div
      *ngIf="element.params.additionalInfo"
      class="text-right font-italic mt-2"
    >
      {{ element.params.additionalInfo }}
    </div>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'selectCountryAv'"
  >
    <span
      class="input-label {{
        element.params.labelClass ? element.params.labelClass : ''
      }}"
    >
      {{ element.params.label }}<sup class="sup-required">*</sup></span
    >
    <span class="select-wrapper">
      <select
        class="select-input"
        id="{{ element.params.id }}"
        (change)="OnChangeSelect($event)"
        [disabled]="element.params.readOnly"
        [attr.changemethod]="element.params.changemethod"
        [attr.isRequired]="element.params.required"
        [value]="
          element.params.optionselected ? element.params.optionselected : -1
        "
      >
        <option
          value="{{ option.value }}"
          *ngFor="let option of element.params.options"
        >
          {{ option.label }}
        </option>
      </select>
    </span>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'range'"
  >
    <span
      class="input-label {{
        element.params.labelClass ? element.params.labelClass : ''
      }}"
      >{{ element.params.label }}</span
    >
    <div class="range-wrapper">
      <input
        type="range"
        [attr.min]="element.params.min"
        [attr.max]="element.params.max"
        class="range-input w-100"
        id="{{ element.params.id }}"
        (change)="OnChangeSelect($event)"
        (input)="OnChangeSelect($event)"
        [disabled]="element.params.readOnly"
        [attr.changemethod]="element.params.changemethod"
        [attr.value]="
          element.params.defaultValue ? element.params.defaultValue : -1
        "
      />
      <div *ngIf="element.params.labels" class="range-labels unselectable">
        <div class="range-label" *ngFor="let label of element.params.labels">
          {{ label }}
        </div>
      </div>
      <div class="range-input-indicator">
        {{ element.params.defaultIndicator }}
      </div>
    </div>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'contributors'"
  >
    <!-- PARAGRAPH WITH ICON -->
    <div
      class="d-flex position-relative"
      *ngIf="element.params.display === true"
    >
      <div
        class="d-flex absolute-spinner-wrapper"
        *ngIf="element.params.displayOverlayLoader"
      >
        <img
          src="/assets/images/spinner-green.svg"
          width="32px"
          height="32px"
          class="d-flex align-self-center mx-auto"
        />
      </div>
      <table class="w-100">
        <thead>
          <tr>
            <td>Contributors</td>
            <td width="100px"></td>
            <td width="100px">Role</td>
            <td width="155px">Actions</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="element.params.displayLoader">
            <td colspan="4" class="text-center">
              <img
                src="/assets/images/spinner-gray.svg"
                width="32px"
                height="32px"
                class="align-self-center p-3"
              />
            </td>
          </tr>
          <!-- <div *ngFor="let contributor of element.params.contributors">
              <div>{{contributor.email}}</div>
            </div> -->
          <tr *ngFor="let contributor of element.params.contributors">
            <td>{{ contributor.email }}</td>
            <td>
              <span
                class="new-contributor"
                *ngIf="element.params.highlightEmail === contributor.email"
              >
                Just added
              </span>
            </td>
            <td>{{ contributor.role }}</td>
            <td>
              <a
                href="#"
                id="{{ contributor.id }}"
                [attr.clickmethod]="'OnContributorMakeOwner'"
                (click)="OnButtonClick($event)"
                *ngIf="contributor.makeOwner && !contributor.permissionPending"
                >Make owner</a
              >
              <span
                *ngIf="contributor.makeOwner && !contributor.permissionPending"
              >
                |
              </span>
              <a
                href="#"
                id="{{ contributor.id }}"
                [attr.clickmethod]="'OnContributorRemove'"
                (click)="OnButtonClick($event)"
                *ngIf="contributor.makeOwner && !contributor.permissionPending"
                >Remove</a
              >
              <span
                class="font-italic"
                *ngIf="!contributor.makeOwner && !contributor.permissionPending"
                >none</span
              >
              <a
                href="#"
                id="{{ contributor.id }}"
                title="User has accepted the invitation, but you need to authorize him to gain access to the project"
                [attr.clickmethod]="'OnContributorAuthorize'"
                (click)="OnButtonClick($event)"
                *ngIf="contributor.permissionPending"
                >Authorize</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'invitations'"
  >
    <!-- PARAGRAPH WITH ICON -->
    <div
      class="d-flex position-relative"
      *ngIf="element.params.display === true"
    >
      <table class="w-100">
        <thead>
          <tr>
            <td>Invitee</td>
            <td width="100px">Status</td>
            <td width="100px">Role</td>
            <td width="155px">Actions</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="element.params.displayLoader">
            <td colspan="3" class="text-center">
              <img
                src="/assets/images/spinner-gray.svg"
                width="32px"
                height="32px"
                class="align-self-center p-3"
              />
            </td>
          </tr>
          <!-- <div *ngFor="let contributor of element.params.contributors">
                <div>{{contributor.email}}</div>
              </div> -->
          <tr *ngFor="let invitee of element.params.invitees">
            <td>{{ invitee.email }}</td>
            <td>{{ invitee.status }}</td>
            <td>{{ invitee.role }}</td>
            <td [ngSwitch]="invitee.status">
              <a
                href="#"
                *ngSwitchCase="'expired'"
                id="{{ invitee.id }}"
                [attr.clickmethod]="'OnReInvite'"
                (click)="OnButtonClick($event)"
                >Re-invite</a
              >
              <a
                href="#"
                *ngSwitchCase="'pending'"
                [attr.url]="invitee.url"
                [attr.clickmethod]="'copyInvitationLink'"
                (click)="OnButtonClick($event)"
                >Copy link</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'inputColorSelector'"
  >
    <span class="input-label font-weight-bold">{{ element.params.label }}</span>
    <div class="d-flex">
      <input
        class="colorPicker"
        [id]="element.params.id"
        [value]="element.params.color"
        [ngClass]="element.params.textClass ? element.params.textClass : ''"
        [style.background]="element.params.color"
        (colorPickerChange)="OnColorPickerChange($event)"
        [cpOutputFormat]="hex"
        [cpPresetLabel]="''"
        [cpPresetColors]="
          element.params.standarColors ? element.params.standarColors : []
        "
        [cpPresetColorsClass]="'standar-colors'"
        [cpMaxPresetColorsLength]="5"
        [cpOKButton]="true"
        [cpOKButtonText]="'Save'"
        [cpOKButtonClass]="'form-button form-button-6 cp-save-button cp-btn'"
        [cpCancelButton]="true"
        [cpCancelButtonText]="'Cancel'"
        [cpCancelButtonClass]="
          'form-button form-button-6 cp-cancel-button cp-btn'
        "
        [(colorPicker)]="element.params.color"
      />
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'privacyUrlInput'"
  >
    <span class="input-label font-weight-bold">{{ element.params.label }}</span>
    <div class="d-flex">
      <input
        class="privacyUrlInput"
        [id]="element.params.id"
        [ngClass]="element.params.textClass ? element.params.textClass : ''"
        [value]="element.params.privacyUrl"
      />
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'imageDragDropUpload'"
  >
    <span class="input-label font-weight-bold">{{ element.params.label }}</span>
    <div class="d-flex flex-column">
      <div
        class="d-flex position-relative dropzone p-0"
        (dragenter)="OnImageUploadDragEnter($event)"
        (dragover)="OnImageUploadDragOver($event)"
        (drop)="OnImageUploadDrop($event)"
        (click)="OnImageUploadClick($event)"
        [attr.data-target]="element.params.id"
        [attr.uploadmethod]="element.params.uploadmethod"
      >
        <div
          class="w-100 h-100 position-absolute img-preview"
          [ngStyle]="{
            'background-image':
              'url(' + (!image ? element.params.image : image) + ')'
          }"
          *ngIf="image || element.params.image"
        ></div>
        <div
          class="align-self-center text-center mx-auto image-upload-instructions"
          [attr.data-target]="element.params.id"
          (click)="OnImageUploadClick($event)"
          *ngIf="!image && !element.params.image"
        >
          Click or Drag and drop to select a logo!
          <div>
            (min 300px x 200 px, max 1MB, transparent PNG for best results)
          </div>
        </div>
      </div>
      <div class="d-flex w-100">
        <div class="d-flex w-50 upload-status"></div>
        <div class="d-flex w-50">
          <button
            class="form-button form-button-1 ml-auto mt-2"
            *ngIf="image || element.params.image"
            [attr.data-target]="element.params.id"
            [attr.uploadmethod]="element.params.uploadmethod"
            (click)="OnImageUploadClick($event)"
          >
            Change image
          </button>
        </div>
      </div>
      <input
        type="file"
        id="{{ element.params.id }}"
        multiple=""
        accept="image/*"
        style="display: none"
        [attr.uploadmethod]="element.params.uploadmethod"
        (change)="OnImageUploadSelected($event)"
      />
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'projectCustomMessage'"
  >
    <span class="input-label font-weight-bold">{{ element.params.label }}</span>
    <div class="d-flex flex-column">
      <textarea
        id="{{ element.params.id }}"
        class="d-flex position-relative tempTextInput"
        [ngClass]="element.params.textClass ? element.params.textClass : ''"
        [ngStyle]="{ 'background-color': element.params.backgroundColor }"
        (input)="onPersonalizedMessageTextChange($event)"
        >{{ element.params.consentMessage }}</textarea
      >
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'projectPreview'"
  >
    <div
      class="preview-window d-flex flex-column p-2 p-sm-3 mx-auto tealish-gradient-bg"
      [ngStyle]="{
        'background-color':
          element.params && element.params.projectColor
            ? element.params.projectColor
            : ''
      }"
    >
      <div class="header-top d-flex justify-content-between py-3">
        <div class="navbar p-0">
          <img
            class="finos-logo-img"
            src="{{
              element.params && element.params.logo
                ? element.params.logo
                : 'assets/images/meshId_logo.svg'
            }}"
          />
        </div>
      </div>
      <div class="d-flex flex-column flex-fill justify-content-center">
        <div
          class="unauth-message text-center mx-auto mt-3"
          [ngClass]="element.params.textClass ? element.params.textClass : ''"
        >
          <span id="projectCustomMessageDisplay">{{
            element.params.consentMessage
          }}</span>
          <div class="d-flex mt-5">
            <button
              class="form-button form-button-clear-bg mx-auto"
              [ngClass]="
                element.params.textClass ? element.params.textClass : ''
              "
            >
              Acknowledge
            </button>
          </div>
          <div class="d-flex justify-content-center mt-4 font-weight-bold">
            <div class="d-inline">
              For more information please see our <u>privacy policy</u>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="element-wrapper d-flex"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'indentWithBorder'"
  >
    <app-content-elements
      (contentReturn)="parseContentReturn($event)"
      [contentDOM]="element.params.elements"
      (displayLoader)="OnDisplayLoader($event)"
    ></app-content-elements>
  </div>

  <div
    [attr.id]="element.params.id"
    class="error-field custom"
    *ngSwitchCase="'custom_error_field'"
  ></div>

  <div
    class="element-wrapper no-margin"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'documentListBuilder'"
  >
    <div class="document-category-wrapper">
      <div
        *ngFor="let documentCategory of element.params.documentCategories"
        class="document-category"
      >
        <div class="input-label-large d-flex justify-content-between">
          <h4>
            {{ documentCategory.documentCategoryName }}
          </h4>
          <div
            class="draggable-buttons-wrapper d-flex justify-content-center align-items-center"
          >
            <a class="delete-document-section-icon">
              <img src="/assets/images/close_icon_grey.png" />
            </a>
            <a class="hamburger-icon-drag">
              <div></div>
              <div></div>
              <div></div>
            </a>
          </div>
        </div>
        <div class="document-container-wrapper">
          <div
            *ngFor="let currentDocument of documentCategory.currentDocuments"
            class="documents-container input-transparent"
          >
            <span class="input-label"> <span>Document</span></span>
            <div class="d-flex select-draggable-wrapper">
              <span class="select-wrapper">
                <select
                  class="select-input"
                  (change)="OnChangeSelect($event)"
                  [attr.changemethod]="element.params.changemethod"
                  [attr.isRequired]="element.params.required"
                  [value]="currentDocument.key"
                >
                  <option
                    *ngFor="let option of documentCategory.documentOptions"
                    value="{{ option.key }}"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </span>
              <div
                class="draggable-buttons-wrapper d-flex justify-content-center align-items-center"
              >
                <a class="delete-document-icon">
                  <img src="/assets/images/close_icon_grey.png" />
                </a>
                <a class="hamburger-icon-drag">
                  <div></div>
                  <div></div>
                  <div></div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <button class="add-document-button mt-4 form-button form-button-type-2">
          <span class="button-icon">+</span> Add document
        </button>
        <hr />
      </div>
    </div>
  </div>

  <div
    class="element-wrapper d-flex"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'divided_section'"
  >
    <div
      class="left-section w-50"
      [ngClass]="element.left.params.class ? element.left.params.class : ''"
    >
      <app-content-elements
        (contentReturn)="parseContentReturn($event)"
        [contentDOM]="element.left.params.elements"
        (displayLoader)="OnDisplayLoader($event)"
      ></app-content-elements>
    </div>
    <div
      class="right-section w-50"
      [ngClass]="element.right.params.class ? element.right.params.class : ''"
    >
      <app-content-elements
        (contentReturn)="parseContentReturn($event)"
        [contentDOM]="element.right.params.elements"
        (displayLoader)="OnDisplayLoader($event)"
      ></app-content-elements>
    </div>
  </div>

  <div
    class="element-wrapper mt-0 documentUploaderOwner"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'documentUploaderOwner'"
  >
    <a class="document-category-main position-relative evidence-sub">
      <h3 class="basic-field-name">
        <span *ngIf="element.params.RProle" class="role-span">
          {{
            element.params.RProle
              ? parseRelatedPartyRole(element.params.RProle)
              : ""
          }}
        </span>
        <div>{{ element.params.ownerName }}</div>
      </h3>
      <div
        *ngIf="element.params.loadingFromBe && element.isActive"
        class="position-absolute document-category-arrow"
      >
        <img
          src="/assets/images/spinner-green.svg"
          width="30px"
          height="30px"
          class="my-auto mx-auto"
        />
      </div>
      <!-- TODO: enable click and expand investor/related party details and make them available for edit -->
      <!-- <div class="position-absolute document-category-arrow">
    <img (click)="OnExpandSide($event,element.params.dataActivates,element.params.evidenceKey)" src="assets/images/collapse_document_upload.png">
  </div> -->
    </a>
  </div>

  <div
    *ngSwitchCase="'documentUploaderMain'"
    [attr.data-evidenceKey]="element.params.evidenceKey"
    class="element-wrapper mt-0 document-category-main-all position-relative rz"
    [ngClass]="element.class ? element.class : ''"
  >
    <a
      *ngIf="!element?.completedByServiceProvider && (!element.params.replaceFlow || element.isActive)"
      [attr.data-evidenceOrder]="element.params.order"
      [attr.data-activates]="element.params.dataActivates"
      [attr.data-evidenceKey]="element.params.evidenceKey"
      [attr.data-requestKey]="
        element.params.originRequest
          ? element.params.originRequest
          : element.params.requestKey
      "
      [ngClass]="{
        'category-active': element.isActive,
        'evidence-sub': element.wrapperClass
      }"
      [attr.data-flowType]="element.params.flowType"
      class="document-category-main position-relative"
    >
      <h3
        [ngClass]="element.params.isBasicFields ? 'basic-field-name' : ''"
        class="d-flex align-items-top flex-column"
      >
        <span class="role-span" style="max-width: 200px">
          {{
            element.params.RProle
              ? parseRelatedPartyRole(element.params.RProle)
              : ""
          }}
        </span>
        <div>{{ element.params.document_category_name }}</div>
      </h3>
      <div
        *ngIf="!element.params.loadingFromBe"
        class="position-absolute document-category-arrow"
      >
        <div class="d-flex justify-content-center align-items-center">
          <div
            *ngIf="
              element.params.reviewState === false &&
              !element.params.isDigitalVerification
            "
            class="d-flex justify-content-center align-items-center"
          >
            <span class="clarification-text">{{
              "common.done" | translate
            }}</span>
            <img
              src="assets/images/check.svg"
              class="mr-2"
              width="16px"
              height="16px"
            />
          </div>
          <div
            *ngIf="
              element.params.reviewState === false &&
              element.params.isDigitalVerification
            "
            class="d-flex justify-content-center align-items-center"
          >
            <span class="clarification-text">{{
              "common.pending" | translate
            }}</span>
            <img
              src="assets/images/icon-triangle-blue.svg"
              id="collapse_document_image_icon"
              class="mr-2"
              width="16px"
              height="14px"
            />
          </div>
          <div
            *ngIf="
              element.params.reviewState && element.params.reviewState === true
            "
            class="d-flex justify-content-center align-items-center"
          >
            <span class="clarification-text">{{
              "common.toDo" | translate
            }}</span>
            <img
              src="assets/images/icon-triangle-orange.svg"
              id="collapse_document_image_icon"
              class="mr-2"
              width="16px"
              height="14px"
            />
          </div>
          <img
            *ngIf="
              element.params.notSharable === true ||
              element.params.notSharable === undefined
            "
            width="11px"
            height="18px"
            src="assets/images/collapse_document_upload.png"
          />
          <img
            *ngIf="element.params.notSharable === false"
            src="assets/images/check.svg"
          />
        </div>
      </div>
      <div
        (click)="
          OnExpandSide(
            $event,
            element.params.dataActivates,
            element.params.evidenceKey,
            element.params.evidenceTypes
          )
        "
        style="z-index: 100; top: 0; left: 0"
        class="clickable-element h-100 w-100 position-absolute"
      ></div>
      <div
        *ngIf="element.params.loadingFromBe && element.isActive"
        class="position-absolute document-category-arrow"
      >
        <img
          src="/assets/images/spinner-green.svg"
          width="30px"
          height="30px"
          class="my-auto mx-auto"
        />
      </div>
    </a>
  </div>

  <div *ngSwitchCase="'documentUploaderMainSharing'">
    <div
      [ngClass]="!element.params.evidencePresent ? 'no-evidence' : ''"
      class="sharing-form-steps"
    >
      <div class="form-steps-wrapper">
        <div
          *ngFor="let step of element.params.steps"
          class="form-step {{ step.class }}"
        >
          <div class="form-step-order">
            {{ step.count }}
          </div>
          <div class="form-step-title">
            {{ step.title }}
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="element.params.evidencePresent"
      class="document-category-main document-category-main-sharing"
    >
      <div
        *ngFor="let evidenceType of element.params.evidenceTypes"
        class="cursor-pointer d-flex-low"
        [ngClass]="{
          'non-interactive' : evidenceType.sharedOn,
          'd-none': (evidenceType.sharedOn || evidenceType.subjectIsOwner ||  evidenceType.hideFromList || evidenceType.completedByServiceProvider),
          'document-label': evidenceType.justLabel
        }"
        [attr.data-dvType]="
          evidenceType.originalEvidenceKey
            ? evidenceType.originalEvidenceKey
            : evidenceType.key
        "
        (click)="
          documentUpdateFunction(
            $event,
            evidenceType.originalEvidenceTypes
              ? evidenceType.originalEvidenceTypes
              : element.params.originalEvidenceTypes,
            evidenceType,
            evidenceType.originalRequestKey
              ? evidenceType.originalRequestKey
              : element.params.requestKey,
            evidenceType.key,
            element.params.relatedPartyObject
          )
        "
      >
        <img
          *ngIf="!evidenceType.notSharable && !evidenceType.justLabel"
          src="assets/images/check.svg"
        />
        <img
          *ngIf="evidenceType.notSharable && !evidenceType.justLabel"
          src="assets/images/icon-triangle-orange.svg"
        />
        <img
          class="loading-image"
          *ngIf="evidenceType.loadingRP"
          src="assets/images/loader-small.gif"
        />
        <h3 *ngIf="!evidenceType.justLabel">
          {{
            evidenceType.customEvidenceType
              ? evidenceType.customEvidenceType
              : evidenceType.verificationType
              ? helperService.translateVerificationType(
                  evidenceType.verificationType
                )
              : parseBackendName(evidenceType.type)
          }}
          {{evidenceType.sharedOn}}
        </h3>
        <h3
          [ngClass]="evidenceType.loadingRP ? 'loading-text' : ''"
          *ngIf="evidenceType.justLabel"
        >
          {{ evidenceType.type }}
        </h3>
      </div>
    </div>
  </div>

  <!-- left side to generating verification -->
  <div
    *ngSwitchCase="'documentUploaderMainVerification'"
    class="element-wrapper mt-0 document-category-main-all position-relative"
    [ngClass]="element.class ? element.class : ''"
  >
    <a
      class="document-category-main position-relative"
      [ngClass]="{ 'category-active': element.isActive }"
      [attr.data-evidenceOrder]="element.params.order"
      [attr.data-activates]="element.params.dataActivates"
      [attr.data-flowType]="element.params.flowType"
    >
      <h3
        [ngClass]="element.params.isCompletedt ? 'text-blured' : ''"
        class="d-flex align-items-center unselectable"
      >
        {{ element.params.document_category_name }}
      </h3>
      <div
        *ngIf="!element.params.loadingFromBe && !element.params.isCompletedt"
        class="position-absolute document-category-arrow"
      >
        <img src="assets/images/collapse_document_upload.png" />
      </div>
      <div
        *ngIf="!element.params.loadingFromBe && element.params.isCompletedt"
        class="position-absolute document-category-arrow"
      >
        <img src="assets/images/check.svg" />
      </div>
      <a
        [ngClass]="
          element.params.isCompletedt ? 'verification-main-disabled' : ''
        "
        [attr.clickmethod]="element.params.clickFunction"
        (click)="OnButtonClick($event)"
        style="z-index: 100; top: 0; left: 0"
        class="clickable-element h-100 w-100 position-absolute"
      >
      </a>
      <div
        *ngIf="element.params.loadingFromBe && element.isActive"
        class="position-absolute document-category-arrow"
      >
        <img
          src="/assets/images/spinner-green.svg"
          width="30px"
          height="30px"
          class="my-auto mx-auto"
        />
      </div>
    </a>
  </div>

  <!-- UPLOADER SIDE VERIFICATION-->
  <div
    *ngSwitchCase="'documentUploaderSideVerification'"
    class="documentUploaderSide"
    [ngClass]="[
      element.class ? element.class : '',
      element.isActive ? 'evidence-is-active' : '',
      element.params.loadingFromBe ? 'loading-from-be' : ''
    ]"
  >
    <form *ngIf="element.isActive" class="form-container">
      <div class="document-upload-side-overlay">
        <img
          src="/assets/images/spinner-green.svg"
          width="65px"
          height="65px"
          class="my-auto mx-auto"
        />
      </div>
      <div class="mt-2 input-label-large">
        <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
        <h4>Configure the verification</h4>
      </div>
      <div class="element-wrapper">
        <div class="input-fields-wrapper">
          <!-- INPUT FIELDS -->
          <div
            *ngIf="element.params.showCountriesAndTypes"
            class="evidence-fields type-country-wrapper"
          >
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold"
                >Accept documents from</span
              >
              <span class="select-wrapper">
                <select
                  class="select-input"
                  id="IDVerificationCountry"
                  name="IDVerificationCountry"
                  (change)="OnChangeSelect($event)"
                  [attr.changemethod]="'onChangeTypeCountry'"
                  [attr.isRequired]="spCompletingDocument"
                  [value]="'emptySelect'"
                >
                  <option
                    value="{{ option.countryCode }}"
                    *ngFor="let option of element.params.countryList"
                  >
                    {{ option.countryName }}
                  </option>
                </select>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
          <div
            *ngIf="element.params.showCountriesAndTypes"
            class="evidence-fields verification-type-wrapper"
          >
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold"
                >Accept documents of type</span
              >
              <span class="select-wrapper">
                <select
                  class="select-input"
                  id="IDVerificationType"
                  name="IDVerificationType"
                  (change)="OnChangeSelect($event)"
                  [attr.isRequired]="'false'"
                  [value]="'emptySelect'"
                >
                  <option
                    value="{{ option.key }}"
                    *ngFor="let option of element.params.idTypeList"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold">Language</span>
              <span class="select-wrapper">
                <select
                  class="select-input"
                  id="Locale"
                  name="Locale"
                  (change)="OnChangeSelect($event)"
                  [attr.isRequired]="'false'"
                  [(ngModel)]="element.params.defaultLocale"
                >
                  <option
                    value="{{ option.key }}"
                    *ngFor="let option of element.params.localeList"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold"
                >Capture methods allowed</span
              >
              <span class="select-wrapper">
                <select
                  class="select-input"
                  id="WorkflowID"
                  name="WorkflowID"
                  (change)="OnChangeSelect($event)"
                  [attr.isRequired]="'false'"
                  [value]="'201'"
                >
                  <option
                    value="{{ option.key }}"
                    *ngFor="let option of element.params.workflowList"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper mt-2 input-transparent">
              <input
                class="custom-checkbox"
                type="checkbox"
                id="idVerificationAlternate"
                name="idVerificationAlternateN"
                value="id alternate"
                [checked]="false"
              />
              <label
                class="custom-checkbox-label"
                for="idVerificationAlternate"
              >
                Verify document only</label
              >
            </div>
          </div>
        </div>
        <!-- BUTTONS -->
        <div class="d-flex justify-content-between align-items-center">
          <button
            [attr.clickmethod]="'idvGenerationStepOne'"
            (click)="OnButtonClick($event)"
            type="submit"
            class="form-button form-button-1 mt-4 evidence-submit-button"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
  <!-- UPLOADER SIDE ADDRESS VERIFICATION-->
  <div
    *ngSwitchCase="'documentUploaderSideAVerification'"
    class="documentUploaderSide"
    [ngClass]="[
      element.class ? element.class : '',
      element.isActive ? 'evidence-is-active' : '',
      element.params.loadingFromBe ? 'loading-from-be' : ''
    ]"
  >
    <app-idv-uploader-side-address-verification
      [element]="element"
      [inputHidden]="inputHidden"
      (contentReturn)="onContentReturnFromElements($event)"
    ></app-idv-uploader-side-address-verification>
  </div>
  <!-- UPLOADER SIDE CONTRACT -->
  <div
    *ngSwitchCase="'documentUploaderSideContract'"
    class="documentUploaderSide"
    [ngClass]="[
      element.class ? element.class : '',
      element.isActive ? 'evidence-is-active' : '',
      element.params.loadingFromBe ? 'loading-from-be' : ''
    ]"
  >
    <form *ngIf="element.isActive" class="form-container">
      <div class="document-upload-side-overlay">
        <img
          src="/assets/images/spinner-green.svg"
          width="65px"
          height="65px"
          class="my-auto mx-auto"
        />
      </div>
      <div class="mt-2 input-label-large">
        <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
        <h4>Configure the verification</h4>
      </div>
      <div class="element-wrapper">
        <div class="input-fields-wrapper">
          <!-- INPUT FIELDS -->
          <div class="evidence-fields">
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold"
                >Choose a smart form</span
              >
              <span class="select-wrapper">
                <select
                  class="select-input"
                  [disabled]="element.params.disabled"
                  id="ContractTemplate"
                  name="ContractTemplate"
                  (change)="OnChangeSelect($event)"
                  [attr.isRequired]="'false'"
                  [value]="element.params.contractValue"
                >
                  <option
                    value="{{ option.key }}"
                    *ngFor="let option of element.params.contractTemplates"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </span>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
              <div class="single-checkbox-wrapper no-border pb-0">
                <input
                  class="custom-checkbox"
                  type="checkbox"
                  id="hideConfirmation"
                  name="hideConfirmation"
                  />
                <label for="hideConfirmation" class="custom-checkbox-label">Hide all mention of electronic signing on document completion</label>
              </div>
              <div class="single-checkbox-wrapper no-border">
                <input
                  class="custom-checkbox"
                  type="checkbox"
                  id="hideSignedDocumentCopy"
                  name="hideSignedDocumentCopy"
                  />
                <label for="hideSignedDocumentCopy" class="custom-checkbox-label">Hide the pop-up offering an option to receive a signed copy of the completed document</label>
              </div>
            </div>
          </div>
        </div>
        <!-- BUTTONS -->
        <div class="d-flex justify-content-between align-items-center">
          <button
            [attr.clickmethod]="'idvGenerationStepThree'"
            (click)="OnButtonClick($event)"
            type="submit"
            class="form-button form-button-1 mt-4 evidence-submit-button"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
  <!-- UPLOADER SIDE APS -->
  <div
    *ngSwitchCase="'documentUploaderSideAPS'"
    class="documentUploaderSide"
    [ngClass]="[
      element.class ? element.class : '',
      element.isActive ? 'evidence-is-active' : '',
      element.params.loadingFromBe ? 'loading-from-be' : ''
    ]"
  >
    <form *ngIf="element.isActive" class="form-container">
      <div class="document-upload-side-overlay">
        <img
          src="/assets/images/spinner-green.svg"
          width="65px"
          height="65px"
          class="my-auto mx-auto"
        />
      </div>
      <div class="mt-2 input-label-large">
        <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
        <h4>Configure the verification</h4>
      </div>
      <div class="element-wrapper">
        <div class="input-fields-wrapper">
          <!-- INPUT FIELDS -->
          <div class="evidence-fields fuziness-level-wrapper">
            <div class="element-wrapper input-transparent">
              <span class="input-label font-weight-bold">Screening scope</span>
              <div class="range-wrapper">
                <input
                  type="range"
                  [attr.min]="1"
                  [attr.max]="10"
                  class="range-input w-100"
                  id="FuzinessLevel"
                  (change)="OnChangeSelect($event)"
                  [attr.changemethod]="'onChangeScreeningRange'"
                  (input)="OnChangeSelect($event)"
                  [attr.value]="3"
                />
                <div class="range-labels unselectable">
                  <div class="range-label">Narrow</div>
                  <div class="range-label">Broad</div>
                </div>
                <div class="range-input-indicator">30%</div>
              </div>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>
          <div class="evidence-fields">
            <div class="element-wrapper mt-3 input-transparent">
              <input
                class="custom-checkbox"
                type="checkbox"
                id="exactMatch"
                name="exactMatch"
                value="exactMatch"
                [attr.changeMethod]="'exactMatchClicked'"
                (change)="OnChangeSelect($event)"
                [checked]="element.params.checked ? true : false"
                *ngIf="
                  element.params.display === undefined ||
                  element.params.display === true
                "
              />
              <label class="custom-checkbox-label" for="exactMatch"
                >Exact match</label
              >
            </div>
          </div>
        </div>
        <!-- BUTTONS -->
        <div class="d-flex justify-content-between align-items-center">
          <button
            [attr.clickmethod]="'idvGenerationStepFour'"
            (click)="OnButtonClick($event)"
            type="submit"
            class="form-button form-button-1 mt-4 evidence-submit-button"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>

  <!-- TEMPLATE CREATION SCREEN -->
  <app-template-creation-config
    [element]="element"
    (contentReturn)="onContentReturnFromElements($event)"
    *ngSwitchCase="'templateCreationConfig'"
  >
  </app-template-creation-config>
  <!-- END TEMPLATE CREATION SCREEN -->

  <!-- UPLOADER SIDE DIGITAL VERIFICATION EVIDENCE -->
  <!-- refactor prio 1 -->
  <div
    *ngSwitchCase="'documentUploaderSideDigitalEvidence'"
    class="documentUploaderSide documentUploaderSideAlternate p-0"
    [ngClass]="[
      element.class ? element.class : '',
      element.isActive ? 'evidence-is-active' : '',
      element.params.loadingFromBe ? 'loading-from-be' : '',
      element.isActive && element.investorSide && !element.params.reviewState
        ? 'already-reviewed-container rt'
        : ''
    ]"
  >
    <div
      class="form-container-side-verification position-relative"
      *ngIf="!element.params.reviewSide || element.params.reviewStateGroup"
    >
      <div class="document-upload-side-overlay">
        <img
          src="/assets/images/spinner-green.svg"
          width="65px"
          height="65px"
          class="my-auto mx-auto"
        />
      </div>
      <div
        class="d-flex flex-row align-items-start notification info mx-4 mt-4"
        *ngIf="false"
      >
        <img
          src="assets/images/icon-information-prepared-teal.svg"
          width="16px"
        />
        <div class="d-flex flex-column pl-3 content">
          <div class="description">
            {{ "review.digitalVerifications.content7" | translate }}
          </div>
        </div>
      </div>
      <!-- ID VERIFICATION  -->
      <div
        *ngFor="let digitalVerification of element.params.digitalEvidenceTypes"
      >
        <app-digital-verification-evidence-id-verification
          *ngIf="
            !element.params.reviewSide &&
            digitalVerification.verificationType === 'Identity' &&
              ((!element.params.reviewSide &&
                !digitalVerification.hideFromList) ||
                (element.params.reviewSide &&
                  (digitalVerification.reviewState ||
                    digitalVerification.justReviewed))) &&
              !digitalVerification.isResolved
          "
          [digitalVerification]="digitalVerification"
          [element]="element"
          (contentReturn)="onContentReturnFromElements($event)"
        >
        </app-digital-verification-evidence-id-verification>
        <app-digital-verification-evidence-id-verification-go
          *ngIf="
            !element.params.reviewSide &&
            digitalVerification.verificationType === 'IdentityInstantAI' &&
              ((!element.params.reviewSide &&
                !digitalVerification.hideFromList) ||
                (element.params.reviewSide &&
                  (digitalVerification.reviewState ||
                    digitalVerification.justReviewed))) &&
              !digitalVerification.isResolved
          "
          [digitalVerification]="digitalVerification"
          [element]="element"
          (contentReturn)="onContentReturnFromElements($event)"
        >
        </app-digital-verification-evidence-id-verification-go>
        <app-digital-verification-evidence-address-verification
          *ngIf="
            !element.params.reviewSide &&
            digitalVerification.verificationType === 'Address' &&
              ((!element.params.reviewSide &&
                !digitalVerification.hideFromList) ||
                (element.params.reviewSide &&
                  (digitalVerification.reviewState ||
                    digitalVerification.justReviewed))) &&
              !digitalVerification.isResolved
          "
          [digitalVerification]="digitalVerification"
          [element]="element"
          (contentReturn)="onContentReturnFromElements($event)"
        >
        </app-digital-verification-evidence-address-verification>
        <app-digital-verification-evidence-contracts
          *ngIf="
            !element.params.reviewSide &&
            digitalVerification.verificationType === 'Contract' &&
              ((!element.params.reviewSide &&
                !digitalVerification.hideFromList) ||
                (element.params.reviewSide &&
                  (digitalVerification.reviewState ||
                    digitalVerification.justReviewed))) &&
              !digitalVerification.isResolved
          "
          [digitalVerification]="digitalVerification"
          [element]="element"
          (contentReturn)="onContentReturnFromElements($event)"
        >
        </app-digital-verification-evidence-contracts>
        <app-digital-verification-evidence-aps
          *ngIf="
            !element.params.reviewSide &&
            digitalVerification.verificationType === 'APS' &&
              ((!element.params.reviewSide &&
                !digitalVerification.hideFromList) ||
                (element.params.reviewSide &&
                  (digitalVerification.reviewState ||
                    digitalVerification.justReviewed))) &&
              !digitalVerification.isResolved
          "
          [digitalVerification]="digitalVerification"
          [element]="element"
          (contentReturn)="onContentReturnFromElements($event)"
        >
        </app-digital-verification-evidence-aps>
      </div>
    </div>

    <!-- SHARE DIALOGUE -->
    <app-digital-verification-evidence-share-dialog
      *ngIf="
        (element.params.digitalEvidenceTypes &&
          element.params.digitalEvidenceTypes[0] &&
          element.params.reviewSide &&
          element.params.reviewStateGroup &&
          element.params.reviewStateGroup &&
          !element.params.singleIdentityGoStepRelated) ||
        element.params.singleIdentityGoStep ||
        (element.params.singleIdentityGoStepRelated &&
          !element.params.dverificationUrl)
      "
      [element]="element"
      (contentReturn)="onContentReturnFromElements($event)"
    >
    </app-digital-verification-evidence-share-dialog>

    <!-- URL -->
    <app-digital-verification-evidence-share-link
      *ngIf="
        (element.params.reviewSide &&
          !element.params.reviewStateGroup &&
          !element.params.singleIdentityGoStep) ||
        (element.params.singleIdentityGoStepRelated &&
          element.params.dverificationUrl)
      "
      [element]="element"
      (contentReturn)="onContentReturnFromElements($event)"
    ></app-digital-verification-evidence-share-link>
  </div>
  <!-- EOF: refactor prio 1 -->

  <!-- UPLOADER SIDE -->

  <!-- refactor prio 2 -->
  <div
    class="documentUploaderSide documentUploaderSideAlternate parseEvidenceList"
    [ngClass]="[
      element.class ? element.class : '',
      element.isActive ? 'evidence-is-active' : '',
      element.params.loadingFromBe ? 'loading-from-be-review' : '',
      element.isActive && element.investorSide && !element.params.reviewState
        ? 'already-reviewed-container'
        : ''
    ]"
    [id]="element.params.evidenceKey"
    [attr.data-requestKey]="
      element.params.originRequest
        ? element.params.originRequest
        : element.params.requestKey
    "
    *ngSwitchCase="'documentUploaderSide'"
  >
    <div class="main-content">
      <!-- funds or investor but no review -->
      <form
        *ngIf="
          element.isActive &&
          (!element.investorSide ||
            (element.investorSide && !!element.params.reviewState))
        "
        [id]="element.params.evidenceKey + 'form'"
        class="form-container form-container-side"
      >
        <div class="document-upload-side-overlay">
          <img
            src="/assets/images/spinner-green.svg"
            width="65px"
            height="65px"
            class="my-auto mx-auto"
          />
        </div>

        <div
          *ngIf="element.params.multipleChoiceDocuments"
          class="mt-2 input-label-large"
        >
          <h4 class="mb-3">
            {{ "review.title2" | translate }}
          </h4>
          <div
            class="py-1"
            *ngFor="let evidence of element.params.multipleChoiceDocuments"
          >
            <input
              [attr.checked]="evidence.documentActive"
              (change)="OnChangeSelect($event)"
              [attr.changeMethod]="'multipleChoiceEvidenceChange'"
              class="custom-radio-button"
              type="radio"
              id="{{
                evidence.customEvidenceType
                  ? evidence.customEvidenceType + 'multipleradio'
                  : evidence.type + 'multipleradio'
              }}"
              name="multiple_document_selection"
              value="{{ evidence.type }}"
            />
            <label
              class="custom-radio-label"
              for="{{
                evidence.customEvidenceType
                  ? evidence.customEvidenceType + 'multipleradio'
                  : evidence.type + 'multipleradio'
              }}"
              >{{
                evidence.customEvidenceType
                  ? evidence.customEvidenceType
                  : parseBackendName(evidence.type)
              }}</label
            >
          </div>
        </div>

        <div
          *ngIf="!element.params.multipleChoiceDocuments"
          class="mt-2 input-label-large"
        >
          <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
          <h4
            *ngIf="
              !element.investorSide ||
              (element.investorSide && element.investorSide === false)
            "
          >
            {{ element.params.document_category_name }}
          </h4>
          <h4
            *ngIf="
              element.investorSide &&
              element.evidence &&
                element.evidence.formattedExistingDocuments &&
                element.evidence.formattedExistingDocuments.length > 0 &&
              !element.evidence.isBasicFields
            "
          >
            {{ "review.title3" | translate }}
          </h4>
          <div
            *ngIf="
              element.investorSide &&
              element.params.Notes &&
              !element.evidence.isBasicFields
            "
          >
            <div class="d-flex flex-row align-items-start notification info">
              <img
                src="assets/images/icon-information-prepared-teal.svg"
                width="16px"
              />
              <div class="d-flex flex-column pl-3 content">
                <h3 class="ml-0 mr-auto mb-2 mt-0">
                  {{ "review.title4" | translate }}
                </h3>
                <div id="reviewFlowNotes" class="description">
                  {{ element.params.Notes }}
                </div>
              </div>
            </div>
          </div>
          <h4
            *ngIf="
              element.investorSide &&
              element.evidence.isBasicFields &&
              !element.evidence.rpOptions
            "
          >
            {{ "review.title5" | translate }}
          </h4>
          <h4
            *ngIf="
              element.investorSide &&
              element.evidence.isBasicFields &&
              element.evidence.rpOptions &&
              element.evidence.rpOptions.length > 0
            "
          >
            {{ "review.title3" | translate }}
          </h4>
          <h4
            *ngIf="
              element.investorSide &&
              element.evidence.isBasicFields &&
              element.evidence.rpOptions &&
              element.evidence.rpOptions.length === 0
            "
          >
            {{ "review.title5" | translate }}
          </h4>
        </div>

        <!-- show existing documents -->
        <div
          id="existingDocumentsWrapper"
          *ngIf="
            element.evidence &&
            element.evidence.formattedExistingDocuments &&
            element.evidence.formattedExistingDocuments.length > 0 &&
            !element.evidence.isBasicFields
          "
          [ngClass]="
            element.evidence.reviewStateStelected === 'continue_with_new_info'
              ? 'disabled'
              : ''
          "
        >
          <div class="element-wrapper">
            <!-- Radio Group -->
            <div
              class="show-all-evidence-button"
              (click)="showHiddenEvidence()"
              *ngIf="element.params.displayShowMoreEvidenceButton"
            >
              {{ "review.title7" | translate }}
            </div>
            <div
              class="evidence-radio-wrapp"
              [ngClass]="
                radio.params.differentProjectEvidence &&
                element.params.displayShowMoreEvidenceButton
                  ? 'different-project-evd'
                  : ''
              "
              *ngFor="
                let radio of element.evidence.formattedExistingDocuments;
                let first = first
              "
            >
              <input
                *ngIf="radio.params.selected === true"
                class="custom-radio-button"
                type="radio"
                id="{{ radio.params.id }}"
                name="{{ radio.params.name }}"
                value="{{ radio.params.value }}"
                [attr.checked]="true"
              />
              <input
                [checked]="first && !element.evidence.reviewStateStelected"
                *ngIf="
                  radio.params.selected === undefined ||
                  radio.params.selected === false
                "
                class="custom-radio-button"
                type="radio"
                id="{{ radio.params.id }}"
                name="{{ radio.params.name }}"
                value="{{ radio.params.value }}"
              />
              <label class="custom-radio-label" for="{{ radio.params.id }}">{{
                radio.params.label
              }}</label>
              <div
                [ngClass]=""
                class="document-inner-entry ml-4 mb-4"
                *ngIf="radio.params.elementType"
                (click)="
                  onReviewEvidenceClick(
                    radio.params.value,
                    element.params.requestKey,
                    radio.params.documents,
                    true,
                    true
                  )
                "
              >
                <div class="document-upload-entry-image">
                  <img
                    [src]="
                      idVerificationService.returnimgrlByType(
                        radio.params.elementTypeUnparsed
                      )
                    "
                  />
                </div>
                <div class="document-upload-entry-detail">
                  <h5 class="document-name">{{ radio.params.elementType }}</h5>
                </div>
              </div>
            </div>
            <div
              class="error-field"
              [attr.validatorMessage]="element.params.validatorMessage"
              [attr.validatorMessageField]="element.params.validatorMessage"
            ></div>
          </div>
        </div>

        <div
          class="rp-deduplication-wrapper"
          *ngIf="
            element.evidence.isBasicRPFields &&
            element.evidence.rpOptions.length > 0 &&
            element.investorSide &&
            element.evidence &&
              (!element.evidence.formattedExistingDocuments ||
                (element.evidence.formattedExistingDocuments &&
                  element.evidence.formattedExistingDocuments.length === 0))
          "
        >
          <div class="element-wrapper input-transparent">
            <span style="font-size: 16px; color: #333333" class="">{{
              "review.title8" | translate
            }}</span>
            <div class="rp-delegation-wrapper d-flex">
              <input
                class="custom-radio-button"
                [attr.checked]="true"
                type="radio"
                name="rp_delegation_selected"
                id="rp_delegation_selected"
              />
              <label
                class="custom-radio-label"
                for="rp_delegation_selected"
              ></label>
              <div class="select-wrapper">
                <select
                  class="select-input"
                  (change)="OnChangeSelect($event)"
                  [value]="element.evidence.rpOptions[0].key"
                >
                  <option
                    value="{{ option.key }}"
                    *ngFor="let option of element.evidence.rpOptions"
                  >
                    {{ option.investorReviewName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <input
          class="custom-checkbox"
          type="checkbox"
          id="continue_rp_with_new_info"
          name="continue_rp_with_new_info"
          *ngIf="
            element.evidence.isBasicRPFields &&
            element.evidence.rpOptions.length > 0 &&
            element.investorSide &&
            element.evidence &&
              (!element.evidence.formattedExistingDocuments ||
                (element.evidence.formattedExistingDocuments &&
                  element.evidence.formattedExistingDocuments.length === 0))
          "
          value="1"
          [checked]="false"
          [disabled]="false"
          (click)="toggleDocumentsClassesRP()"
        />

        <label
          class="custom-checkbox-label mt-4"
          for="continue_rp_with_new_info"
          *ngIf="
            element.evidence.isBasicRPFields &&
            element.evidence.rpOptions.length > 0 &&
            element.investorSide &&
            element.evidence &&
              (!element.evidence.formattedExistingDocuments ||
                (element.evidence.formattedExistingDocuments &&
                  element.evidence.formattedExistingDocuments.length === 0))
          "
        >
          {{ "review.title9" | translate }}
        </label>
        <!-- to decide wether we fill basic fields in here or in the PCS -->
        <div
          *ngIf="
            element.investorSide &&
            element.evidence.isBasicFields &&
            element.evidence.displayBasicFieldDVChoice
          "
          class="basic-field-select mt-4"
        >
          <p
            *ngIf="
              element.evidence.isBasicRPFields &&
              element.evidence.rpOptions.length > 0 &&
              element.investorSide &&
              element.evidence &&
                (!element.evidence.formattedExistingDocuments ||
                  (element.evidence.formattedExistingDocuments &&
                    element.evidence.formattedExistingDocuments.length === 0))
            "
          >
            {{ "review.label" | translate }}
          </p>
          <input
            class="custom-checkbox"
            type="checkbox"
            id="continue_with_new_info_bf"
            name="continueWithNewInformationBF"
            value="1"
            [checked]="
              true &&
              !(
                element.evidence.isBasicRPFields &&
                element.evidence.rpOptions.length > 0 &&
                element.investorSide &&
                element.evidence &&
                  (!element.evidence.formattedExistingDocuments ||
                    (element.evidence.formattedExistingDocuments &&
                      element.evidence.formattedExistingDocuments.length === 0))
              )
            "
            [disabled]="
              element.evidence.isBasicRPFields &&
              element.evidence.rpOptions.length > 0 &&
              element.investorSide &&
              element.evidence &&
                (!element.evidence.formattedExistingDocuments ||
                  (element.evidence.formattedExistingDocuments &&
                    element.evidence.formattedExistingDocuments.length === 0))
            "
            (click)="toggleBFCheckbox($event)"
            *ngIf="element.investorSide && element.evidence.isBasicFields"
          />
          <label
            class="custom-checkbox-label w-100 mb-3"
            for="continue_with_new_info_bf"
            *ngIf="
              element.investorSide &&
              element.evidence.isBasicFields &&
              element.evidence.displayBasicFieldDVChoice
            "
            [innerHTML]="
              'review.label1'
                | translate: { person: element.params.document_category_name }
            "
          >
          </label>
          <input
            class="custom-checkbox"
            type="checkbox"
            id="transfer_to_pcs_bf"
            name="transferToPcsBf"
            value="1"
            [disabled]="
              element.evidence.isBasicRPFields &&
              element.evidence.rpOptions.length > 0 &&
              element.investorSide &&
              element.evidence &&
                (!element.evidence.formattedExistingDocuments ||
                  (element.evidence.formattedExistingDocuments &&
                    element.evidence.formattedExistingDocuments.length === 0))
            "
            (click)="toggleBFCheckbox($event)"
            (click)="toggleBFCheckbox($event)"
            *ngIf="element.investorSide && element.evidence.isBasicFields"
          />
          <label
            class="custom-checkbox-label w-100"
            for="transfer_to_pcs_bf"
            *ngIf="
              element.investorSide &&
              element.evidence.isBasicFields &&
              element.evidence.displayBasicFieldDVChoice
            "
          >
            {{ "review.label2" | translate }}
          </label>
        </div>
        <div class="element-wrapper" *ngIf="element.investorSide">
          <input
            class="custom-checkbox"
            type="checkbox"
            id="continue_with_new_info"
            name="continueWithNewInformation"
            value="1"
            [checked]="
              !element.evidence.existingDocuments ||
              element.evidence.reviewStateStelected ===
                'continue_with_new_info' ||
              (element.evidence.existingDocuments &&
                element.evidence.existingDocuments.length === 0)
                ? true
                : false
            "
            [disabled]="
              !element.evidence.existingDocuments ||
              (element.evidence.existingDocuments &&
                element.evidence.existingDocuments.length === 0)
                ? true
                : false
            "
            (click)="toggleDocumentsClasses()"
          />
          <label
            class="custom-checkbox-label"
            for="continue_with_new_info"
            *ngIf="
              !element.evidence.isBasicFields &&
              element.investorSide &&
              element.evidence &&
                (!element.evidence.formattedExistingDocuments ||
                  (element.evidence.formattedExistingDocuments &&
                    element.evidence.formattedExistingDocuments.length === 0))
            "
          >
            {{ "review.title10" | translate }}
          </label>
          <label
            class="custom-checkbox-label"
            for="continue_with_new_info"
            *ngIf="
              element.investorSide &&
              element.evidence &&
                element.evidence.formattedExistingDocuments &&
                element.evidence.formattedExistingDocuments.length > 0
            "
          >
            {{ "review.title11" | translate }}
          </label>
        </div>
        <div
          class="input-fields-wrapper"
          [ngClass]="
            (element.evidence &&
              element.evidence.existingDocuments &&
              element.evidence.existingDocuments.length > 0 &&
              element.evidence.reviewStateStelected !==
                'continue_with_new_info') ||
            element.evidence.rpOptionsLength ||
            (element.investorSide &&
              element.evidence.isBasicFields &&
              element.evidence.displayBasicFieldDVChoice)
              ? 'disabled'
              : ''
          "
        >
          <!-- INPUT FIELDS -->
          <app-parse-evidence-fields
            [element]="element"
            [spCompletingDocument]="spCompletingDocument"
            (contentReturn)="onContentReturnFromElements($event)"
            (displayLoader)="onDisplayLoaderFromElements($event)"
          ></app-parse-evidence-fields>

          <!-- IMAGE UPLOAD  -->
          <div
            *ngIf="
              !element.evidence.isBasicFields &&
              !element.evidence.isBasicFieldsReplace
            "
            class="element-wrapper"
          >
            <div class="d-flex flex-column">
              <span
                *ngIf="
                  element.params.documents && element.params.documents.length
                "
                class="input-label font-weight-bold mb-0"
                style="margin-bottom: -15px"
                >{{ "review.label6" | translate }}</span
              >
              <div
                class="evidence-list-image-wrapper"
                [attr.data-wrapperEvidenceKey]="element.params.evidenceKey"
              >
                <div
                  *ngFor="let document of element.params.documents"
                  class="evidence-list-image-container evidence-container-type-one"
                >
                  <div class="d-flex">
                    <div>
                      <img
                        src="/assets/images/ico-templates.svg"
                        width="16px"
                        height="20px"
                        class="mr-3"
                      />
                    </div>
                    <div
                      *ngIf="element.params.fundVerification"
                      (click)="
                        onReviewEvidenceClick(
                          element.params.evidenceKey,
                          element.params.relatedPartyKey
                            ? element.params.relatedPartyKey
                            : element.params.requestKey,
                          element.params.documents,
                          true,
                          true,
                          false,
                          document.filename
                        )
                      "
                      class="evience-list-member"
                    >
                      {{ document.filename }}
                    </div>
                    <div
                      *ngIf="!element.params.fundVerification"
                      (click)="
                        onReviewEvidenceClick(
                          element.params.evidenceKey,
                          element.params.relatedPartyKey
                            ? element.params.relatedPartyKey
                            : element.params.requestKey,
                          element.params.documents,
                          true,
                          true,
                          false,
                          document.filename
                        )
                      "
                      class="evience-list-member"
                    >
                      {{ document.filename }}
                    </div>
                  </div>
                  <button
                    attr.data-documentKey="{{ document.Key }}"
                    attr.data-requestKey="{{ element.params.requestKey }}"
                    attr.data-evidenceKey="{{ element.params.evidenceKey }}"
                    (click)="
                      element.params.addressDocumentUpload
                        ? onDocumentAddressVerificationDelete(document)
                        : OnDocumentDelete(
                            element.params.evidenceTypes,
                            element.params.evidenceKey,
                            document
                          )
                    "
                    data-documentKey="document"
                    class="evidence-list-delete-button form-button form-button-1"
                  >
                    <img src="assets/images/delete-document-white.svg" />
                  </button>
                  <div class="upload-button-loading-screen">
                    <img
                      src="/assets/images/spinner-green.svg"
                      width="20px"
                      height="20px"
                      class="my-auto mx-auto"
                    />
                  </div>
                </div>
              </div>
              <span
                *ngIf="
                  !(element.params.documents && element.params.documents.length)
                "
                class="input-label font-weight-bold mb-2"
                >{{ "review.label3" | translate }}</span
              >
              <span
                *ngIf="
                  element.params.documents && element.params.documents.length
                "
                class="input-label font-weight-bold mb-2"
                >{{ "review.label7" | translate }}</span
              >
              <a
                class="d-flex position-relative dropzone"
                [ngClass]="element.params.evidenceKey"
                (dragenter)="OnImageUploadDragEnter($event)"
                (dragover)="OnImageUploadDragOver($event)"
                (dragleave)="OnImageUploadDragLeave($event)"
                (drop)="
                  element.params.addressDocumentUpload
                    ? OnAddressDropFileUpload($event)
                    : OnImageUploadDropEvidence(
                        $event,
                        element.params.requestKey,
                        element.params.evidenceKey,
                        element.params.evidenceTypes,
                        element.params.flowType,
                        element.investorSide,
                        element.evidence
                      )
                "
                (click)="OnDocumentUploadClick($event)"
                [attr.data-target]="element.params.image_upload_id"
                [attr.data-evidenceKey]="element.params.evidenceKey"
                [attr.uploadmethod]="'htpmUploadFileEvidence'"
                (id)="(element.params.evidenceKey)"
              >
                <div
                  class="w-100 h-100 position-absolute img-preview"
                  [ngStyle]="{
                    'background-image':
                      'url(' + (!image ? element.params.image : image) + ')'
                  }"
                  *ngIf="image || element.params.image"
                ></div>
                <div
                  class="align-self-center text-center mx-auto image-upload-instructions"
                  [attr.data-target]="element.params.image_upload_id"
                  *ngIf="!image && !element.params.image"
                >
                  <div class="upload-button">
                    {{
                      !element.params.isMobile
                        ? ("appSpecific.imgCapture" | translate)
                        : ("appSpecific.imgCapture2" | translate)
                    }}
                  </div>
                  {{
                    !element.params.isMobile
                      ? ("appSpecific.imgCapture3" | translate)
                      : ("appSpecific.imgCapture4" | translate)
                  }}
                  <div>{{ "appSpecific.imgCapture5" | translate }}</div>
                </div>
                <div class="dropzone-loading-screen">
                  <img
                    src="/assets/images/spinner-green.svg"
                    width="35px"
                    height="35px"
                    class="my-auto mx-auto"
                  />
                </div>
              </a>
              <input
                type="file"
                id="{{ element.params.image_upload_id }}"
                accept="image/png, image/jpeg, image/gif, application/pdf"
                style="display: none"
                [attr.uploadmethod]="'htpmUploadFileEvidence'"
                [attr.data-InputEvidenceKey]="element.params.evidenceKey"
                (change)="
                  element.params.addressDocumentUpload
                    ? OnImageUploadAddressVerification($event)
                    : OnImageUploadSelectedEvidence(
                        $event,
                        element.params.requestKey,
                        element.params.evidenceKey,
                        element.params.evidenceTypes,
                        element.params.flowType,
                        element.investorSide,
                        element.evidence
                      )
                "
              />
              <p class="image-upload-notes" *ngIf="element.params.uploadNotes">
                {{ element.params.uploadNotes }}
              </p>
              <div
                style="margin-bottom: 25px"
                class="error-field-required-image d-none"
                [attr.validatorMessage]="'validation.validation4' | translate"
              ></div>
            </div>
          </div>
          <!-- NOTES INPUT-->
          <div
            class="element-wrapper"
            *ngIf="
              !element.params.Notes &&
              !element.evidence.isBasicFields &&
              !element.investorSide &&
              !element.params.replaceFlow &&
              !element.params.addressDocumentUpload
            "
          >
            <a
              [attr.clickMethod]="'addDocumentNotes'"
              (click)="OnButtonClick($event)"
              class="more-details-button"
            >
              <img src="/assets/images/ico_green.svg" />
              <span>{{ "appSpecific.notes2" | translate }}</span>
            </a>
          </div>
          <div
            class="element-wrapper {{ element.params.Notes ? '' : 'd-none' }}"
            id="client_notes_wrapper"
            *ngIf="
              !element.evidence.isBasicFields &&
              !element.investorSide &&
              !element.params.replaceFlow
            "
          >
            <span class="input-label font-weight-bold">{{
              "appSpecific.notes" | translate
            }}</span>
            <div class="d-flex flex-column">
              <textarea
                [attr.isMaxLength]="400"
                maxlength="400"
                style="
                  border: 2px solid #d8d8d8;
                  border-radius: 4px;
                  padding: 15px;
                "
                id="client_notes"
                class="d-flex position-relative tempTextInput"
                rows="4"
                >{{ element.params.Notes }}</textarea
              >
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
            </div>
          </div>

          <!-- Finalize required -->
          <!-- ONLY TO BE SHOWN ON SP CREATE/UPDATE EVIDENCE FLOW -->
          <!-- PURPOSES SELECT -->
          <div class="element-wrapper">
            <div
              [ngClass]="
                element.investorSide ? 'purposes-without-selecting' : ''
              "
              class="purposes-container checkbox-container"
              [attr.isRequired]="element.params.replaceFlow ? false : true"
            >
              <div
                *ngIf="!element.evidence.isBasicFields"
                class="input-label font-weight-bold"
              >
                {{
                  element.investorSide
                    ? ("review.label4" | translate)
                    : element.params.replaceFlow
                    ? ""
                    : ("review.label5" | translate)
                }}
                <sup
                  *ngIf="!element.investorSide && !element.params.replaceFlow"
                  class="ml-0 sup-required"
                  >*</sup
                >
              </div>
              <a
                *ngIf="!element.investorSide && !element.params.replaceFlow"
                (click)="toggleClass(element.params.evidenceKey, 'd-none')"
                class="purposes-button purposes-button-form"
              >
                {{ "common.pleaseChoose" | translate }}
                <div class="purposes-arrow-icon"></div>
              </a>
              <div
                [ngClass]="element.investorSide ? 'd-none' : ''"
                [attr.data-purposeEvidenceKey]="element.params.evidenceKey"
                [id]="element.params.evidenceKey"
                class="purposes-checkbox-list d-none"
              >
                <div
                  *ngFor="let purpose of element.params.purposesOfUse"
                  class="purposes-checkbox"
                >
                  <div class="single-checkbox-wrapper">
                    <input
                      class="custom-checkbox purpose-checkbox"
                      type="checkbox"
                      [name]="purpose.id"
                      [value]="purpose.id"
                      [id]="element.params.evidenceKey + purpose.id"
                      (change)="OnChangeSelect($event); onSelectPurpose($event)"
                      [checked]="purpose.checked"
                      [attr.label]="purpose.label"
                      [attr.data-purposeId]="
                        element.params.evidenceKey + purpose.id
                      "
                    />
                    <label
                      [for]="element.params.evidenceKey + purpose.id"
                      class="custom-checkbox-label"
                    >
                      {{
                        this.helperService.translatePurposesOfUse(purpose.id)
                      }}
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="error-field"
                [attr.validatorMessage]="element.params.validatorMessage"
                [attr.validatorMessageField]="element.params.validatorMessage"
              ></div>
              <div
                [ngClass]="!element.evidence.isBasicFields ? '' : 'd-none'"
                class="purpose-tags-container"
              >
                <div
                  class="purpose-tag-wrapper"
                  *ngFor="let purpose of element.params.purposesOfUse"
                >
                  <div
                    [attr.data-purposeId]="
                      element.params.evidenceKey + purpose.id
                    "
                    class="purpose-tag"
                    [ngClass]="!purpose.checked ? 'd-none' : ''"
                  >
                    {{ this.helperService.translatePurposesOfUse(purpose.id) }}
                    <a
                      (click)="
                        element.investorSide ? '' : onPurposeDelete($event)
                      "
                      class="close-button"
                    >
                      <img
                        *ngIf="
                          !element.investorSide && !element.params.replaceFlow
                        "
                        [attr.data-forPurpose]="
                          element.params.evidenceKey + purpose.id
                        "
                        src="assets/images/close_form_icon.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
                    <!-- NOTOLDERTHAN DATE -->
          <!-- ONLY TO BE SHOWN ON SP CREATE/UPDATE EVIDENCE FLOW -->
          <div 
          class="form-clarification custom-collapse mt-4 p-3"
          *ngIf="
          !element.investorSide &&
          !element.params.replaceFlow &&
          !element.params.addressDocumentUpload
        ">
            <div href="#additionalSettings" data-toggle="collapse" role="button" aria-expanded="true"
              aria-controls="multiCollapseExample1" class="d-flex collapse-header">
              <h2 class="form-clarification-title pl-2 mb-0">Additional settings</h2>
              <img src="assets/images/arrow-up-teal.svg" width="20px" height="20px" class="icon-tooltip dropdown" />
            </div>
            <div class="collapse clarification-content" id="additionalSettings">
              <div *ngIf="
                       !element.investorSide &&
                       !element.params.replaceFlow &&
                       !element.params.addressDocumentUpload
                     " class="element-wrapper">
                <div>
                  <!-- Input Date -->
                  <span class="input-label font-weight-bold">
                    Not older than
                    <!-- <sup class="sup-required">*</sup> -->
                  </span>
                  <app-my-date-picker name="notOlderThanN" id="notOlderThan" method="setMinEndDate" dateInputId="notOlderThanN"
                    isReqiured="false" value="" startDate="{{ helperService.getAsMinDateMinusYears(1) }}"
                    maxDate="" minDate="" (dateChange)="parseDateContentReturn($event)" value="{{ element.notOlderThanValue }}"
                    [(ngModel)]="element.notOlderThanModel"></app-my-date-picker>
                  <div class="error-field" [attr.validatorMessage]="'validation.validation1' | translate"
                    [attr.validatorMessageField]="
                           'validation.validation1' | translate
                         "></div>
                  <div class="error-field-wrong-format d-none" [attr.validatorMessage]="
                           '*the date must be in the correct format: dd.mm.yyyy '
                         "></div>
                </div>
              </div>
              <div *ngIf="
                     !element.investorSide &&
                     !element.params.replaceFlow &&
                     !element.params.addressDocumentUpload
                   " class="element-wrapper">
                <div class="element-wrapper input-transparent">
                  <input class="custom-checkbox" type="checkbox" id="finalizeRequired" name="finalizeRequired" value="true"
                    [checked]="element.finalizeRequiredChecked" />
                  <label class="custom-checkbox-label" for="finalizeRequired">
                    This document requires my final acceptance on receipt</label>
                </div>
              </div>
              <div *ngIf="
                     !element.investorSide &&
                     !element.params.replaceFlow
                   " class="element-wrapper">
                <div class="element-wrapper input-transparent">
                  <input class="custom-checkbox" type="checkbox" id="claimCorrect" name="claimCorrect" value="true"
                    (change)="changeSpCompletingDocument($event)" [checked]="spCompletingDocument" />
                  <label class="custom-checkbox-label" for="claimCorrect">
                    Do not include this document in a request, and mark it as completed immediately after saving.</label>
                </div>
              </div>
            </div>
          </div>
          <div class="element-wrapper"></div>
        </div>
        <!-- BUTTONS -->
        <div class="d-flex justify-content-between align-items-center">
          <button
            (click)="
              !element.investorSide && !element.params.replaceFlow
                ? !isPcsChecked()
                  ? OnButtonClick($event, element.params.evidenceTypes)
                  : return
                : OnReviewButtonClick(
                    $event,
                    element.params.evidenceTypes,
                    element.params.replaceFlow,
                    element.evidence.isBasicFields ||
                      element.evidence.isBasicFieldsReplace,
                    element.evidence.rpOptionsLength
                  )
            "
            [attr.data-requestKey]="
              element.params.originRequest
                ? element.params.originRequest
                : element.params.requestKey
            "
            [attr.data-evidenceKey]="element.params.evidenceKey"
            [attr.clickmethod]="
              element.params.replaceFlow
                ? 'htpmSubmitReplaceFields'
                : 'htpmSubmitEvidenceFields'
            "
            type="submit"
            *ngIf="!element.params.addressDocumentUpload"
            class="form-button form-button-1 mt-5 evidence-submit-button"
          >
            {{
              element.params.replaceFlow
                ? ("common.save" | translate)
                : element.params.isFinalize &&
                  !element.investorSide &&
                  !element.params.replaceFlow
                ? ("common.saveNRequest" | translate)
                : ("common.save" | translate)
            }}
          </button>
          <button
            (click)="
              onDeleteEvidence(
                $event,
                element.params.requestKey,
                element.params.evidenceTypes,
                element.params.evidenceKey
              )
            "
            *ngIf="
              !element.investorSide &&
              !element.params.replaceFlow &&
              !element.params.addressDocumentUpload
            "
            class="evidence-delete-button mt-5"
            [attr.data-requestKey]="element.params.requestKey"
            [attr.data-evidenceKey]="element.params.evidenceKey"
          ></button>
          <button
            (click)="
              (!element.investorSide && !element.params.replaceFlow) ||
              element.params.addressDocumentUpload
                ? OnButtonClick($event, element.params.evidenceTypes)
                : OnReviewButtonClick(
                    $event,
                    element.params.evidenceTypes,
                    element.params.replaceFlow,
                    element.evidence.isBasicFields ||
                      element.evidence.isBasicFieldsReplace,
                    element.evidence.rpOptionsLength
                  )
            "
            [attr.data-requestKey]="
              element.params.originRequest
                ? element.params.originRequest
                : element.params.requestKey
            "
            [attr.data-evidenceKey]="element.params.evidenceKey"
            [attr.clickmethod]="
              element.params.addressDocumentUpload
                ? 'uploadAddressFields'
                : element.params.replaceFlow
                ? 'htpmSubmitReplaceFields'
                : 'htpmSubmitEvidenceFields'
            "
            *ngIf="element.params.addressDocumentUpload"
            type="submit"
            class="form-button form-button-1 mt-3 evidence-submit-button"
          >
            {{
              element.params.addressDocumentUpload
                ? ("common.next" | translate)
                : element.params.replaceFlow
                ? ("common.save" | translate)
                : element.params.isFinalize &&
                  !element.investorSide &&
                  !element.params.replaceFlow
                ? ("common.saveNRequest" | translate)
                : ("common.save" | translate)
            }}
          </button>
          <div
            style="z-index: 10000"
            class="mt-5 mr-auto ml-3 review-flow-add-spinner d-flex-low d-none align-items-center"
          >
            <img
              width="30px"
              height="30px"
              src="/assets/images/spinner-green.svg"
            />
            <p class="mb-0 ml-2 font-weight-bold">
              {{ "common.pleaseWait" | translate }}
            </p>
          </div>
        </div>
        <div style="display: none" class="submit-fields-general-error">
          *{{ "review.validation" | translate }}
        </div>
      </form>

      <!-- investor but no review -->
      <form
        *ngIf="
          element.isActive &&
          element.investorSide && !element.params.reviewState
        "
        [id]="element.params.evidenceKey + 'form'"
        class="form-container"
      >
        <div class="mt-2 input-label-large">
          <h4 class="text-center mt-5">
            {{
              element.evidence.isBasicFields
                ? ("kompanySearch.label12" | translate)
                : ("kompanySearch.label13" | translate)
            }}
          </h4>
          <div class="element-wrapper">
            <!-- IMAGE -->
            <img
              src="/assets/images/check.svg"
              class="result-image"
              [attr.width]="150"
            />
          </div>
          <div class="element-wrapper mt-2 mb-5">
            <h4 class="result-paragraph text-center font-weight-normal">
              {{ "kompanySearch.label14" | translate }}
            </h4>
          </div>
        </div>
      </form>

      <!-- no documents -->
      <!-- <div class="button-error-field" id="AVDocumentsNotSelected">
      *No documents were selected
    </div> -->
    </div>
    <div class="mobile-loading-review">
      <img
        src="/assets/images/spinner-green.svg"
        width="30px"
        height="30px"
        class="my-auto mx-auto"
      />
    </div>
  </div>
  <!-- EOF: refactor prio 2 -->

  <!-- refactor prio 2 for PCS -->
  <div
    class="parseEvidenceList basicFieldsEvidenceList"
    [ngClass]="[
      element.class ? element.class : '',
      element.isActive ? 'evidence-is-active' : '',
      element.params.loadingFromBe ? 'loading-from-be' : ''
    ]"
    *ngSwitchCase="'basicFieldsPCS'"
  >
    <div class="document-upload-side-overlay d-none">
      <img
        src="/assets/images/spinner-green.svg"
        width="65px"
        height="65px"
        class="my-auto mx-auto"
      />
    </div>
    <div>
      <!-- funds or investor but no review -->
      <form
        *ngIf="
          element.isActive &&
          (!element.investorSide ||
            (element.investorSide && !!element.params.reviewState))
        "
        [id]="element.params.evidenceKey + 'form'"
        class="form-container"
      >
        <div class="input-fields-wrapper">
          <!-- INPUT FIELDS -->
          <!-- CASE DATE -->
          <app-parse-evidence-fields
            [element]="element"
            (contentReturn)="onContentReturnFromElements($event)"
            (displayLoader)="onDisplayLoaderFromElements($event)"
          ></app-parse-evidence-fields>

          <div class="element-wrapper"></div>
        </div>
        <!-- BUTTONS -->
        <div class="d-flex justify-content-between align-items-center">
          <button
            (click)="OnButtonClick($event, element.params.evidenceTypes)"
            [attr.data-requestKey]="
              element.params.originRequest
                ? element.params.originRequest
                : element.params.requestKey
            "
            [attr.data-evidenceKey]="element.params.evidenceKey"
            [attr.clickmethod]="'submitPcsBasicInformation'"
            type="submit"
            *ngIf="!element.params.addressDocumentUpload"
            class="form-button form-button-1 mt-4 evidence-submit-button"
          >
            Save
          </button>
        </div>
        <div style="display: none" class="submit-fields-general-error">
          *please make sure that all the required fields are properly filled in
        </div>
      </form>

      <!-- investor but no review -->
      <form
        *ngIf="
          element.isActive &&
          element.investorSide && !element.params.reviewState
        "
        [id]="element.params.evidenceKey + 'form'"
        class="form-container"
      >
        <div class="mt-2 input-label-large">
          <h4 class="text-center mt-5">
            {{
              element.evidence.isBasicFields
                ? "Data already reviewed!"
                : "Document already reviewed"
            }}
          </h4>
          <div class="element-wrapper">
            <!-- IMAGE -->
            <img
              src="/assets/images/check.svg"
              class="result-image"
              [attr.width]="150"
            />
          </div>
          <div class="element-wrapper mt-2 mb-5">
            <h4 class="result-paragraph text-center font-weight-normal">
              No further action can be taken at this time.
            </h4>
          </div>
        </div>
      </form>

      <!-- no documents -->
      <!-- <div class="button-error-field" id="AVDocumentsNotSelected">
      *No documents were selected
    </div> -->
    </div>
  </div>
  <!-- EOF: refactor prio 2 for PCS -->

  <!-- EXPIRED BASIC EVIDENCE WARNING -->
  <div
    *ngSwitchCase="'innerNotification'"
    [attr.tabGroup]="element.tabGroup"
    [attr.tabKey]="element.tabKey"
    class="element-wrapper mb-1 w-100 d-none"
  >
    <div class="d-flex flex-row align-items-center notification info">
      <img src="assets/images/icon-triangle-orange.svg" width="16px" />
      <div class="ml-2 description font-weight-bold">
        {{ "serviceprovider.basicFieldWarning" | translate }}
      </div>
    </div>
  </div>
  <app-profiles-status-information
    [profileRecord]="element.recordOfInvestor"
    *ngSwitchCase="'profilesStatusInformation'"
  ></app-profiles-status-information>

  <app-entry-notifications-sp-side
    [element]="element"
    (contentReturn)="onContentReturnFromElements($event)"
    (displayLoader)="onDisplayLoaderFromElements($event)"
    *ngSwitchCase="'spNotificationComponent'"
  ></app-entry-notifications-sp-side>

  <div
    [attr.tabGroup]="element.tabGroup"
    [attr.tabKey]="element.tabKey"
    class="element-wrapper document-upload-inner"
    *ngSwitchCase="'documentUploadInnerStatus'"
    [ngClass]="element.class"
  >
    <div
      class="d-flex align-items-center document-upload-inner-status"
      [ngClass]="{ 'pl-0': element.class == 'scheduled', 'justify-content-between' : (element?.class && element.class.includes('risk-assessment')) }"
    >
      <div class="pl-0 content">
        <img [src]="element.icon" />
        <h3>{{ element.content }}</h3>
      </div>
      <div *ngIf="element?.buttons?.length > 0" class="d-flex">
        <div *ngFor="let btn of element.buttons" [ngClass]="btn.class">
          <button 
          *ngIf="btn.displayBtn"
          [attr.clickMethod]="btn.action"
          (click)="OnButtonClick($event)"
          [ngClass]="btn.btnClass" 
          class="button">{{btn.label}}</button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="element-wrapper dashboard-tabs"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'dashboardTabs'"
  >
    <div class="tab-container">
      <div
        [ngClass]="tab.active ? 'tab active unselectable' : 'tab unselectable'"
        [attr.activatesTab]="tab.activates"
        [attr.fromTabGroup]="element.tabGroup"
        *ngFor="let tab of element.tabs"
        (click)="OnChangeDashTab($event)"
        class="d-flex"
        id="{{ tab.activates }}"
      >
        <img
          class="img-icon"
          *ngIf="!tab.tooltip && tab.iconURL"
          src="{{ tab.iconURL }}"
        />
        <img
          [attr.activatesTab]="tab.activates"
          [attr.fromTabGroup]="element.tabGroup"
          (click)="OnChangeDashTab($event)"
          *ngIf="tab.tooltip && tab.iconURL"
          src="{{ tab.iconURL }}"
          [tooltip]="tab.tooltip"
        />
        <img
        class="img-icon"
        *ngIf="tab.profileVerificationIcon"
        src="{{ tab.profileVerificationIcon }}"
        />
        <span>{{ tab.name }}</span>
      </div>
    </div>
  </div>

  <!-- refactor prio 3 -->
  <!-- PARSE EVIDENCE ON SP AND INVESTOR DASHBOARD: -->
  <div
    [attr.tabGroup]="element.requestKey"
    [attr.tabKey]="element.requestKey + '_evidence'"
    class="element-wrapper half-width-wrapper document-upload-inner"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'documentUploadInner'"
  >
    <!-- <div
    *ngIf="(element.evidenceTypes && element.evidenceTypes.length >0)"
    class="person-section-title title-t2"
    style="margin-bottom: -6px !important;"
  >
    Evidence
  </div> -->

    <app-funds-parse-evidence-none
      [element]="element"
      (contentReturn)="onContentReturnFromElements($event)"
    >
    </app-funds-parse-evidence-none>

    <app-funds-parse-evidence-sp-side
      [element]="element"
      (contentReturn)="onContentReturnFromElements($event)"
      (displayLoader)="onDisplayLoaderFromElements($event)"
    >
    </app-funds-parse-evidence-sp-side>

    <app-funds-parse-evidence-investor-side
      [element]="element"
      (contentReturn)="onContentReturnFromElements($event)"
      (displayLoader)="onDisplayLoaderFromElements($event)"
    >
    </app-funds-parse-evidence-investor-side>
  </div>
  <!-- EOF: PARSE EVIDENCE ON SP AND INVESTOR DASHBOARD -->
  <!-- EOF: refactor prio 3 -->

  <!-- refactor prio 4 -->
  <!-- PARSE RELATED PARTIES ON SP AND INVESTOR DASHBOARD -->
  <div
    class="element-wrapper related-parties-container"
    [ngClass]="element.investorStatus === 'CLAIMED' ? '' : ''"
    *ngSwitchCase="'relatedPartiesList'"
  >
    <hr class="related-party-divider" />
    <h4
      *ngIf="element.isInvestor && !element.relatedPartiesLoading"
      class="result-title related-parties-title"
    >
      {{ "appSpecific.RelatedParties" | translate }}
      {{ element.count ? "(" + element.count + ")" : "" }}
    </h4>
    <h4
      *ngIf="!element.isInvestor && !element.relatedPartiesLoading"
      class="result-title related-parties-title"
    >
      {{ "appSpecific.RelatedProfiles" | translate }}
      {{ element.count ? "(" + element.count + ")" : "" }}
    </h4>
    <app-funds-parse-related-party
      [element]="element"
      (contentReturn)="onContentReturnFromElements($event)"
      (displayLoader)="onDisplayLoaderFromElements($event)"
    >
    </app-funds-parse-related-party>
    <app-funds-add-related-party
      *ngIf="element.isEmpty"
      [element]="element"
    ></app-funds-add-related-party>
    <app-related-party-loading-pillar
      *ngIf="element.relatedPartiesLoading"
      [element]="element"
    >
    </app-related-party-loading-pillar>
  </div>
  <!-- EOF: PARSE RELATED PARTIES ON SP AND INVESTOR DASHBOARD -->
  <!-- EOF: refactor prio 4 -->

  <app-preview-window-cdd-sharing
    [element]="element"
    (contentReturn)="onContentReturnFromElements($event)"
    (displayLoader)="onDisplayLoaderFromElements($event)"
    *ngSwitchCase="'evidencePreview'"
  >
  </app-preview-window-cdd-sharing>

  <app-risk-assessment-preview
  [element]="element"
  (contentReturn)="onContentReturnFromElements($event)"
  (displayLoader)="onDisplayLoaderFromElements($event)"
  *ngSwitchCase="'RiskAssessmentPreview'">
</app-risk-assessment-preview>

  <app-preview-window-id-verification
    [element]="element"
    (contentReturn)="onContentReturnFromElements($event)"
    (displayLoader)="onDisplayLoaderFromElements($event)"
    *ngSwitchCase="'evidencePreviewAlternate'"
  >
  </app-preview-window-id-verification>

  <div class="position-relative" *ngSwitchCase="'newEvidenceShare'">
    <div class="existing-providers-overlay"></div>
    <div class="single-checkbox-wrapper no-border position-relative">
      <!-- [attr.clickaction]="checkbox.params.onclick" -->
      <input
        class="custom-checkbox"
        type="checkbox"
        id="share_new_document_existing_sp_check"
        name="share_new_document_with_existing_sp"
        value="yes"
        (click)="OnContentElementClick($event)"
        [checked]="true"
        (change)="toggleOverlayAccess()"
      />
      <label
        *ngIf="!element.params.hidecheckbox"
        class="custom-checkbox-label"
        for="share_new_document_existing_sp_check"
        >{{ "replace.title4" | translate }}</label
      >
      <p
        [ngClass]="!element.params.hidecheckbox ? 'ml-4 mt-3 p-small-font' : ''"
      >
        {{ "replace.content" | translate }}
      </p>
    </div>

    <div
      class="d-flex justify-content-center align-items-center element-wrapper no-min-height mt-0"
    >
      <div id="previewAccessTabContent" class="access-content">
        <div class="access-labels p-0">
          <div class="label-fund">{{ "appSpecific.Profile" | translate }}</div>
          <div class="label-provider">
            {{ "appSpecific.ServiceProvider" | translate }}
          </div>
          <div class="label-granted-on text-center">
            {{ "evidencePreview.accessLabel3" | translate }}
          </div>
          <div class="label-granted-on text-center">
            {{ "appSpecific.Updates" | translate }}
          </div>
        </div>
        <div
          *ngFor="let acessInfo of element.params.accessInformation"
          class="access-values"
        >
          <div
            *ngFor="let provider of acessInfo.providers; let i = index"
            class="access-provider"
          >
            <div class="label-fund">
              <span *ngIf="i === 0">{{ acessInfo.fund }}</span>
            </div>
            <div class="content-fund">
              {{ provider.name }}
            </div>
            <div class="content-fund text-center">
              {{ provider.accessGranted }}
            </div>
            <div class="access-toggle text-center">
              <label class="switch">
                <input
                  [attr.data-accessKey]="acessInfo.accessKey"
                  [attr.data-evidenceKey]="element.params.evidenceKey"
                  type="checkbox"
                  [checked]="provider.access"
                  (click)="
                    OnToggleDocumentAccessClick(
                      provider.id,
                      provider.toglleActionEnabled,
                      $event,
                      acessInfo.accessKey,
                      undefined,
                      element.params.requestKey
                    )
                  "
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-flex justify-content-center align-items-center element-wrapper document-preview-container"
    *ngSwitchCase="'evidenceVersion'"
  >
    <img style="max-width: 100px" src="/assets/images/spinner-green.svg" />
  </div>

  <div *ngSwitchCase="'evidenceList'" class="document-entry-list">
    <div
      *ngIf="!element.investorSide || element.investorSide === false"
      class="document-entry-list"
    >
      <!-- PARSE EVIDENCE ON SERVICE PROVIDER SIDE -->
      <div
        *ngFor="let evidence of element.evidenceTypes"
        [attr.data-evidenceKey]="evidence.key"
        class="document-inner-entry"
        (click)="
          onReviewEvidenceClick(
            evidence.key,
            element.requestKey,
            evidence['documents'],
            true,
            true
          )
        "
      >
        <div class="document-upload-entry-image q-icon">
          <img
            [src]="
              idVerificationService.returnimgrlByType(
                evidence.customEvidenceType
                  ? evidence.customEvidenceType
                  : evidence.type
              )
            "
          />
        </div>
        <div class="document-upload-entry-detail">
          <h5 class="document-name">
            {{
              parseBackendName(
                evidence.customEvidenceType
                  ? evidence.customEvidenceType
                  : evidence.type
              )
            }}
          </h5>
        </div>
      </div>
      <div *ngIf="!element.evidenceTypes">
        <div class="d-flex absolute-spinner-wrapper-centered">
          <img
            src="/assets/images/spinner-green.svg"
            width="50px"
            height="50px"
            class="d-flex align-self-center mx-auto"
          />
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'loadingScreen'">
    <div
      *ngIf="!element.params.isLoading"
      class="d-flex absolute-spinner-wrapper-centered"
    >
      <img
        src="/assets/images/spinner-green.svg"
        width="50px"
        height="50px"
        class="d-flex align-self-center mx-auto"
      />
    </div>
  </div>

  <app-kompany-seach
    *ngSwitchCase="'kompanyInput'"
    [element]="element"
    (contentReturn)="onContentReturnFromElements($event)"
  >
  </app-kompany-seach>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'button_group_review_group'"
  >
    <!-- BUTTON GROUP -->
    <span
      class="wrapper"
      *ngIf="element.display === undefined || element.display === true"
    >
      <span *ngFor="let button of element.params">
        <button
          [ngClass]="
            button.class
              ? 'form-button ' + button.class
              : 'form-button form-button-1'
          "
          *ngIf="button.display"
          [attr.id]="button.id"
          [attr.clickmethod]="button.action"
          [attr.type]="button.type"
          (click)="
            OnButtonClickReviewGroup(
              button.requestKey,
              button.evidence,
              button.action
            )
          "
        >
          {{ button.content }}
        </button>
      </span>
    </span>
  </div>

  <div
    [attr.tabGroup]="element.tabGroup"
    [attr.tabKey]="element.tabKey"
    class="element-wrapper"
    [ngClass]="[
      element.class ? element.class : '',
      !element.params.tags || element.params.tags.length <= 0 ? '' : ''
    ]"
    *ngSwitchCase="'person_tags'"
  >
    <div class="purposes-container filter-tags-container">
      <h4 *ngIf="element.params.showTitle" class="tags-title">Tags: </h4>
      <div
        [ngClass]="element.params.showDiagnostics ? 'mt-0' : ''"
        class="purpose-tags-container purpose-tags-container-d"
      >
        <div
          class="purpose-tag-wrapper flex-wrap d-flex align-items-center"
          [ngClass]="
            element.params.showDiagnostics ? 'diagnostics-padding' : ''
          "
        >
          <div
            [attr.tag-v]="tag"
            [ngClass]="[
              tag,
              !element.params.showDiagnostics ? 'purpose-tag-no-close' : ''
            ]"
            class="purpose-tag-wrapper-sub"
            *ngFor="let tag of element.params.tags"
          >
            <div [attr.tag-value]="tag" class="purpose-tag">
              <span class="tag-value">{{
                helperService.parseTagNameForFE(tag)
              }}</span>
              <a
                *ngIf="element.params.allowDelete"
                (click)="element.params.investor ? onInvestorTagDelete($event) :  onPersonTagDelete($event)"
                class="close-button delete-tag-button"
              >
                <img src="assets/images/close_form_icon.png" />
              </a>
            </div>
          </div>
          <div
            [ngClass]="tag"
            class="purpose-tag-wrapper-sub"
            *ngFor="let tag of element.params.unaddedTags"
          >
            <div [attr.tag-value]="tag" class="purpose-tag unaded-tag">
              <span class="tag-value unaded-tag-value">{{
                helperService.parseTagNameForFE(tag)
              }}</span>
              <a
                (click)="
                  onPersonTagUnadedDelete($event, i, element.params.onAddPerson)
                "
                class="close-button"
              >
                <span>x</span>
              </a>
            </div>
          </div>
          <a
            *ngIf="element.params.showAddButton"
            [attr.clickMethod]="element.params.investor ? 'idOnAddTagsInvestor' : 'idOnAddTags'"
            (click)="OnButtonClick($event)"
            class="add-tags-button"
          >
          </a>
        </div>
        <div
          class="suggested-tag-wrapper mt-0"
          *ngIf="
            element.params.suggestedTags &&
            element.params.suggestedTags.length > 0
          "
        >
          <div class="suggested-tags-label mt-2">Suggestions:</div>
          <a
            [attr.clickMethod]="element.params.investor ? 'idOnAddSuggestedTagCDD' : 'idOnAddSuggestedTag'"
            [attr.tagValue]="suggestedTag.tag"
            (click)="OnButtonClick($event)"
            class="suggested-tag mt-2"
            *ngFor="
              let suggestedTag of element.params.suggestedTags | slice: 0 : 15
            "
          >
            {{ helperService.parseTagNameForFE(suggestedTag.tag) }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div
    class="element-wrapper"
    style="margin-top: -5px"
    *ngSwitchCase="'diagnostics_button'"
  >
    <a
      [attr.clickMethod]="isIdVerification()? 'onOpenDiagnostics' : 'onOpenDiagnosticsOnBoard'"
      (click)="OnButtonClick($event)"
      class="diagnostics-button"
    >
    </a>
  </div>

  <!-- TAG SELECT IN REPORT GENERATION -->
  <div
    class="element-wrapper"
    [ngClass]="[element.class ? element.class : '']"
    *ngSwitchCase="'project_tags_selection'"
  >
    <div
      class="purposes-container checkbox-container"
      [attr.isRequired]="element.params.isRequired"
    >
      <div class="input-label font-weight-bold">
        Include verifications with tag
        <sup *ngIf="element.params.isRequired" class="ml-0 sup-required">*</sup>
      </div>
      <a
        [ngClass]="
          element.params.tags === undefined || element.params.tags.lenght === 0
            ? 'select-disabled'
            : ''
        "
        (click)="
          element.params.tags ? toggleClass('checkboxlistt', 'd-none') : ''
        "
        class="purposes-button purposes-button-form"
      >
        {{
          element.params.tags === undefined
            ? "There are no available tags in the current project"
            : element.params.tags.length === 0
            ? "There are no available tags in the current project"
            : "Please choose"
        }}
        <div class="purposes-arrow-icon"></div>
      </a>
      <div
        [attr.data-purposeEvidenceKey]="'checkboxlistt'"
        class="purposes-checkbox-list d-none"
      >
        <div *ngFor="let tag of element.params.tags" class="purposes-checkbox">
          <div class="single-checkbox-wrapper">
            <input
              class="custom-checkbox purpose-checkbox"
              type="checkbox"
              [name]="tag"
              [value]="tag"
              [id]="tag"
              (change)="OnChangeSelect($event); onSelectPurpose($event, true)"
              [attr.data-purposeId]="tag"
            />
            <label [for]="tag" class="custom-checkbox-label">{{
              helperService.parseTagNameForFE(tag)
            }}</label>
          </div>
        </div>
      </div>
      <div
        class="error-field"
        [attr.validatorMessage]="element.params.validatorMessage"
        [attr.validatorMessageField]="element.params.validatorMessage"
      ></div>
      <div class="purpose-tags-container">
        <div
          class="purpose-tag-wrapper"
          *ngFor="let tag of element.params.tags"
        >
          <div [attr.data-purposeId]="tag" class="purpose-tag d-none">
            {{ helperService.parseTagNameForFE(tag) }}
            <a
              (click)="element.investorSide ? '' : onPurposeDelete($event)"
              class="close-button"
            >
              <img
                [attr.data-forPurpose]="tag"
                src="assets/images/close_form_icon.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'diagnostics_detail'"
  >
    <div class="diagnostics-pairing-container">
      <div *ngFor="let pairing of element.params.pairings">
        <div
          *ngIf="pairing.value !== undefined && pairing.value !== ''"
          class="diagnostics-pairing"
        >
          <div class="diagnostics-label">
            {{ pairing.label }}
          </div>
          <div class="diagnostics-value" [innerHTML]="pairing.value">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="element-wrapper showOrHideDetails"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'show_or_hide_details'"
  >
    <a
      [attr.clickMethod]="element.method"
      (click)="OnButtonClick($event)"
      class="more-details-button"
    >
      <img src="/assets/images/ico_green.svg" />
      <span>{{ element.text ? element.text : "Add more details" }}</span>
    </a>
    <a
      [attr.clickMethod]="element.method"
      (click)="OnButtonClick($event)"
      class="more-details-button d-none"
    >
      <img src="/assets/images/ico_green.svg" />
      <span>Less details</span>
    </a>
  </div>

  <div
    style="padding-left: 5px"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'custom_evidecne_type_select'"
  >
    <div
      class="counter-container custom-evidence-counter-container checkbox-container"
      [id]="element.id ? element.id : ''"
    >
      <div
        class="single-checkbox-wrapper"
        *ngFor="let checkbox of element.params.checkboxes"
      >
        <div class="custom-counter">
          <div
            [ngClass]="checkbox.params.counter ? 'd-none' : 'd-flex'"
            class="counter-passive"
          >
            <button
              (click)="increaseCountEmpty($event, true)"
              class="button-plus-empty"
            >
              <span>+</span>
            </button>
          </div>
          <div
            [ngClass]="checkbox.params.counter ? 'd-flex' : 'd-none'"
            class="counter-active"
          >
            <button
              (click)="
                increaseCountMinus(
                  $event,
                  checkbox.params.counter ? checkbox.params.counter : 0,
                  true
                )
              "
              class="button-minus button-inactive"
            >
              <span>-</span>
            </button>
            <input
              id="{{ checkbox.params.id }}"
              [attr.baseValue]="
                checkbox.params.counter ? checkbox.params.counter : 0
              "
              type="text"
              readonly="readonly"
              class="custom-counter-count"
              value="{{
                checkbox.params.counter ? checkbox.params.counter : 0
              }}"
            />
            <input
              *ngFor="let evidenceKey of checkbox.params.dataEvidenceKeys"
              [attr.data-Oftype]="checkbox.params.id"
              readonly="readonly"
              class="hidden-counter-field"
              value="{{ evidenceKey }}"
              type="hidden"
            />
            <button
              (click)="increaseCountPlus($event, true)"
              class="button-plus"
            >
              <span>+</span>
            </button>
          </div>
          <div class="custom-counter-label">{{ checkbox.params.label }}</div>
        </div>
        <div
          *ngIf="checkbox.params.existingCustomTypes.length"
          class="custom-evidence-types-input-wrapper"
        >
          <div
            [ngClass]="
              existingCustomType.customEvidenceType ? 'evd-disabled' : ''
            "
            class="mt-2 custom-evidence-types-container"
            *ngFor="
              let existingCustomType of checkbox.params.existingCustomTypes;
              let i = index
            "
          >
            <!-- <span class="input-label"> Custom evidence type </span> -->
            <input
              [value]="existingCustomType.customEvidenceType"
              [disabled]="existingCustomType.customEvidenceType"
              type="text"
              class="text-input"
              [id]="'customEvidenceType' + i + 1"
            />
            <div class="custom-evidence-delete-button d-none"></div>
          </div>
        </div>
        <div
          *ngIf="!checkbox.params.existingCustomTypes.length"
          class="custom-evidence-types-input-wrapper d-none"
        >
          <div class="custom-evidence-types-container mt-3">
            <!-- <span class="input-label"> Custom evidence type </span> -->
            <input
              type="text"
              class="text-input"
              [id]="'customEvidenceType' + '1'"
              onkeydown="return (event.keyCode!=13);"
            />
            <div class="custom-evidence-delete-button"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="element-wrapper sms-area-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'verification_code'"
  >
    <div id="SMSArea" class="d-flex SMSArea">
      <div *ngFor="let number of [0, 1, 2, 3, 4, 5]">
        <input
          (keyup)="vCodeOnKeyUp($event)"
          (keydown)="vCodeOnKeyDown($event)"
          (onfocus)="vCodeOnFocus($event)"
          type="text"
          maxlength="1"
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
          class="smsCode text-center rounded-lg"
        />
      </div>
    </div>
    <div *ngIf="element.params.errorMessage" class="button-error-field">
      {{ element.params.errorMessage }}
    </div>
  </div>

  <div
    class="element-wrapper"
    [ngClass]="element.class ? element.class : ''"
    *ngSwitchCase="'stacked_pill_choices'"
  >
    <ul class="stacked_pill_choices nav flex-column flex-nowrap">
      <li
        class="nav-item"
        *ngFor="let pill of element.params.pills"
        [ngSwitch]="true"
      >
        <div
          [ngClass]="{
            active: pill.isActive,
            completed: pill.status === 'COMPLETED',
            rejected: pill.rejected,
            warning: pill.status === 'WARNING',
            started: pill.status === 'STARTED'
          }"
          *ngSwitchCase="
            pill.substeps.length === 0 ||
            (pill.substeps.length > 0 && !pill.isActive)
          "
        >
          <a
            class="d-flex p-0 flex-row nav-link"
            [attr.clickmethod]="pill.isActive ? pill.clickAction : null"
            [attr.type]="pill.type"
            (click)="OnComplexAncorClick($event)"
          >
            <div class="d-flex pillStatus">
              <div></div>
            </div>
            <div class="d-flex flex-column pillContent pl-3 flex-grow-1">
              <div class="pill-title">{{ pill.title }}</div>
              <div [innerHTML]="pill.subTitle" class="subtitle"></div>
              <div class="hr my-3"></div>
            </div>
          </a>
        </div>
        <div
          [ngClass]="{
            active: pill.isActive,
            completed: pill.status === 'COMPLETED',
            rejected: pill.rejected,
            warning: pill.warning,
            started: pill.status == 'STARTED'
          }"
          [attr.type]="pill.type"
          *ngSwitchCase="pill.substeps.length > 0 && pill.isActive"
        >
          <div class="d-flex p-0 flex-row nav-link">
            <div class="d-flex pillStatus">
              <div></div>
            </div>
            <div class="d-flex flex-column pillContent pl-3 flex-grow-1">
              <div class="pill-title">{{ pill.title }}</div>
              <div [innerHTML]="pill.subTitle" class="subtitle"></div>

              <div class="subtitle mt-3">
                {{ pill.subStepsLabel }}
              </div>
              <div class="element-wrapper">
                <!-- Radio Group -->
                <div *ngFor="let radio of pill.substeps">
                  <input
                    class="custom-radio-button"
                    type="radio"
                    id="{{ pill.type }}_{{ radio.id }}"
                    name="{{ pill.type }}"
                    value="{{ radio.clickAction }}"
                    (click)="
                      setStepMethod(radio.clickAction, pill.type, pill.isActive)
                    "
                  />
                  <label
                    class="custom-radio-label"
                    for="{{ pill.type }}_{{ radio.id }}"
                  >
                    <span class="font-weight-bold">{{ radio.title }}</span>
                    <div class="d-flex flex-row description align-items-center">
                      <img
                        src="{{ radio.logo }}"
                        [attr.width]="radio.logoWidth"
                        [attr.height]="radio.logoWidth"
                        *ngIf="radio.logo"
                        class="mt-1 mr-2"
                      />
                      <div class="d-flex">
                        {{ radio.description }}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div
                class="substep-continue-button disabled mt-2"
                id="{{ pill.type }}_continue"
                [attr.type]="pill.type"
                (click)="continueStep($event)"
              >
                Continue
              </div>
              <div class="hr my-3"></div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div *ngSwitchCase="'numberedList'" class="element-wrapper">
    <div
      class="history-entry history-entry-alternate"
      [ngClass]="entry.class"
      *ngFor="let entry of element.params.entries"
    >
      <h3 class="entry-title">{{ entry.title }}</h3>
      <p class="entry-text">{{ entry.text }}</p>
      <div class="history-entry-dot history-entry-dot-slim">
        <div>{{ entry.count }}</div>
      </div>
    </div>
  </div>

  <div
    *ngSwitchCase="'questionareVersions'"
    class="element-wrapper questionare-versions-wrapper mb-4"
  >
    <div class="hits-wrapper">
      <div
        *ngFor="let version of element.params.versions; let i = index"
        class="hit-name-container"
      >
        <a
          class="document-category-main"
          style="max-width: 100%; min-width: auto"
        >
          <h3>{{ version.record.name }}</h3>
          <div class="questionare-version-date">
            Version date:
            {{ helperService.formatISODate(version.record.lastModifiedOn) }}
          </div>
          <div
            [id]="version.key"
            [attr.clickmethod]="'fetchOlderVersion'"
            (click)="OnButtonClick($event)"
            style="z-index: 100; top: 0; left: 0"
            class="clickable-element h-100 w-100 position-absolute"
          ></div>
        </a>
      </div>
    </div>
  </div>

  <!-- VERIFICATION SUBJECT SIDE: CONTRACT-->
  <div
    *ngSwitchCase="'documentVsSideVerification'"
    class="contractVsSide evidence-is-active"
    [ngClass]="[
      element.class ? element.class : '',
      element.params.loadingFromBe ? 'loading-from-be' : ''
    ]"
  >
    <div class="visual-steps-overlay" *ngIf="!element.hideConfirmation">
      <div class="visual-steps-wrapper">
        <h3 class="result-h3">
          {{ element.params.labelElectronicallySigning }}
        </h3>
        <img
          class="result-image request-share-img"
          src="/assets/images/illustration-contract.svg"
        />
        <div class="contract-steps">
          <div class="visual-contract-step step-in-progress">
            {{
              element.params.isMultipleContracts
                ? ("IDPCS.contract.label5" | translate)
                : ("IDPCS.contract.label6" | translate)
            }}
          </div>
          <div class="visual-contract-step">
            {{ "IDPCS.contract.content2" | translate }}
          </div>
          <div class="visual-contract-step">
            {{
              element.params.isMultipleContracts
                ? ("IDPCS.contract.label14" | translate)
                : ("IDPCS.contract.label15" | translate)
            }}
          </div>
        </div>
        <h4 class="result-paragraph text-center">
          {{ element.params.labelPleaseWait }}
        </h4>
      </div>
    </div>
    <div class="visual-steps-overlay" *ngIf="element.hideConfirmation">

      <div class="loading">
        <div
        class="px-3"
        style="
          font-size: 22px;
          font-weight: 700;
          margin-top: 30px;
          text-align: center;
        "
      >
        {{ "common.pleaseWait" | translate }}
      </div>
        <img
          class="result-image request-share-img"
          src="/assets/images/spinner-green.svg"
        />
      </div>
    </div>
    <form class="form-container">
      <!-- <div class="document-upload-side-overlay">
    <img src="/assets/images/spinner-green.svg" width="65px" height="65px" class="my-auto mx-auto">
  </div> -->
      <div
        class="mt-2 mt-lg-0 d-flex justify-content-between align-items-center input-label-large"
      >
        <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
        <h4 class="contract-signing-title mx-auto">
          {{ element.params.formTitle }}
        </h4>
        <!-- <div>

    </div> -->
      </div>
      <div class="element-wrapper" style="overflow: hidden">
        <div
          *ngIf="!element.signedMode"
          style="width: 100%; border: 0"
          class="contract-preview-html d-flex flex-column"
          [ngStyle]="element.contractStyle"
        >
          <ul
            class="nav nav-tabs"
            id="previewMultipleContractsTabs"
            role="tablist"
            *ngIf="element.contractTemplates"
          >
            <li
              class="nav-item"
              *ngFor="
                let contractTemplate of element.contractTemplates;
                let i = index
              "
            >
              <a
                class="nav-link"
                [innerHTML]="
                  'IDPCS.contract.label16' | translate: { number: i + 1 }
                "
                [ngClass]="{ active: i === 0 }"
                id="tab{{ i }}"
                data-toggle="tab"
                href="#tab-pane{{ i }}"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
              </a>
            </li>
          </ul>
          <div class="tab-content" id="previewMultipleContractsTabsContent">
            <div
              class="tab-pane fade show"
              [ngClass]="{ active: i === 0 }"
              id="tab-pane{{ i }}"
              role="tabpanel"
              aria-labelledby="tab{{ i }}"
              [innerHTML]="contractTemplate"
              *ngFor="
                let contractTemplate of element.contractTemplates;
                let i = index
              "
            ></div>
          </div>
          <div
            [innerHTML]="element.contractTemplate"
            *ngIf="element.contractTemplate"
          ></div>
          <!-- <button
    [attr.clickmethod]="'downloadContractPreview'" (click)="OnButtonClick($event)"
    class="form-button form-button-1 evidence-submit-button mt-1 mx-auto"
    *ngIf="!element.signedMode"
    >
    Download full contract
  </button> -->
        </div>
        <div
          *ngIf="element.signedMode"
          style="width: 100%; height: 60vh; border: 0"
        >
          <div class="completed-contract-wrapper">
            <img
              class="completed-image"
              src="/assets/images/request_screening.svg"
            />
            <h4 class="px-4 completed-text">
              {{ "IDPCS.contract.content3" | translate }}
            </h4>
          </div>
        </div>
        <div class="input-fields-wrapper w-100">
          <!-- INPUT FIELDS -->
          <div
            class="fields-wrapper d-flex flex-column flex-md-row w-100 align-items-end pb-2"
          >
            <!-- <div class="preview-value-list d-flex" *ngIf="!element.editMode && !element.signedMode">
        <div class="element-wrapper input-transparent">
          <span class="input-label font-weight-bold">First name</span>
          <div>{{element.params.firstName}}</div>
        </div>
        <div class="element-wrapper input-transparent">
          <span class="input-label font-weight-bold">Last name</span>
          <div>{{element.params.lastName}}</div>
        </div>
        <div class="element-wrapper input-transparent" >
          <span class="input-label font-weight-bold">Date of birth</span>
          <div>{{element.params.dateOfBirth}}</div>
        </div>
      </div> -->
            <div
              class="preview-value-list d-flex"
              *ngIf="
                !element.editMode && element.signedMode && !element.quickPreview
              "
            >
              <div
                class="element-wrapper input-transparent signed-successfully"
              >
                <div class="signed-confirmation">
                  <img
                    src="/assets/images/check.svg"
                    width="16px"
                    height="16px"
                    class="mr-3"
                  />
                  <span
                    [innerHTML]="
                      'IDPCS.contract.content4'
                        | translate: { date: element.params.today }
                    "
                  ></span>
                </div>
              </div>
            </div>
            <div class="btn-wrapper flex-fill">
              <!-- BUTTONS -->
              <div
                class="d-flex align-items-center justify-content-end w-100"
                *ngIf="element.editMode && !element.loaderMode"
              >
                <button
                  [attr.clickmethod]="'confirmContractSigning'"
                  (click)="OnButtonClick($event)"
                  type="submit"
                  class="form-button form-button-1 mt-4 evidence-submit-button"
                >
                  {{ "common.save" | translate }}
                </button>
                <button
                  type="submit"
                  class="form-button form-button-0 mr-0 mt-4 evidence-submit-button"
                >
                  {{
                    element.params.isMultipleContracts
                      ? ("IDPCS.contract.label17" | translate)
                      : ("IDPCS.contract.label18" | translate)
                  }}
                </button>
              </div>
              <div
                class="d-flex align-items-center justify-content-end w-100"
                *ngIf="
                  !element.editMode &&
                  !element.signedMode &&
                  !element.loaderMode
                "
              >
                <div
                  class="element-wrapper mt-4"
                  *ngIf="!element.params.signedOn"
                >
                  <a
                    href="#"
                    [attr.clickmethod]="'onEditInformation'"
                    class="end-integrity-check"
                    (click)="OnButtonClick($event)"
                  >
                    {{ "common.edit" | translate }}
                  </a>
                </div>
                <div class="element-wrapper mt-4">
                  <button
                    [attr.clickmethod]="'signContract'"
                    (click)="OnButtonClick($event)"
                    type="submit"
                    class="form-button form-button-1 mr-0 evidence-submit-button"
                    *ngIf="
                      !element.quickPreview &&
                      (projectType == 'id-verification' ||
                        (projectType == 'funds' &&
                          element.params.isSigner &&
                          (element.params.signedOn ||
                            element.params.isInvestor)))
                    "
                  >
                    {{
                      element.params.isMultipleContracts
                        ? ("IDPCS.contract.label17" | translate)
                        : ("IDPCS.contract.label18" | translate)
                    }}
                  </button>
                  <button
                    [attr.clickmethod]="'signContract'"
                    (click)="OnButtonClick($event)"
                    type="submit"
                    class="form-button form-button-1 mr-0 evidence-submit-button"
                    *ngIf="element.quickPreview"
                  >
                    {{ "IDPCS.contract.labelGeneratePreview" | translate }}
                  </button>
                </div>
              </div>
              <div
                class="d-flex align-items-center justify-content-end w-100"
                *ngIf="
                  !element.editMode && element.signedMode && !element.loaderMode
                "
              >
                <button
                  [attr.clickmethod]="'onCloseContract'"
                  (click)="OnButtonClick($event)"
                  type="submit"
                  class="form-button form-button-2 mt-4 evidence-submit-button"
                  *ngIf="
                    !element.quickPreview &&
                    (projectType == 'id-verification' ||
                      (projectType == 'funds' && element.params.isInvestor))
                  "
                >
                  {{ "common.close" | translate }}
                  <span class="close-contract-counter"
                    >({{ element.params.closeContractCounter }}s)</span
                  >
                </button>
                <button
                  [attr.clickmethod]="'onCloseContract'"
                  (click)="OnButtonClick($event)"
                  type="submit"
                  class="form-button form-button-2 mt-4 evidence-submit-button"
                  *ngIf="element.quickPreview"
                >
                  {{ "evidencePreview.button3" | translate }}
                </button>
                <!-- <button
            [attr.clickmethod]="'onDownloadContract'" (click)="OnButtonClick($event)"
            type="submit"
            class="form-button form-button-1 mt-4 evidence-submit-button"
          >
            Download
          </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <app-investor-select-entity-type
    *ngSwitchCase="'selectEntityType'"
    [element]="element"
    (contentReturn)="onContentReturnFromElements($event)"
  ></app-investor-select-entity-type>

  <div *ngSwitchCase="'tinyMCEDescription'" class="mt-4 tiny-mce-investor">
    <div
      [innerHTML]="
        this.sanitizer.bypassSecurityTrustHtml(element.params.content)
      "
    ></div>
  </div>

  <div *ngSwitchCase="'tinyMCE'" class="element-wrapper">
    <textarea id="{{ element.params.id }}" style="display: none">{{
      tinyHtml
    }}</textarea>
    <span class="input-label {{ element.params.labelClass }}">{{
      element.params.label
    }}</span>
    <editor
      (onInit)="setTinyVal(element.params.value)"
      [(ngModel)]="this.tinyHtml"
      initialValue="test"
      [init]="{
        base_url: '/tinymce',
        suffix: '.min',
        height: 200,
        plugins: 'lists textcolor',
        font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
        menubar: false,
        toolbar:
          ' bold italic forecolor | fontsize |  h1 h2 h3 | \
        bullist numlist  | \
        alignleft aligncenter alignright alignjustify | outdent indent | removeformat'
      }"
    ></editor>
    <!-- plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ], -->
  </div>
  <div
    class="{{ element.class }} edit-workflow-step"
    *ngSwitchCase="'editWorkflowStep'"
    [attr.workflowStep]="element.params.id"
  >
    <div class="d-flex justify-content-between">
      <h5 class=" {{ element.workflowStepClass }}">
        {{ element.params.workflowStep }}
      </h5>
      <app-toggle-button
        [toggleChecked]="element.params.workflowStepChecked"
        (toggle)="
          element.params.workflowStepChecked =
            !element.params.workflowStepChecked
        "
        class="ml-2 workflowStepToggle"
      ></app-toggle-button>
    </div>
    <div *ngIf="element.params.workflowStepChecked" class="mb-3 mt-1">
      <div class="w-25 mb-2">
        <span class="input-label"> Choose language: </span>
        <!-- (change)="OnChangeSelect($event); onChangeSelectLegalType($event)" -->
        <span class="select-wrapper">
          <select
            class="select-input input-transparent"
            id="language{{ element.params.id }}"
            [(ngModel)]="element.params.defaultLng"
          >
            <option value="en">English</option>
            <option value="nl">Dutch</option>
          </select>
        </span>
      </div>
      <small>Descriptive text shown</small>
      <textarea
        [(ngModel)]="element.params.enDescription"
        class="text-input-div rounded"
        id="en{{ element.params.id }}"
        rows="7"
        [ngClass]="{ 'd-none': element.params.defaultLng !== 'en' }"
        >{{ element.params.enDescription }}</textarea
      >
      <textarea
        [(ngModel)]="element.params.nlDescription"
        class="text-input-div rounded"
        id="nl{{ element.params.id }}"
        rows="7"
        [ngClass]="{ 'd-none': element.params.defaultLng !== 'nl' }"
        >{{ element.params.nlDescription }}</textarea
      >
    </div>
  </div>

  <div *ngSwitchCase="'risk_suggestions'">
    <div
      class="suggested-rr-wrapper"
      *ngIf="
        element.params.listOfSuggestions &&
        element.params.listOfSuggestions.length > 0
      "
    >
      <div class="suggested-tags-label mt-2">Suggestions:</div>
      <a
        [attr.tagValue]="suggestedRisk.riskRating"
        [attr.colorValue]="suggestedRisk.color"
        (click)="idOnAddSuggestedRR($event)"
        class="suggested-rr mt-2"
        *ngFor="
          let suggestedRisk of element.params.listOfSuggestions | slice: 0 : 15
        "
      >
        {{ helperService.parseTagNameForFE(suggestedRisk.riskRating) }}
      </a>
    </div>
  </div>

  <div class="{{ element.class }} toggle-btn" *ngSwitchCase="'toggle'">
    <div class="{{element.toggleWrapperClass}}">
      <app-toggle-button
        [toggleChecked]="element.params.toggleOn"
        [toggleDisabled]="element.params.toggleDisable"
        [toggleTooltip]="
          element.params?.disableToggleTooltip
            ? element.params.disableToggleTooltip
            : ''
        "
        (toggle)="element.params.additionalInfo ? openAdditionalInfo() : element?.params?.toggleFunction ? element.params.toggleFunction() : noop() "
        [ngClass]="element.params.toggleClass"
        toggleId="{{ element.params.id }}"
      ></app-toggle-button>
      <span class="tooggle-text">{{ element.params.monitoringText }}</span>
    </div>
    <div *ngIf="element.params.additionalInfo">
      <button #additionalInfoBtn type="button" class="d-none" data-toggle="collapse" data-target="#MoreInfo"></button>
    <div id="MoreInfo" class="collapse mt-2 ml-2" [ngClass]="{'show': element.params.toggleOn}">
      <app-content-elements
      [contentDOM]="element.params.additionalInfoData"
    ></app-content-elements>
    </div>
    </div>
  </div>

  <div
    class="{{ element.class }} form-clarification p-3"
    *ngSwitchCase="'collapseInputFields'"
  >
    <div
      [attr.href]="'#inputFields'"
      data-toggle="collapse"
      role="button"
      aria-expanded="true"
      aria-controls="multiCollapseExample1"
      class="d-flex collapse-header"
    >
      <h4 class="form-clarification-title mb-0">
        {{ element.params.label }}
      </h4>
      <img
        src="assets/images/arrow-up-teal.svg"
        width="20px"
        height="20px"
        class="icon-tooltip dropdown"
      />
    </div>
    <div class="collapse clarification-content" [id]="'inputFields'">
      <div
        *ngFor="let input of element.params.inputFields"
        class="input-field-wrapper mb-3"
      >
        <span class="input-label d-flex {{ input.labelClass }}"
          >{{ input.label }}
          <app-tooltip
            *ngIf="input.tooltip"
            [tooltipText]="input.tooltip"
            [icon]="input.tooltipIcon"
            class="d-flex"
          ></app-tooltip>
          <sup
            *ngIf="input.required"
            class="sup-required sup-required-alternate"
            >*</sup
          >
        </span>
        <input
          type="text"
          class="text-input"
          id="{{ input.id }}"
          name="{{ input.name }}"
          value="{{ input.content }}"
          [attr.isValid]="true"
          [readonly]="input.readOnly"
          [attr.isRequired]="input.required"
          [attr.isMinLength]="input.minLength"
          [attr.isMaxLength]="input.maxLength"
          [attr.patternMatch]="input.patternMatch"
          [attr.enterkeyaction]="input.onenterkeyaction"
          [attr.enterChangeAction]="input.onEnterChangeAction"
          [attr.autofocus]="input.autofocus"
          (input)="OnChange($event)"
          (keyup)="OnInputKeyUp($event)"
          (blur)="OnInputBlur($event)"
        />
      </div>
    </div>
  </div>

  <div *ngSwitchDefault class="text-warning">
    THIS SHOULD NOT BE DISPLAYED!!! {{ element.type }}
  </div>
</div>
