import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { UserService } from "src/app/user.service";
import { ElementsService } from "../elements.service";

@Component({
  selector: "app-template-creation-config",
  templateUrl: "./template-creation-config.component.html",
  styleUrls: ["./template-creation-config.component.scss"],
})
export class TemplateCreationConfigComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  evidenceTypes;
  requestType;
  showDVTab;
  currentlySelectedDVTab;
  purposesOfUse;
  relatedPartyTypeList;
  isFinalStep;
  existingData;
  existingVerificationTypes;
  existingEvidenceTypes;
  existingIDType;
  existingIDAIType;
  existingAddressType;
  existingContractType;
  existingAPSType;
  existingProfileVerificationType;
  noExistingData;
  existingAdddressDocuments;
  mainType;
  evidenceTypesFirst;
  evidenceTypesSecond;
  evidenceTypesThird;
  generalErrorState;
  editMode;
  customEvidenceTypes = [];
  ConfigLightboxParam;

  templatenotOlderThan;
  apsIsMonitored;
  apsScreeningScope : number = 3;
  constructor(
    private idVerificationService: IdVerificationService,
    public elementService: ElementsService,
    public helperService: HelperServiceService,
    private userService: UserService,
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.ConfigLightboxParam = {
      display: false,
      top: 0,
      left: 0,
      currentEvidenceConfig: {
        params: {},
      },
    };
    // values for not older than option
    this.templatenotOlderThan =
      this.idVerificationService.getTemplatenotOlderThan();
    // get the purposes of use
    this.purposesOfUse = [...this.idVerificationService.parsePurposeOfUse([])];
    // find if request is the last one
    const localTemplateData = this.idVerificationService.getLocalTemplateData();
    this.mainType = localTemplateData.activeEdit.mainType;
    console.log('mmarker236');
    console.log(this.mainType);
    if (localTemplateData.editMode) {
      this.editMode = true;
    }
    if (localTemplateData.activeEdit) {
      if (localTemplateData.activeEdit.mainType === "mainRequest") {
        if (
          localTemplateData.activeEdit.count ===
          localTemplateData.mainRequestTemplates.length - 1
        ) {
          if (
            localTemplateData.relatedPartyTemplates &&
            localTemplateData.relatedPartyTemplates.length > 0
          ) {
            this.isFinalStep = false;
          } else {
            this.isFinalStep = true;
          }
        } else {
          this.isFinalStep = false;
        }
      } else {
        // it's a related request
        if (
          localTemplateData.activeEdit.count ===
          localTemplateData.relatedPartyTemplates.length - 1
        ) {
          this.isFinalStep = true;
        } else {
          this.isFinalStep = false;
        }
      }
    } else {
      this.isFinalStep = true;
    }
    // get the checkbox types
    this.requestType = this.element.params.requestType;
    // see if it's a natural related party role
    if (
      this.requestType === "NaturalPerson" ||
      this.requestType === "NaturalPersonMinor"
    ) {
      this.evidenceTypes = JSON.parse(
        JSON.stringify(
          this.idVerificationService.getDocumentTypeList().NaturalPerson
            .checkbox_list[2].params.checkboxes,
        ),
      );
    } else if (this.requestType === "LegalPerson") {
      this.evidenceTypes = JSON.parse(
        JSON.stringify(
          this.idVerificationService.getDocumentTypeList().LegalPerson
            .checkbox_list[1].params.checkboxes,
        ),
      );
    }

    this.evidenceTypes.sort(function (a, b) {
      if (a.params.label < b.params.label) {
        return -1;
      }
      if (a.params.label > b.params.label) {
        return 1;
      }
      return 0;
    });

    // check if existing data exists
    if (localTemplateData.activeEdit.mainType === "mainRequest") {
      const template = localTemplateData.mainRequestTemplates.find(
        (template2) => template2.name === localTemplateData.activeEdit.type,
      );
      this.existingData = template.config;
    } else {
      const template = localTemplateData.relatedPartyTemplates.find(
        (template2) => template2.name === localTemplateData.activeEdit.type,
      );
      this.existingData = template.config;
    }
    // parse existing data for easier use
    if (this.existingData) {
      this.existingVerificationTypes = this.existingData.verificationTypes;
      if (this.existingData.evidenceTypes) {
        this.existingEvidenceTypes = [...this.existingData.evidenceTypes];
        let othecCount = 1;
        this.existingEvidenceTypes.forEach((evidence) => {
          if (evidence.type === "Other") {
            evidence.internalID = "customEvidenceType" + othecCount;
            evidence.label = "Custom document";
            this.customEvidenceTypes.push(evidence);
            othecCount++;
          }
        });
      } else {
        this.existingEvidenceTypes = [];
      }
    } else {
      this.noExistingData = true;
    }
    if (
      this.existingVerificationTypes &&
      this.existingVerificationTypes.length
    ) {
      this.showDVTab = true;
      let identificator = this.existingVerificationTypes[0].type;
      if (identificator === "Identity") {
        identificator = "IdentityVerification";
      }
      if (identificator === "Contract") {
        identificator = "ContractVerification";
      }
      if (identificator === "Address") {
        identificator = "AddressVerification";
      }
      if (identificator === "APS") {
        identificator = "APS";
      }
      if (identificator === "ProfileVerification") {
        identificator = "ProfileVerification";
      }
      this.currentlySelectedDVTab = identificator;
      const existingIDType = this.existingVerificationTypes.find(
        (config) => config.type === "Identity",
      );
      const existingIDAIType = this.existingVerificationTypes.find(
        (config) => config.type === "IdentityInstantAI",
      );
      
      if (existingIDType) {
        this.existingIDType = existingIDType;
      }

      if (existingIDAIType) {
        this.existingIDAIType = existingIDAIType;
      }

      const existingAddressType = this.existingVerificationTypes.find(
        (config) => config.type === "Address",
      );
      if (existingAddressType) {
        this.existingAddressType = existingAddressType;
      }
      const existingContractType = this.existingVerificationTypes.find(
        (config) => config.type === "Contract",
      );
      if (existingContractType) {
        this.existingContractType = existingContractType;
      }
      const existingAPSType = this.existingVerificationTypes.find(
        (config) => config.type === "APS",
      );
      if (existingAPSType) {
        this.existingAPSType = existingAPSType;
      }

      const existingProfileVerificationType = this.existingVerificationTypes.find(
        (config) => config.type === "ProfileVerification",
      );
      if (existingProfileVerificationType) {
        this.existingProfileVerificationType = existingProfileVerificationType;
      }
    }

    // parse the checkbox types
    this.evidenceTypes.forEach((checkbox) => {
      // make them all a single checkbox
      checkbox.params.oneDocument = true;
      const existingEvidenceType =
        this.existingEvidenceTypes &&
        this.existingEvidenceTypes.find(
          (evidenceType) => evidenceType.type === checkbox.params.id,
        );
      if (existingEvidenceType) {
        checkbox.params.counter = 1;
        checkbox.params.purposeOfUse = existingEvidenceType.purposesOfUse[0];
      }
      // fill in existing checkbox data
    });
    // split the evidencTypes into three groups only for display
    const denominator = Math.round(this.evidenceTypes.length / 3);
    this.evidenceTypesFirst = this.evidenceTypes.slice(0, denominator);
    this.evidenceTypesSecond = this.evidenceTypes.slice(
      denominator,
      denominator * 2,
    );
    this.evidenceTypesThird = this.evidenceTypes.slice(denominator * 2);

    // existing address documents
    // make the existing address documents array with the default options
    this.existingAdddressDocuments = [
      { type: "UtilityBill", counter: 0 },
      { type: "BankStatement", counter: 0 },
      { type: "CreditCardStatement", counter: 0 },
      { type: "InsuranceAgreement", counter: 0 },
      { type: "DriversLicence", counter: 0 },
    ];
    // if there are existing address documents go trough the array
    if (
      this.existingAddressType &&
      this.existingAddressType.verificationConfig.allowedDocuments
    ) {
      this.existingAddressType.verificationConfig.allowedDocuments.forEach(
        (doc) => {
          // if in the existing documents there is a document from the default settings change checked to true
          const existingDocObject = this.existingAdddressDocuments.find(
            (defaultDoc) => defaultDoc.type === doc,
          );
          if (existingDocObject) {
            existingDocObject.counter = 1;
          } else {
            // else add it as new
            this.existingAdddressDocuments.push({ type: doc, counter: 1 });
          }
        },
      );
    }
    // going trought he validation function only to get the status
    if (!this.existingEvidenceTypes) {
      this.existingEvidenceTypes = [];
    }
    this.apsIsMonitored = this.idVerificationService.getfundsOrganizationDetails()?.MonitorAPSByDefault;
    this.validateTemplate(localTemplateData, false);
  }

  ngAfterViewInit() {
    $(".form-steps-title ").animate({ scrollTop: 0 }, "slow");
    // if country is selected we need to emmit an event to handle the country / allowed documetns connection
    const this_ = this;
    if (this.existingIDType) {
      if (this.existingIDType.verificationConfig.country) {
        setTimeout(function () {
          this_.onEmit({
            method: "onChangeTypeCountry",
            params: { type: this_.existingIDType.verificationConfig.type },
          });
        }, 150);
      }
      setTimeout(function () {
        $("#IdentityVerificationTab").removeClass("tab-disabled");
      }, 150);
    }
    // if  address documents are selected we need to make sure to display the documents tab
    if (this.existingAddressType) {
      if (this.existingAddressType.verificationConfig.documentsRequested) {
        setTimeout(function () {
          this_.onEmit({
            method: "addressTemplateVerificationDocumentChange",
          });
        }, 150);
      }
      setTimeout(function () {
        $("#AddressVerificationTab").removeClass("tab-disabled");
      }, 150);
    }
    if (this.existingIDAIType) {
      setTimeout(function () {
        $("#IdentityInstantAITab").removeClass("tab-disabled");
      }, 150);
    }
    if (this.existingContractType) {
      setTimeout(function () {
        $("#ContractVerificationTab").removeClass("tab-disabled");
      }, 150);
    }
    if (this.existingAPSType) {
      if(this.existingAPSType.verificationConfig.isMonitored)
      {
        this_.apsIsMonitored = this.existingAPSType.verificationConfig.isMonitored
      }
      if (this.existingAPSType.verificationConfig.fuzziness) {
        setTimeout(function () {
          this_.onChangeScreeningRange(
            undefined,
            this_.existingAPSType.verificationConfig.fuzziness * 10,
          );
        }, 150);
      }
      else if(this.existingAPSType.verificationConfig.exactMatch)
      {
        setTimeout(function () {
          this_.exactMatchClicked();
        }, 150);
      }
      setTimeout(function () {
        $("#APSTab").removeClass("tab-disabled");
      }, 150);
    }
    if (this.existingProfileVerificationType) {
      setTimeout(function () {
        $("#ProfileVerificationTab").removeClass("tab-disabled");
      }, 150);
    }
  }
  /**

   * @param configureButton: this means that we've started this function from the button below the evidence and not from the checkbox
   */
  toggleEvdConfig(event, configureButton?) {
    this.ConfigLightboxParam["display"] = false;

    const checkboxWrapper = $(event.target).closest(".single-checkbox-wrapper");
    const checkboxContainer = checkboxWrapper.find(
      ".evidence-config-lightbox-button",
    );
    const baseCheckboxWrapper = checkboxWrapper.find(
      ".custom-counter.counter-alternate",
    );
    $(".send-button-error").removeClass("d-flex");
    $(".custom-documents-error-state").removeClass("d-flex");
    $(".general-error-state").removeClass("d-flex");
    if (!configureButton) {
      checkboxContainer.toggleClass("d-none");
    }
    // check if button is clicked
    const isChecked = checkboxWrapper.find(".counter-passive.d-flex").length;
    const id = $(checkboxWrapper).find(".custom-counter-count").attr("id");
    // we're unchecking
    if (isChecked && !configureButton) {
      // if we're unchecking the active evidence that is being configured, close the lightbox
      if (id === this.ConfigLightboxParam.currentEvidenceConfig.type) {
        this.ConfigLightboxParam["display"] = false;
      }
      // remove the evidence from the evidneceList
      const index = this.existingEvidenceTypes.findIndex(
        (evidenceType) => evidenceType.type === id,
      );
      if (index > -1) {
        this.existingEvidenceTypes.splice(index, 1);
      }
    } else {
      // we're checking
      // add the evidence from the evidneceList
      if (!configureButton) {
        this.existingEvidenceTypes.push({
          type: id,
          purposesOfUse: ["ProofOfIdentity"],
        });
      }
      this.ConfigLightboxParam.currentEvidenceConfig =
        this.existingEvidenceTypes.find(
          (evidenceType) => evidenceType.type === id,
        );
      this.ConfigLightboxParam.currentEvidenceConfig.label =
        this.evidenceTypes.find((evidenceType) => evidenceType.params.id === id)
          .params?.label;
      const topPosition = checkboxWrapper.position().top;
      const leftPosition = checkboxWrapper.position().left;
      const checkboxHeight = baseCheckboxWrapper.outerHeight();
      setTimeout(() => {
        this.ConfigLightboxParam["display"] = true;
      });
      this.ConfigLightboxParam["top"] = Math.floor(topPosition + 33) + "px";
      this.ConfigLightboxParam["left"] = Math.floor(leftPosition) + "px";
    }
  }

  toggleEvdConfigCustom(event) {
    let id;
    if (event.target) {
      id = $(event.target)
        .closest(".custom-evidence-types-container")
        .find(".text-input")
        .attr("id");
    } else {
      id = $(event)
        .closest(".custom-evidence-types-container")
        .find(".text-input")
        .attr("id");
    }
    this.ConfigLightboxParam.currentEvidenceConfig =
      this.existingEvidenceTypes.find(
        (evidenceType) => evidenceType.internalID === id,
      );
    if (
      $("#" + id)
        .val()
        .toString()
        .trim().length
    ) {
      this.ConfigLightboxParam.currentEvidenceConfig.customEvidenceType = $(
        "#" + id,
      )
        .val()
        .toString()
        .trim();
    }
    const topPosition = $(
      ".custom-evidence-counter-container-template ",
    ).position().top;
    const leftPosition = $(
      ".custom-evidence-counter-container-template ",
    ).position().left;
    this.ConfigLightboxParam["display"] = true;
    this.ConfigLightboxParam["top"] = Math.floor(topPosition - 134) + "px";
    this.ConfigLightboxParam["left"] = Math.floor(leftPosition) + "px";
  }

  hasIDVpermission() :boolean
  {
    return this.idVerificationService.permissionUpdateIDVerificationOnboard();
  }

  hasJumioGopermission() :boolean
  {
    return this.idVerificationService.permissionJumioGo();
  }
  addCustomEvidenceTemplate() {
    $(".send-button-error").removeClass("d-flex");
    $(".general-error-state").removeClass("d-flex");
    $(".custom-documents-error-state").removeClass("d-flex");
    const internalId = $(
      ".custom-evidence-template-wrapper .custom-evidence-types-container:last",
    )
      .find(".text-input")
      .attr("id");
    this.existingEvidenceTypes.push({
      type: "Other",
      isCustom: true,
      internalID: internalId,
      purposesOfUse: ["ProofOfIdentity"],
      label: "Custom document",
    });
  }

  deleteCustomEvidenceTemplate(event) {
    $(".custom-documents-error-state").removeClass("d-flex");
    setTimeout(() => {
      let evdToDelete = -1;
      for (let i = 0; i < this.existingEvidenceTypes.length; i++) {
        if (this.existingEvidenceTypes[i].type === "Other") {
          if (!$("#" + this.existingEvidenceTypes[i].internalID).length) {
            evdToDelete = i;
          }
          if (
            $("#" + this.existingEvidenceTypes[i].internalID)
              .closest(".custom-evidence-types-input-wrapper")
              .hasClass("d-none")
          ) {
            evdToDelete = i;
          }
        }
      }
      if (evdToDelete !== -1) {
        this.existingEvidenceTypes.splice(evdToDelete, 1);
      }
    }, 300);
  }

  configureEvidence(event) {
    event.preventDefault();
    this.toggleEvdConfig(event, true);
  }

  closeConfigLightbox(event) {
    event.preventDefault();
    this.ConfigLightboxParam["display"] = false;
  }

  saveConfigLightbox(event, isCustom?) {
    // check if there's an invalid date error
    if (
      !$(".evidence-config-lightbox .error-field-wrong-format.d-none").length
    ) {
      return;
    }

    let currentEvidenceConfig;
    if (!isCustom) {
      const id = $(event.target)
        .closest(".evidence-config-lightbox")
        .attr("dataId");
      currentEvidenceConfig = this.existingEvidenceTypes.find(
        (evidenceType) => evidenceType.type === id,
      );
    } else {
      const id = $(event.target)
        .closest(".evidence-config-lightbox")
        .attr("customId");
      currentEvidenceConfig = this.existingEvidenceTypes.find(
        (evidenceType) => evidenceType.internalID === id,
      );
    }

    const purposeOfUse = $("#currentDocumentPurpose option:selected").val();
    const note = $("#evidenceNotes").val().toString().trim();
    let notOlderThan = $("#currentNotOlderThan").val();
    if (notOlderThan === "custom") {
      notOlderThan = Number($("#currentNotOlderThanCustom").val());
    }
    currentEvidenceConfig.purposesOfUse = [purposeOfUse];
    currentEvidenceConfig.notOlderThanM = notOlderThan;
    currentEvidenceConfig.Notes = note;
    this.ConfigLightboxParam["display"] = false;
  }

  checkIfValueIsInTemplate(value) {
   
    let retOption = this.templatenotOlderThan.find(
      (option) => {option.key == value},
    );  


    if(retOption && retOption.key) {
   
      return true;
    }
    return false;

  }

  OnChangeNotOlderThan(event) {
    const target = $(event.target);
    if (target.val() === "custom") {
      $(target)
        .closest(".element-wrapper")
        .find(".notOlderThan-text-input")
        .addClass("d-block");
      $(target)
        .closest(".element-wrapper")
        .find(".notOlderThan-text-input")
        .removeClass("d-none");
    } else {
      $(target)
        .closest(".element-wrapper")
        .find(".notOlderThan-text-input")
        .removeClass("d-block");
      $(target)
        .closest(".element-wrapper")
        .find(".notOlderThan-text-input")
        .addClass("d-none");
    }
  }

  selectDVCheckbox(event) {
    const checkedDVN = $(".custom-dv-checkbox:checked").length;
    if (checkedDVN === 0) {
      this.showDVTab = false;
    } else {
      this.showDVTab = true;
    }
    //
    const clickedDVN = $(event.target).attr("id").toString();
    const tabQuery = "#" + clickedDVN + "Tab";
    const clivkedDVNTab = $(tabQuery);
    if (clickedDVN === this.currentlySelectedDVTab) {
      if (checkedDVN === 0) {
        this.currentlySelectedDVTab = undefined;
      } else {
        this.currentlySelectedDVTab = $(".custom-dv-checkbox:checked")
          .attr("id")
          .toString();
      }
      clivkedDVNTab.removeClass("active");
    } else if (this.currentlySelectedDVTab === undefined) {
      this.currentlySelectedDVTab = clickedDVN;
      clivkedDVNTab.addClass("active");
    }
    if ($(event.target).prop("checked")) {
      clivkedDVNTab.removeClass("tab-disabled");
    } else {
      clivkedDVNTab.addClass("tab-disabled");
    }

    //
    $(".send-button-error").removeClass("d-flex");
    $(".general-error-state").removeClass("d-flex");
    $(".custom-documents-error-state").removeClass("d-flex");
  }

  selectDVTab(event) {
    const clickedDVN = $(event.target).attr("id").toString().slice(0, -3);
    this.currentlySelectedDVTab = clickedDVN;
    $(".tab.active").removeClass("active");
    $(event.target).addClass("active");
    $(".send-button-error").removeClass("d-flex");
    $(".general-error-state").removeClass("d-flex");
    $(".custom-documents-error-state").removeClass("d-flex");
  }

  generateAPS(): {type: string, verificationConfig: any}
  {
    if ($("#APS").prop("checked")) {
      let verificaitonConfig;
      verificaitonConfig = {};
      const exactMatch = $("#exactMatch:checked").length;
      const fuzinessLevel =
        parseInt($("#FuzinessLevel").val().toString(), 10) / 10;
      verificaitonConfig.isMonitored = $("#kyc-screening-monitoring-toggle").is(":checked");
      if (exactMatch) {
        verificaitonConfig.exactMatch = true;
      } else {
        verificaitonConfig.fuzziness = fuzinessLevel;
      }
      return verificaitonConfig;
    }
    return;
  }

  onSaveTemplateData(event, exitAfterSave) {
    const localTemplateData = this.idVerificationService.getLocalTemplateData();
    // create the configuration object
    let configurationObject;
    configurationObject = {};
    configurationObject.type = localTemplateData.activeEdit.type;
    configurationObject.verificationTypes = [];
    configurationObject.evidenceTypes = [];
    this.ConfigLightboxParam["display"] = false;
    let customEvidenceValid = true;
    let normalEvidenceCount = 0;
    const customEvidenceCount = 0;
    // normal evidence type
    let normalEvidenceNotSelected = true;

    const counters = $(event.target)
      .closest(".type-of-documents")
      .find(".main-evidence-counter-container .custom-counter-count")
      .filter(function () {
        return this.value !== "0";
      });
    normalEvidenceCount = this.existingEvidenceTypes.length;

    if (normalEvidenceCount > 0) {
      this.existingEvidenceTypes.forEach((evidence) => {
        let tempConfigurationObject;
        tempConfigurationObject = {
          type: evidence.type,
          purposesOfUse: [evidence.purposesOfUse[0]],
        };

        if (evidence.notOlderThanM) {
          tempConfigurationObject.notOlderThanM = parseInt(
            evidence.notOlderThanM,
          );
        }
        if (evidence.Notes) {
          tempConfigurationObject.Notes = evidence.Notes;
        }
        if (evidence.type === "Other") {
          if (
            $(
              ".custom-evidence-counter-container-template #" +
                evidence.internalID,
            )
              .val()
              .toString()
              .trim()
          ) {
            tempConfigurationObject.customEvidenceType = $(
              ".custom-evidence-counter-container-template #" +
                evidence.internalID,
            ).val();
          } else {
            customEvidenceValid = false;
          }
        }
        configurationObject.evidenceTypes.push(tempConfigurationObject);
      });
    } else {
      delete configurationObject.evidenceTypes;
    }
    // VERIFICAITON CONFIGURATION
    let dvNotSelected = true;
    const apsVerifications : {type: string, verificationConfig: any} | undefined = this.generateAPS();
    if (this.requestType === "LegalPerson") {
      if ($("#ContractVerification").prop("checked")) {

        let verificationConfig = {};
        verificationConfig['template'] = $("#ContractTemplate option:selected").val();
        const skipToSmartForm = $("#skipToSmartForm:checked").length;
        if(skipToSmartForm) {
          verificationConfig['skipToSmartForm'] = true;
        }       


        configurationObject.verificationTypes.push({
          type: "Contract",
          verificationConfig: verificationConfig
        });
        dvNotSelected = false;
      }
      if(apsVerifications)
      {
        configurationObject.verificationTypes.push({
          type: "APS",
          verificationConfig: apsVerifications,
        });
        dvNotSelected = false;
      }     
    } else if (
      this.requestType === "NaturalPerson" ||
      this.requestType === "NaturalPersonMinor"
    ) {
      if ($("#IdentityVerification").prop("checked")) {
        let verificationConfig;
        let workflowVal = $("#WorkflowID option:selected").val();
        if ($("#idVerificationAlternate").prop("checked")) {
          workflowVal =
            this.idVerificationService.resolveAlternateWorkflowID(workflowVal);
        }
        let idvNotOlderThan: any = $("#idVNotOlderThan").val();
        if (idvNotOlderThan === "custom") {
          idvNotOlderThan = $("#idVNotOlderThanCustom").val();
        }
        verificationConfig = {
          country: $("#IDVerificationCountry option:selected").val(),
          locale: $("#Locale option:selected").val(),
          workflowId: parseInt(workflowVal.toString(), 10),
        };
        if (verificationConfig.country === "emptySelect") {
          delete verificationConfig.country;
        }
        if (
          $("#IDVerificationCountry option:selected").val() !== "emptySelect"
        ) {
          verificationConfig.type = $(
            "#IDVerificationType option:selected",
          ).val();
        }
        configurationObject.verificationTypes.push({
          type: "Identity",
          notOlderThanM: parseInt(idvNotOlderThan),
          verificationConfig: verificationConfig,
        });
        dvNotSelected = false;
      }
      if ($("#IdentityInstantAI").prop("checked")) {
        let verificationConfig;

        let idvNotOlderThan: any = $("#notOlderThanNIDGO").val();
        if (idvNotOlderThan === "custom") {
          idvNotOlderThan = $("#notOlderThanNIDGOCustom").val();
        }
        verificationConfig = {
          country: $("#IDVerificationCountryGo option:selected").val()
        };

        if (verificationConfig.country === "emptySelect") {
          delete verificationConfig.country;
        }


        configurationObject.verificationTypes.push({
          type: "IdentityInstantAI",
          notOlderThanM: parseInt(idvNotOlderThan),
          verificationConfig: verificationConfig,
        });
        dvNotSelected = false;
      }
      if ($("#ContractVerification").prop("checked")) {

        let verificationConfig = {};
        verificationConfig['template'] = $("#ContractTemplate option:selected").val();
        const skipToSmartForm = $("#skipToSmartForm:checked").length;
        if(skipToSmartForm) {
          verificationConfig['skipToSmartForm'] = true;
        }       

        configurationObject.verificationTypes.push({
          type: "Contract",
          verificationConfig: verificationConfig,
        });
        dvNotSelected = false;
      }
      if ($("#AddressVerification").prop("checked")) {
        dvNotSelected = false;
        let verificaitonConfig;
        verificaitonConfig = {};
        verificaitonConfig.verificationLetterRequested = false;
        let avNotOlderThan: any = $("#AVNotOlderThan").val();
        if (avNotOlderThan === "custom") {
          avNotOlderThan = $("#AVNotOlderThanCustom").val();
        }
        if ($("#addressVerificaitonLetter").is(":checked")) {
          verificaitonConfig.verificationLetterRequested = true;
        }
        if (
          $("#avNotes").length &&
          $("#avNotes").val().toString().trim() !== ""
        ) {
          verificaitonConfig.documentNotes = this.helperService.sanitize(
            $("#avNotes").val().toString().trim(),
          );
        }
        if ($("#addressVerificationiDN").is(":checked")) {
          verificaitonConfig.idinAllowed = true;
        }
        if ($("#geoLocationRequested").is(":checked")) {
          verificaitonConfig.geoLocationRequested = true;
        } else {
          verificaitonConfig.geoLocationRequested = false;
        }

        let documentCounters;
        if ($("#addressSupportingDocument").is(":checked")) {
          documentCounters = $(".address-counter-group")
            .find(".custom-counter-count")
            .filter(function () {
              return $(this).val() !== "0";
            });
          if (documentCounters.length !== 0) {
            verificaitonConfig.documentsRequested = true;
            const evidenceTypes = [];
            let order = 0;
            const otherCount = 0;
            documentCounters.each(function () {
              order++;
              const fieldType = $(this).attr("id");
              if (fieldType === "Other") {
                for (let t = 1; t <= this.value; t++) {
                  if (
                    $("#customEvidenceType" + t)
                      .val()
                      .toString()
                      .trim() === ""
                  ) {
                    evidenceTypes.push("Other");
                  }
                  evidenceTypes.push($("#customEvidenceType" + t).val());
                }
              } else {
                evidenceTypes.push(fieldType);
              }
            });
            verificaitonConfig.allowedDocuments = evidenceTypes;
          }
        }
        configurationObject.verificationTypes.push({
          type: "Address",
          notOlderThanM: parseInt(avNotOlderThan),
          verificationConfig: verificaitonConfig,
        });
      }
      if(apsVerifications)
      {
        configurationObject.verificationTypes.push({
          type: "APS",
          verificationConfig: apsVerifications,
        });
        dvNotSelected = false;
      }

        if ($("#ProfileVerification").prop("checked")) {
          let verificaitonConfig;
          verificaitonConfig = {};
          configurationObject.verificationTypes.push({
            type: "ProfileVerification",
            verificationConfig: verificaitonConfig,
          });
        dvNotSelected = false;
      }
    } else {
      alert("There was an error with determining the profile type");
    }

    // customEvidenceType
    if (normalEvidenceCount + customEvidenceCount > 0) {
      normalEvidenceNotSelected = false;
    }

    if (normalEvidenceNotSelected && dvNotSelected) {
      $(".send-button-error").addClass("d-flex");
      return;
    }
    if (!customEvidenceValid) {
      $(".custom-documents-error-state").addClass("d-flex");
      return;
    }
    if (dvNotSelected) {
      delete configurationObject.verificationTypes;
    }

    // only decide the next step for now
    event.preventDefault();
    if (localTemplateData.activeEdit) {
      // check for extra properties that somehow get transfered in a bug that we cannot recreate
      if (configurationObject.internalID) {
        delete configurationObject.internalID;
      }
      // first we need to save the configuration into the object of the active edit
      if (localTemplateData.activeEdit.mainType === "mainRequest") {
        const template = localTemplateData.mainRequestTemplates.find(
          (template2) => template2.name === localTemplateData.activeEdit.type,
        );
        template.config = configurationObject;
        template.errorState = false;
      } else {
        const template = localTemplateData.relatedPartyTemplates.find(
          (template2) => template2.name === localTemplateData.activeEdit.type,
        );
        template.config = configurationObject;
        template.errorState = false;
      }
      if (localTemplateData.activeEdit.mainType === "mainRequest") {
        // if it's the last of the main templates go to the first RP templates if it exists
        // if it doesnt exist
        if (
          localTemplateData.activeEdit.count ===
          localTemplateData.mainRequestTemplates.length - 1
        ) {
          if (
            localTemplateData.relatedPartyTemplates &&
            localTemplateData.relatedPartyTemplates.length > 0
          ) {
            localTemplateData.activeEdit = {
              mainType: "relatedParty",
              type: localTemplateData.relatedPartyTemplates[0].name,
              count: 0,
            };
            if (exitAfterSave) {
              this.finalizeTemplate(localTemplateData);
            } else {
              this.idVerificationService.skipToStep(22, localTemplateData);
            }
          } else {
            this.finalizeTemplate(localTemplateData);
          }
        } else {
          localTemplateData.activeEdit.count =
            localTemplateData.activeEdit.count + 1;
          localTemplateData.activeEdit.type =
            localTemplateData.mainRequestTemplates[
              localTemplateData.activeEdit.count
            ].name;
          if (exitAfterSave) {
            this.finalizeTemplate(localTemplateData);
          } else {
            this.idVerificationService.skipToStep(22, localTemplateData);
          }
        }
      } else {
        // it's a related request
        if (
          localTemplateData.activeEdit.count ===
          localTemplateData.relatedPartyTemplates.length - 1
        ) {
          this.finalizeTemplate(localTemplateData);
        } else {
          localTemplateData.activeEdit.count =
            localTemplateData.activeEdit.count + 1;
          localTemplateData.activeEdit.type =
            localTemplateData.relatedPartyTemplates[
              localTemplateData.activeEdit.count
            ].name;
          if (exitAfterSave) {
            this.finalizeTemplate(localTemplateData);
          } else {
            this.idVerificationService.skipToStep(22, localTemplateData);
          }
        }
      }
    } else {
      // error
      this.idVerificationService.skipToStep(0);
    }
  }

  validateTemplate(localTemplateData, showWarnings = true, finalStep = false) {
    this.generalErrorState = false;
    // validate main request types
    localTemplateData.mainRequestTemplates.forEach((mainRequest) => {
      if (!mainRequest.config) {
        this.generalErrorState = true;
        if (showWarnings) {
          mainRequest.errorState = true;
          // show the erorr at the bottom
          $(".general-error-state").addClass("d-flex");
          // show the error in the step list
          $(
            '.substep[clickmethod="changeTemplateMainStep"][type="' +
              mainRequest.name +
              '"] .substep-warning',
          ).removeClass("d-none");
        }
      }
      if(finalStep)
        {
          mainRequest?.config?.evidenceTypes?.forEach(evidence =>
            {
              if(evidence.internalID)
                {
                  delete evidence.internalID;
                }
            }
          )

        }
    });
    localTemplateData.relatedPartyTemplates.forEach((rpRequest) => {
      if (!rpRequest.config) {
        this.generalErrorState = true;
        if (showWarnings) {
          rpRequest.errorState = true;
          $(".general-error-state").addClass("d-flex");
          // show the error in the step list
          $(
            '.substep[clickmethod="changeTemplateRPStep"][type="' +
              rpRequest.name +
              '"] .substep-warning',
          ).removeClass("d-none");
        }
      }
    if(finalStep)
      {
        rpRequest?.config?.evidenceTypes?.forEach(evidence =>
          {
            if(evidence.internalID)
              {
                delete evidence.internalID;
              }
          }
        )
      }
    });
  }

  counterLabelClick(event) {}

  permissionProfileVerification()
  {
    return this.idVerificationService.permissionProfileVerification();
  }

  finalizeTemplate(localTemplateData) {



    this.ConfigLightboxParam["display"] = false;
    // validate that all template data is filled in
    this.validateTemplate(localTemplateData, true, true);
    const returnTemplatValue =
      this.transformLocalDataToTemplate(localTemplateData);
    if (!this.generalErrorState) {
      $(".template-loading-container").removeClass("d-none");
      let endpoint = "/api/organizations/templates";
      if (this.editMode) {
        endpoint =
          "/api/organizations/templates/" +
          localTemplateData.existingTemplateKey;
      }
      this.userService
        .postEndPoint(
          endpoint,
          returnTemplatValue,
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              return;
            }
            // changes in the template object only happen if we're addding new object
            let existingTemplatesObject =
              this.idVerificationService.getfundsOrganizationTemplates();
            if (!this.editMode) {
              if (!existingTemplatesObject) {
                existingTemplatesObject = [];
                existingTemplatesObject.unshift(response);
              } else {
                existingTemplatesObject.unshift(response);
              }
              this.idVerificationService.fundsOrganizationTemplates =
                existingTemplatesObject;
            } else {
              // if it is edit mode update the appropriate template
              const templateIndex = existingTemplatesObject.findIndex(
                (template) =>
                  template.key === localTemplateData.existingTemplateKey,
              );
              existingTemplatesObject[templateIndex] = response;
              this.idVerificationService.fundsOrganizationTemplates =
                existingTemplatesObject;
            }
            // close window
            this.idVerificationService.skipToStep(0);
          },
          (error) => {
            alert(error.error.message);
            $(".template-loading-container").addClass("d-none");
            return;
          },
        );
    }
  }

  transformLocalDataToTemplate(localTemplateData) {
    // name
    let template;
    template = {};
    template.templateName = localTemplateData.templateName;
    template.richDescription = localTemplateData.richDescription;
    template.published = localTemplateData.published;
    // main template
    const mainRequestTemplates = [];
    // related party template
    const relatedPartyTemplates = [];

    let templateStructure;
    templateStructure = {};
    localTemplateData.mainRequestTemplates.forEach((mainTemplate) => {
      const mainTemplateLocal = JSON.parse(JSON.stringify(mainTemplate.config));
      if (mainTemplateLocal.investorType) {
        delete mainTemplateLocal.investorType;
      }

      const mainTemplateVerificationTypes = mainTemplateLocal.verificationTypes;
      const mainTemplateEvidenceTypes = mainTemplateLocal.evidenceTypes;
      if (mainTemplateVerificationTypes) {
        mainTemplateVerificationTypes.forEach((verificationType) => {
          if(verificationType.verificationConfig) {
            delete verificationType.verificationConfig.addressData;
          }
          delete verificationType.purposesOfUse;
          delete verificationType.label;
          if (verificationType.notes) {
            verificationType.Notes = verificationType.notes;
          }
        });
      }
      if (mainTemplateEvidenceTypes) {
        mainTemplateEvidenceTypes.forEach((evidenceType) => {
          delete evidenceType.label;
          if (evidenceType.notes) {
            evidenceType.Notes = evidenceType.notes;
            delete evidenceType.notes;
          }
        });
      }

      mainRequestTemplates.push(mainTemplateLocal);
    });

    localTemplateData.relatedPartyTemplates.forEach((relatedTemplate) => {
      const relatedTemplateLocal = JSON.parse(
        JSON.stringify(relatedTemplate.config),
      );
      if (relatedTemplateLocal.investorType) {
        delete relatedTemplateLocal.investorType;
      }

      const relatedTemplateVerificationTypes =
        relatedTemplateLocal.verificationTypes;
      const relatedTemplateEvidenceTypes = relatedTemplateLocal.evidenceTypes;

      if (relatedTemplateVerificationTypes) {
        relatedTemplateVerificationTypes.forEach((verificationType) => {
          delete verificationType.purposesOfUse;
          delete verificationType.label;
          if(verificationType.verificationConfig) {
            delete verificationType.verificationConfig.addressData;
          }
          if (verificationType.notes) {
            verificationType.Notes = verificationType.notes;
          }
        });
      }
      if (relatedTemplateEvidenceTypes) {
        relatedTemplateEvidenceTypes.forEach((evidenceType) => {
          delete evidenceType.label;
          if (evidenceType.notes) {
            evidenceType.Notes = evidenceType.notes;
          }
        });
      }
      relatedPartyTemplates.push(relatedTemplateLocal);
    });

    templateStructure.mainRequestTemplates = mainRequestTemplates;
    if (relatedPartyTemplates) {
      templateStructure.relatedPartyTemplates = relatedPartyTemplates;
    }
    templateStructure.workflowSteps = localTemplateData.workflowSteps;
    template.templateStructure = templateStructure;

    return template;
  }

  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  onChangeScreeningRange(event, setValue?) {
    let value = event ? $(event.target).val().toString() : '';
    if(setValue)
    {
      this.apsScreeningScope = setValue;
      value = setValue
    }
    if(value)
    {
      let parsedValue;
      let cssValue;
      parsedValue = 10 * parseInt(value, 10);
      parsedValue = parsedValue + "%";
      cssValue = 10 * (parseInt(value, 10) - 1);
      cssValue = cssValue + "%";
      $(".range-input-indicator").text(parsedValue);
      $(".range-input-indicator").css("left", cssValue);
    }
  }

  addressVerificationDocumentChange() {
    $(".address-counter-group.counter-group").toggleClass("d-none");
  }

  exactMatchClicked() {
    if ($(".range-wrapper input").prop("disabled")) {
      $(".range-wrapper input").prop("disabled", false);
    } else {
      $(".range-wrapper input").prop("disabled", "disabled");
    }
  }

  /*  FUNCTIONS FOR COUNTER ELEMENT  */

  increaseCountEmptyCustom(event, isCustomEvidenceType) {
    const targetClass = ".custom-evidence-counter-container-template ";
    if (isCustomEvidenceType) {
      $(targetClass + ".custom-evidence-types-input-wrapper").removeClass(
        "d-none",
      );
    }
    // hide passive button
    $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".counter-passive")
      .addClass("d-none");
    $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".counter-passive")
      .removeClass("d-flex");
    // increase counter
    const currentValue = $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".custom-counter-count")
      .val()
      .toString();
    const nextValue = parseInt(currentValue, 10) + 1;
    $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".custom-counter-count")
      .val(nextValue);
    $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".button-minus")
      .removeClass("button-inactive");
    // show active button
    $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".counter-active")
      .addClass("d-flex");
    $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".counter-active")
      .removeClass("d-none");
    $(
      targetClass +
        ".custom-evidence-types-container:last .custom-evidence-delete-button",
    ).on("click", function () {
      $(this)
        .closest(".custom-evidence-types-container")
        .addClass("marked-for-delete");
      $(
        targetClass + ".custom-evidence-counter-container .button-minus",
      ).trigger("click");
    });
    // if EITHER identity verificaiton OR Smart form is checked APS needs to be shown
    // this will tell us if we are in the right window
    if ($(".verification-configuration-screen").length) {
      if (this.idVerificationService.getCCType() === "funds") {
        if (
          $("#IdentityVerification").val() !== "0" ||
          $("#ContractVerification").val() !== "0"
        ) {
          $(".aps-checkbox-class").show();
        }
      } else {
        if (
          $("#idVerificationSelect").val() !== "0" ||
          $("#ContractSigningSelect").val() !== "0"
        ) {
          $(".aps-checkbox-class").show();
        }
      }
    }
  }
  existingCustomEvidenceDelete(event) {
    $(event.target)
      .closest(".custom-evidence-types-container")
      .addClass("marked-for-delete");
    $(".custom-evidence-counter-container-template .button-minus").trigger(
      "click",
    );
  }
  increaseCountPlusCustom(event, isCustomEvidenceType) {
    const targetClass = ".custom-evidence-counter-container-template ";

    const currentValue = $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".custom-counter-count")
      .val()
      .toString();
    const nextValue = parseInt(currentValue, 10) + 1;
    $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".button-minus")
      .removeClass("button-inactive");
    $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".custom-counter-count")
      .val(nextValue);
    if (isCustomEvidenceType) {
      const newElement = $(
        targetClass + ".custom-evidence-types-container:first",
      ).clone();
      $(targetClass + ".custom-evidence-types-input-wrapper").append(
        newElement,
      );
      $(targetClass + ".custom-evidence-types-container:last input ").val("");
      $(targetClass + ".custom-evidence-types-container:last input ").prop(
        "disabled",
        false,
      );
      $(targetClass + ".custom-evidence-types-container:last input").attr(
        "id",
        "customEvidenceType" + nextValue,
      );
      $(
        targetClass +
          ".custom-evidence-types-container:last .custom-evidence-delete-button",
      ).removeClass("d-none");
      $(
        targetClass +
          ".custom-evidence-types-container:last .custom-evidence-delete-button",
      ).on("click", function () {
        $(this)
          .closest(".custom-evidence-types-container")
          .addClass("marked-for-delete");
        $(
          targetClass + ".custom-evidence-counter-container .button-minus",
        ).trigger("click");
      });
      const that = this;
      $(
        targetClass +
          ".custom-evidence-types-container:last .evidence-config-lightbox-button",
      ).on("click", function () {
        that.toggleEvdConfigCustom(this);
      });
    }
  }

  increaseCountMinusCustom(event, baseValue, isCustomEvidenceType) {
    const targetClass = ".custom-evidence-counter-container-template ";
    const currentValue = $(event.target)
      .closest(".single-checkbox-wrapper")
      .find(".custom-counter-count")
      .val()
      .toString();
    const nextValue = parseInt(currentValue, 10) - 1;
    const baseValueI = !baseValue ? 0 : parseInt(baseValue, 10);
    if (nextValue > baseValueI) {
      $(event.target)
        .closest(".single-checkbox-wrapper")
        .find(".custom-counter-count")
        .val(nextValue);
    }
    if (nextValue === baseValueI) {
      $(event.target)
        .closest(".single-checkbox-wrapper")
        .find(".custom-counter-count")
        .val(nextValue);
      $(event.target)
        .closest(".single-checkbox-wrapper")
        .find(".button-minus")
        .addClass("button-inactive");
    }
    if (nextValue === 0) {
      $(event.target)
        .closest(".single-checkbox-wrapper")
        .find(".custom-counter-count")
        .val(nextValue);
      // show passive button
      $(event.target)
        .closest(".single-checkbox-wrapper")
        .find(".counter-passive")
        .addClass("d-flex");
      $(event.target)
        .closest(".single-checkbox-wrapper")
        .find(".counter-passive")
        .removeClass("d-none");
      // hide active button
      $(event.target)
        .closest(".single-checkbox-wrapper")
        .find(".counter-active")
        .addClass("d-none");
      $(event.target)
        .closest(".single-checkbox-wrapper")
        .find(".counter-active")
        .removeClass("d-flex");
    }
    if (isCustomEvidenceType) {
      if ($(targetClass + ".custom-evidence-types-container").length === 1) {
        $(targetClass + ".custom-evidence-types-input-wrapper").addClass(
          "d-none",
        );
        $(targetClass + ".custom-evidence-types-container input").val("");
        $(targetClass + ".custom-evidence-types-container").removeClass(
          "marked-for-delete",
        );
      } else {
        $(
          targetClass + ".custom-evidence-types-container.marked-for-delete",
        ).remove();
      }
    }
    // if identity verificaiton and Smart form are both unchecked then compliance check needs to be hidden
    // this will tell us if we are in the right window
    if ($(".verification-configuration-screen").length) {
      if (this.idVerificationService.getCCType() === "funds") {
        if (
          $("#IdentityVerification").val() === "0" &&
          $("#ContractVerification").val() === "0"
        ) {
          $(".aps-checkbox-class").hide();
          $(".aps-checkbox-class .button-minus").trigger("click");
        }
      } else {
        if (
          (!$("#idVerificationSelect").length ||
            $("#idVerificationSelect").val() === "0") &&
          (!$("#ContractSigningSelect").length ||
            $("#ContractSigningSelect").val() === "0")
        ) {
          $(".aps-checkbox-class").hide();
          $(".aps-checkbox-class .button-minus").trigger("click");
        }
      }
    }
  }

  /* EOF:  FUNCTIONS FOR COUNTER ELEMENT  */

  // function for resolving the state of "Not older than"  input field
  isSelectedOption(option, inputFieldFor?) {
    let selectedValue;
    if (inputFieldFor == "idV") {
      // values for idV "Not older than" input field

      selectedValue = this.existingIDType?.notOlderThanM;
    }
    else if (inputFieldFor == "idVGo") {
      // values for idV "Not older than" input field
      selectedValue = this.existingIDAIType?.notOlderThanM;
    }
    else if (inputFieldFor == "AV") {
      // values for AV "Not older than" input field
      selectedValue = this.existingAddressType?.notOlderThanM;
    } else {
      // values for docment "Not older than" input field
      selectedValue =
        this.ConfigLightboxParam.currentEvidenceConfig?.notOlderThanM;
    }

    return this.isSelectedOptionHelper(option, selectedValue);
  }

  isSelectedOptionHelper(option, selectedValue) {

    

    if (this.checkIfValueIsInTemplate(selectedValue)) {
      if (selectedValue == option) {
        return true;
      }
      return false;
    } else {
      if (
        (selectedValue == null && option == "0") ||
        (selectedValue != null && option == "custom")
      ) {
        return true;
      }
      return false;
    }
  }
}
