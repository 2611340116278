<div
  class="wideContainerWrapperParent parseEvidenceList evidence-is-active position-relative"
  *ngIf="!evidenceIsDeleted"
>
  <form class="form-container wideContainerWrapper input-fields-wrapper">
    <div
      class="element-wrapper input-transparent divider-title big-title hr"
      *ngIf="title"
    >
      <h3 class="title">
        {{ title }}
      </h3>
      <p class="subtitle">
        {{
          currentEvidence.customEvidenceType
            ? currentEvidence.customEvidenceType
            : helperService.translateEvidenceType(currentEvidence.type)
        }}
      </p>
      <div *ngIf="currentEvidence.fields?.Notes">
        <div class="d-flex flex-row align-items-start notification info">
          <img
            src="assets/images/icon-information-prepared-teal.svg"
            width="16px"
          />
          <div class="d-flex flex-column pl-3 content">
            <h3 class="ml-0 mr-auto mb-2 mt-0">
              {{ "review.title4" | translate }}
            </h3>
            <div id="reviewFlowNotes" class="description">
              {{ currentEvidence.fields.Notes }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="{
        'evd-field-full':
          field.type === 'title' ||
          field.type === 'checkbox' ||
          field.type === 'subtitle'
      }"
      class="evd-field"
      *ngFor="let field of fields"
      [ngSwitch]="field.type"
    >
      <!-- INPUT DATE -->

      <!-- SELECT OPTION -->
      <div
        class="element-wrapper input-transparent efw-50"
        *ngSwitchCase="'selectOptions'"
      >
        <div
          [ngClass]="{
            'd-none':
              (field.displayCondition || field.hideCondition) &&
              !isFieldChecked(
                field.displayCondition,
                field.hideCondition,
                field.id
              )
          }"
        >
          <span class="input-label"
            >{{ field.label }}
            <img
              *ngIf="
                selectValueIsValid(field.value, field.selectOptionsList) &&
                (field.disabled === true || fieldIsDisabled(field.name))
              "
              alt="Locked input"
              class="icon ml-1"
              src="/assets/images/lock-icon.svg"
              width="20"
              height="20"
            />
            <sup class="sup-required">{{
              !field.optional ? "*" : ""
            }}</sup></span
          >
          <span class="select-wrapper">
            <select
              class="select-input"
              id="{{ field.id }}"
              name="{{ field.name }}"
              (change)="onEmit(elementService.OnChangeSelect($event))"
              [attr.isRequired]="!field.optional ? 'true' : 'false'"
              [value]="field.value ? field.value : -1"
              [disabled]="
                selectValueIsValid(field.value, field.selectOptionsList) &&
                (field.disabled === true || fieldIsDisabled(field.name))
              "
              [attr.skipParse]="field.skipParse"
              [attr.changemethod]="field.changemethod"
            >
              <option
                [selected]="field.value === option.key"
                value="{{ option.key }}"
                *ngFor="let option of field.selectOptionsList"
              >
                {{ option.name }}
              </option>
            </select>
            <div
              class="error-field"
              [attr.validatorMessage]="'validation.validation1' | translate"
              [attr.validatorMessageField]="
                'validation.validation1' | translate
              "
            ></div>
          </span>
          <!-- ################ jas da go sredam validatorot -->
          <!-- <div class="error-field" [attr.validatorMessage]="element.params.validatorMessage"
          [attr.validatorMessageField]="element.params.validatorMessage"></div> -->
        </div>
      </div>
      <!-- INPUT DATE -->
      <div *ngSwitchCase="'date'" class="element-wrapper efw-50">
        <!-- Input Date -->
        <span class="input-label">
          {{ field.label }}
          <sup class="sup-required">*</sup>
        </span>
        <app-my-date-picker
          name="{{ field.name }}"
          id="{{ field.id }}"
          method="setMinEndDate"
          dateInputId="{{ field.name }}"
          isReqiured="{{ true }}"
          value="{{ parseDate(field.value) }}"
          startDate="{{
            field.name == 'DocumentDate' && currentEvidence?.notOlderThan
              ? convertNotOlderThan(currentEvidence.notOlderThan)
              : field.startDate
              ? field.startDate
              : field.minDate
              ? field.minDate
              : ''
          }}"
          maxDate="{{ field.maxDate }}"
          minDate="{{
            field.name == 'DocumentDate' && currentEvidence?.notOlderThan
              ? convertNotOlderThan(currentEvidence.notOlderThan)
              : field.minDate
          }}"
          (dateChange)="parseDateContentReturn($event)"
          [(ngModel)]="field.name"
          model="{{ field.id }}"
          additionalClass="rounded"
        ></app-my-date-picker>
        <small
          class="small-text text-left ml-1"
          *ngIf="field.name == 'DocumentDate' && currentEvidence?.notOlderThan"
          >{{
            "evidenceFields.documentCantBeOlderThen"
              | translate
                : {
                    date: helperService.formatISODate(
                      currentEvidence.notOlderThan,
                      "yyyy-MM-dd"
                    )
                  }
          }}</small
        >
        <div
          class="error-field"
          [attr.validatorMessage]="'validation.validation1' | translate"
          [attr.validatorMessageField]="'validation.validation1' | translate"
        ></div>
        <div
          class="error-field-wrong-format d-none"
          [attr.validatorMessage]="
            '*the date must be in the correct format: dd.mm.yyyy '
          "
        ></div>
      </div>
      <!-- INPUT TEXT -->
      <div
        class="element-wrapper efw-50"
        [ngClass]="{ 'no-border': field.noBottomBorder }"
        *ngSwitchCase="'inputText'"
      >
        <div
          [ngClass]="{
            'd-none':
              (field.displayCondition || field.hideCondition) &&
              !isFieldChecked(
                field.displayCondition,
                field.hideCondition,
                field.id
              )
          }"
        >
          <span class="input-label d-flex align-items-center"
            >{{ field.label }}
            <img
              *ngIf="
                textInputValueisValid(field.value) &&
                fieldIsDisabled(field.name)
              "
              alt="Locked input"
              class="ico ml-1"
              src="/assets/images/lock-icon.svg"
              width="20"
              height="20"
            />
            <sup class="sup-required">{{ !field.optional ? "*" : "" }}</sup>
            <app-tooltip
              *ngIf="field.toolTip"
              [tooltipText]="field.toolTip"
              [icon]="'/assets/images/question-circle-solid.svg'"
            ></app-tooltip>
          </span>
          <input
            type="text"
            class="text-input"
            id="{{ field.id }}"
            name="{{ field.name }}"
            [attr.isValid]="true"
            [attr.isRequired]="!field.optional ? 'true' : 'false'"
            [attr.isMaxLength]="field.maxLength"
            [attr.isMinLength]="field.minLength"
            [attr.skipParse]="field.skipParse"
            (keyup)="onEmit(elementService.OnInputKeyUp($event))"
            value="{{ field.value }}"
            (blur)="onEmit(elementService.OnInputBlur($event))"
            [disabled]="
              textInputValueisValid(field.value) && fieldIsDisabled(field.name)
            "
          />
          <div
            class="error-field"
            [attr.validatorMessage]="'Field is required'"
            [attr.validatorMessageField]="'Field is required'"
          ></div>
        </div>
      </div>
      <!-- DIVIDER TITLE -->
      <div
        [ngClass]="{'d-none': field.hideCondition}"
        class="element-wrapper input-transparent divider-title {{field.class}}"
        *ngSwitchCase="'title'"
      >
        <h3 class="title">
          {{ field.label }}
        </h3>
        <p class="subtitle" *ngIf="field.subtitle">{{ field.subtitle }}</p>
      </div>

      <!-- CHECKBOX -->
      <div
        [ngClass]=""
        class="single-checkbox-wrapper"
        *ngSwitchCase="'checkbox'"
      >
        <input
          class="custom-checkbox"
          type="checkbox"
          id="{{ field.id }}"
          name="{{ field.name }}"
          [attr.skipParse]="field.skipParse"
          [checked]="field.checked"
        />
        <!-- (change)="OnChangeSelect($event)" -->
        <!-- (change)="onEmit(elementService.OnChangeSelect($event))" -->
        <label [for]="field.id" class="custom-checkbox-label">{{
          field.label
        }}</label>
      </div>
      <!-- CHECKBOX -->
      <!-- GOOGLE MAP -->
      <div
        class="element-wrapper efw-50 mt-2"
        *ngSwitchCase="'inputGoogleAutocomplete'"
      >
        <div>
          <div id="mapImage" [ngClass]="!map ? '' : 'display-none'"></div>
          <div #mapWrapper id="map" [ngClass]="map ? '' : 'display-none'"></div>
        </div>
        <div>
          <div
            class="mapInfo input-label"
            [ngClass]="map ? '' : 'display-none'"
          >
            {{ "evidencePreview.addressLabel1" | translate }}
          </div>

          <!-- <span class="input-label {{ element.params.labelClass }}"
          >{{ element.params.label
          }}<sup *ngIf="element.params.required" class="sup-required">*</sup></span > -->
          <!-- Input Text -->
          <input
            type="text"
            id="googleAutoComplete"
            class="text-input google-autocomplete google-autocomplete-review"
            #gautocomplete
            [attr.isValid]="true"
            autocomplete="chrome-off"
            (focus)="OnInitGoogleAutocomplete($event)"
            (keyup)="onEmit(elementService.OnInputKeyUp($event))"
            [attr.isRequired]="true"
            />
            <div class="error-field" [attr.validatorMessage]="'Field is required'"
            [attr.validatorMessageField]="'Field is required'"></div>
        </div>
      </div>
      <!-- GOOGLE MAP -->
    </div>
    <!-- IMAGE UPLOAD  -->
    <div class="element-wrapper efw-50 image-upload" *ngIf="!isBasicFields">
      <div class="d-flex flex-row">
        <div class="upload-files w-50">
          <div class="upload-files-wrapper">
            <span
              *ngIf="
                !(currentEvidence.documents && currentEvidence.documents.length)
              "
              class="input-label mb-2"
              >{{ "review.label3" | translate }}</span
            >
            <span
              *ngIf="
                currentEvidence.documents && currentEvidence.documents.length
              "
              class="input-label mb-0"
              >{{ "review.label7" | translate }}</span
            >
            <span
              *ngIf="
                currentEvidence.documents && currentEvidence.documents.length
              "
              class="grey-font"
            >
              {{ "review.BrowseOrDragDrop" | translate }}
            </span>
            <div
              class="d-flex position-relative dropzone form-button-6"
              [ngClass]="currentEvidenceKey"
              (dragenter)="OnImageUploadDragEnter($event)"
              (dragover)="OnImageUploadDragOver($event)"
              (dragleave)="OnImageUploadDragLeave($event)"
              (drop)="OnImageUploadDropEvidence($event)"
              (click)="OnDocumentUploadClick($event)"
              [attr.data-target]="image_upload_id"
              [attr.data-evidenceKey]="currentEvidenceKey"
              [attr.uploadmethod]="'htpmUploadFileEvidence'"
              (id)="(currentEvidenceKey)"
            >
              <div
                class="w-100 h-100 position-absolute img-preview"
                [ngStyle]="{
                  'background-image': 'url(' + (!image ? image : image) + ')'
                }"
                *ngIf="image || image"
              ></div>
              <div
                class="align-self-center text-center mx-auto image-upload-instructions"
                [attr.data-target]="image_upload_id"
                *ngIf="!image && !image"
              >
                <div class="upload-button">
                  <img class="upload-icon" src="/assets/images/upload.svg" />
                  {{ "review.Upload" | translate }}
                </div>
              </div>
              <div class="dropzone-loading-screen">
                <img
                  src="/assets/images/spinner-green.svg"
                  width="35px"
                  height="35px"
                  class="my-auto mx-auto"
                />
              </div>
            </div>
            <div class="small-text">
              {{ "appSpecific.imgCapture5" | translate }}
            </div>
            <input
              type="file"
              id="{{ image_upload_id }}"
              accept="image/png, image/jpeg, image/gif, application/pdf"
              style="display: none"
              [attr.uploadmethod]="'htpmUploadFileEvidence'"
              [attr.data-InputEvidenceKey]="currentEvidenceKey"
              (change)="OnImageUploadSelectedEvidence($event)"
            />
            <p class="image-upload-notes" *ngIf="uploadNotes">
              {{ uploadNotes }}
            </p>
            <div
              style="margin-bottom: 25px"
              class="error-field-required-image d-none"
              [attr.validatorMessage]="'validation.validation4' | translate"
            ></div>
          </div>
        </div>
        <div class="uploaded-files w-50">
          <span
            *ngIf="
              currentEvidence.documents && currentEvidence.documents.length
            "
            class="input-label mb-0"
            style="margin-bottom: -15px"
            >{{ "review.label6" | translate }}</span
          >
          <div
            class="evidence-list-image-wrapper"
            [attr.data-wrapperEvidenceKey]="currentEvidenceKey"
          >
            <div
              *ngFor="let document of currentEvidence.documents"
              class="evidence-list-image-container evidence-container-type-one"
            >
              <div class="d-flex">
                <div>
                  <img
                    src="/assets/images/ico-templates.svg"
                    width="16px"
                    height="20px"
                    class="mr-3"
                  />
                </div>
                <div
                  (click)="onReviewEvidenceClick()"
                  class="evience-list-member"
                >
                  {{
                    document.filename
                      ? document.filename
                      : document?.record?.metadata?.filename
                  }}
                </div>
              </div>
              <button
                attr.data-documentKey="{{
                  document.Key ? document.Key : document.key
                }}"
                attr.data-requestKey="{{ requestKey }}"
                attr.data-evidenceKey="{{ evidenceKey }}"
                (click)="OnDocumentDelete($event, document)"
                data-documentKey="document"
                class="evidence-list-delete-button form-button"
              >
                <img src="assets/images/delete-document.svg" />
              </button>
              <div class="upload-button-loading-screen">
                <img
                  src="/assets/images/spinner-green.svg"
                  width="20px"
                  height="20px"
                  class="my-auto mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PURPOSES SELECT -->
    <div *ngIf="!isBasicFields" class="element-wrapper efw-50">
      <div
        class="purposes-container checkbox-container purposes-without-selecting"
        [attr.isRequired]="true"
      >
        <div class="input-label">
          {{ "review.label4" | translate }}
        </div>

        <div
          [attr.data-purposeEvidenceKey]="currentEvidenceKey"
          [id]="currentEvidenceKey"
          class="purposes-checkbox-list d-none"
        >
          <div
            *ngFor="let purpose of currentEvidence.purposesOfUse"
            class="purposes-checkbox"
          >
            <div class="single-checkbox-wrapper">
              <input
                class="custom-checkbox purpose-checkbox"
                type="checkbox"
                [name]="purpose.id"
                [value]="purpose.id"
                [id]="currentEvidenceKey + purpose.id"
                (change)="OnChangeSelect($event); onSelectPurpose($event)"
                [checked]="purpose.checked"
                [attr.label]="purpose.label"
                [attr.data-purposeId]="currentEvidenceKey + purpose.id"
              />
              <label
                [for]="currentEvidenceKey + purpose.id"
                class="custom-checkbox-label"
              >
                {{ this.helperService.translatePurposesOfUse(purpose.id) }}
              </label>
            </div>
          </div>
        </div>
        <div class="purpose-tags-container">
          <div
            class="purpose-tag-wrapper"
            *ngFor="let purpose of currentEvidence.purposesOfUse"
          >
            <div
              [attr.data-purposeId]="currentEvidenceKey + purpose.id"
              class="purpose-tag"
              [ngClass]="!purpose.checked ? 'd-none' : ''"
            >
              {{ this.helperService.translatePurposesOfUse(purpose.id) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Continue button-->
    <div
      class="d-flex justify-content-end align-items-center w-100 continue-button-pcs"
      style="margin-left: 15px; margin-right: 15px"
    >
      <div
        style="display: none"
        class="submit-fields-general-error mr-2 align-self-end"
      >
        *{{ "review.validation" | translate }}
      </div>
      <button
        [ngClass]="profileDVLoading ? 'submit-loading' : ''"
        (click)="OnReviewButtonClick($event)"
        type="submit"
        class="form-button form-button-1 evidence-submit-button"
      >
        {{ "common.continue" | translate }}
      </button>
    </div>
  </form>
  <div *ngIf="loadingScreen" class="fields-loading-screen">
    <div class="fields-loading-screen-wrapper">
      <div class="loading">
        <img
          src="/assets/images/MeshID-Loading-Animation-WhiteBG.gif"
          width="100px"
          height="100px"
          class="my-auto mx-auto"
        />
        <div
          class="px-3"
          style="
            font-size: 22px;
            font-weight: 700;
            margin-top: 30px;
            text-align: center;
          "
        >
          {{ "common.pleaseWait" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="evidenceIsDeleted"  class=" evidence-deleted wideContainerWrapperParent parseEvidenceList evidence-is-active position-relative">
  <p>{{ "review.pcs.evidenceWasDeleted"  | translate }}</p>
  <button class="form-button form-button-link reloadPage" (click)="realodPage()"> {{ "review.pcs.clickToUpdateTasks"  | translate }} </button>
</div>
