<div *ngIf="showPCSScreen" class="new-cdd-pcs" [ngClass]="{'hide-height' : displayLoader || displayLoaderOverride}">
  <app-sidebar
    #sideBar
    [navBarData]="navBar"
    [logo]="logo"
    (navLinkCLick)="changeRightPanel($event)"
    (changeActiveProfile)="changeActiveProfile($event)"
    (processControllCompleted)="setTheProcessControllIsCompleted($event)"
    (displayRightPanel)="displayRightPanelChange($event)"
    (updateNavData)="updateNavData($event)"
    class="sidebar"
  >
  </app-sidebar>
  <div
    *ngIf="displayRightPanel && !theProcessControllIsCompleted"
    [@inOutAnimation]
    [@.disabled]="!animateOnMobile"
    style="background-color: white"
    class="w-100"
  >
    <div class="right-pannel-wrapper m-md-5">
      <app-document-share-dialog
        *ngIf="activeEvidence == 'documents' || activeEvidence == 'basicInfo'"
        [currentEvidenceKey]="currentEvidenceKey"
        [currentRequestKey]="currentRequestKey"
        [currentEvidence]="currentEvidence"
        [title]="
          activeEvidence == 'documents'
            ? translate.instant('review.pcs.title3')
            : null
        "
        [profileDVLoading]="profileDVLoading"
        (changeEvidenceStatus)="changeEvidenceStatus($event)"
      >
      </app-document-share-dialog>
      <app-contract-share-dialog
        *ngIf="activeEvidence == 'contract' && activeProfileNavBar"
        [profile]="activeProfileNavBar"
      [requestKey]="requestKey"
      ></app-contract-share-dialog>

      <app-address-share-dialog  
      *ngIf=" activeEvidence == 'address' "
      [profile]="activeProfileNavBar"
      [currentEvidenceKey]="currentEvidenceOrriginalKey" 
      [currentRequestKey]="currentRequestKey"
      [currentEvidenceStatus]="currentEvidenceStatus"
      [verificationLink]="verificationLink"
      [logo]="logo"
      [projectColor]="backgroundColor"
      (changeEvidenceStatus)="changeEvidenceStatus($event)"
      ></app-address-share-dialog>

      <app-generate-verification-link-share-dialog
        *ngIf="
          (activeEvidence == 'JumioGo' ||
            activeEvidence == 'digitalVerifications') &&
          activeProfileNavBar
        "
        [profile]="activeProfileNavBar"
        [requestKey]="requestKey"
        [verificationLink]="verificationLink"
        (markCurrentStepAsCompleted)="markCurrentStepAsCompleted($event)"
        (refreshCurrentRelatedPartyTop)="refreshCurrentRelatedParty($event)"
        [isJumioGo]="activeEvidence == 'JumioGo'"
      ></app-generate-verification-link-share-dialog>
      <div *ngIf="activeEvidence == 'waiting'">
        <div class="waiting-window-wrapper">
          <img class="task-to-be-done" src="/assets/images/tasks.svg" />
          <p class="result-paragraph">
            {{ "review.pcs.waitingForVerificationsToInitiate" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="displayRightPanel && theProcessControllIsCompleted"
    class="completed"
  >
    <div class="right-pannel-wrapper">
      <img class="mb-2" src="/assets/images/checkmark-circle-svgrepo-com.svg" />
      <h2 class="form-clarification-title mb-2">
        {{ "review.pcs.allDone" | translate }}
      </h2>
      <h5 class="form-clarification-title mb-5">
        {{ "review.pcs.noRemainingTasks" | translate }}
      </h5>
      <!-- <button class="form-button form-button-1" (click)="goToDashboard()">{{ "review.pcs.goToYourDashboard" | translate }}</button> -->
    </div>
  </div>
</div>

<app-content-builder
  *ngIf="showARPCSScreen"
  [contentDOM]="contentDom"
  (contentReturn)="parseContentReturn($event)"
></app-content-builder>

<app-fullscren-loading-overlay *ngIf="displayLoader || displayLoaderOverride">
</app-fullscren-loading-overlay>


