import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnDestroy,
  AfterContentInit,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { UserService } from "src/app/user.service";
import { ElementsService } from "../elements.service";
import { KompanySeachComponent } from "../kompany-seach/kompany-seach.component";
import { param } from "jquery";

@Component({
  selector: "app-investor-select-entity-type",
  templateUrl: "./investor-select-entity-type.component.html",
  styleUrls: ["./investor-select-entity-type.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InvestorSelectEntityTypeComponent
  implements OnInit, OnDestroy, AfterContentInit, AfterViewInit
{
  @Input() element;
  @Input() organizationLogo;
  @Output() contentReturn = new EventEmitter<any>();

  selectedProfile: any = {};
  noSelectedTypeError = false;
  kompanySearchInProgressError = false;
  showRelatedProfile = true;
  showAdditionalInformation = false;
  relatedProfiles: any = [];
  confirmationMode = false;
  showRelatedPartyExplanation = true;
  investorFields;

  showTypeNotification;
  typeNotificationText;
  kompanyElement;
  permissionKompany;
  kompanySearchListener;
  kompanyDataRetreived;
  hideCompanySearch;
  automaticCompanySearch;
  notAllowedLegalTypes;
  legalMainOptionAllowed;
  naturalMainOptionAllowed;
  hideRelatedPartyOptions;
  onlyOneOption;
  existingFields;
  allowKompanySearch = true;

  multipleStepManagement;
  multipleStepConstants;
  resetExplanationToggle = true;

  savedInformation;
  existingType;

  explanatoryNotes = false;

  // lightbox parameters:
  displayModal: boolean;
  displayLoader: boolean;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any = {};
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  legalTypeTooltipText: string;;
  showLegalTypeTooltip : boolean = true;

  constructor(
    public elementService: ElementsService,
    private idVerificationService: IdVerificationService,
    public helperService: HelperServiceService,
    public translate: TranslateService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {

    this.generateMultipleStepConstants();
    this.legalTypeTooltipText = this.translate.instant(
      "legalTypeToolTip.Company",
    ),



    this.savedInformation = {
      profileLegalName: "",
      profileLegalNameLocked: null,
      profileLegalEntityType: "",
      profileLegalEntityTypeLocked: null,
      profileNaturalFirstName: "",
      profileNaturalFirstNameLocked: null,
      profileNaturalLastName: "",
      profileNaturalLastNameLocked: null,
      profileNaturalMinor: false,
    };

    this.permissionKompany = this.idVerificationService.permissionKompany();
    // if there's permissionf or kompany search we parse the initial data
    if (this.permissionKompany) {
      this.automaticCompanySearch = true;
      this.kompanyElement = {
        params: {
          countryList: this.idVerificationService.kompanyTypeCountries,
          basicFieldsProcess: true,
          processStart: true,
        },
      };
      this.idVerificationService.localFundCreationData = {};
      this.idVerificationService.localFundCreationData.basicFieldsProcess =
        true;
      this.notAllowedLegalTypes = this.element.params.notAllowedLegalTypes;
      this.legalMainOptionAllowed =
        !this.notAllowedLegalTypes?.notAllowedMainRequest?.includes(
          "LegalPerson",
        );
      this.naturalMainOptionAllowed =
        !this.notAllowedLegalTypes?.notAllowedMainRequest?.includes(
          "NaturalPerson",
        );
      this.hideRelatedPartyOptions =
        this.notAllowedLegalTypes?.notAllowedRelatedRequest?.includes(
          "LegalPerson",
        ) &&
        this.notAllowedLegalTypes?.notAllowedRelatedRequest?.includes(
          "NaturalPerson",
        );

      // additional checks for allowed types based on additional details configuration in the SP

      // subscribe to listener
      this.kompanySearchListener =
        this.idVerificationService.detectBasicFieldsCompanySearch.subscribe(
          (response) => {
            if (response === "cancel") {
              this.automaticCompanySearch = false;
              return;
            }
            if (response === "finish") {
              if (
                this.idVerificationService.localFundCreationData &&
                this.idVerificationService.localFundCreationData.investorFields
              ) {
                if (
                  this.idVerificationService.localFundCreationData
                    .investorFields.RegisteredName
                ) {
                  $("#profileLegalName").val(
                    this.idVerificationService.localFundCreationData
                      .investorFields.RegisteredName,
                  );
                  $("#profileLegalName").prop("disabled", true);
                }
                this.investorFields =
                  this.idVerificationService.localFundCreationData.investorFields;
                this.kompanyDataRetreived = true;
                this.hideCompanySearch = true;
                this.kompanySearchInProgressError = false;
              }
              return;
            }
          },
        );
    }

    // console.log('this is element', this.element);
    if (
      this.element &&
      this.element["params"] &&
      this.element["params"].fieldTypes &&
      !this.element["params"].fieldTypesDV
    ) {
      let lastTitle;
      lastTitle = {};
      this.element["params"].fieldTypes.forEach((fieldType) => {
        if (fieldType.type === "title") {
          lastTitle = fieldType;
          lastTitle.showTitle = false;
        } else if (fieldType.value) {
          lastTitle.showTitle = true;
        }
      });
    }

    //if we need to skip the AR go to teh last step immediately
    if(this.element.params.skipAr) {
      this.hideRelatedPartyOptions = true;

      setTimeout(() => {
        $('#arpcsContinue').trigger('click');
      }, 300);
    }
  }

  generateMultipleStepConstants()
  {
    this.multipleStepConstants = {
      steps: [
        {
          stepType: "mainProfile",
          stepCount: 0,
          relatedProfiles: [],
          stepAppearsOn: ["legal", "natural"],
          timelineImgLP: "assets/images/ARTimeline/LP-ProgressBar-step1.png",
          timelineImgNP: "assets/images/ARTimeline/NP-ProgressBar-step1.png",
        },
        {
          title: this.translate.instant(
            "ARRelatedParty.ultimateBeneficialOwners.title",
          ),
          stepType: "relatedParty",
          subtitle: this.translate.instant(
            "ARRelatedParty.ultimateBeneficialOwners.subtitle",
          ),
          stepCount: 1,
          relatedProfiles: [],
          relatedPartyTypeName: this.translate.instant(
            "ARRelatedParty.ultimateBeneficialOwners.typeName",
          ),
          listTitle: this.translate.instant(
            "ARRelatedParty.ultimateBeneficialOwners.listTitle",
          ),
          dropdownTitle: this.translate.instant(
            "ARRelatedParty.ultimateBeneficialOwners.dropdownTitle",
          ),
          dropdownText: this.helperService.getDescription(
            "ultimateBeneficialOwners",
            this.element.params.record.workflowSteps,
          ),
          buttonLabel: this.translate.instant(
            "ARRelatedParty.ultimateBeneficialOwners.buttonLabel",
          ),
          allowedProfileType: "NaturalPerson",
          allowedRelatedPartyType: [
            {
              key: "UltimateBeneficialOwner",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.UltimateBeneficialOwner",
              ),
            },
          ],
          alternateCancelText: this.translate.instant(
            "ARRelatedParty.ultimateBeneficialOwners.alternateCancelText",
          ),
          stepAppearsOn: !this.helperService.getDoNotDisplay(
            "ultimateBeneficialOwners",
            this.element.params.record.workflowSteps,
            true,
          )
            ? ["legal"]
            : "",
          timelineImgLP: "assets/images/ARTimeline/LP-ProgressBar-step2.png",
          hideCancelButton: true,
        },
        {
          title: this.translate.instant("ARRelatedParty.indirectOwners.title"),
          stepType: "relatedParty",
          subtitle: this.translate.instant(
            "ARRelatedParty.indirectOwners.subtitle",
          ),
          stepCount: 2,
          relatedProfiles: [],
          relatedPartyTypeName: this.translate.instant(
            "ARRelatedParty.indirectOwners.typeName",
          ),
          listTitle: this.translate.instant(
            "ARRelatedParty.indirectOwners.listTitle",
          ),
          dropdownTitle: this.translate.instant(
            "ARRelatedParty.indirectOwners.dropdownTitle",
          ),
          dropdownText: this.helperService.getDescription(
            "indirectOwners",
            this.element.params.record.workflowSteps,
          ),
          buttonLabel: this.translate.instant(
            "ARRelatedParty.indirectOwners.buttonLabel",
          ),
          allowedRelatedPartyType: [
            {
              key: "Shareholder",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Shareholder",
              ),
            },
            {
              key: "Nominee",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Nominee",
              ),
            },
            {
              key: "BeneficiaryVested",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.BeneficiaryVested",
              ),
            },
            {
              key: "LimitedPartner",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.LimitedPartner",
              ),
            },
          ],

          alternateCancelText: this.translate.instant(
            "ARRelatedParty.indirectOwners.alternateCancelText",
          ),
          stepAppearsOn: !this.helperService.getDoNotDisplay(
            "indirectOwners",
            this.element.params.record.workflowSteps,
            true,
          )
            ? ["legal"]
            : "",
          timelineImgLP: "assets/images/ARTimeline/LP-ProgressBar-step3.png",
        },
        {
          title: this.translate.instant("ARRelatedParty.indirectControl.title"),
          subtitle: this.translate.instant(
            "ARRelatedParty.indirectControl.subtitle",
          ),
          stepType: "relatedParty",
          stepCount: 3,
          dropdownTitle: this.translate.instant(
            "ARRelatedParty.indirectControl.dropdownTitle",
          ),
          dropdownText: this.helperService.getDescription(
            "indirectControl",
            this.element.params.record.workflowSteps,
          ),
          relatedProfiles: [],
          listTitle: this.translate.instant(
            "ARRelatedParty.indirectControl.listTitle",
          ),
          relatedPartyTypeName: this.translate.instant(
            "ARRelatedParty.indirectControl.typeName",
          ),
          buttonLabel: this.translate.instant(
            "ARRelatedParty.indirectControl.buttonLabel",
          ),
          allowedRelatedPartyType: [
            {
              key: "Director",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Director",
              ),
            },
            {
              key: "Controller",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Controller",
              ),
            },
            {
              key: "Trustee",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Trustee",
              ),
            },
            {
              key: "Settlor",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Settlor",
              ),
            },
            {
              key: "Manager",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Manager",
              ),
            },
            {
              key: "AuthorisedSignatory",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.AuthorisedSignatory",
              ),
            },
            {
              key: "GeneralPartner",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.GeneralPartner",
              ),
            },
            {
              key: "LimitedPartner",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.LimitedPartner",
              ),
            },
            {
              key: "Other",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Other",
              ),
            },
          ],
          alternateCancelText: this.translate.instant(
            "ARRelatedParty.indirectControl.alternateCancelText",
          ),
          stepAppearsOn: !this.helperService.getDoNotDisplay(
            "indirectControl",
            this.element.params.record.workflowSteps,
            true,
          )
            ? ["legal"]
            : "",
          timelineImgLP: "assets/images/ARTimeline/LP-ProgressBar-step4.png",
        },
        {
          title: this.translate.instant("ARRelatedParty.relevantParty.title"),
          subtitle: this.translate.instant(
            "ARRelatedParty.relevantParty.subtitle",
          ),
          stepType: "relatedParty",
          stepSubType: "other",
          stepCount: 4,
          dropdownTitle: this.translate.instant(
            "ARRelatedParty.relevantParty.dropdownTitle",
          ),
          dropdownText: this.helperService.getDescription(
            "relevantParty",
            this.element.params.record.workflowSteps,
          ),
          relatedProfiles: [],
          listTitle: this.translate.instant(
            "ARRelatedParty.relevantParty.listTitle",
          ),
          buttonLabel: this.translate.instant(
            "ARRelatedParty.relevantParty.buttonLabel",
          ),
          allowedRelatedPartyType: [
            {
              key: "Protector",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Protector",
              ),
            },
            {
              key: "Agent",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Agent",
              ),
            },
            {
              key: "Custodian",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Custodian",
              ),
            },
            {
              key: "Administrator",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Administrator",
              ),
            },
            {
              key: "InvestmentAdvisor",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.InvestmentAdvisor",
              ),
            },
            {
              key: "JointAccountHolder",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.JointAccountHolder",
              ),
            },
            {
              key: "FamilyMember",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.FamilyMember",
              ),
            },
            {
              key: "LimitedPartner",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.LimitedPartner",
              ),
            },
            {
              key: "Founder",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Founder",
              ),
            },
            {
              key: "CouncilMember",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.CouncilMember",
              ),
            },
            {
              key: "Guardian",
              name: this.translate.instant(
                "ARRelatedParty.allowedRelatedPartyType.Guardian",
              ),
            },
          ],

          relatedPartyTypeName: this.translate.instant(
            "ARRelatedParty.relevantParty.typeName",
          ),
          alternateCancelText: this.translate.instant(
            "ARRelatedParty.relevantParty.alternateCancelText",
          ),
          stepAppearsOn: !this.helperService.getDoNotDisplay(
            "relevantParty",
            this.element.params.record.workflowSteps,
            true,
          )
            ? ["legal", "natural"]
            : "",
          timelineImgLP: "assets/images/ARTimeline/LP-ProgressBar-step5.png",
          timelineImgNP: "assets/images/ARTimeline/NP-ProgressBar-step2.png",
        },
        {
          title: this.translate.instant(
            "ARRelatedParty.summaryOfRelatedParties.title",
          ),
          subtitle: this.translate.instant(
            "ARRelatedParty.summaryOfRelatedParties.subtitle",
          ),
          alternateSubtitle: this.translate.instant(
            "ARRelatedParty.summaryOfRelatedParties.alternateSubtitle",
          ),
          stepType: "finalize",
          stepCount: 5,
          relatedProfiles: this.relatedProfiles,
          buttonLabel: this.translate.instant(
            "ARRelatedParty.summaryOfRelatedParties.buttonLabel",
          ),
          listTitle: this.translate.instant(
            "ARRelatedParty.summaryOfRelatedParties.listTitle",
          ),
          relatedPartyTypeName: this.translate.instant(
            "ARRelatedParty.summaryOfRelatedParties.typeName",
          ),
          stepAppearsOn: ["legal", "natural"],
          timelineImgLP: "assets/images/ARTimeline/LP-ProgressBar-step6.png",
          timelineImgNP: "assets/images/ARTimeline/NP-ProgressBar-step3.png",
        },
      ],
    };
    this.multipleStepManagement = this.multipleStepConstants.steps[0];
  }

  ngAfterContentInit(): void {
    // get the list of supported kompany countries
    if (this.permissionKompany) {
      this.userService
        .getEndPoint(
          "api/search/companies",
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .pipe(catchError((e) => of({})))
        .toPromise()
        .then((response) => {
          this.idVerificationService.parseKompanyCountries(response);
          this.kompanyElement.params.countryList =
            this.idVerificationService.kompanyTypeCountries;
        });
    }
    if (this.element.params.record) {
      if (this.element.params.record.richDescription) {
        this.explanatoryNotes = true;
        this.generateAndDisplayLightbox(
          this.element.params.record.richDescription,
        );
      }
      this.existingFields = this.element.params.record.investorFields;
      // if there's a fieldsource that means that the SP filled in the data and the investor is not allowed to do automatic company search
      this.allowKompanySearch = !(
        this.element.params.record.fieldsSource &&
        this.element.params.record.fieldsSource.length &&
        this.element.params.record.fieldsSource[0].name === "MANUAL"
      );
      this.existingType = this.element.params.record.typeOfInvestor;
      let fieldSource = this.element.params.record.fieldsSource;
      if (this.existingFields.FirstName) {
        this.savedInformation.profileNaturalFirstName =
          this.existingFields.FirstName;
        if (fieldSource?.FirstName?.locked || !this.allowKompanySearch) {
          this.savedInformation.profileNaturalFirstNameLocked = true;
        }
      }
      if (this.existingFields.LastName) {
        this.savedInformation.profileNaturalLastName =
          this.existingFields.LastName;
        if (fieldSource?.LastName?.locked || !this.allowKompanySearch) {
          this.savedInformation.profileNaturalLastNameLocked = true;
        }
      }
      if (this.existingFields.RegisteredName) {
        this.savedInformation.profileLegalName =
          this.existingFields.RegisteredName;
        if (fieldSource?.RegisteredName?.locked || !this.allowKompanySearch) {
          this.savedInformation.profileLegalNameLocked = true;
        }
      }
      if (this.existingFields.Type) {
        this.savedInformation.profileLegalEntityType = this.existingFields.Type;
      }
      if (this.existingType === "LegalPerson") {
        this.naturalMainOptionAllowed = false;
        if (fieldSource?.Type?.locked || !this.allowKompanySearch) {
          this.savedInformation.profileLegalEntityTypeLocked = true;
        }
        this.changeToManualCreation();
      }
      if (this.existingType === "NaturalPerson") {
        this.legalMainOptionAllowed = false;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.legalMainOptionAllowed && !this.naturalMainOptionAllowed) {
      // legal person should be automaticall selected
      this.onSelectType(undefined, "legal");
      this.onlyOneOption = true;
    }
    if (!this.legalMainOptionAllowed && this.naturalMainOptionAllowed) {
      // natural person should be automaticall selected
      this.onSelectType(undefined, "natural");
      this.onlyOneOption = true;
    }
    if (!this.legalMainOptionAllowed && !this.naturalMainOptionAllowed) {
      this.legalMainOptionAllowed = true;
      this.naturalMainOptionAllowed = true;
    }
  }

  ngOnDestroy() {
    if (this.kompanySearchListener) {
      this.kompanySearchListener.unsubscribe();
    }
  }

  generateAndDisplayLightbox(content) {
    this.btnCancelText = "Close";
    this.lightboxClass.cancelBtnClass = "form-button-grey";

    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "tinyMCEDescription",
              class: "text-initial mb-3",
              params: {
                content: content,
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  changeToManualCreation() {
    this.automaticCompanySearch = false;
  }

  changeToAutomaticCreation() {
    this.relatedProfiles = [];
    this.generateMultipleStepConstants();
    if (this.kompanyDataRetreived) {
      this.kompanyDataRetreived = false;

    this.kompanyElement.params.fetchedCompanyList =
    undefined;
    this.kompanyElement.params.kompanyCallError = false;
    this.element.params.fetchedCompanyList = undefined;
    this.kompanyElement.params.processStart = true;
    this.kompanyElement.params.kompanyCallError = false;
    this.kompanyElement.params.dontBlur = true;

    }
    this.automaticCompanySearch = true;
    this.savedInformation.profileLegalName = "";
    this.savedInformation.profileLegalEntityType = "";
    this.savedInformation.profileLegalNameLocked = null;

    if (this.existingFields.RegisteredName) {
      this.savedInformation.profileLegalName =
        this.existingFields.RegisteredName;
      this.savedInformation.profileLegalNameLocked = true;
    }
    if (this.existingFields.Type) {
      this.savedInformation.profileLegalEntityType = this.existingFields.Type;
    }
  }

  onSelectType(event, type) {
    event?.preventDefault();
    // if it's changed to natural type first save the legal information
    // and the other way arround
    if (type === "natural") {
      this.savedInformation.profileLegalName = $("#profileLegalName").val();
      this.savedInformation.profileLegalEntityType = $(
        "#profileLegalEntityType",
      ).val();
      if ($("#profileLegalName").prop("disabled")) {
        this.savedInformation.profileLegalNameLocked = "";
      } else {
        this.savedInformation.profileLegalNameLocked = null;
      }
    } else if (type === "legal") {
      this.savedInformation.profileNaturalFirstName = $(
        "#profileNaturalFirstName",
      ).val();
      this.savedInformation.profileNaturalLastName = $(
        "#profileNaturalLastName",
      ).val();
    }
    this.selectedProfile.type = type;
    this.noSelectedTypeError = false;
    // this.showRelatedProfile = false;
    this.kompanySearchInProgressError = false;
  }

  processProfileInfo($event, goToConfirmation?) {
    if (!this.selectedProfile.type) {
      this.noSelectedTypeError = true;
    }
    if (
      !(
        (this.kompanyDataRetreived && this.automaticCompanySearch) ||
        !this.automaticCompanySearch ||
        this.selectedProfile.type === "natural"
      )
    ) {
      if (!this.noSelectedTypeError) {
        this.kompanySearchInProgressError = true;
        $(".kompany-search-title").addClass("text-red-strong");
      }
      return;
    }

    let error = false;
    switch (this.selectedProfile.type) {
      case "legal":
        if (
          !$("#profileLegalName").val() ||
          ($("#profileLegalName").val() &&
            $("#profileLegalName").val().toString().trim() === "")
        ) {
          error = true;
          $("#profileLegalName").closest(".element-wrapper").addClass("error");
        } else {
          $("#profileLegalName")
            .closest(".element-wrapper")
            .removeClass("error");
          this.selectedProfile.legalName = $("#profileLegalName").val();
        }
        if ($("#profileLegalEntityType").val() === "emptySelect") {
          error = true;
          $("#profileLegalEntityType")
            .closest(".element-wrapper")
            .addClass("error");
        } else {
          $("#profileLegalEntityType")
            .closest(".element-wrapper")
            .removeClass("error");
          this.selectedProfile.legalType = $("#profileLegalEntityType").val();
        }
        break;

      case "natural":
        if (
          !$("#profileNaturalFirstName").val() ||
          ($("#profileNaturalFirstName").val() &&
            $("#profileNaturalFirstName").val().toString().trim() === "")
        ) {
          error = true;
          $("#profileNaturalFirstName")
            .closest(".element-wrapper")
            .addClass("error");
        } else {
          $("#profileNaturalFirstName")
            .closest(".element-wrapper")
            .removeClass("error");
          this.selectedProfile.naturalFirstName = $(
            "#profileNaturalFirstName",
          ).val();
        }
        if (
          !$("#profileNaturalLastName").val() ||
          ($("#profileNaturalLastName").val() &&
            $("#profileNaturalLastName").val().toString().trim() === "")
        ) {
          error = true;
          $("#profileNaturalLastName")
            .closest(".element-wrapper")
            .addClass("error");
        } else {
          $("#profileNaturalLastName")
            .closest(".element-wrapper")
            .removeClass("error");
          this.selectedProfile.naturalLastName = $(
            "#profileNaturalLastName",
          ).val();
        }
        if ($("#profileNaturalMinor").prop("checked")) {
          this.selectedProfile.isMinor = true;
        }
        break;
    }
    // if (this.relatedProfiles.length > 0) {
    //   this.selectedProfile.relatedProfiles = this.relatedProfiles;
    // }
    if (!error) {
      this.kompanySearchInProgressError = false;
      // main profile data is processed without error
      // go to the next step
      if (
        this.hideRelatedPartyOptions ||
        $("#additionalRelatedParties").prop("checked")
      ) {
        // if there is no related party configuration avaialble continue on sending the data to the BE
        this.onEmit(
          this.elementService.OnButtonClick($event, this.selectedProfile),
        );
      } else {
        // go to the related party steps
        this.goToNextStep();
      }
    }
  }

  goToNextStep() {
    let currentStep = this.multipleStepManagement.stepCount;
    this.resetExplanationToggle = false;
    this.resetExplanationToggle = true;

    if (currentStep === this.multipleStepConstants.steps.length - 1) {
      this.confirmationMode = true;
    } else {
      this.multipleStepManagement =
        this.multipleStepConstants.steps[++currentStep];
      while (
        !this.multipleStepManagement.stepAppearsOn.includes(
          this.selectedProfile.type,
        )
      ) {
        this.multipleStepManagement =
          this.multipleStepConstants.steps[++currentStep];
        currentStep = this.multipleStepManagement.stepCount;
        if (currentStep === this.multipleStepConstants.steps.length - 1) {
          this.confirmationMode = true;
        }
      }
      if (this.multipleStepManagement.stepType === "finalize") {
        this.showRelatedProfile = false;
        this.multipleStepManagement.relatedProfiles = this.relatedProfiles;
      }
      if(this.multipleStepManagement.stepType === 'relatedParty') {
        if(this.multipleStepManagement.relatedPartyTypeName === 'Control' &&   this.idVerificationService
        .localFundCreationData.fetchedCompanyListDeatils?.parsedRelatedParties?.directors?.length > 0) 
        {
          this.multipleStepManagement.relatedProfiles = 
          this.idVerificationService
                .localFundCreationData.fetchedCompanyListDeatils.parsedRelatedParties.directors;
        }
        if(this.multipleStepManagement.relatedPartyTypeName === 'Ownership' && this.idVerificationService
        .localFundCreationData.fetchedCompanyListDeatils?.parsedRelatedParties?.shareholders?.length > 0) 
          {
            this.multipleStepManagement.relatedProfiles = 
            this.idVerificationService
                  .localFundCreationData.fetchedCompanyListDeatils.parsedRelatedParties.shareholders;

          }
          if(this.multipleStepManagement.relatedPartyTypeName === 'UBO' && this.idVerificationService
          .localFundCreationData.fetchedCompanyListDeatils?.parsedRelatedParties?.ubo?.length > 0) 
            {
              this.multipleStepManagement.relatedProfiles = 
              this.idVerificationService
                    .localFundCreationData.fetchedCompanyListDeatils.parsedRelatedParties.ubo;
            }

            this.multipleStepManagement.relatedProfiles?.forEach( profile => {
              if(!this.relatedProfiles.includes(profile))
                {
                  this.relatedProfiles.push(profile);
                }
            })
      }
      if (this.multipleStepManagement.stepSubType === "other") {
        this.showRelatedProfile = false;
      }
      this.confirmationMode = false;
    }
    this.showRelatedProfile = this.multipleStepManagement.stepType != "finalize" && this.multipleStepManagement.relatedProfiles.length == 0;
    
    if(this.multipleStepManagement.stepType === 'finalize' && this.multipleStepManagement.relatedProfiles.length == 0) {
      this.multipleStepManagement.alternateSubtitle = undefined
      this.multipleStepManagement.title = undefined
    }
    
  }

  goToPreviousStep() {
    this.showRelatedProfile = true;
    this.resetExplanationToggle = false;
    this.resetExplanationToggle = true;
    let currentStep = this.multipleStepManagement.stepCount;
    this.multipleStepManagement =
      this.multipleStepConstants.steps[--currentStep];
    while (
      !this.multipleStepManagement.stepAppearsOn.includes(
        this.selectedProfile.type,
      )
    ) {
      this.multipleStepManagement =
        this.multipleStepConstants.steps[--currentStep];
      currentStep = this.multipleStepManagement.stepCount;
      if (currentStep === this.multipleStepConstants.steps.length - 1) {
        this.confirmationMode = true;
      }
    }
    this.confirmationMode = false;
    this.showRelatedProfile = false;
  }

  finalizeSteps($event) {
    if (this.relatedProfiles.length > 0) {
      this.selectedProfile.relatedProfiles = this.relatedProfiles;
    }
    this.onEmit(
      this.elementService.OnButtonClick($event, this.selectedProfile),
    );
  }

  backToEditing() {
    this.confirmationMode = false;
  }

  OnChangeSelect(event) {
    if ($("#profileLegalEntityType").val() === "emptySelect") {
      $("#profileLegalEntityType")
        .closest(".element-wrapper")
        .addClass("error");
    } else {
      $("#profileLegalEntityType")
        .closest(".element-wrapper")
        .removeClass("error");
      this.selectedProfile.legalType = $("#profileLegalEntityType").val();
    }
  }

  onChangeSelectLegalType(event) {
    this.showLegalTypeTooltip = true;
    const value = $(event.target).val();
    this.showTypeNotification = false;
    this.typeNotificationText = "";
    if (value === "Trust") {
      this.showTypeNotification = true;
      this.typeNotificationText = this.translate.instant("ARPCS.rpContent3");
    }
    if (
      value === "Publicly traded company" ||
      value === "PubliclyTradedCompany"
    ) {
      this.showTypeNotification = true;
      this.typeNotificationText = this.translate.instant("ARPCS.rpContent4");
    }

    //handle the tooltip content
  
    this.legalTypeTooltipText = this.translate.instant("legalTypeToolTip." + value );
    if(!this.legalTypeTooltipText) {
      this.showLegalTypeTooltip = false;
    }
    
  }

  onShowRelatedProfile() {
    // this.showRelatedPartyExplanation = false;
    this.showRelatedProfile = true;
  }

  onShowAdditionalInformation() {
    this.showAdditionalInformation = true;
  }

  onCancelAdditionalInformation(event) {
    event.preventDefault();
    if (!this.kompanyDataRetreived) {
      if (this.idVerificationService.localFundCreationData.investorFields) {
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration =
          undefined;
      }
      this.idVerificationService.localFundCreationData.fetchedCompanyList =
        undefined;
      this.idVerificationService.localFundCreationData.kompanyCallError = false;
      this.kompanyElement.params.processStart = true;
      this.kompanyElement.params.kompanyCallError = false;
      this.kompanyElement.params.selectedCountryValue = "emptySelect";
      this.showAdditionalInformation = false;
    }
    this.showAdditionalInformation = false;
  }

  onAddRelatedProfile(params) {
    this.showRelatedProfile = false;
    if (params.status === "cancel") {
      if (this.multipleStepManagement) {
        this.multipleStepManagement.alternateCancelText = null;
      }
      this.showRelatedPartyExplanation = true;
      return;
    }
    params.rpProfile.relatedPartyTypeName =
      this.multipleStepManagement.relatedPartyTypeName;
    this.multipleStepManagement.relatedProfiles.push(params.rpProfile);
    this.relatedProfiles.push(params.rpProfile);
    
  }

  onAddKompanyInformation(params) {
    this.contentReturn.emit(params);
  }

  onCancelRelatedProfile($event) {
    $event.preventDefault();
    this.showRelatedProfile = false;
    this.showRelatedPartyExplanation = true;
  }

  removeRelatedProfile($event, profile) {
    $event.preventDefault();
    this.multipleStepManagement.relatedProfiles =
      this.multipleStepManagement.relatedProfiles.filter(
        (rp) =>
          {
            let removeRP: boolean = !(
              (rp.naturalFirstName &&
                rp.naturalFirstName === profile.naturalFirstName &&
                rp.naturalLastName === profile.naturalLastName) ||
              (rp.legalName && rp.legalName === profile.legalName)
            );
            if(this.multipleStepManagement.relatedPartyTypeName === 'Control')
            {
              this.idVerificationService.removeRPFromParsedRelatedParties('directors', rp)
              
            }
            else if(this.multipleStepManagement.relatedPartyTypeName === 'Ownership')
            {
              this.idVerificationService.removeRPFromParsedRelatedParties('shareholders', rp)
            }
            else if(this.multipleStepManagement.relatedPartyTypeName === 'UBO')
            {
              this.idVerificationService.removeRPFromParsedRelatedParties('ubo', rp)
            }

            return removeRP;
          }
      );

    this.relatedProfiles = this.relatedProfiles.filter(
      (rp) =>
        !(
          (rp.naturalFirstName &&
            rp.naturalFirstName === profile.naturalFirstName &&
            rp.naturalLastName === profile.naturalLastName) ||
          (rp.legalName && rp.legalName === profile.legalName)
        ),
    );
    this.multipleStepConstants.steps[5].relatedProfiles = this.relatedProfiles;
    // UBO is required, when you delete all of them the user do not know why he can not click next button
    if (
      this.multipleStepManagement.relatedProfiles.length == 0 &&
      profile.relatedPartyTypeName === "UBO"
    ) {
      this.showRelatedProfile = true;
    }
  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  onLightboxClose(event) {
    this.displayModal = false;
  }
}
