import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  OnDestroy,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { IdVerificationService } from "../../id-verification.service";
import { ContentBuilderComponent } from "src/app/ui/content-builder/content-builder.component";
import { Subject, Subscription, forkJoin } from "rxjs";
import { HelperServiceService } from "src/app/helper-service.service";
import { DatePipe, Location } from "@angular/common";
import { first, last, takeUntil } from "rxjs/operators";
import { UserService } from "src/app/user.service";
import { LighboxContentComponent } from "src/app/ui/lighbox-content/lighbox-content.component";
import { VFEs } from "src/app/consts/const";
import { PdfServerService } from "src/app/pdf-server.service";
import { TranslateService } from "@ngx-translate/core";
import { createImportSpecifier } from "typescript";
import { ToastNotificationsService } from "../../../ui/toast-notifications.service";
import { RiskAssessmentService } from "../risk-assessment/risk-assessment.service";

declare var $: any;

@Component({
  selector: "app-id-verification-dashboard",
  templateUrl: "./id-verification-dashboard.component.html",
  styleUrls: ["./id-verification-dashboard.component.scss"],
  providers: [DatePipe],
})
export class IdVerificationDashboardComponent implements OnInit, OnDestroy {
  // date format variables
  date_pattern = /(\d{2}).(\d{2}).(\d{4})/;
  date_format = "$2/$1/$3";

  // type variables
  projectsType: string;

  // user variables
  activeUser: number; // -1 - unknown, 0 - not permitted, 1 - permitted on the system

  // header variables
  headerInfo: {};
  headerClasses: {};
  kvkResults: any;

  // local variables
  content: string;
  requests: any; // this will store the activeIntegrityChecks, closedIntegrityChecks and integrityRequests as they come from backend
  groups: any; // this will store the parsed activeIntegrityChecks, closedIntegrityChecks and integrityRequests groups
  openIntegrityChecks: any; // list of all opened integrity checks
  closedIntegrityChecks: any; // list of all closed integrity checks
  flaggedCompanies: any; // list of all companies flagged by me
  requestIntegrityCheckForKvkNumber: string; // the KVK number of the company we are requesting more information
  requestIntegrityCheckForKey: string; // the key of the integrity check we are requesting more information
  endIntegrityCheckKey: string; // the key of the integrity check we are attempting to close
  isScrolled: boolean;
  postinformationRequestKey: string;
  activeVerificationKey: string;
  pdfGeneratorActive: boolean;
  addressVerificationPdfGeneratorActive: boolean;
  tempContributorEmail: string;
  tempContributorType: string;
  logoUploadFinished: boolean;
  lastLogoUploadStatus: boolean; // true - upload succes; false - upload failed
  // logoUpdateProjectFinished: boolean;
  cancelingVerification = "";
  removeIdvImage = "";
  keepIdvImage = "";
  activePersonFrontImage: "";
  activePersonBackImage: "";
  selectedVerifications;
  isInvestorLoaded = false; // don't load investor if he's loaded
  logoUpdateBrandingFinished: boolean; // the branding/color has finished updating
  @ViewChildren(ContentBuilderComponent)
  builderComponents: QueryList<ContentBuilderComponent>;
  @ViewChildren(LighboxContentComponent)
  lightboxComponents: QueryList<LighboxContentComponent>;
  unsubscribe$: Subject<void> = new Subject<void>();

  // content variables
  contentDOM: any[];

  // lightbox parameters:
  displayModal: boolean;
  displayLoader: boolean;
  formType: string;
  formTypeAlignment: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;
  refreshType: string;

  // top lightbox parameters:
  topDisplayModal: boolean;
  topDisplayLoader: boolean;
  topFormType: string;
  topFormTypeAlignment: string;
  topFormTitle: string;
  topFormSubTitle: string;
  topFormClass: string;
  topLightboxClass: any;
  topLightboxContent: any[];
  topBtnOkText: string;
  topFormValidator: string;
  topBtnCancelText: string;
  topBtnOkEnabled: boolean;
  topBtnCancelEnabled: boolean;
  topBtnContentElements: string;
  topRefreshType: string;

  // foremost lightbox parameters:
  foremostDisplayModal: boolean;
  foremostDisplayLoader: boolean;
  foremostFormType: string;
  foremostFormTypeAlignment: string;
  foremostFormTitle: string;
  foremostFormSubTitle: string;
  foremostFormClass: string;
  foremostLightboxClass: any;
  foremostLightboxContent: any[];
  foremostBtnOkText: string;
  foremostFormValidator: string;
  foremostBtnCancelText: string;
  foremostBtnOkEnabled: boolean;
  foremostBtnCancelEnabled: boolean;
  foremostBtnContentElements: string;
  foremostRefreshType: string;

  // subscriptions
  activeUserStatus: Subscription;
  retrieveActiveLegalRepresentatives: Subscription;
  retrieveActiveProjects: Subscription;
  toggleHeaderStatus: Subscription;
  loadAdditionalPersonDetails: Subscription;
  parseLegalRepresentativesStatus: Subscription;
  editLegalRepresentativesStatus: Subscription;
  loadPersonImage: Subscription;
  detectDisplayCustomizeProjectSettings: Subscription;
  detectUpdateProjectBrandingStatus: Subscription;
  detectUploadProjectLogoStatus: Subscription;
  detectDisplayInviteContributorsLightbox: Subscription;
  detectDisplayEditTemplateLightbox: Subscription;
  detectDisplayManageContributorsLightbox: Subscription;
  detectDisplayGenerateReportLightbox: Subscription;
  detectDisplayAdvancedSettingsLightbox: Subscription;
  detectProjectDelete: Subscription;
  detectProjectDeleteDialogue: Subscription;
  detectGenerateContributorInviteUrlStatus: Subscription;
  detectAddContributorStatus: Subscription;
  detectactiveProjectsStatus: Subscription;
  detectLoadProjectContributors: Subscription;
  detectLoadProjectInvitations: Subscription;
  detectReinviteContributorStatus: Subscription;
  detectLeaveAProjectStatus: Subscription;
  cancelVerificationStatus: Subscription;
  resetBrandingStatus: Subscription;
  completePDFStatus: Subscription;
  detectTriggerIDPreview: Subscription;
  // permission for project screening
  permissionProjectScreening: boolean;
  permissionAddressVerification: boolean;
  permissionIDVerification: boolean;
  permissionAPS: boolean;
  permissionContract: boolean;
  permissionIDIN: boolean;
  permissionReportGeneration: boolean;
  permissionDeleteSubject: boolean;
  // funds
  parseInvestorsStatus: Subscription;
  retrieveActiveInvestors: Subscription;
  loadAdditionalInvestorDetails: Subscription;
  didGetUrlPerson = false;
  getUserPermission: Subscription;
  displayTopModal: Subscription;
  displayPreview: Subscription;
  displayAccessPopUp: Subscription;
  reviewStatus: string;
  showPCSLoading: boolean;
  dvInvestorStatus: string;
  displayInvestorReviewStatus: Subscription;
  displayInvestorDVReviewStatus: Subscription;
  pcsFlowTrough: boolean; // variable to keep track if we went trough a review process from the pcs
  // APS
  localApsDetail = null;
  // needed for specific case where we need to hide the logo
  hideHeaderLogo = null;
  templates = null;
  singleRequestMode = false;

  constructor(
    private helper: HelperServiceService,
    public idVerificationService: IdVerificationService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private location: Location,
    private pdfService: PdfServerService,
    public translate: TranslateService,
    private toastNotification: ToastNotificationsService,
    private riskAssessmentService: RiskAssessmentService
  ) {
    datePipe = new DatePipe("en-US");
  }

  ngOnInit() {

    // check if links are in old link format
    const regex = /^\/id-verification\/dashboard\/PRJ:[\w-]+\/VFS:[\w-]+$/;
    //convert link to new format
    if(regex.test(this.router.url)) {
      const projectId = this.route.snapshot.paramMap.get('projectId');
      const verificationId = this.route.snapshot.paramMap.get('verificationId')
      this.router.navigate([`/id-verification/dashboard/${projectId}/verifications/${verificationId}`], { replaceUrl: true });
    }
    this.reviewStatus = "loading";
  
    if (this.router.url.includes("funds")) {
      this.projectsType = "funds";
    } else if (this.router.url.includes("id-verification")) {
      this.projectsType = "id-verification";
    } else if (this.router.url.includes("investor")) {
      this.projectsType = "investor";
    }
    if (this.router.url.includes("/request")) {
      this.singleRequestMode = true;
    }
    if (this.router.url.includes("/verifications")) {
      this.singleRequestMode = true;
    }
    this.idVerificationService.setProjectType(this.projectsType);
    this.getUserPermission = this.userService
      .getUserPermissionsSubject()
      .subscribe((response) => {
        if (response) {
          if (
            !response.includes("access:funds") &&
            this.projectsType === "funds"
          ) {
            this.router.navigate(["/"]);
          }
          if (
            !response.includes("access:investor") &&
            this.projectsType === "investor"
          ) {
            this.router.navigate(["/"]);
          }
        }
      });

      if(!this.idVerificationService.isInvestorType())
        {
      this.userService
        .getEndPoint(
          "/api/organizations/templates",
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              console.log("Error while fetching record for additional data!");
              return;
            }
            this.templates = response;
            this.idVerificationService.settfundsOrganizationTemplates(response);
          },
          (error) => {
            return;
          },
        );
      }
      if(!this.idVerificationService.isInvestorType())
      {
        this.userService
        // .getEndPoint(`api/pdf/templates/templates-and-drafts/${this.userService.getUserOrganizationId()}`
        .getEndPoint(
          `/api/organizations/questionnaires`,
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .toPromise()
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.error(error));
      }

    this.activeUser = -1;
    if (this.userService.getUserInfo() !== null) {
      this.activeUser = 1;
    }
    // dissallow pdf generation:
    this.pdfGeneratorActive = false;
    // dissallow address pdf generation:
    this.addressVerificationPdfGeneratorActive = false;
    // by default set this to false
    this.isScrolled = false;
    // by default mark last logo upload as success
    this.lastLogoUploadStatus = true;
    // initialize the header
    if (this.projectsType === "funds") {
      this.headerInfo = {
        headerType: "full", // 'inline',
        headerTitle: "Onboard",
        activeNavLink: "funds",
        projectType: "Fund",
      };
    } else if (this.projectsType === "id-verification") {
      this.headerInfo = {
        headerType: "full", // 'inline',
        headerTitle: "Verify",
        activeNavLink: "idVerification",
        projectType: "Project",
      };
    } else if (this.projectsType === "investor") {
      this.headerInfo = {
        headerType: "full", // 'inline',
        headerTitle: "My dashboard",
        activeNavLink: "funds",
        projectType: "Investor",
        hideMainNav: true,
      };
    }
    this.headerClasses = {
      backgroundClass: "tealish-gradient-bg",
      sectionClass: "ic_height_full_page", // 'ic_height_auto',
    };
    // init modal lightbox
    this.displayModal = false;
    this.formType = "";
    this.formTitle = "";
    this.topDisplayModal = false;
    this.topFormType = "";
    this.topFormTitle = "";
    this.foremostDisplayModal = false;
    this.foremostFormType = "";
    this.foremostFormTitle = "";

    // handle user status
    if (this.projectsType === "id-verification") {
      this.activeUserStatus =
        this.idVerificationService.activeUserStatus.subscribe((status) => {
          if (status !== false) {
            // fetching active legal representatives while initting the id verification dashboard!
            // set user to permitted
            this.activeUser = 1;
            // check if there is an active project selected
            // and then fetch the legal representatives for it
            if (
              typeof this.idVerificationService.getActiveProject() ===
              "undefined"
            ) {
              // fetching active legal representatives while initting the id verification
              // dashboard failed because there was no active project selected
              return;
            }
          } else {
            // set user to forbidden
            this.activeUser = 0;
          }
        });
    }

    // handle investor status
    if (this.projectsType === "funds" || this.projectsType === "investor") {
      this.activeUserStatus =
        this.idVerificationService.activeUserStatus.subscribe((status) => {
          if (status !== false) {
            // fetching active legal representatives while initting the id verification dashboard!
            // set user to permitted
            this.activeUser = 1;
            // check if there is an active project selected
            // and then fetch the legal representatives for it
            if (
              typeof this.idVerificationService.getActiveProject() ===
              "undefined"
            ) {
              // fetching active legal representatives while initting the id verification
              // dashboard failed because there was no active project selected
              return;
            }
          } else {
            // set user to forbidden
            this.activeUser = 0;
          }
        });
    }

    // full pdf report generation
    if (this.projectsType === "id-verification") {
      this.completePDFStatus =
        this.idVerificationService.detectCompletePDFDownload.subscribe(
          (status) => {
            this.onDownloadCompletePDF(undefined, status);
          },
        );
    }

    if (this.projectsType === "funds") {
      this.completePDFStatus =
        this.idVerificationService.detectCompletePDFDownload.subscribe(
          (status) => {
            this.onDownloadCDDImages(undefined, status);
          },
        );
    }

    // reset active legal representitive
    if (this.projectsType === "id-verification") {
      this.resetActiveLegalRepresentativesList();
    }

    // reset active investor list
    if (this.projectsType === "funds" || this.projectsType === "investor") {
      this.resetActiveInvestorList();
    }

    // load active project list
    this.retrieveActiveProjects = this.idVerificationService
      .retrieveActiveProjects()
      .subscribe((response) => {
        if (!response.status) {
          this.idVerificationService.resetActiveProjects();
          return;
        }
        this.idVerificationService.setActiveProjects(
          response.data.records,
          response.data.metadata.bookmark,
          response.append,
          response.lastLoad,
          response.selectedFilter,
          response.loadMore,
        );
      });

    // detect header toggle
    this.toggleHeaderStatus =
      this.idVerificationService.toggleHeaderStatus.subscribe((response) => {
        this.headerInfo["headerType"] = response;
        if (response === "full") {
          this.headerClasses["sectionClass"] = "ic_height_full_page";
        }
        if (response === "inline") {
          this.headerClasses["sectionClass"] = "ic_height_auto";
        }
      });

    

    // detect load additional person data
    if (this.projectsType === "id-verification") {
      this.loadAdditionalPersonDetails =
        this.idVerificationService.detectAdditionalPersonDetailsStatus.subscribe(
          (result) => {
            if (result !== null && result.response.record !== undefined) {
              // since two or more boxes could be expanded
              // before any result is returned from BE
              // we will update corresponding perons detail
              // not only active person detail
              this.idVerificationService.updatePersonDetail(
                result.response.key,
                result.response,
              );
              // check if we need to execute a lazy recovery
              if (result.alwaysExecuteLazy) {
                console.log(
                  "alwaysExecuteLazy set to true! Executing the resolve active person status now. RESOLVE ACTIVE PERSON STATUS NOW, BECAUSE ALWAYS EXECUTE LAZY IS SET TO TRUE",
                  result.response.key,
                );
                // do the resolve for any VFE that might need resolving since alwaysExecuteLazy is set to true
                this.idVerificationService.doOneByOneDirectLazyLoad(result);
              } else if (
                result.response.record.lastVerification !== undefined &&
                result.response.record.lastVerification.record.status ===
                  "processing"
              ) {
                // do this only for VFEs that have lazyDelay set to > 0 (such as IDV/lastVerification)
                // as it is expected that the resolve should work in most cases after (lazyDelay) milliseconds
                // this is necessary for scenarios like IDV so we don't bombard BE/JUMIO
                // with too many unneccessary calls
                if (
                  new Date().getTime() -
                    this.helper.getTimestamp(
                      result.response.record.lastVerification.record
                        .lastModified,
                    ) >
                  15 * 60 * 1000
                ) {
                  // do the lazy recovery
                  this.idVerificationService.doOneByOneDirectLazyLoad(
                    result,
                    true,
                  );
                }
              }
            }
            // expand the box even if there are no results and if there is an event target:
            if (
              result.eventTarget !== undefined &&
              result.eventTarget !== null
            ) {
              let eventTarget = $(result.eventTarget.target);
              let eventImg = eventTarget.find("img");
              if (eventTarget.is("img")) {
                eventTarget = $(eventTarget).closest("a");
                eventImg = $(eventTarget);
              }
              eventTarget
                .find("img")
                .attr("src", "assets/images/result_arrow_down.png")
                .height("10px");
              eventTarget.removeClass(result.eventTarget.originalclass);
              eventTarget.addClass(result.eventTarget.toggleclass);
              const resultContainer = eventTarget.closest(".result-container");
              const prevResultContainer = resultContainer.prev();
              const nextResultContainer = resultContainer.next();
              resultContainer.addClass("expanded");
              prevResultContainer.addClass("prevExpanded");
              nextResultContainer.addClass("nextExpanded");
              // simulate click:
              $(eventTarget).click();
            }
          },
        );
    }

    // detect id verificaiton product preview function
    if (this.projectsType === "id-verification") {
      this.detectTriggerIDPreview =
        this.idVerificationService.detectTriggerIDPreview.subscribe((event) => {
          const method = $(event.target).attr("clickmethod");
          if (method === "OnDisplayContractInLightbox") {
            this.OnDisplayContractInLightbox(event, undefined);
          }
          if (method === "OnDisplayImageInLightbox") {
            this.OnDisplayImageInLightbox(undefined);
          }
          if (method === "OnDisplayImageAddressInLightboxLoading") {
            this.OnDisplayImageAddressInLightboxLoading(undefined, false);
          }
          if (method === "OnDisplayAPSInLightbox") {
            this.OnDisplayAPSInLightbox(undefined, undefined);
          }
        });
    }

    // load person image
    if (this.projectsType === "id-verification") {
      this.loadPersonImage =
        this.idVerificationService.detectLoadPersonImageStatus.subscribe(
          (result) => {
            // console.log('LOAD PERSON IMAGE STATUS', result);
            // console.log('LOAD PERSON IMAGE', result.response);
            // tslint:disable-next-line: deprecation
            status = result.response;
            if (
              result !== null &&
              result.response !== undefined &&
              result.type === ""
            ) {
              // since two or more boxes could be expanded
              // before any result is returned from BE
              // we will update corresponding perons detail
              // not only active person detail
            } else if (
              result !== null &&
              result.response !== undefined &&
              result.type === "back"
            ) {
              this.activePersonBackImage = result.response;
              if (this.activePersonBackImage) {
                this.OnDisplayImageInLightbox(
                  undefined,
                  this.activePersonBackImage,
                  this.activePersonBackImage,
                );
              } else {
                this.OnDisplayImageInLightbox(undefined, false);
              }
            }

            if (
              result !== null &&
              (result.backImg !== undefined || result.frontImg) &&
              result.type === "both"
            ) {
              const backImage = result.backImg;
              const frontImage = result.frontImg;
              const verificationDetails = result.verificationDetails;
              if (frontImage || backImage) {
                this.OnDisplayImageInLightbox(
                  undefined,
                  frontImage,
                  backImage,
                  verificationDetails,
                );
              } else {
                this.OnDisplayImageInLightbox(undefined, false);
              }
            }

            if (
              result &&
              (result.type === "back" || result.type === "both") &&
              !result.backImg &&
              !result.frontImg
            ) {
              this.OnDisplayImageInLightbox(undefined, false);
            }

            if (result.type === "address") {
              if (result.imagesArray) {
                this.OnDisplayImageAddressInLightbox(
                  undefined,
                  result.imagesArray,
                );
              } else {
                this.OnDisplayImageAddressInLightbox(undefined, false);
              }
            }

            if (result.type === "aps") {
              // console.log('aps details', result.apsDetails);
              this.OnDisplayAPSInLightbox(undefined, result.apsDetails);
            }

            if (result.type === "contract") {
              this.OnDisplayContractInLightbox(
                undefined,
                result.contractDetails,
              );
            }
          },
        );
    }

    // detect screening status
    if (this.projectsType === "id-verification") {
      this.idVerificationService.detectScreeningStatus
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response) => {
            let group = {};
            if (
              response.status &&
              response.data !== undefined &&
              response.data.record !== undefined &&
              response.data.record.screeningResults !== undefined
            ) {
              const statusPep =
                response.data.record.screeningResults.bkrPep === "red"
                  ? "red"
                  : "green";
              const statusSanctions =
                response.data.record.screeningResults.bkrSan === "red"
                  ? "red"
                  : "green";
              let screeningBottomMessage = "";
              if (statusPep === "green" && statusSanctions === "green") {
                screeningBottomMessage = "The check did not produce any hits.";
              } else if (statusPep === "green" && statusSanctions === "red") {
                screeningBottomMessage =
                  "There was a hit in one of the sanctions list.";
              } else if (statusPep === "red" && statusSanctions === "green") {
                screeningBottomMessage = "There was a hit in the PEP database.";
              } else if (statusPep === "red" && statusSanctions === "red") {
                screeningBottomMessage =
                  "There were hits in both the sanctions lists and PEP database.";
              }
              group = {
                section_class:
                  "results d-flex flex-column justify-items-center p-0",
                result_container_class: "",
                is_collapsable: false,
                result_details: [
                  {
                    group_result_title: "request_new_verification_group",
                    elements: [
                      {
                        type: "h3",
                        class: "mt-0",
                        params: {
                          content: "The results are in.",
                        },
                      },
                      {
                        type: "tabbed_content",
                        class: "hide-tabs",
                        params: {
                          tabs: [
                            {
                              id: "shareholders",
                              status: "active",
                              content: [
                                {
                                  key: "Last name",
                                  content:
                                    response.data.record.personData.last_name,
                                },
                                {
                                  key: "Date of Birth",
                                  content:
                                    response.data.record.personData
                                      .date_of_birth,
                                },
                              ],
                            },
                          ],
                        },
                      },
                      {
                        type: "unordered_list_icons",
                        listClass: "custom-list-icons-results",
                        params: [
                          {
                            content: "PEP",
                            li_class: statusPep,
                          },
                          {
                            content: "Sanctions",
                            li_class: statusSanctions,
                          },
                        ],
                      },
                      {
                        type: "paragraph",
                        params: {
                          content: screeningBottomMessage,
                        },
                      },
                      {
                        type: "button",
                        params: {
                          content: "Close",
                          display: true,
                          action: "OnCloseLightboxWithButton",
                        },
                      },
                    ],
                  },
                ],
              };
            } else if (response.reason !== "timeout") {
              group = {
                section_class:
                  "results d-flex flex-column justify-items-center p-0",
                result_container_class: "",
                form_id: "form-screening-error-container",
                is_collapsable: false,
                result_details: [
                  {
                    group_result_title: "perform_integrity_check",
                    elements: [
                      {
                        type: "image",
                        params: {
                          src: "assets/images/icon-triangle-orange.svg",
                        },
                      },
                      {
                        type: "h3",
                        params: {
                          content: "Screening failure",
                        },
                      },
                      {
                        type: "paragraph",
                        params: {
                          content:
                            "There was an error while performing the screening. Please close this window, refresh the page and start the screening again. If you continue to receive this message, please contact support.",
                        },
                      },
                      {
                        type: "button",
                        params: {
                          content: "Close",
                          display: true,
                          action: "OnCloseLightboxWithButton",
                        },
                      },
                    ],
                  },
                ],
              };
            } else {
              group = {
                section_class:
                  "results d-flex flex-column justify-items-center p-0",
                result_container_class: "",
                form_id: "form-screening-error-container",
                is_collapsable: false,
                result_details: [
                  {
                    group_result_title: "perform_integrity_check",
                    elements: [
                      {
                        type: "image",
                        params: {
                          src: "/assets/images/loading_screen.gif",
                          class: "full-max-size",
                        },
                      },
                      {
                        type: "h3",
                        params: {
                          content: "This might take a while",
                        },
                      },
                      {
                        type: "paragraph",
                        params: {
                          content:
                            "We will do the check and show the results in the legal representatives list.",
                        },
                      },
                      {
                        type: "button",
                        params: {
                          content: "Close",
                          display: true,
                          action: "OnCloseLightboxWithButton",
                        },
                      },
                    ],
                  },
                ],
              };
            }
            // set the content to the lightbox
            this.lightboxContent = [];
            this.lightboxContent.push(group);
            this.displayLoader = false;
          },
          (error) => {
            const group = {
              section_class:
                "results d-flex flex-column justify-items-center p-0",
              result_container_class: "",
              form_id: "form-screening-error-container",
              is_collapsable: false,
              result_details: [
                {
                  group_result_title: "perform_integrity_check",
                  elements: [
                    {
                      type: "image",
                      params: {
                        src: "assets/images/icon-triangle-orange.svg",
                      },
                    },
                    {
                      type: "h3",
                      params: {
                        content: "Screening failure",
                      },
                    },
                    {
                      type: "paragraph",
                      params: {
                        content:
                          "There was an error while performing the screening. Please close this window, refresh the page and start the screening again. If you continue to receive this message, please contact support.",
                      },
                    },
                    {
                      type: "button",
                      params: {
                        content: "Close",
                        display: true,
                        action: "OnCloseLightboxWithButton",
                      },
                    },
                  ],
                },
              ],
            };
            // set the content to the lightbox
            this.lightboxContent = [];
            this.lightboxContent.push(group);
            this.displayLoader = false;
          },
        );
    }

    // parse generate ID verification URL response
    if (this.projectsType === "id-verification") {
      this.idVerificationService.detectVerificationUrlStatus
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          if (!response.status) {
            alert(
              "We were not able to generate the link at this time! Please try again later.",
            );
            $("#lightBoxCloseImage").click();
            return;
          }
          if (!response.afterCreation) {
            if (
              this.selectedVerifications &&
              this.selectedVerifications.AVerification
            ) {
              this.generateAVerificationLink();
              return;
            } else {
              this.idVerificationService.getActivePersonDetails()["record"][
                "lastVerificationStatus"
              ] = "pending";
              this.loadLinksAfterConfiguration(response.data.record.url);
            }
          }
        });
      // parse generate address verification URL response
      this.idVerificationService.detectAddressVerificationStatus
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response) => {
            let content = `Generate a unique address verification link to be shared with the person being verified. To copy the link, please press "Copy" after generating the link.<br>For testing purposes this link should be confirmed by using following confirmation key: <b>${response.data.record.confirmationKey.replace(
              /(?=.{3}$)/,
              " ",
            )}</b>`;
            let input = {};
            let cta = {};
            if (!response.status) {
              content =
                "We were not able to fetch the address verification url for you please try again!";
              input = input = {
                type: "inputGoogleAutocomplete",
                params: {
                  label: "Address to be verified",
                  // 'id': 'AddressInputField'
                  placeholder: this.translate.instant(
                    "evidencePreview.addressLabel2",
                  ),
                },
              };
              cta = {
                type: "button",
                params: {
                  content: "Try again",
                  display: true,
                  action: "idvOnGenerateAddressVerificationUrl",
                },
              };
            } else {
              cta = {
                type: "inputTextWithButton",
                params: {
                  id: "generatedAddressVerificationLink",
                  content: response.data.record.url,
                  classes: "generated-link",
                  button: {
                    display: true,
                    content: "Copy",
                    class: "ml-2 mr-0 form-button-1",
                    action: "CopyAddressLinkToClipboard",
                  },
                },
              };
            }
            const group = {
              section_class:
                "results d-flex flex-column justify-items-center p-0",
              result_container_class: "",
              is_collapsable: false,
              form_id: "request_new_verification",
              result_details: [
                {
                  group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
                  elements: <any>[
                    {
                      type: "title",
                      params: {
                        content: "Invite with url",
                      },
                    },
                    {
                      type: "paragraph",
                      params: {
                        content: content,
                      },
                    },
                  ],
                },
              ],
            };
            if (Object.keys(input).length > 0) {
              group.result_details[0].elements.push(input);
            }
            group.result_details[0].elements.push(cta);

            // set the content to the lightbox
            this.lightboxContent = [];
            this.lightboxContent.push(group);
            this.displayLoader = false;
          },
          (error) => {
            const content =
              "We were not able to fetch the address verification url for you please try again!";
            const cta = {
              type: "button",
              params: {
                content: "Try again",
                display: true,
                action: "idvOnGenerateInviteUrl",
              },
            };
            const group = {
              section_class:
                "results d-flex flex-column justify-items-center p-0",
              result_container_class: "",
              is_collapsable: false,
              form_id: "request_new_verification",
              result_details: [
                {
                  group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
                  elements: <any>[
                    {
                      type: "title",
                      params: {
                        content: "Invite with url",
                      },
                    },
                    {
                      type: "paragraph",
                      class: "mt-1",
                      params: {
                        paragraphClass: "mt-1",
                        content:
                          'Generate a unique link to be shared with the person being verified. To copy the link, please press "Copy" after generating the link',
                      },
                    },
                    {
                      type: "button",
                      params: {
                        content: "Generate unique link",
                        display: true,
                        action: "idvOnGenerateInviteUrl",
                      },
                    },
                    {
                      type: "paragraph",
                      params: {
                        content: content,
                      },
                    },
                  ],
                },
              ],
            };
            group.result_details[0].elements.push(cta);

            // set the content to the lightbox
            this.lightboxContent = [];
            this.lightboxContent.push(group);
            this.displayLoader = false;
          },
        );
    }

    // parse changes in active legal representatives
    if (this.projectsType === "id-verification") {
      this.parseLegalRepresentativesStatus =
        this.idVerificationService.detectActiveLegalRepresentativesStatus.subscribe(
          (response) => {
            this.parseActiveLegalRepresentativesList(
              this.idVerificationService.getActivePersons(),
            );
            if (response === "generateLinks") {
              this.loadLinksAfterCreation();
            }
          },
        );
    }

    // close the lightbox on edit person
    if (this.projectsType === "id-verification") {
      this.editLegalRepresentativesStatus =
        this.idVerificationService.editLegalRepresentativeStatus.subscribe(
          (response) => {
            this.displayLoader = false;
            if (response === undefined || response.status === false) {
              // display unexpected error
              this.DisplayFailedUpdatePerson(
                response.personData,
                response.error,
              );
              return;
            }
            if (!response.onCreate) {
              this.displayModal = false;
            }
          },
        );
    }

    // detect PDF generation
    if (this.projectsType === "id-verification") {
      this.idVerificationService.detectPdfGenerationStatus
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((status) => {
          console.log("displaying PDF generator", status);
          this.pdfGeneratorActive = status;
        });
    }

    // detect address verification PDF generation
    if (this.projectsType === "id-verification") {
      this.idVerificationService.detectAddressVerificationPdfGenerationStatus
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((status) => {
          this.addressVerificationPdfGeneratorActive = status;
        });
    }

    // parse changes in active investors
    if (this.projectsType === "funds" || this.projectsType === "investor") {
      this.parseInvestorsStatus =
        this.idVerificationService.detectActiveInvestorsStatus.subscribe(
          (response) => {
            if (this.idVerificationService.getActiveInvestors() === undefined) {
              this.resetActiveInvestorList();
            } else if (this.projectsType === "investor") {
              // investor side
              this.parseActiveInvestorsListInvestor(
                this.idVerificationService.getActiveInvestors(),
                response ? response.investorType : "none",
                response ? response.isLoading : true,
                response ? response.investors : false,
              );
            } else {
              // funds side
              this.parseActiveInvestorsList(
                this.idVerificationService.getActiveInvestors(),
                response ? response.investorType : "none",
                response ? response.isLoading : false,
              );
            }
          },
        );
    }

    // load additional investor data
    if (this.projectsType === "funds" || this.projectsType === "investor") {
      this.loadAdditionalInvestorDetails =
        this.idVerificationService.detectAdditionalInvestorDetailsStatus.subscribe(
          (response) => {
            // load additional investor data
            if (this.projectsType === "investor") {
              // parse the list of investors
              this.parseActiveInvestorsListInvestor(
                this.idVerificationService.getActiveInvestors(),
                this.idVerificationService.getActiveInvestorFilter(),
                response ? response.isLoading : true,
                response ? response.investors : false,
              );
            } else {
              this.parseActiveInvestorsList(
                this.idVerificationService.getActiveInvestors(),
                this.idVerificationService.getActiveInvestorFilter(),
                response ? response.isLoading : false,
                response ? response.relatedPartiesLoading : false,
              );
            }
          },
        );
    }
    this.displayInvestorReviewStatus =
      this.idVerificationService.detectisplayLoadInvestorReviewStatus.subscribe(
        (statuses) => {
          this.reviewStatus = statuses.reviewState;
          this.dvInvestorStatus = statuses.dvState;
          if (!this.reviewStatus) {
            this.reviewStatus = "loading";
          }
          this.showPCSLoading = true;
          this.idVerificationService.forceLoadingScreen = false;
          // if we went trough the pcs screen and the status was changed to 'no-review' (the review flow was done)
          // then we need to initialize a reset and fetch the data from the backend again because of BE limitations
          if (this.reviewStatus === "no-review" && this.pcsFlowTrough) {
            const currentRoute = this.router.url;
            this.router
              .navigateByUrl("/verifications", { skipLocationChange: true })
              .then(() => {
                this.router.navigate([currentRoute]); // navigate to same route
              });
          }
          // if there's a url to redirec too we need to hide the logo
          const investorData =
            this.idVerificationService.getActiveInvestorDetaisl();
          if (investorData.redirectUrl) {
            this.hideHeaderLogo = true;
          }
        },
      );

    // display Project Customization screen
    this.detectDisplayCustomizeProjectSettings =
      this.idVerificationService.detectDisplayCustomizeProjectLightbox.subscribe(
        (status) => {
          if (status) {
            this.displayCustomizeProjectSettings();
          }
        },
      );

    // display Invite Contributors screen
    this.detectDisplayInviteContributorsLightbox =
      this.idVerificationService.detectDisplayInviteContributorsLightbox.subscribe(
        (status) => {
          if (status) {
            this.displayInviteContributors();
          }
        },
      );

    // display edit template screen
    this.detectDisplayEditTemplateLightbox =
      this.idVerificationService.detectDisplayEditTemplateLightbox.subscribe(
        (status) => {
          if (status) {
            this.displayEditTemplate();
          }
        },
      );

    // display Manage Contributors screen
    this.detectDisplayManageContributorsLightbox =
      this.idVerificationService.detectDisplayManageContributorsLightbox.subscribe(
        (status) => {
          if (status) {
            this.takeActionsToDisplayManageContributors();
          }
        },
      );

    // display Generate Report screen
    this.detectDisplayGenerateReportLightbox =
      this.idVerificationService.detectDisplayGenerateReportLightbox.subscribe(
        (response) => {
          if (
            response.verificationType === "verification" &&
            response.reportType === "usage"
          ) {
            this.displayGenerateReportInit(response.tags);
          } else if (response.reportType === "status") {
            this.displayGenerateReportStatus();
          }
        },
      );

    // display Manage Contributors screen
    this.detectLoadProjectContributors =
      this.idVerificationService.detectLoadProjectContributors.subscribe(
        (status) => {
          if (status) {
            // first check if we are trying to leave a project
            // if yes, the getActiveProjectLeave will return a projectKey
            this.displayLoader = false;
            if (
              this.idVerificationService.getActiveProjectLeave() !==
                undefined &&
              status.initiatedByLeaveProject
            ) {
              // initiate a Leave a project flow

              // if there is only one project owner it should be me
              if (this.idVerificationService.getProjectOwners().length > 1) {
                // I AM ALLOWED TO LEAVE THE PROJECT AS THERE ARE OTHER OWNERS!
                // console.log('LIST OF PROJECT OWNERS: ', this.idVerificationService.getProjectOwners());
                this.displayConfirmationProjectLeave();
                return;
              }
              // there are no other owners but me, check if there are members
              if (this.idVerificationService.getProjectMembers().length > 0) {
                // I SHOULD BE PRESENTED WITH AN OPTION TO CHOOSE A MEMBER TO UPGRADE TO OWNER
                // OR TO INVITE A NEW PROJECT OWNER, BEFORE I LEAVE A PROJECT
                this.displayProjectLeaveMembersAvailable();
                return;
              }
              // there are no other owners NOR members
              // I SHOULD BE PRESENTED WITH AN OPTION TO INVITE NEW PROJECT OWNER BEFORE I LEAVE THE PROJECT
              this.displayProjectLeaveNotAvailable();
              // reset the active project leave data
              this.idVerificationService.resetActiveProjectLeave();
              return;
            }
            this.displayManageContributors(false, status.highlightEmail);
          }
        },
      );

    // display Manage Contributors screen
    this.detectLoadProjectInvitations =
      this.idVerificationService.detectLoadProjectInvitations.subscribe(
        (status) => {
          if (status) {
            this.displayManageContributors();
          }
        },
      );

    // display Advanced Settings scren
    this.detectDisplayAdvancedSettingsLightbox =
      this.idVerificationService.detectDisplayAdvancedSettingsLightbox.subscribe(
        (status) => {
          if (status) {
            this.displayAdvancedSettings();
          }
        },
      );

    // determine if the project settings window should be closed or not - part 1
    this.detectUpdateProjectBrandingStatus =
      this.idVerificationService.detectUpdateProjectBrandingStatus.subscribe(
        (response) => {
          // in any case mark the branding update as finished
          this.logoUpdateBrandingFinished = true;
          if (this.logoUploadFinished) {
            // both project name and logo upload are already finished and we can close the dialog
            this.displayLoader = false;
            this.displayModal = false;
          }
        },
      );

    // determine if the project settings window should be closed or not - part 2
    this.detectUploadProjectLogoStatus =
      this.idVerificationService.detectUploadProjectLogoStatus.subscribe(
        (response) => {
          // in any case mark the upload as finished
          this.logoUploadFinished = true;
          if (response.status) {
            $(".upload-status").text("Upload successfull!").fadeOut();
            this.lastLogoUploadStatus = true; // success
            this.displayLoader = false;
            this.displayModal = false;
          } else {
            this.lastLogoUploadStatus = false; // fail
            this.displayLoader = false;
            $(".upload-status").css("color", "#c74848").text(response.reason);
          }
        },
      );

    // determine if the project settings window should be closed or not - part 3
    // when project name is updated it will send an event only after active project list is updated
    // all other actions on that event are handled in other components, here we need to handle the
    // project name update and the lightbox close
    this.detectactiveProjectsStatus =
      this.idVerificationService.detectActiveProjectsStatus.subscribe(
        (status) => {
          if (
            (this.idVerificationService.getActiveProjectSettingsLogo() ===
              undefined ||
              this.logoUploadFinished) &&
            this.logoUpdateBrandingFinished
          ) {
            if (this.lastLogoUploadStatus) {
              this.displayLoader = false;
              this.displayModal = false;
            } else {
              this.displayLoader = false;
            }
          }
        },
      );

    // handle after project delete
    this.detectProjectDelete =
      this.idVerificationService.detectProjectDelete.subscribe((status) => {
        if (status == null) {
          // delete was not successfull
          alert("We could not remove the project for you!");
          this.displayLoader = false;
          return;
        } else {
          this.displayLoader = false;
          $(".close-form-button,#lightBoxCloseImage").trigger("click");
          this.idVerificationService.removeProject(status);
        }
      });

    this.detectProjectDeleteDialogue =
      this.idVerificationService.detectprojectDeleteDialogue.subscribe(
        (projectData) => {
          this.displayDeleteProjectDialogue(projectData);
        },
      );

    // detect generation of invite link
    this.detectGenerateContributorInviteUrlStatus =
      this.idVerificationService.detectGenerateContributorInviteUrlStatus.subscribe(
        (status) => {
          this.idvOnGenerateConributorInviteUrlComplete(status);
        },
      );

    // detect generation of reinvite link
    this.detectReinviteContributorStatus =
      this.idVerificationService.detectReinviteContributorStatus.subscribe(
        (response) => {
          this.displayLoader = false;
          if (response.status) {
            this.displayModal = false;
          } else {
            $('[id="' + response.target + '"]')
              .addClass("error")
              .text("Failed");
            setTimeout(function () {
              $('[id="' + response.target + '"]')
                .removeClass("error")
                .text("Re-invite");
            }, 3000);
          }
        },
      );

    // detect contributor added to a project
    this.detectAddContributorStatus =
      this.idVerificationService.detectAddContributorStatus.subscribe(
        (status) => {
          this.idvOnAddConributorComplete(status);
        },
      );

    // detect leave a project
    this.detectLeaveAProjectStatus =
      this.idVerificationService.detectLeaveAProjectStatus.subscribe(
        (response) => {
          if (response.status) {
            this.displayLoader = false;
            this.displayModal = false;
            // reload list of active projects!
            this.idVerificationService.getActiveProjectDetails();
            this.idVerificationService.resetActiveProjectLeave();
            return;
          }
          this.displayLeaveProjectError(response.reason);
        },
      );

    // cancel a verification
    this.cancelVerificationStatus =
      this.idVerificationService.cancelVerificationStatus.subscribe(
        (response) => {
          if (response && response.status) {
            this.displayModal = false;
          } else {
            this.displayCancelVerificationError(response.reason);
          }
        },
      );

    // reset branding
    this.resetBrandingStatus =
      this.idVerificationService.resetBrandingStatus.subscribe((response) => {
        if (response && response.status) {
          this.displayModal = false;
        } else {
          this.displayBrandingResetError(response.reason);
        }
      });

    // REVIEW
    this.displayTopModal =
      this.idVerificationService.detectDisplayConfirmUseOfNewInformationLightbox.subscribe(
        (status) => {
          this.displayConfirmUseOfNewInformationLightbox(
            status.true,
            status.requestKey,
            status.evidenceField,
          );
        },
      );

    // this.displayTopModal = this.idVerificationService.detectDisplayReplaceExistingDocumentLightbox.subscribe(status => {
    //   if (status) {
    //     this.displayReplaceExistingDocument();
    //   }
    // });

    this.displayTopModal =
      this.idVerificationService.detectDisplayCreateNewDocumentConfirmation.subscribe(
        (status) => {
          if (status) {
            this.displayCreateNewDocumentConfirmation(true);
          } else if (status === false) {
            this.displayCreateNewDocumentConfirmation(false);
          }
        },
      );
    this.displayPreview =
      this.idVerificationService.detectDispalyReviewPreviewlightbox.subscribe(
        (response) => {
          this.onEvidencePreviewInvestor(response.response);
        },
      );
    this.displayAccessPopUp =
      this.idVerificationService.detectDisplayAccessPopUp.subscribe(
        (response) => {
          this.displayAccessPopUpFunc({
            providers: response["providers"],
            evidenceKey: response["evidenceKey"],
            requestKey: response["requestKey"],
          });
        },
      );
  }
  setReviewStatus($event) {
    this.reviewStatus = $event;
  }

  // EOF ngOnInit

  /**
   * method used to take all actions to display ManageContributors modal
   */
  takeActionsToDisplayManageContributors(highlightEmail = "") {
    // reset any previous contributor data
    this.idVerificationService.resetProjectContributorsData();
    // reset any previous invitation data
    this.idVerificationService.resetProjectInvitationsData();
    this.displayManageContributorsInit();
    // get project contributors
    this.idVerificationService.loadContributors(
      this.idVerificationService.getActiveProjectSettings(),
      false,
      highlightEmail,
    );
    // get ACTIVE project invitations
  }

  /**
   * On fail edit existing person
   * @param event event
   */
  DisplayFailedUpdatePerson(personData, errorMessage) {
    const params = {
      formType: "Edit person",
      formTitle: "",
      btnOkText: "",
      formValidator: "",
      btnCancelText: "",
      // build the content for the failed Edit representative information lightbox
      group: {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "edit_existing_representative",
        result_details: [
          {
            group_result_title: "edit_existing_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "paragraph",
                params: {
                  contentHTML: `We were unable to create the person at the moment!<div class="font-italic font-bold">Reason: <span class="text-red">\"${
                    errorMessage.message ? errorMessage.message : errorMessage
                  }\"</span></div>`,
                },
              },
              {
                type: "inputText",
                params: {
                  label: "First name",
                  id: "lb-representative-fname",
                  name: "lbRepresentativeName",
                  required: true,
                  content: personData.firstName,
                  maxLength: 100,
                },
              },
              {
                type: "inputText",
                params: {
                  label: "Last name ",
                  id: "lb-representative-lname",
                  name: "lbRepresentativeName",
                  required: true,
                  content: personData.lastName,
                  maxLength: 100,
                },
              },
              {
                type: "inputText",
                params: {
                  label: "E-mail",
                  id: "lb-representative-email",
                  name: "lbRepresentativeEmail",
                  patternMatch:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  validatorMessage: this.translate.instant(
                    "validation.validation5",
                  ),
                  content: personData.email,
                  required: false,
                },
              },
              {
                type: "input_date",
                params: {
                  label: "Date of birth",
                  id: "lb-representative-dob",
                  name: "lbRepresentativeDob",
                  startDate:
                    personData.dateOfBirth !== undefined
                      ? this.helper.getAsStartDate(personData.dateOfBirth)
                      : undefined,
                  minDate: this.helper.getAsStartDate("01.01.1915"),
                  maxDate: this.helper.getAdultAsMaxDate(),
                  required: false,
                },
              },
              {
                type: "button",
                params: {
                  content: "Save",
                  type: "submit",
                  display: true,
                  action: "htpmOnSaveExistingRepresentative",
                },
              },
            ],
          },
        ],
      },
    };

    this.formType = params.formType;
    this.formTitle = params.formTitle; // 'Financial Holdings B.V';
    this.btnOkText = params.btnOkText;
    this.formValidator = params.formValidator;
    this.btnCancelText = params.btnCancelText;
    // build the content for the Request Information light box
    const group = params.group;
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
    this.displayLoader = false;
  }

  /**
   * handle click on lightbox confirm
   * @param event event
   */
  onLightboxConfirm(event) {
    if (typeof event.method === "string" && event.method !== "") {
      // allow only strings as acceptable method name
      let params = "";
      if (Array.isArray(event.params) || typeof event.params === "object") {
        params = event.params;
      }
      if (typeof event.params.name === "string") {
        params = event.params;
      }
      try {
        // console.log('calling', event.method, 'with params', params);
        this[event.method](params); // call if it exists
      } catch (error) {
        console.log(
          event.method + " needs to be defined before it is called",
          "sending params:",
          params,
          error,
        );
      }
    } else {
      console.log("**method name not string or empty string");
    }
    // execute default action
    if (typeof event.method === "undefined") {
      // allow only strings as acceptable method name
      // execute default OnConfirm action for this component
      this.displayLoader = true; // display the loading screen
    }
  }
  /**
   * handle click on lightbox close
   */
  onLightboxClose(event) {
    let classes = "";
    if ($(event.target).hasClass("close-form-button")) {
      classes = $(event.target).attr("class");
    } else {
      classes = $(event.target).closest(".close-form-button").attr("class");
    }
    let lightboxType = "displayModal";
    if (classes && classes.includes("top-dialog-box")) {
      lightboxType = "topDisplayModal";
    }
    if (classes && classes.includes("foremost-dialog-box")) {
      lightboxType = "foremostDisplayModal";
    }
    this.idVerificationService.resetActiveProjectSettingsLogo();
    this[lightboxType] = false;
  }

  /**
   * reset active legal representative's list back to initial state/null
   */
  resetActiveLegalRepresentativesList() {
    this.groups = {
      legalRepresentatives: [
        {
          section_class:
            "results d-flex flex-column justify-items-center person-list-loading",
          group_name: "List of persons to be verified",
          is_collapsable: true,
          // tslint:disable-next-line:max-line-length
          emptyMessage: "Please wait while the project is being loaded...",
          displayLoader: true,
          result_details: [],
        },
      ],
    };
  }
  /**
   * reset active legal investors list back to initial state/null
   */
  resetActiveInvestorList() {
    this.groups = {
      legalRepresentatives: [
        {
          section_class:
            "results d-flex flex-column justify-items-center person-list-loading",
          group_name: this.idVerificationService.isInvestorType() ? "" : "",
          special_class: "mt-4",
          is_collapsable: true,
          // tslint:disable-next-line:max-line-length
          result_details: [],
          displayLoader: this.idVerificationService.isInvestorType()
            ? false
            : true,
        },
      ],
    };
  }

  /**
   * parse active legal representative's for active project data
   */
  parseActiveLegalRepresentativesList(persons) {
    // added condition if there are any tags continue
    if (
      persons === undefined ||
      (persons.length === 0 &&
        !this.idVerificationService.getActiveLegalRepresentativesTags())
    ) {
      this.resetActiveLegalRepresentativesList();
      return;
    }

    this.permissionProjectScreening =
      this.idVerificationService.permissionUpdateProjectScreening();
    this.permissionAddressVerification =
      this.idVerificationService.permissionUpdateAddressVerification();
    this.permissionIDVerification =
      this.idVerificationService.permissionUpdateIDVerification();
    this.permissionAPS = this.idVerificationService.permissionUpdateAPS();
    this.permissionContract = this.idVerificationService.permissionContract();
    this.permissionIDIN = this.idVerificationService.permissionIDIN();
    this.permissionReportGeneration =
      this.idVerificationService.permissionUpdateReportGeneration();
    this.permissionDeleteSubject =
      this.idVerificationService.permissionUpdateDeleteSubject();
    const group = {
      section_class: "results d-flex flex-column justify-items-center",
      // 'group_name' : 'List of persons to be verified',
      group_sorter: true,
      is_collapsable: true,
      result_details: [],
      fixedHeight: true,
      sorterTitle: "List of persons",
      verificationDashboard: true,
    };

    let triggerImageRecoverForId = "";
    // COUNTERS ARE NOT USED ANYMORE
    let counter_all = 0;
    let counter_verified = 0;
    let counter_pending = 0;
    let counter_not_started = 0;
    let counter_failed = 0;
    let counter_action_required = 0;
    const deleteloadingbar = false;
    persons.forEach((person) => {
      // parse answers from previous companies
      const parsedRequests = [];
      // Define Content
      const result = person.record;
      if (!this.didGetUrlPerson) {
        this.didGetUrlPerson = true;
        if (this.router.url.includes("id-verification")) {
          this.route.params
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((params) => {
              const verificationKey = params["verificationId"];
              const urlProjectId = params["projectId"];
              if (verificationKey !== "" || verificationKey !== undefined) {
                this.idVerificationService.setActivePerson(verificationKey);
                const projectRecords =
                  this.idVerificationService.getActiveProjects();
                let projectFound = false;
                projectRecords.forEach((project, key) => {
                  if (project.key === urlProjectId) {
                    projectFound = true;
                    this.location.go(
                      "/id-verification/dashboard/" + urlProjectId,
                    );
                  }
                });
              }
            });
        }
        // this.idVerificationService.setScreeningFinished(true);
      }
      if (
        person.key === this.idVerificationService.getActivePerson() &&
        person.record &&
        person.record.lastVerification
      ) {
        triggerImageRecoverForId = person.record.lastVerification.key;
      }
      // check
      // reset person if lazy fetch is in progress, to solve issue when changing projects
      if (
        this.idVerificationService.getActivePerson() === person.key &&
        person.record &&
        person.record.lastVerification &&
        person.record.lastVerification.record &&
        person.record.lastVerification.record.status === "verified" &&
        !person.record.lastVerification.record.areImagesFetched
      ) {
        // this.idVerificationService.resetActivePerson();
      }
      counter_all++;
      if (result.verificationSubjectStatus) {
        if (result.verificationSubjectStatus === "COMPLETED") {
          counter_verified++;
        }
        if (result.verificationSubjectStatus === "IN_PROGRESS") {
          counter_pending++;
        }
        if (result.verificationSubjectStatus === "FAILED") {
          counter_failed++;
        }
        if (result.verificationSubjectStatus === "NOT_STARTED") {
          counter_not_started++;
        }
        if (result.verificationSubjectStatus === "ACTION_REQUIRED") {
          counter_action_required++;
        }
      } else {
        counter_not_started++;
      }

      if (!person.key) {
        person.key = this.idVerificationService.refreshIdVerificaitonKey;
      }
      const personParsed = this.generateActiveLegalRepresentativesList(
        result,
        person.key,
      );
      if(result.tags?.length > 0)
      {
        personParsed["tags"] = result.tags;
      }
      group["result_details"].push(personParsed);
      group["form_id"] = person.key;
      // dont show loading bar if at least once
    });

    // loading_person_l is a marker for displaying the load more button
    group['singleRequestMode'] = this.singleRequestMode;

    if (
      counter_all % 15 === 0 &&
      counter_all !== 0 &&
      !this.idVerificationService.getActiveLegalrepresentativesFilters()
        .lastRecord0
    ) {
      group["result_details"].push({
        loading_person_l: true,
        result_container_class:
          "person-list-entry result-container my-0 minified",
      });
      // check if scrolltop of list will trigger load more
      // if it is move it a bit up so it doesn't.
    }
    // group['fixedHeight'] = true;
    if (
      persons.length === 0 &&
      this.idVerificationService.getActiveLegalrepresentativesFilters()
        .firstLoadEmpty
    ) {
      group["group_name"] = "List of persons to be verified";
      group["emptyMessage"] =
        "Add a new person to be verified using the ‘New person’ button above. You can also invite a contributor to the project from the project settings. Contributors will see all verification events in this project.";
    }
    group["show_person_filter"] = true;
    group["show_report_generation"] = this.permissionReportGeneration;
    group["tags"] =
      this.idVerificationService.getActiveLegalRepresentativesTags();
    group["scrollTop"] =
      this.idVerificationService.getActiveLegalrepresentativesFilters().scrollTop;
    group["activeTags"] =
      this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags;
    group["filterObj"] =
      this.idVerificationService.getActiveLegalrepresentativesFilters();
    group["firstLoadEmpty"] =
      this.idVerificationService.getActiveLegalrepresentativesFilters().firstLoadEmpty;
    group["specialDeleteButton"] = this.permissionDeleteSubject;
    group["filter"] = [
      { key: "all_statuses", name: "All statuses", count: counter_all },
      { key: "NOT_STARTED", name: "Not started", count: counter_not_started },
      { key: "COMPLETED", name: "Verified", count: counter_verified },
      { key: "IN_PROGRESS", name: "In progress", count: counter_pending },
      {
        key: "ACTION_REQUIRED",
        name: "Action required",
        count: counter_action_required,
      },
      { key: "FAILED", name: "Failed", count: counter_failed },
    ];
    group["activeFilter"] =
      this.idVerificationService.getActiveLegalrepresentativesFilters().activeFilter;
    this.groups["legalRepresentatives"] = [];
    this.groups["legalRepresentatives"].push(group);
  }

  // Fieldspart is unneccesary right now and can be removed
  generateSingleInvestorContentFields(
    fields,
    investorType,
    fieldspart = 1,
    fieldSource?,
  ) {
    let ret = [];
    if (investorType === "NaturalPerson") {
      if (fieldspart === 1) {
        const showAddress: boolean =
          fields.AddressStreet ||
          fields.AddressNumber ||
          fields.AddressPostalCode ||
          fields.AddressCity ||
          fields.AddressCountry;
        ret = [
          {
            key: "Title",
            content: fields.Title,
            icon:
              fieldSource && fieldSource["Title"] && fieldSource["Title"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["Title"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.FirstName"),
            content: fields.FirstName,
            icon:
              fieldSource &&
              fieldSource["FirstName"] &&
              fieldSource["FirstName"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["FirstName"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.MiddleNames"),
            content: fields.MiddleNames,
            icon:
              fieldSource &&
              fieldSource["MiddleNames"] &&
              fieldSource["MiddleNames"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["MiddleNames"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.LastName"),
            content: fields.LastName,
            icon:
              fieldSource &&
              fieldSource["LastName"] &&
              fieldSource["LastName"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["LastName"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.FormerFirstNames"),
            content: fields.FormerFirstNames,
            icon:
              fieldSource &&
              fieldSource["FormerFirstNames"] &&
              fieldSource["FormerFirstNames"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["FormerFirstNames"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.FormerLastNames"),
            content: fields.FormerLastNames,
            icon:
              fieldSource &&
              fieldSource["FormerLastNames"] &&
              fieldSource["FormerLastNames"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["FormerLastNames"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.Gender"),
            content: fields.Gender,
            icon:
              fieldSource &&
              fieldSource["Gender"] &&
              fieldSource["Gender"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["Gender"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.DateOfBirth"),
            content: fields.DateOfBirth
              ? this.helper.formatISODate(fields.DateOfBirth.toString(),"yyy.MM.dd",false)
              : undefined,
            icon:
              fieldSource &&
              fieldSource["DateOfBirth"] &&
              fieldSource["DateOfBirth"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["DateOfBirth"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.CountryOfBirth"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.CountryOfBirth,
            ),
            icon:
              fieldSource &&
              fieldSource["CountryOfBirth"] &&
              fieldSource["CountryOfBirth"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["CountryOfBirth"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.Nationality"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.Nationality,
            ),
            icon:
              fieldSource &&
              fieldSource["Nationality"] &&
              fieldSource["Nationality"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["Nationality"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.DualNationality"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.DualNationality,
            ),
            icon:
              fieldSource &&
              fieldSource["DualNationality"] &&
              fieldSource["DualNationality"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["DualNationality"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.CountryOfResidence"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.CountryOfResidence,
            ),
            icon:
              fieldSource &&
              fieldSource["CountryOfResidence"] &&
              fieldSource["CountryOfResidence"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["CountryOfResidence"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.EmailAddress"),
            content: fields.EmailAddress,
            icon:
              fieldSource &&
              fieldSource["EmailAddress"] &&
              fieldSource["EmailAddress"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["EmailAddress"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.Occupation"),
            content: fields.Occupation,
            icon:
              fieldSource &&
              fieldSource["Occupation"] &&
              fieldSource["Occupation"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["Occupation"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.TaxIDNumber"),
            content: fields.TaxIDNumber,
            icon:
              fieldSource &&
              fieldSource["TaxIDNumber"] &&
              fieldSource["TaxIDNumber"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["TaxIDNumber"].status,
                  )
                : "",
          },
          {
            key: "Label",
            content: showAddress
              ? this.translate.instant("evidenceFields.Address")
              : undefined,
            title: true,
          },
          {
            key: this.translate.instant("evidenceFields.AddressStreet"),
            content: fields.AddressStreet,
            icon:
              fieldSource &&
              fieldSource["AddressStreet"] &&
              fieldSource["AddressStreet"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["AddressStreet"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.AddressNumber"),
            content: fields.AddressNumber,
            icon:
              fieldSource &&
              fieldSource["AddressNumber"] &&
              fieldSource["AddressNumber"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["AddressNumber"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.AddressPostalCode"),
            content: fields.AddressPostalCode,
            icon:
              fieldSource &&
              fieldSource["AddressPostalCode"] &&
              fieldSource["AddressPostalCode"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["AddressPostalCode"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.AddressCity"),
            content: fields.AddressCity,
            icon:
              fieldSource &&
              fieldSource["AddressCity"] &&
              fieldSource["AddressCity"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["AddressCity"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.AddressCountry"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.AddressCountry,
            ),
            icon:
              fieldSource &&
              fieldSource["AddressCountry"] &&
              fieldSource["AddressCountry"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["AddressCountry"].status,
                  )
                : "",
          },
        ];
      } else if (fieldspart === 2) {
        ret = [];
      }
    } else if (investorType === "LegalPerson") {
      if (fieldspart === 1) {
        const showAddress: boolean =
          fields.RegisteredAddressStreet ||
          fields.RegisteredAddressNumber ||
          fields.RegisteredAddressPostalCode ||
          fields.RegisteredAddressCity ||
          fields.CountryOfRegistration;
        ret = [
          {
            key: this.translate.instant("evidenceFields.RegisteredName"),
            content: fields.RegisteredName,
            icon:
              fieldSource &&
              fieldSource["RegisteredName"] &&
              fieldSource["RegisteredName"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["RegisteredName"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.TradeNames"),
            content: fields.TradeNames,
            icon:
              fieldSource &&
              fieldSource["TradeNames"] &&
              fieldSource["TradeNames"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["TradeNames"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.RegistrationNumber"),
            content: fields.RegistrationNumber,
            icon:
              fieldSource &&
              fieldSource["RegistrationNumber"] &&
              fieldSource["RegistrationNumber"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["RegistrationNumber"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.DateOfRegistration"),
            content: fields.DateOfRegistration
              ? this.helper.formatISODate(fields.DateOfRegistration.toString(),"yyy.MM.dd", false)
              : undefined,
            icon:
              fieldSource &&
              fieldSource["DateOfRegistration"] &&
              fieldSource["DateOfRegistration"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["DateOfRegistration"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.LegalEntityIdentifier"),
            content: fields.LegalEntityIdentifier,
            icon:
              fieldSource &&
              fieldSource["LegalEntityIdentifier"] &&
              fieldSource["LegalEntityIdentifier"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["LegalEntityIdentifier"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.CountryOfRegistration"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.CountryOfRegistration,
            ),
            icon:
              fieldSource &&
              fieldSource["CountryOfRegistration"] &&
              fieldSource["CountryOfRegistration"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["CountryOfRegistration"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.Regulated"),
            content: this.generateSingleInvestorContentFieldsHelper(
              fields.Regulated,
              this.idVerificationService.getYNList(),
            ),
            icon:
              fieldSource &&
              fieldSource["Regulated"] &&
              fieldSource["Regulated"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["Regulated"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.TaxNumber"),
            content: fields.TaxNumber,
            icon:
              fieldSource &&
              fieldSource["TaxNumber"] &&
              fieldSource["TaxNumber"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["TaxNumber"].status,
                  )
                : "",
          },
          {
            key: "Label",
            content: showAddress
              ? this.translate.instant("evidenceFields.RegisteredAddress")
              : undefined,
            title: true,
          },
          {
            key: this.translate.instant("evidenceFields.AddressStreet"),
            content: fields.RegisteredAddressStreet,
            icon:
              fieldSource &&
              fieldSource["RegisteredAddressStreet"] &&
              fieldSource["RegisteredAddressStreet"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["RegisteredAddressStreet"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.AddressNumber"),
            content: fields.RegisteredAddressNumber,
            icon:
              fieldSource &&
              fieldSource["RegisteredAddressNumber"] &&
              fieldSource["RegisteredAddressNumber"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["RegisteredAddressNumber"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.AddressPostalCode"),
            content: fields.RegisteredAddressPostalCode,
            icon:
              fieldSource &&
              fieldSource["RegisteredAddressPostalCode"] &&
              fieldSource["RegisteredAddressPostalCode"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["RegisteredAddressPostalCode"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.AddressCity"),
            content: fields.RegisteredAddressCity,
            icon:
              fieldSource &&
              fieldSource["RegisteredAddressCity"] &&
              fieldSource["RegisteredAddressCity"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["RegisteredAddressCity"].status,
                  )
                : "",
          },
          {
            key: this.translate.instant("evidenceFields.AddressCountry"),
            content: this.idVerificationService.returnNameOfCountryFromValue(
              fields.RegisteredAddressCountry,
            ),
            icon:
              fieldSource &&
              fieldSource["RegisteredAddressCountry"] &&
              fieldSource["RegisteredAddressCountry"].status
                ? this.helper.getIconForProfileVerification(
                    fieldSource["RegisteredAddressCountry"].status,
                  )
                : "",
          },
        ];
      } else if (fieldspart === 2) {
        ret = [];
      }
    } else if (investorType === "idVerification") {
      ret = [
        { key: "First name", content: fields.firstName },
        { key: "Last name", content: fields.lastName },
        { key: "Email", content: fields.email },
        {
          key: "Date of birth",
          content: fields.dateOfBirth
            ? this.helper.formatISODate(fields.dateOfBirth.toString(), "yyy.MM.dd", false,)
            : undefined,
        },
      ];
    }
    return ret;
  }

  generateSingleInvestorContentFieldsHelper(fieldValue, listOfValues) {
    if (fieldValue === true || fieldValue === "true") {
      return "Yes";
    }
    if (fieldValue === false || fieldValue === "false") {
      return "No";
    }
  }
  /**
   * This will be used for the service provider view   */
  parseActiveInvestorsList(
    investors,
    typeFilter = "none",
    isLoading = false,
    relatedPartiesLoading?,
  ) {
    this.groups["legalRepresentatives"] = [];
    let personDetailsPushed = false;
    let automaticRiskAssesment = 
    this.idVerificationService.automaticRiskAssesment();
    this.permissionReportGeneration =
      this.idVerificationService.permissionUpdateReportGeneration();
    // which status filter is active
    let activeStatusFilter;

    if (
      this.idVerificationService.getActiveLegalrepresentativesFilters().status
    ) {
      if (
        this.idVerificationService.getActiveLegalrepresentativesFilters()
          .status !== "NOT_COMPLETED"
      ) {
        // if the status filter is any one but 'Not completed' or 'All statuses' set the filter 'hideCompletedProfilesStatus' on false
        // and set the active status on the cliked one
        activeStatusFilter =
          this.idVerificationService.getActiveLegalrepresentativesFilters()
            .status;
        this.idVerificationService.setHideCompletedProfiles(false);
      } else {
        // if the status filter is 'Not completed' or 'All statuses' set the filter 'hideCompletedProfilesStatus' on the oposite status if filter by status is clicked not filter by profile type
        // and set the active status on the 'All statuses'
        activeStatusFilter = "ALL_STATUSES";
      }
    } else {
      activeStatusFilter = "ALL_STATUSES";
    }
    const group = {
      section_class:
        "results d-flex flex-column justify-items-center investor-group-dashboard",
      is_collapsable: true,
      show_list_filter: true,
      show_verification_filter: true,
      verificationFilter: [],
      verificationStatuses: [],
      activeFilter:
        this.idVerificationService.getActiveLegalrepresentativesFilters().type
          ? this.idVerificationService.getActiveLegalrepresentativesFilters()
              .type
          : "All",
      activeStatusFilter: activeStatusFilter,
      isPartial: false,
      display_orange_dot: false,
      result_details: [],
      tags: this.idVerificationService.getActiveLegalRepresentativesTags(),
      activeTags : this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags,
      emptyInvestorMessage:
        investors.length === 0 &&
        !this.idVerificationService.getActiveLegalrepresentativesFilters()
          .showFilters
          ? "true"
          : undefined,
      emptyFilterMessage:
        investors.length === 0 &&
        this.idVerificationService.getActiveLegalrepresentativesFilters()
          .showFilters
          ? "true"
          : undefined,
      filter: [],
      disableDeleteProjectBtn:
        this.idVerificationService.isMember() ||
        this.idVerificationService.isActiveFilterPresent()
          ? true
          : false,
      disabledDeleteProjectBtnTooltip: this.idVerificationService.isMember()
        ? "Only project owners can delete projects"
        : this.idVerificationService.isActiveFilterPresent()
        ? "You currently have a filter enabled which is preventing you from deleting the project."
        : "",
      statusFilter: [],
      RRFilterByDate: [],
      riskRatings: this.idVerificationService.getRiskRatingsForProject(),
      group_sorter: true,
      titleOnly: investors.length === 0, // show only the title of the group sorter because the BE is still building it
      show_report_generation: this.permissionReportGeneration,
      show_hide_completed_profiles_filter: {
        // we should show the filter only when "all statuses" is cliked
        show_filter: activeStatusFilter === "ALL_STATUSES",
        filter_status: this.idVerificationService.getHideCompletedProfiles(),
      },
      filterObj:
        this.idVerificationService.getActiveLegalrepresentativesFilters(),
      sorterTitle: "List of profiles",
      fixedHeight: true,
      isSP: true,
      singleRequestMode: false,
    };
    let numNaturalPerson = 0;
    let numLegalPerson = 0;
    let numAutomaticRequest = 0;
    const numOther = 0;
    let shareData;

  
    // adding css classes to previous and next container
    const resultContainer = $(
      '.result-container[key="' +
        this.idVerificationService.getActiveInvestor() +
        '"]',
    );
    // prev and next containers only serve to add a css class
    const prevKey = resultContainer.prev().attr("key");
    const nextKey = resultContainer.next().attr("key");
    let prevExpanded = false;
    let nextExpanded = false;

    // determine general status
    investors.forEach((investor) => {
      const result = investor.record;
      let isAR = false;
      const riskRating = investor.record?.riskReview;
      const generalStatus =
        this.idVerificationService.buildVerificationStatusObject(
          result.verificationSubjectStatus,
        );

      // we will merge the evidence types and digital verifications
      let evidenceTypes = [];
      if (result["evidenceTypes"]) {
        evidenceTypes =
          this.idVerificationService.concatEvidenceTypesWithDigitalVerifications(
            result["evidenceTypes"],
            result["digitalVerifications"],
          );
      }

      const key = investor.key;
      const isClaimed = result.claimedOn;
      let title = "";
      let typeOfInvestorIcon = "/assets/images/icon-automatic-small.svg";
      if (result.typeOfInvestor === "LegalPerson") {
        numLegalPerson++;
      } else if (result.typeOfInvestor === "NaturalPerson") {
        numNaturalPerson++;
      } else if (result.typeOfInvestor === "AutomaticRequest") {
        numAutomaticRequest++;
      }
      if (typeFilter !== "none" && typeFilter !== result.typeOfInvestor) {
      } else {
        let isNew = true;

        if (key === this.idVerificationService.getActiveInvestor()) {
          isNew = false; // this means it's active investor
        } else {
          isNew = true;
        }
        if (key === prevKey && !isLoading) {
          prevExpanded = true;
        }
        if (key === nextKey && !isLoading) {
          nextExpanded = true;
        }
        //
        if (result.typeOfInvestor === "LegalPerson") {
          title = result.investorFields.RegisteredName;
          typeOfInvestorIcon = "/assets/images/ico-legal-small.svg";
        }
        if (result.typeOfInvestor === "NaturalPerson") {
          title =
            result.investorFields.FirstName +
            " " +
            result.investorFields.LastName;
          typeOfInvestorIcon = "/assets/images/ico-natural-small.svg";
        }
        if (result.isAutomaticRequest && !result.convertedOn) {
          isAR = true;
          title = result.investorFields.EmailAddress;
          if (!title) {
            title = result.investorFields.RegisteredName;
          }
        }

        if (
          this.projectsType === "funds" &&
          key === this.idVerificationService.getActiveInvestor() &&
          !isNew &&
          !isLoading &&
          this.idVerificationService.getActiveInvestorShareData() &&
          this.idVerificationService.getActiveInvestorShareData().shareEntities
            .length >= 1
        ) {
          this.idVerificationService
            .getActiveInvestorShareData()
            .shareEntities.sort((a, b) =>
              a.sharedOn > b.sharedOn ? 1 : a.sharedOn === b.sharedOn ? 1 : -1,
            );

          shareData =
            this.idVerificationService.getActiveInvestorShareData()
              .shareEntities[0];
        }

        if (!isNew && !isLoading && !result.activeTabMainProfile) {
          // setting up the default selected tab is one does not exist
          result.activeTabMainProfile = "Profile details";
          if (isAR) {
            result.activeTabMainProfile = "Share link";
          }
        }
        if (result.activeTabMainProfile) {
        }
        const personParsed = {
          key: key,
          result_title: title,
          typeOfInvestorIcon: typeOfInvestorIcon,
          result_container_class:
            "person-list-entry result-container my-0 minified investor-list-entry" +
            (isNew
              ? ""
              : " expanded" +
                (isLoading && !isNew ? " currentlyLoading" : "")) +
            (nextExpanded
              ? " nextExpanded"
              : prevExpanded
              ? " prevExpanded"
              : ""),

          isNew: isNew && !isLoading,
          isClaimed: isClaimed,
          onArrowClickMethod: isNew ? "onExpandActiveBox" : null,
          result_title_classes: "mt-0",
          hideActions: false,
          isInvestorLoading: isLoading && !isNew,
          investorStatus: result.status,
          group_result_title:
            "pi_result_" + key.split(":").pop() + new Date().getTime(),
          additional_info: generalStatus.content,
          statusTooltip: generalStatus.toolTip,
          additional_info_classes: generalStatus.class,
          elements:
            isNew || isLoading
              ? <any>[]
              : <any>[
                  {
                    type: "tabbed_content",
                    class:
                      result.activeTabMainProfile === "Profile details"
                        ? "half-width-wrapper mt-3"
                        : "half-width-wrapper mt-3 d-none",
                    params: {
                      sectionTitle: "Summary",
                      sectionTitleClass: "title-t2",
                      showScreeningElementsResults: true,
                      investorStatus: result.status,
                      tabKey: key + "_details",
                      tabGroup: key,
                      tabs: [
                        {
                          id: "entity_type",
                          status: "active",
                          content: !isNew
                            ? this.generateSingleInvestorContentFields(
                                result.investorFields,
                                result.typeOfInvestor,
                                1,
                                result.fieldsSource,
                              )
                            : "",
                        },
                      ],
                      profileVerificationStatus: result?.fieldsSourceStatus
                        ? this.helper.basicFieldsPreviewHeading(
                            result.fieldsSourceStatus,
                            result.fieldsSourceValidationDate,
                          )
                        : "",
                    },
                  },
                ],
        };
        // items to show during the loading status
        // the user click and the basic fields will be shown if it's not AR, becasuse AR doesn't have basic fields
        // meanwhile the rest of the data will get loaded in the backround


        if(result.activeTabMainProfile === 'Tags') {
          result.activeTabMainProfile = 'Profile details';
        }

        if (!isNew && !isAR && isLoading) {
          personDetailsPushed = true;
          personParsed["elements"].push(
            {
              type: "dashboardTabs",
              tabGroup: key,
              class: "tab-group-disabled",
              tabs: [
                {
                  name: "Profile details",
                  activates: key + "_details",
                  "/assets/images/icon-triangle-orange.svg": "",
                  active: true,
                },
                {
                  name: "Evidence",
                  activates: key + "_evidence",
                  active: false,
                },
              ],
            },
            {
              type: "tabbed_content",
              class: "half-width-wrapper mt-3",
              params: {
                sectionTitle: this.translate.instant("appSpecific.Summary"),
                sectionTitleClass: "title-t2",
                showScreeningElementsResults: true,
                tabKey: key + "_details",
                tabGroup: key,
                tabs: [
                  {
                    id: "entity_type",
                    status: "active",
                    content: this.generateSingleInvestorContentFields(
                      result.investorFields,
                      result.typeOfInvestor,
                      1,
                      result.fieldsSource,
                    ),
                  },
                ],
              },
            },
            {
              type: "relatedPartiesList",
              relatedPartiesLoading: true,
            },
          );
        }

        if (result.riskReview) {
          personParsed["risk_rating"] = result.riskReview;
        }
        if (result.tags) {
          personParsed["tags"] = result.tags;
        }

        if (!isNew && !isLoading) {
          // setting up the default selected tab is one does not exist
          personParsed["elements"].unshift({
            type: "documentUploadInner",
            typeOfInvestor: result.typeOfInvestor,
            evidenceTypes: evidenceTypes,
            requestKey: key,
            dontRender: !isNew,
            investorStatus: result.status,
            class: result.activeTabMainProfile === "Evidence" ? "" : "d-none",
          });
          // tabs on the dashboard
          let isBasicFieldsExpired = false;
          if (!isAR) {
            // check if basic field evidence are expired
            if (!isNew) {
              if (
                result.basicFieldsEvidence?.status.toLocaleLowerCase() ===
                "expired"
              ) {
                isBasicFieldsExpired = true;
              }
            }
            const evidenceInProgress =
              this.helper.isEvidenceActionRequiredOrInProgress(
                result.digitalVerifications,
              ) ||
              this.helper.isEvidenceActionRequiredOrInProgress(
                result.evidenceTypes,
              );
            personDetailsPushed = true;
            personParsed["elements"].unshift({
              type: "dashboardTabs",
              tabGroup: key,
              class: riskRating ? "mt-3" : "",
              tabs: [
                {
                  name: "Profile details",
                  activates: key + "_details",
                  iconURL: isBasicFieldsExpired
                    ? "/assets/images/icon-triangle-orange.svg"
                    : this.helper.isEvidenceActionRequiredOrInProgress([
                        result.basicFieldsEvidence,
                      ])
                    ? "assets/images/icon-information-prepared-teal.svg"
                    : result.basicFieldsEvidence?.status?.toLocaleLowerCase() ===
                      "completed"
                    ? "assets/images/icon-checkmark-prepared-green.svg"
                    : "",
                  tooltip: this.helper.isEvidenceActionRequiredOrInProgress([
                    result.basicFieldsEvidence,
                  ])
                    ? this.translate.instant(
                        "appSpecific.profileDetailsTooltip",
                      )
                    : undefined,
                  active: result.activeTabMainProfile === "Profile details",
                  profileVerificationIcon: result?.fieldsSourceStatus
                    ? this.helper.getIconForProfileVerification(
                        result.fieldsSourceStatus,
                      )
                    : "",
                },
                {
                  name: "Evidence",
                  activates: key + "_evidence",
                  active: result.activeTabMainProfile === "Evidence",
                  iconURL: evidenceInProgress
                    ? "assets/images/icon-information-prepared-teal.svg"
                    : "",
                  tooltip: evidenceInProgress
                    ? this.helper.generateTooltipText(
                        "appSpecific.evidenceTooltip",
                        evidenceTypes,
                      )
                    : undefined,
                },
              ],
            });
          }

          if (isAR) {
            let templateName = "";
            this.templates?.forEach((element) => {
              if (element.key === investor.record.templateKey) {
                templateName = element.record.templateName;
              }
            });
            personParsed.elements.push({
              type: "title",
              class: "ml-3 mb-1 " + (templateName ? " " : "d-none"),
              tabGroup: key,
              tabKey: key + "_url",
              params: {
                titleClass: "mb-2" + (!result.shareLink ? " mt-3" : ""),
                display: true,
                content: "KYC Template used: " + templateName,
              },
            });
          }

          // Add the share link tab if there is share link or  ifthere is info for the shared link
          if (
            (this.projectsType === "funds" &&
              key === this.idVerificationService.getActiveInvestor() &&
              !isNew &&
              !isLoading &&
              this.idVerificationService.getActiveInvestorShareData() &&
              this.idVerificationService.getActiveInvestorShareData()
                .shareEntities.length >= 1) ||
            result.shareLink
          ) {
            if (isAR) {
              personParsed["elements"].unshift({
                type: "dashboardTabs",
                class: riskRating ? "mt-3" : "",
                tabGroup: key,
                tabs: [],
              });
            }
            personParsed["elements"][0]["tabs"].push({
              name: "Share link",
              activates: key + "_url",
              active: result.activeTabMainProfile === "Share link",
            });
            if (!personDetailsPushed) {
              personParsed["elements"][0]["tabs"].push({
                name: "Profile details",
                activates: key + "_details",
                iconURL: isBasicFieldsExpired
                  ? "/assets/images/icon-triangle-orange.svg"
                  : result?.basicFieldsEvidence?.status?.toLocaleLowerCase() ===
                    "completed"
                  ? "assets/images/icon-checkmark-prepared-green.svg"
                  : "",
                active: result.activeTabMainProfile === "Profile details",
              });
            }
          }

          // if there's a share link add the sharelink tab
          if (result.shareLink) {
            personParsed.elements.push(
              {
                type: "title",
                class:
                  "ml-3 mb-1 " +
                  (result.activeTabMainProfile === "Share link"
                    ? " "
                    : "d-none"),
                tabGroup: key,
                tabKey: key + "_url",
                params: {
                  titleClass: "mb-2",
                  display: true,
                  content: "Share link",
                },
              },
              {
                type: "inputTextWithButton",
                class:
                  "mt-3 text-with-button-dash short-overlay " +
                  (result.activeTabMainProfile === "Share link"
                    ? " "
                    : "d-none"),
                tabGroup: key,
                tabKey: key + "_url",
                params: {
                  id: "generatedVerificationLink",
                  content: result.shareLink,
                  classes: "generated-link generated-link-fund-share",
                  innerLabel: "Copy link",
                  readonly: true,
                  button: {
                    display: true,
                    content: "Copy",
                    class: "ml-2 mr-0 form-button-1",
                    action: "CopyLinkToClipboard2",
                  },
                },
              },
              {
                type: "inputTextWithButton",
                class:
                  "mt-3 text-with-button-dash short-overlay " +
                  (result.activeTabMainProfile === "Share link"
                    ? " "
                    : "d-none"),
                tabGroup: key,
                tabKey: key + "_url",
                params: {
                  id: "sendVerificationLink",
                  content: shareData ? shareData.sharedWith : "",
                  innerLabel: "Send email",
                  classes: "generated-link generated-link-fund-share",
                  errorMessage: this.translate.instant(
                    "validation.validation5",
                  ),
                  placeholder: "Type in an email address",
                  readonly: true,
                  requestKey: this.idVerificationService.getActiveInvestor(),
                  button: {
                    display: true,
                    content: "Send",
                    class: "ml-2 mr-0 form-button-1",
                    action: "sendVerificationLink",
                  },
                },
              },
            );
          }
          if (
            this.projectsType === "funds" &&
            key === this.idVerificationService.getActiveInvestor() &&
            !isNew &&
            !isLoading &&
            this.idVerificationService.getActiveInvestorShareData() &&
            this.idVerificationService.getActiveInvestorShareData()
              .shareEntities.length >= 1
          ) {
            this.idVerificationService
              .getActiveInvestorShareData()
              .shareEntities.sort((a, b) =>
                a.sharedOn > b.sharedOn
                  ? 1
                  : a.sharedOn === b.sharedOn
                  ? 1
                  : -1,
              );

            shareData =
              this.idVerificationService.getActiveInvestorShareData()
                .shareEntities[0];
            personParsed.elements.push({
              type: "documentUploadInnerStatus",
              class:
                result.activeTabMainProfile === "Share link" ? " " : "d-none",
              tabGroup: key,
              tabKey: key + "_url",
              icon:
                result.status === "REJECTED"
                  ? "assets/images/icon-triangle-orange.svg"
                  : "assets/images/icon-information-prepared-teal.svg",
              content: shareData.claimedOn
                ? "The data was claimed by " +
                  shareData.sharedWith +
                  " on " +
                  this.helper.formatISODate(
                    shareData.sharedOn,
                    "dd.MM.yyyy hh:mm",
                  ) +
                  " (GMT)"
                : (result.status === "REJECTED"
                    ? "The request was rejected by "
                    : "The share link was generated for ") +
                  shareData.sharedWith +
                  " on " +
                  this.helper.formatISODate(
                    shareData.sharedOn,
                    "dd.MM.yyyy hh:mm",
                  ) +
                  " (GMT)",
            });
          }

          // ADD IN DASHBOARD TAGS SECTION

          personParsed.elements[0].tabs.push({
            name: "Tags",
            activates: key + "_tags",
          });

          personParsed.elements.push({
            type: "person_tags",
            tabGroup: key,
            tabKey: key + "_tags",
            class: "dashboard-tags px-3 d-none mb-4",
            params: {
              tags: result.tags,
              showAddButton: true,
              showTitle: true,
              showDiagnostics: false,
              allowDelete: true,
              investor: true,
              active: result.activeTabMainProfile === "Tags" 
            },
          });

          if (isBasicFieldsExpired) {
            personParsed.elements.push({
              type: "innerNotification",
              class: "ml-3 mb-1 d-none notification info",
              tabGroup: key,
              tabKey: key + "_details",
              params: {
                titleClass: "mb-2",
                display: true,
                content: this.translate.instant(
                  "serviceprovider.basicFieldWarning",
                ),
              },
            });
          }
          if (
            result.digitalVerifications &&
            result.digitalVerifications.length
          ) {
            personParsed["elements"][0]["tabs"]?.splice(2, 0, {
              name: "Digital verifications",
              activates: key + "_checks",
              active: result.activeTabMainProfile === "Digital verifications",
            });
            personParsed["elements"].push(
              // VERIFICATION STATUSES IS THE ELEMENT THAT DISPLAYS ALL THE VERIFICATION STATUSES ON THE DASHBOARD
              {
                type: "verification_statuses",
                tabGroup: key,
                tabKey: key + "_checks",
                class:
                  result.activeTabMainProfile === "Digital verifications"
                    ? "px-3 mt-0"
                    : "px-3 mt-0 d-none",
                params: {
                  funds: true,
                  vtype: "funds",
                  statuses: this.idVerificationService.parseFundDVStatuses(
                    result.digitalVerifications,
                  ),
                  requestKey: key,
                  evidenceTypes: evidenceTypes,
                },
              },
            );
          }
          const checkNotSharedEvidences: any =
            this.CheckNotSharedEvidences(result);
          if (!!checkNotSharedEvidences) {
            personParsed["elements"].unshift({
              type: "spNotificationComponent",
              evidenceTypes: checkNotSharedEvidences.evidenceTypes,
              evidence: checkNotSharedEvidences.evidence,
              requestKey: key,
            });
          }
        }
        if (!isNew && !isLoading && result.investorFields && !isAR) {
          personParsed["elements"].push({
            type: "update_button",
            action: "updateBasicInformation",
            evidenceTypes: evidenceTypes,
            evidence: result.basicFieldsEvidence,
            requestKey: key,
            basicFeildsevidenceKey: result.basicFieldsEvidence.key,
            content: isClaimed ? "View details" : "Edit",
            updateFlow: !isClaimed,
            previewFlow: isClaimed,
            class:
              result.activeTabMainProfile === "Profile details" ? "" : "d-none",
            params: {
              action: "updateBasicInformation",
            },
          });
        }
        // Adding Risk rating
        if (
          this.projectsType === "funds" &&
          key === this.idVerificationService.getActiveInvestor() &&
          !isNew &&
          !isLoading &&
          riskRating?.nextReviewOn && !riskRating.riskReviewKey
        ) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const RRScheduled = riskRating.nextReviewOn;
          const RRScheduledDate = new Date(RRScheduled);
          const overdue = today > RRScheduledDate;

          personParsed["elements"].unshift({
            type: "documentUploadInnerStatus",
            class: overdue ? "overdue" : "scheduled",
            icon: overdue
              ? "assets/images/outline-exclamation-icon.svg"
              : "assets/images/risk-rating-icon.svg",
            content: overdue
              ? "Risk assessment overdue!"
              : "Risk assessment scheduled for " +
                this.helper.formatISODate(RRScheduled, "dd.MM.yyyy"),
          });
        }

        if (
          this.projectsType === "funds" &&
          key === this.idVerificationService.getActiveInvestor() &&
          !isNew &&
          !isLoading &&
          investor.record?.riskReview?.riskReviewKey
        ) {
          let overdue = false;
          let comingUp = false;
          let nextReviewOn = investor.record.riskReview.nextReviewOn;
          if(nextReviewOn)
          {
            comingUp = true;
            if(this.helper.compareDateToTodays(nextReviewOn) == -1)
              {
                overdue = true;
                comingUp = false;
              }
          }
          let inReview = investor.record.riskReview.reviewRequired; //  investor.record.reviewFinalizeRequired 
          personParsed["elements"].unshift({
            type: "documentUploadInnerStatus",
            class: "risk-assessment " + (overdue ? "overdue" : inReview ? "in-review" :  comingUp ? "coming-up" : "in-progress"),
            icon: overdue
              ? "assets/images/outline-exclamation-icon.svg" 
              : inReview 
              ? "assets/images/risk-assessment-in-progress.svg"
              : comingUp
              ? "assets/images/risk-rating-icon.svg"
              :  "assets/images/risk-assessment-in-progress.svg",
            content: overdue 
            ? "Risk assessment overdue!" 
            : inReview 
            ? "Risk assessment ready for completion" 
            :  comingUp 
            ? "Risk assessment coming up on " + this.helper.formatISODate(nextReviewOn, "dd.MM.yyyy")
            : "Risk assessment in progress",
            buttons: [{
              displayBtn: true,
              btnClass: "form-button form-button-link form-button-normal-14px p-0",
              label: overdue
              ? "Start assessment" 
              : inReview 
              ? "Complete assessment" 
              :  comingUp 
              ? "View assessment" 
              : "Continue",
              action: "onRiskRatingPreview",
            }, 
            {
              displayBtn: comingUp,
              btnClass: "form-button form-button-link form-button-normal-14px p-0",
              label: "See dashboard",
              action: "goToRRDashboard",
            }]
          });
        }

        // share entities
        if (
          this.projectsType === "funds" &&
          key === this.idVerificationService.getActiveInvestor() &&
          !isNew &&
          !isLoading
        ) {
          personParsed["actionsMenu"] = [
            {
              name: "Share the request",
              method: isAR
                ? "documentShareFunctionAR"
                : "documentShareFunction",
              src: "/assets/images/action-share.svg",
            },
          ];
          // add aps verification
          // different methods for legal, different for natural person
          // first need to check if there is an existing digital verificaiton
          // the compliance check can't continue if there is one AND it is in ACTION REQUIRED state
          let apsButtonClass = "";
          if (result.digitalVerifications) {
            result.digitalVerifications.forEach((dv) => {
              if (
                dv.verificationType === "APS" &&
                dv.status === "Action required"
              ) {
                apsButtonClass = "disabled";
              }
            });
          }
          // if it is AR don't add those actions
          if (
            !(
              !isNew &&
              result.isAutomaticRequest &&
              (result.typeOfInvestor === "AutomaticRequest" ||
                (!result.convertedOn &&
                  (!result.evidenceTypes || !result.evidenceTypes.length) &&
                  (!result.digitalVerifications ||
                    !result.digitalVerifications.length) &&
                  result.verificationSubjectStatus === "IN_PROGRESS"))
            )
          ) {
            personParsed["actionsMenu"].push(
              {
                name:
                  result.typeOfInvestor === "NaturalPerson"
                    ? "Add documents or verifications"
                    : "Add documents",
                method: "documentUploadScreen",
                src: "/assets/images/add-action.svg",
                imageClass: "action-plus",
              },
              {
                name: "Create a related profile",
                method: "createRelatedParty",
                src: "/assets/images/action-add-person.svg",
                imageClass: "action-plus",
              },
              {
                name: "Request screening",
                method:
                  result.typeOfInvestor === "NaturalPerson"
                    ? "generateApsLightbox"
                    : "generateApsLightboxLegal",
                src: "/assets/images/screening-action.svg",
                class: apsButtonClass,
                tooltip: apsButtonClass.includes("disabled")
                  ? "A new compliance screening can only be performed when a decision has been taken on the currently active one."
                  : "",
              },
              {
                name: "Verify profile details",
                method:
                  result.typeOfInvestor === "NaturalPerson"
                    ? "startProfileVerification"
                    : "",
                src: "/assets/images/tasks-grey.svg",
                class: this.idVerificationService.permissionProfileVerification() && this.helper.isVerifyProfileDetailsEnabled(result)
                  ? ""
                  : "disabled",
                tooltip: this.idVerificationService.getPVTooltip(result),
              },
              {
                name: "Download files",
                method: "onDownloadCDDImages",
                src: "/assets/images/ico-templates-multiple.svg",
                imageClass: "action-plus",
              },
            );
            // if we have a risk rating permission add the risk rating button
            // if(this.idVerificationService.permissionRiskRating()) {
            //   personParsed["actionsMenu"].push(
            //     {
            //       name: 'Risk assessment',
            //       method: "onRiskRatingPreview",
            //       src: "/assets/images/flag.svg",
            //       imageClass: "action-plus",
            //     },
            //   );
            // }

          }
          if(!automaticRiskAssesment) {
            personParsed["actionsMenu"].push(
              {
                name: riskRating ? "Edit risk rating" : "Assign a risk rating",
                method: "assignProfileRiskRating",
                src: "/assets/images/flag.svg",
              },
            );
          }
          // after a recent change the delete profile button should always be displayed
          personParsed["actionsMenu"].push({
            name: "Delete profile",
            method: "onDeleteProfile",
            src:
              this.idVerificationService.isMember() && result.sharedOn
                ? "/assets/images/delete-document.svg"
                : "/assets/images/delete-document-red.svg",
            class:
              "alternate " +
              (this.idVerificationService.isMember() && result.sharedOn
                ? "disabled "
                : ""),
            tooltip:
              this.idVerificationService.isMember() && result.sharedOn
                ? "You need to be a project owner to take this action"
                : "",
          });
        }
        if(!isNew && !isLoading)
          {
            personParsed.elements.push(
              {
                type: "diagnostics_button",
                class: "mt-0",
              }
            )
          }
        if (!isNew && !isLoading && !isAR) {
          const activeRelatedParty =
            this.idVerificationService.getActiveInvestorDetaisl()[
              "activeRelatedParty"
            ]
              ? this.idVerificationService.getActiveInvestorDetaisl()[
                  "activeRelatedParty"
                ]
              : undefined;
          // related party digital verificaiton group
          const activeRelatedPartyDV =
            this.idVerificationService.getActiveInvestorDetaisl()[
              "activeRelatedParty"
            ]
              ? this.idVerificationService.getActiveInvestorDetaisl()[
                  "activeRelatedParty"
                ]["record"]["digitalVerifications"]
              : undefined;
          const activeRelatedPartyKey = activeRelatedParty
            ? activeRelatedParty["key"]
            : "";
          let dvStatuses;
          let tabs;
          let isRPBasicFieldsExpired = false;
          if (
            activeRelatedParty &&
            activeRelatedParty[
              "record"
            ]?.basicFieldsEvidence?.status.toLocaleLowerCase() === "expired"
          ) {
            isRPBasicFieldsExpired = true;
          }
          const tooltip: boolean =
            activeRelatedParty &&
            ((activeRelatedParty["record"]?.digitalVerifications &&
              this.helper.isEvidenceActionRequiredOrInProgress(
                activeRelatedParty["record"].digitalVerifications,
              )) ||
              (activeRelatedParty["record"]?.evidenceTypes &&
                this.helper.isEvidenceActionRequiredOrInProgress(
                  activeRelatedParty["record"].evidenceTypes,
                )));

          tabs = [
            {
              name: "Profile details",
              activates: activeRelatedParty
                ? activeRelatedParty["key"] + "_details"
                : "",
              iconURL: isRPBasicFieldsExpired
                ? "/assets/images/icon-triangle-orange.svg"
                : activeRelatedParty &&
                  activeRelatedParty["record"]?.basicFieldsEvidence &&
                  this.helper.isEvidenceActionRequiredOrInProgress([
                    activeRelatedParty["record"].basicFieldsEvidence,
                  ])
                ? "assets/images/icon-information-prepared-teal.svg"
                : activeRelatedParty &&
                  activeRelatedParty[
                    "record"
                  ]?.basicFieldsEvidence?.status.toLocaleLowerCase() ===
                    "completed"
                ? "assets/images/icon-checkmark-prepared-green.svg"
                : "",
              tooltip:
                activeRelatedParty &&
                activeRelatedParty["record"]?.basicFieldsEvidence &&
                this.helper.isEvidenceActionRequiredOrInProgress([
                  activeRelatedParty["record"].basicFieldsEvidence,
                ])
                  ? this.translate.instant("appSpecific.profileDetailsTooltip")
                  : undefined,
              active: true,
              profileVerificationIcon:
                activeRelatedParty &&
                activeRelatedParty["record"]?.fieldsSourceStatus
                  ? this.helper.getIconForProfileVerification(
                      activeRelatedParty["record"].fieldsSourceStatus,
                    )
                  : "",
            },
            {
              name: "Evidence",
              activates: activeRelatedParty
                ? activeRelatedParty["key"] + "_evidence"
                : "",
              iconURL: tooltip
                ? "assets/images/icon-information-prepared-teal.svg"
                : "",
              tooltip: tooltip
                ? this.helper.generateTooltipText(
                    "appSpecific.evidenceTooltip",
                    activeRelatedParty["record"].evidenceTypes,
                  )
                : undefined,
            },
          ];
          let apsButtonClassrp = "";
          if (activeRelatedPartyDV && activeRelatedPartyDV.length > 0) {
            // the compliance check can't continue if there is one AND it is in ACTION REQUIRED state
            activeRelatedPartyDV.forEach((dv) => {
              if (
                dv.verificationType === "APS" &&
                dv.status === "Action required"
              ) {
                apsButtonClassrp = "disabled";
              }
            });
            // if there are digital verifications parse the statuses
            dvStatuses =
              this.idVerificationService.parseFundDVStatuses(
                activeRelatedPartyDV,
              );
            // add the status to the RP group
            tabs.splice(2, 0, {
              name: "Digital verifications",
              activates: activeRelatedPartyKey + "_checks",
            });
          }
          this.groups["legalRepresentatives"] = [];

          personParsed.elements.push({
            type: "relatedPartiesList",
            requestKey: key,
            relatedParties: result.relatedParties,
            count: result.relatedParties ? result.relatedParties.length : 0,
            activeRelatedPartyKey: activeRelatedPartyKey,
            activeRelatedPartyEvidenceTypes: activeRelatedParty
              ? activeRelatedParty["record"]["evidenceTypes"]
              : "",
            activeRelatedParty: activeRelatedParty
              ? activeRelatedParty["record"]
              : "",
            activeRelatedPartyFields: activeRelatedParty
              ? this.generateSingleInvestorContentFields(
                  activeRelatedParty["record"]["investorFields"],
                  activeRelatedParty["record"]["typeOfInvestor"],
                  1,
                  activeRelatedParty["record"]["fieldsSource"],
                )
              : undefined,
            isEmpty:
              (!result.relatedParties || result.relatedParties.length === 0) &&
              !relatedPartiesLoading,
            relatedPartiesLoading: relatedPartiesLoading,
            investorStatus: result.status,
            showNewRelatedPartyButton: true,
            basicFieldEvidence: activeRelatedParty
              ? activeRelatedParty["record"]["basicFieldsEvidence"]
              : "",
            basicFieldEvidenceKey:
              activeRelatedParty &&
              activeRelatedParty["record"]["basicFieldsEvidence"]
                ? activeRelatedParty["record"]["basicFieldsEvidence"]["key"]
                : "",
            claimed: activeRelatedParty
              ? activeRelatedParty["record"]["claimedOn"]
              : false,
            sharedOn: activeRelatedParty
              ? activeRelatedParty["record"]["sharedOn"]
              : false,
            tabGroup: activeRelatedParty ? activeRelatedParty["key"] : "",
            activeRelatedPartyDV: activeRelatedPartyDV,
            dvStatuses: dvStatuses,
            vtype: "funds",
            tabs: tabs,
            actionsMenu: true,
            showDeleteAction: true,
            deleteBtnClass:
              activeRelatedParty && activeRelatedParty["record"]["sharedOn"]
                ? "disabled"
                : "",
            deleteRPTooltip:
              activeRelatedParty && activeRelatedParty["record"]["sharedOn"]
                ? ""
                : "",
            showRetriggerAction: true,
            retriggerActionClass: apsButtonClassrp,
            showCreateNewAction: true,
            tooltip: apsButtonClassrp.includes("disabled")
              ? "A new compliance screening can only be performed when a decision has been taken on the currently active one."
              : "",
            isInvestor: false,
            isRPBasicFieldsExpired: isRPBasicFieldsExpired,
          });
          if (
            !isAR &&
            this.projectsType === "funds" &&
            key === this.idVerificationService.getActiveInvestor() &&
            !isNew &&
            !isLoading
          ) {
            personParsed["elements"].unshift({
              type: "profilesStatusInformation",
              recordOfInvestor: result,
            });
          }
        }
        group["result_details"].push(personParsed);
        if (
          this.idVerificationService.isOwner() &&
          !this.idVerificationService.isActiveFilterPresent() &&
          group["result_details"] &&
          group["result_details"].length > 0
        ) {
          group["disableDeleteProjectBtn"] = true;
          group["disabledDeleteProjectBtnTooltip"] =
            "You may only delete projects that do not contain any profiles. Please delete any existing profiles before deleting the project.";
        }
      }
    });
    if (
      numLegalPerson === 0 &&
      numNaturalPerson === 0 &&
      numAutomaticRequest === 0 &&
      !this.idVerificationService.getActiveLegalrepresentativesFilters()
        .showFilters
    ) {
      group.show_list_filter = false;
      group.show_verification_filter = false;
    } else {
      group["filter"].push(
        {
          key: "All",
          name: "View all types",
          count: numNaturalPerson + numLegalPerson + numAutomaticRequest,
        },
        {
          key: "NaturalPerson",
          name: "Natural person",
          count: numNaturalPerson,
          image: "assets/images/ico-natural-small.svg",
        },
        {
          key: "LegalPerson",
          name: "Legal person",
          count: numLegalPerson,
          image: "assets/images/ico-legal-person-gray.svg",
        },
        {
          key: "AutomaticRequest",
          name: "Automatic request",
          count: numAutomaticRequest,
          image: "assets/images/icon-automatic-small.svg",
        },
      );
      group["statusFilter"].push(
        { key: "ALL_STATUSES", name: "All statuses" },
        { key: "NOT_STARTED", name: "Not started" },
        { key: "NOT_REQUESTED", name: "Not requested" },
        { key: "IN_PROGRESS", name: "In progress" },
        { key: "ACTION_REQUIRED", name: "Action required" },
        { key: "COMPLETED", name: "Completed" },
        { key: "FAILED", name: "Failed" },
        { key: "EXPIRED", name: "Expired" },
        { key: "REJECTED", name: "Rejected" },
      );
      group["RRFilterByDate"].push(
        { key: "0", name: "Not applicable" },
        { key: "3", name: "next 3 months" },
        { key: "6", name: "next 6 months" },
        { key: "12", name: "next 12 months" },
        { key: "custom", name: "Custom dates" },
      );
      group["verificationFilter"].push(
        { key: "Address", name: "Address verification" },
        { key: "APS", name: "Compliance check" },
        {
          key: "Contract",
          name: "Smart form",
        },
      );
      if (this.idVerificationService.permissionUpdateIDVerificationOnboard()) {
        group["verificationFilter"].push({
          key: "Identity",
          name: "Identity verification",
        });
      }
      if (this.idVerificationService.permissionJumioGo()) {
        group["verificationFilter"].push({
          key: "IdentityInstantAI",
          name: "Identity verification (AI)",
        });
      }
      group["verificationStatuses"].push(
        { key: "NOT_STARTED", name: "Not started" },
        { key: "NOT_REQUESTED", name: "Not requested" },
        { key: "IN_PROGRESS", name: "In progress" },
        { key: "ACTION_REQUIRED", name: "Action required" },
        { key: "COMPLETED", name: "Completed" },
        { key: "FAILED", name: "Failed" },
      );
    }

    group.singleRequestMode = this.singleRequestMode;
    // loading_person_l is a marker for displaying the load more button
    const counter_all = numLegalPerson + numNaturalPerson + numAutomaticRequest;
    // if (counter_all % 15 === 0 && counter_all !== 0 && !this.idVerificationService.getActiveLegalrepresentativesFilters().lastRecord0) {
    if (
      (counter_all % 10 === 0 &&
      counter_all !== 0 &&
      !this.idVerificationService.getActiveLegalrepresentativesFilters()
        .lastRecord0) || this.idVerificationService.getactiveRecordsCount()!= 0
    ) {
      group["result_details"].push({
        loading_person_l_investor: true,
        result_container_class:
          "person-list-entry result-container my-0 minified",
      });
      // check if scrolltop of list will trigger load more
      // if it is move it a bit up so it doesn't.
    }
    this.groups["legalRepresentatives"] = [];
    group["CDDDashboard"] = true;
    this.groups["legalRepresentatives"].push(group);
  }

  /**
   * method that will check if all evidences are shared
   * if not, allow for notification to be displayed
   */
  CheckNotSharedEvidences(result) {
    let ret: boolean | Object = false;
    if (result.evidenceTypes && ret === false) {
      result.evidenceTypes.forEach((evidenceType) => {
        if (
          evidenceType.status.toLowerCase() === "not requested" &&
          typeof ret === "boolean"
        ) {
          ret = true;
          if (
            typeof evidenceType.passedFromBe !== "undefined" &&
            !evidenceType.passedFromBe
          ) {
            // this will never be true
            ret = {
              evidenceTypes: result.digitalVerifications
                ? [...result.evidenceTypes, ...result.digitalVerifications]
                : result.evidenceTypes,
              evidence: evidenceType,
            };
          }
        }
      });
    }
    if (result.digitalVerifications) {
      result.digitalVerifications.forEach((digitalVerification) => {
        if (
          digitalVerification.status.toLowerCase() === "not requested" &&
          typeof ret === "boolean"
        ) {
          ret = true;
          if (
            typeof digitalVerification.passedFromBe !== "undefined" &&
            !digitalVerification.passedFromBe
          ) {
            // this will never be true
            ret = {
              evidenceTypes: result.evidenceTypes
                ? [...result.evidenceTypes, ...result.digitalVerifications]
                : result.digitalVerifications,
              evidence: digitalVerification,
            };
          }
        }
      });
    }
    return ret;
  }

  /**
   * This will be used for the investor dashboard view
   */
  parseActiveInvestorsListInvestor(
    investors,
    typeFilter = "none",
    isLoading = false,
    originalInvestors,
  ) {
    const group = {
      section_class: "results d-flex flex-column justify-items-center",
      // 'group_name' : ' ',  // group name should not be passed when we don't want the element to be present
      is_collapsable: true,
      show_list_filter: false,
      show_verification_filter: false,
      // 'activeFilter': typeFilter,
      isPartial: false,
      display_orange_dot: false,
      result_details: [],
      filter: [],
    };

    let numNaturalPerson = 0;
    let numLegalPerson = 0;
    const numOther = 0;
    investors.forEach((investor) => {
      const result = investor.record;
      const key = investor.key;
      let title = "";
      // we will merge the evidence types and digital verifications
      let evidenceTypes;
      // Investor status profile
      if (result["evidenceTypes"]) {
        evidenceTypes =
          this.idVerificationService.concatEvidenceTypesWithDigitalVerifications(
            result["evidenceTypes"],
            result["digitalVerifications"],
          );
      }

      if (result.contractStep && result.contractStep.length > 0) {
        result.contractStep.forEach((contractStep) => {
          evidenceTypes.push(contractStep);
        });
      }

      if (result.typeOfInvestor === "LegalPerson") {
        numLegalPerson++;
      } else if (result.typeOfInvestor === "NaturalPerson") {
        numNaturalPerson++;
      }
      if (typeFilter !== "none" && typeFilter !== result.typeOfInvestor) {
      } else {
        let isNew = true;
        if (key === this.idVerificationService.getActiveInvestor()) {
          isNew = false;
        }
        if (result.typeOfInvestor === "LegalPerson") {
          title = result.investorFields.RegisteredName;
          if (title === undefined) {
            title = " ";
          }
        }
        if (result.typeOfInvestor === "NaturalPerson") {
          title =
            result.investorFields.FirstName +
            " " +
            result.investorFields.LastName;
          if (
            result.investorFields.FirstName === undefined &&
            result.investorFields.LastName === undefined
          ) {
            title = " ";
          }
        }
        let emptyEvidenceTypes = false;
        if (evidenceTypes.length === 0) {
          emptyEvidenceTypes = true;
        }

        const personParsed = {
          key: key,
          result_title: title,
          result_container_class:
            "person-list-entry result-container my-0 minified investor-list-entry" +
            (isNew ? "" : " expanded"),
          isNew: isNew,
          onArrowClickMethod: isNew ? "onExpandActiveBox" : null,
          result_title_classes: "mt-0",
          isInvestorLoading: isLoading && !isNew,
          group_result_title:
            "pi_result_" + key.split(":").pop() + new Date().getTime(),
          elements:
            isNew || isLoading
              ? <any>[]
              : <any>[
                  {
                    type: "documentUploadInner",
                    class: emptyEvidenceTypes ? "d-none" : "",
                    hideButtons: true,
                    investorSide: true,
                    typeOfInvestor: result.typeOfInvestor,
                    evidenceTypes: evidenceTypes,
                    requestKey: key,
                  },
                  {
                    type: "tabbed_content",
                    class: emptyEvidenceTypes
                      ? "half-width-wrapper mt-3"
                      : "half-width-wrapper mt-3 d-none",
                    params: {
                      sectionTitle: this.translate.instant(
                        "appSpecific.Summary",
                      ),
                      sectionTitleClass: "title-t2",
                      showScreeningElementsResults: true,
                      tabKey: key + "_details",
                      tabGroup: key,
                      tabs: [
                        {
                          id: "entity_type",
                          status: "active",
                          content: this.generateSingleInvestorContentFields(
                            result.investorFields,
                            result.typeOfInvestor,
                            1,
                          ),
                        },
                      ],
                    },
                  },
                ],
        };

        if (!isNew && !isLoading && !result.basicFieldsEvidence.reviewState) {
          personParsed["elements"].push({
            type: "update_button_investor",
            class: !emptyEvidenceTypes ? "d-none" : "",
            action: "updateBasicInformation",
            params: {
              evidenceKey: result.basicFieldsEvidence.key,
              requestKey: key,
              documents: [],
              basicFields: result.investorFields,
              action: "updateBasicInformation",
            },
          });
        }
        if (this.projectsType === "funds") {
          personParsed["actionsMenu"] = [
            {
              name: "Share with request",
              method: "documentShareFunction",
            },
            {
              name: "Add documents",
              method: "documentUploadScreen",
            },
            {
              name: "Create a related profile",
              method: "createRelatedParty",
            },
          ];
        }
        if (result.verificationLinks) {
          // need to determine if we need to display warning for verificaiton link...
          result.verificationLinks.forEach((verificationLink) => {
            personParsed.elements.push(
              {
                type: "inputTextTitle",
                class: "d-none",
                tabGroup: key,
                tabKey: key + "_url",
                params: {
                  verificationLink: verificationLink.link,
                },
              },
              {
                type: "inputTextWithButton",
                class: "mt-3 text-with-button-dash short-overlay d-none",
                tabGroup: key,
                tabKey: key + "_url",
                params: {
                  id: "generatedVerificationLink",
                  content: verificationLink.link,
                  classes: "generated-link generated-link-fund-share",
                  innerLabel: this.translate.instant("appSpecific.CopyLink"),
                  button: {
                    display: true,
                    content: this.translate.instant("common.copy"),
                    class: "ml-2 mr-0 form-button-1",
                    action: "CopyLinkToClipboard2",
                  },
                },
              },
            );
            personParsed.elements.push({
              type: "inputTextWithButton",
              tabGroup: key,
              tabKey: key + "_url",
              class: "mt-3 text-with-button-dash short-overlay d-none mb-4",
              params: {
                id: "sendVerificationLink" + verificationLink.key,
                innerLabel: this.translate.instant("appSpecific.EmailLink"),
                classes: "generated-link generated-link-fund-share",
                errorMessage: this.translate.instant("validation.validation5"),
                errorMessageClass: "error-margin-left",
                placeholder: "Type in an email address",
                content: result.investorFields.EmailAddress,
                requestKey: key,
                button: {
                  display: true,
                  content: this.translate.instant("common.send"),
                  class: "ml-2 mr-0 form-button-1",
                  action: "sendVerificationLink",
                },
              },
            });
          });
        }
        if (
          result.relatedParties &&
          result.relatedParties.length !== 0 &&
          !isLoading
        ) {
          const activeRelatedParty =
            this.idVerificationService.getActiveInvestorDetaisl()[
              "activeRelatedParty"
            ];
          const emptyRPEvidenceTypes =
            activeRelatedParty &&
            activeRelatedParty["record"]["evidenceTypes"].length === 0;
          const activeRPverificationLinks =
            activeRelatedParty &&
            activeRelatedParty["record"]["verificationLinks"];
          const activeRelatedPartyDV =
            this.idVerificationService.getActiveInvestorDetaisl()[
              "activeRelatedParty"
            ]
              ? this.idVerificationService.getActiveInvestorDetaisl()[
                  "activeRelatedParty"
                ]["record"]["digitalVerifications"]
              : undefined;
          const activeRelatedPartyKey = activeRelatedParty
            ? activeRelatedParty["key"]
            : "";
          let dvStatuses;
          let tabs;
          const tooltip =
            activeRelatedParty &&
            activeRelatedParty["record"]?.basicFieldsEvidence &&
            this.helper.isEvidenceActionRequiredOrInProgress([
              activeRelatedParty["record"].basicFieldsEvidence,
            ]);
          tabs = [
            {
              name: this.translate.instant("appSpecific.ProfileDetails"),
              activates: activeRelatedParty
                ? activeRelatedParty["key"] + "_details"
                : "",
              active: emptyRPEvidenceTypes,
              iconURL: tooltip
                ? "assets/images/icon-information-prepared-teal.svg"
                : activeRelatedParty &&
                  activeRelatedParty[
                    "record"
                  ]?.basicFieldsEvidence?.status?.toLocaleLowerCase() ===
                    "completed"
                ? "assets/images/icon-checkmark-prepared-green.svg"
                : "",
              tooltip: tooltip
                ? this.translate.instant("appSpecific.profileDetailsTooltip")
                : undefined,
            },
          ];
          if (activeRPverificationLinks) {
            let iconUrlRP;
            if (activeRelatedPartyDV && activeRelatedPartyDV.length) {
              activeRelatedPartyDV.forEach((element) => {
                if (
                  element.status === "In progress" ||
                  element.status === "IN_PROGRESS" ||
                  element.status === "pending"
                ) {
                  iconUrlRP = "/assets/images/icon-triangle-orange.svg";
                }
              });
            }
            tabs.push({
              name: this.translate.instant("appSpecific.VerificationLinks"),
              activates: activeRelatedParty["key"] + "_url",
              iconURL: iconUrlRP,
            });
          }
          if (!emptyRPEvidenceTypes) {
            const tooltip =
              activeRelatedParty &&
              activeRelatedParty["record"] &&
              this.helper.isEvidenceActionRequiredOrInProgress(
                activeRelatedParty["record"].evidenceTypes,
              );
            tabs.unshift({
              name: this.translate.instant("appSpecific.Evidence"),
              activates: activeRelatedParty
                ? activeRelatedParty["key"] + "_evidence"
                : "",
              active: true,
              iconURL: tooltip
                ? "assets/images/icon-information-prepared-teal.svg"
                : "",
              tooltip: tooltip
                ? this.helper.generateTooltipText(
                    "appSpecific.evidenceTooltip",
                    activeRelatedParty["record"].evidenceTypes,
                  )
                : undefined,
            });
          }
          if (activeRelatedPartyDV && activeRelatedPartyDV.length > 0) {
            // if there are digital verifications parse the statuses
            dvStatuses =
              this.idVerificationService.parseFundDVStatuses(
                activeRelatedPartyDV,
              );
            // add the status to the RP group
            tabs.splice(1, 0, {
              name: this.translate.instant("appSpecific.DigitalVerifications"),
              activates: activeRelatedPartyKey + "_checks",
            });
          }

          personParsed.elements.push({
            type: "relatedPartiesList",
            requestKey: key,
            relatedParties: result.relatedParties,
            count: result.relatedParties
              ? result.relatedParties.length
              : undefined,
            activeRelatedPartyKey: activeRelatedPartyKey,
            activeRelatedParty: activeRelatedParty
              ? activeRelatedParty["record"]
              : "",
            activeRelatedPartyEvidenceTypes: activeRelatedParty
              ? activeRelatedParty["evidenceTypes"]
              : "",
            activeRelatedPartyFields: activeRelatedParty
              ? this.generateSingleInvestorContentFields(
                  activeRelatedParty["record"]["investorFields"],
                  activeRelatedParty["record"]["typeOfInvestor"],
                )
              : undefined,
            basicFieldEvidence: activeRelatedParty
              ? activeRelatedParty["record"]["basicFieldsEvidence"]
              : "",
            basicFieldEvidenceKey:
              activeRelatedParty &&
              activeRelatedParty["record"]["basicFieldsEvidence"]
                ? activeRelatedParty["record"]["basicFieldsEvidence"]["key"]
                : "",
            basicFieldEvidenceReviewState: activeRelatedParty
              ? activeRelatedParty["record"]["basicFieldsEvidence"][
                  "reviewState"
                ]
              : "",
            investorSide: true,
            verificationLinks: activeRPverificationLinks,
            emptyRPEvidenceTypes: emptyRPEvidenceTypes,
            tabGroup: activeRelatedParty ? activeRelatedParty["key"] : "",
            tabs: tabs,
            dvStatuses: dvStatuses,
            vtype: "investor",
            activeRelatedPartyDV: activeRelatedPartyDV,
            RPEmail: activeRelatedParty
              ? activeRelatedParty["record"]["investorFields"].EmailAddress
              : "",
            isInvestor: true,
          });
        }
        if (!isLoading) {
          const tooltip =
            result?.basicFieldsEvidence &&
            this.helper.isEvidenceActionRequiredOrInProgress([
              result.basicFieldsEvidence,
            ]);
          personParsed["elements"].unshift({
            type: "dashboardTabs",
            tabGroup: key,
            tabs: [
              {
                name: this.translate.instant("appSpecific.ProfileDetails"),
                activates: key + "_details",
                active: emptyEvidenceTypes,
                iconURL: tooltip
                  ? "assets/images/icon-information-prepared-teal.svg"
                  : result?.basicFieldsEvidence?.status?.toLocaleLowerCase() ===
                    "completed"
                  ? "assets/images/icon-checkmark-prepared-green.svg"
                  : "",
                tooltip: tooltip
                  ? this.translate.instant("appSpecific.profileDetailsTooltip")
                  : undefined,
              },
            ],
          });
          if (!emptyEvidenceTypes) {
            const tooltip =
              result &&
              (result.digitalVerifications || result.evidenceTypes) &&
              (this.helper.isEvidenceActionRequiredOrInProgress(
                result.digitalVerifications,
              ) ||
                this.helper.isEvidenceActionRequiredOrInProgress(
                  result.evidenceTypes,
                ));
            personParsed["elements"][0]["tabs"].unshift({
              name: this.translate.instant("appSpecific.Evidence"),
              activates: key + "_evidence",
              active: true,
              iconURL: tooltip
                ? "assets/images/icon-information-prepared-teal.svg"
                : "",
              tooltip: tooltip
                ? this.helper.generateTooltipText(
                    "appSpecific.evidenceTooltip",
                    [...result.digitalVerifications, ...result.evidenceTypes],
                  )
                : undefined,
            });
          }
          // if there is a verificaiton link add the tab and the content
          if (result.verificationLinks) {
            let iconURL;
            if (
              result.digitalVerifications &&
              result.digitalVerifications.length
            ) {
              result.digitalVerifications.forEach((element) => {
                if (element.status === "In progress") {
                  iconURL = "/assets/images/icon-triangle-orange.svg";
                }
              });
            }
            personParsed["elements"][0]["tabs"].push({
              name: this.translate.instant("appSpecific.VerificationLinks"),
              activates: key + "_url",
              iconURL: iconURL,
            });
          }
          // digital verification tab and elements
          if (
            result.digitalVerifications &&
            result.digitalVerifications.length
          ) {
            personParsed["elements"][0]["tabs"].splice(1, 0, {
              name: this.translate.instant("appSpecific.DigitalVerifications"),
              activates: key + "_checks",
            });
            personParsed["elements"].splice(
              2,
              0,
              // VERIFICATION STATUSES IS THE ELEMENT THAT DISPLAYS ALL THE VERIFICATION STATUSES ON THE DASHBOARD
              {
                type: "verification_statuses",
                tabGroup: key,
                tabKey: key + "_checks",
                class: "px-3 mt-0 d-none",
                params: {
                  investor: true,
                  vtype: "investor",
                  requestKey: key,
                  evidneceTypes: evidenceTypes,
                  statuses: this.idVerificationService.parseFundDVStatuses(
                    result.digitalVerifications,
                  ),
                },
              },
            );
          }
        }
        if (
          result.typeOfInvestor !== "AutomaticRequest" &&
          !isNew &&
          !isLoading
        ) {
          personParsed["elements"].unshift({
            type: "profilesStatusInformation",
            recordOfInvestor: result,
          });
        }

        group["result_details"].push(personParsed);
      }
    });
    this.groups["legalRepresentatives"] = [];
    this.groups["legalRepresentatives"].push(group);
  }

  generateActiveLegalRepresentativesList(result, personKey) {
    // Check wether we will show any of the screening elements
    // Users need to both have screening priveleges and user needs to be verified
    // get permission for screening

    const showScreeningElements =
      this.permissionProjectScreening &&
      result.lastVerification &&
      result.lastVerification.record &&
      result.lastVerification.record.status === "verified";
    const showScreeningElementsResults =
      showScreeningElements && result.lastScreening !== undefined;
    const showAddressVerificationElements = this.permissionAddressVerification;
    const showAPS = this.permissionAPS;
    const showContract = this.permissionContract;

    this.formClass = "";

    const isNew =
      personKey === this.idVerificationService.getActivePerson() ||
      personKey === this.idVerificationService.getScrollToKey()
        ? false
        : true;

    let isLazyFetch = false;
    let isLazyFetchRefresh = false;
    let lazyFetchRefreshAction = "";
    VFEs.forEach((VFE) => {
      if ((result[VFE.type] && (result[VFE.type].record.status === "processing" 
        || result[VFE.type].record.status === "verified")) && (
        ((result[VFE.type].record.isPartial !== undefined &&
          result[VFE.type].record.isPartial)) &&
        !this.idVerificationService.refreshIdVerificaitonKey
      ) || result.resolveRequiredFor) {
        isLazyFetch = true;
      }
      // isLazyFetchRefresh takes priority over isLazyFetch because if it exists and is true
      // it means that a previous lazy fetch attempt failed to execute properly
      if (
        result[VFE.type] !== undefined &&
        result[VFE.type].record.isRefresh !== undefined &&
        result[VFE.type].record.isRefresh &&
        !this.idVerificationService.refreshIdVerificaitonKey
      ) {
        isLazyFetchRefresh = true;
        lazyFetchRefreshAction = "OnAttemptLazyRefresh";
        result[VFE.type].record.status = "lazy_refresh";
      }
    });
    // THE GENERAL STATUS OF THE VERIFICATION, CALCULATED ON THE BE FROM THE OTHER STATUSES
    // first we need to calculate if we're going to show a tooltip
    let tooltip;
    if (
      (result &&
      result.lastVerification &&
      result.lastVerification.record.status === "verified" &&
      result.lastVerification.record.isPartial)  
      || result.resolveRequiredFor
    ) {
      tooltip = {
        tooltipText: this.translate.instant("digitalVerifications.idv.tooltip"),
        tooltipIcon: "/assets/images/icon-information-prepared.svg",
      };
    }
    const generalStatus =
      this.idVerificationService.buildVerificationStatusObject(
        result.verificationSubjectStatus,
        tooltip,
      );
    const dateTime = new Date();
    const localOffset = dateTime.getTimezoneOffset().toString();
    const serverOffset = " +0000";
    const _ret = {
      id: personKey, // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
      isLazyFetch: isLazyFetch,
      isLazyFetchRefresh: isLazyFetchRefresh,
      onLazyFetchRefreshAction: lazyFetchRefreshAction,
      result_container_class:
        "person-list-entry result-container my-0 minified" +
        (isNew ? "" : " expanded") +
        (result.noOfFlags > 0 ? " red_left_border" : " green_left_border") +
        (showScreeningElementsResults ? " show_screening_results" : ""),
      showScreeningElementsResults: showScreeningElementsResults,
      // 'screeningStatus' : screeningStatus.content,
      // 'screeningStatusClass' : screeningStatus.class,
      key: personKey, // key string
      status:
        result.lastVerification && result.lastVerification.record.status
          ? result.lastVerification.record.status
          : "",
      verificationKey:
        result.lastVerification && result.lastVerification.key
          ? result.lastVerification.key
          : "",
      addressVerificationKey:
        result.lastAddressVerification && result.lastAddressVerification.key
          ? result.lastAddressVerification.key
          : "",
      isPartial: false, // TODO: check if the record should be considered partial or not
      isNew: isNew,
      display_orange_dot: result.notify ? true : false,
      onArrowClickMethod: isNew ? "onExpandActiveBox" : null,
      additional_info: generalStatus.content,
      additional_info_classes: generalStatus.class,
      statusTooltip: generalStatus.tooltip,
      additional_info_screening: "t",
      group_result_title:
        "pi_result_" + personKey.split(":").pop() + new Date().getTime(),
      result_title: result.name ? result.name : "Name not available", // SELECTED PERSON NAME!
      result_title_classes: result.name ? "mt-0" : "mt-0 name-not-available",
      elements: <any>[
        {
          type: "title",
          class: "px-3 mt-4-5",
          tabGroup: personKey,
          tabKey: personKey + "_checks",
          params: {
            display: true,
            // 'content': 'Checks performed',
          },
        },
      ],
    };
    // ALL OF THIS IS SKIPPED IF ITS NOT THE ACTIVE PERSON
    if (!isNew) {
      // ID VERIFICATION STATUS
      const statusContent = "Verification not started";
      let status;
      status = {
        content: "Not started",
        class: "status-not-started",
        notificationInfo: {
          // tslint:disable-next-line:max-line-length
          content: statusContent,
          buttonAction: "OnDisplayImageInLightbox",
          showButton: false,
          title: "Identity verification",
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-gray",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: false,
      };
      const button_groups = {
        type: "button_group",
        class: "px-3",
        params: [],
      };
      status.notificationInfo.content = "Verification not started";
      // IF A VERIFICATION LINK EXISTS THE DEFAULT IS NOT "NOT STARTED" BUT "NOT INCLUDED".
      // IF ITS INCLUDED IT WILL BE CHANGED BY THE CALCULATIONS BELLOW
      if (
        result.verificationSubjectStatus !== "NOT_STARTED" &&
        result.verificationSubjectStatus !== undefined
      ) {
        status = {
          content: "Not added",
          class: "status-not-included",
          notificationInfo: {
            // tslint:disable-next-line:max-line-length
            content: "Not added",
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            title: "Identity verification",
            statusClass: "status-not-included",
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-light-gray",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
      }
      // IF USER DOESN'T HAVE PERMISION FOR ID VERIFICATION DISPLAY NOT AVAILABLE MESSAGE
      if (!this.permissionIDVerification && !result.lastVerification) {
        status = {
          content: "Not added",
          class: "status-not-included",
          notificationInfo: {
            // tslint:disable-next-line:max-line-length
            content: "Not enabled for your organization",
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            title: "Identity verification",
            statusClass: "status-not-included",
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-light-gray",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
      } else if (
        result.lastVerification !== undefined &&
        result.lastVerification.record !== undefined &&
        result.lastVerification.record.status !== undefined
      ) {
        status = this.idVerificationService.parseIdVerificaitonStatus(
          result,
          "id",
        );
      }
      _ret.elements.push(
        // VERIFICATION STATUSES IS THE ELEMENT THAT DISPLAYS ALL THE VERIFICATION STATUSES ON THE DASHBOARD
        {
          type: "verification_statuses",
          tabGroup: personKey,
          tabKey: personKey + "_checks",
          class: "px-3 mt-minus-30",
          params: {
            statuses: [status.notificationInfo],
          },
        },
      );

      if (button_groups !== null) {
        // make a check for the screening button
        _ret.elements.push(button_groups);
      }
      // PUSH IN THE ADDRESS INFORMATION IF USER HAS PERMISSIONS
      let avStatus;
      avStatus = this.idVerificationService.parseAddressStatus(
        result,
        "id",
        showAddressVerificationElements,
        result.verificationSubjectStatus,
      );
      _ret.elements[1].params.statuses.push(avStatus);
      // PUSH IN THE APS INFORMATION
      const apsStatuses = this.idVerificationService.parseAPSStatus(
        result,
        "id-verification",
        showAPS,
        result.verificationSubjectStatus,
      );
      apsStatuses.forEach((element) => {
        _ret.elements[1].params.statuses.push(element);
      });
      // display contract
      _ret.elements[1].params.statuses.push(
        this.idVerificationService.parseContractStatus(
          result,
          "id",
          showContract,
          result.verificationSubjectStatus,
        ),
      );

      // ADD IN DASHBOARD LINK SECTION
      if (result.url) {
        _ret.elements.push(
          {
            type: "title",
            class: "ml-3 d-none",
            tabGroup: personKey,
            tabKey: personKey + "_url",
            params: {
              display: true,
              content: "Share the verification link:",
            },
          },
          {
            type: "inputTextWithButton",
            class: "mt-3 text-with-button-dash short-overlay d-none",
            tabGroup: personKey,
            tabKey: personKey + "_url",
            params: {
              id: "generatedVerificationLink",
              content: result.url,
              classes: "generated-link generated-link-fund-share",
              innerLabel: "Copy link",
              button: {
                display: true,
                content: "Copy",
                class: "ml-2 mr-0 form-button-1",
                action: "CopyLinkToClipboard",
              },
            },
          },
          {
            type: "inputTextWithButton",
            tabGroup: personKey,
            tabKey: personKey + "_url",
            class: "mt-3 text-with-button-dash short-overlay d-none mb-4",
            params: {
              id: "sendAddressVerificationLink",
              content:
                this.idVerificationService.getActivePersonDetails() &&
                this.idVerificationService.getActivePersonDetails()["record"][
                  "email"
                ]
                  ? this.idVerificationService.getActivePersonDetails()[
                      "record"
                    ]["email"]
                  : "",
              innerLabel: "Email link",
              classes: "generated-link generated-link-fund-share",
              errorMessage: this.translate.instant("validation.validation5"),
              errorMessageClass: "error-margin-left",
              placeholder: "Type in an email address",
              button: {
                display: true,
                content: "Send",
                class: "ml-2 mr-0 form-button-1",
                action: "sendAddressVerificationLink",
              },
            },
          },
        );
        if (result.lastVerification) {
          _ret.elements.push({
            type: "paragraph",
            tabGroup: personKey,
            tabKey: personKey + "_url",
            class: "mt-2 text-with-button-dash short-overlay ml-3 d-none",
            params: {
              content:
                "Verification link will expire on " +
                this.helper.formatISODate(
                  result.lastVerification.record.expiresOn,
                ),
            },
          });
        }
      }
      // ADD IN DASHBOARD TAGS SECTION
      _ret.elements.push({
        type: "person_tags",
        tabGroup: personKey,
        tabKey: personKey + "_tags",
        class: "dashboard-tags px-3 d-none mb-4",
        params: {
          tags: result.tags,
          showAddButton: true,
          showTitle: true,
          showDiagnostics: false,
          allowDelete: true,
          active: result.activeTabMainProfile === "Tags" 
        },
      });
      _ret.elements.push({
        type: "tabbed_content",
        class: "half-width-wrapper mt-0 d-none",
        params: {
          sectionTitleClass: "title-t2 he-0 m-0 p-0",
          showScreeningElementsResults: true,
          tabKey: personKey + "_personDetails",
          tabGroup: personKey,
          tabs: [
            {
              id: "entity_type",
              content: this.generateSingleInvestorContentFields(
                result,
                "idVerification",
                1,
              ),
              status: "active",
            },
          ],
        },
      });
      const actionsMenu = [];

      if (
        (result.lastVerification &&
          this.helper
            .getSuitableIDVerificationStatuses()
            .includes(result.lastVerification.record.status)) ||
        (result.lastAddressVerification &&
          this.helper
            .getSuitableAddressVerificationStatuses()
            .includes(result.lastAddressVerification.record.status)) ||
        (result.lastContractVerification &&
          this.helper
            .getSuitableContractVerificationStatuses()
            .includes(result.lastContractVerification.record.status)) ||
        (result.lastApsVerification &&
          this.helper
            .getSuitableAPSVerificationStatuses()
            .includes(result.lastApsVerification.record.status))
      ) {
        // push in the download full contract button
        actionsMenu.push({
          name: "Download full report",
          method: "onDownloadCompletePDF",
          src: "/assets/images/download-action.svg",
        });
      } else {
        actionsMenu.push({
          class: ["disabled"],
          name: "Download full report",
          method: "onDownloadCompletePDF",
          src: "/assets/images/download-action-grey.svg",
        });
      }

      // DECIDE WETHER TO DISPLAY ADD VERIFICAITON BUTTON
      if (
        !(
          result.lastVerification &&
          result.lastVerification.record.status === "pending"
        ) ||
        !(
          result.lastAddressVerification &&
          result.lastAddressVerification.record.status === "PENDING"
        ) ||
        !(
          result.lastContractVerification &&
          result.lastContractVerification.record.status === "PENDING"
        ) ||
        !(
          result.lastApsVerification &&
          result.lastApsVerification.record.status === "PENDING"
        ) ||
        !result.lastVerification ||
        !result.lastAddressVerification ||
        !result.lastContractVerification
      ) {
        // add 'add verification' to actions
        actionsMenu.push({
          name:
            !result.lastVerification &&
            !result.lastAddressVerification &&
            !result.lastContractVerification &&
            !result.lastApsVerification
              ? "Start a verification"
              : "Add verification",
          method: "generateTypesOfVerification",
          src: "/assets/images/add-action.svg",
          imageClass: "action-plus",
        });
      } else {
        // add in disabled 'add verification' to actions
        actionsMenu.push({
          class: ["disabled"],
          name:
            !result.lastVerification &&
            !result.lastAddressVerification &&
            !result.lastContractVerification &&
            !result.lastApsVerification
              ? "Start a verification"
              : "Add verification",
          method: "generateTypesOfVerification",
          src: "/assets/images/add-action-grey.svg",
        });
      }

      if (true) {
        // add edit button to actions menu
        actionsMenu.push({
          name: "Edit details",
          method: "OnEditPerson",
          src: "/assets/images/edit-action.svg",
        });
      }

      // display the separate APS button
      if (
        (this.permissionAPS && !result.lastApsVerification) ||
        (result.lastApsVerification &&
          result.lastApsVerification.record.status !== "PENDING" &&
          result.lastApsVerification.record.status !== "ACTION_REQUIRED")
      ) {
        // add in the separate APS button to the actions
        actionsMenu.push({
          name: "Request screening",
          method: "generateApsLightbox",
          src: "/assets/images/screening-action.svg",
          class: ["last-in"],
        });
      } else {
        actionsMenu.push({
          name: "Request screening",
          method: "generateApsLightbox",
          src: "/assets/images/screening-action-grey.svg",
          class: ["disabled", "last-in"],
        });
      }

      // DECIDE WETHER TO DISPLAY CANCEL BUTTON
      if (
        (result.lastAddressVerification &&
          (result.lastAddressVerification.record.status === "PENDING" || result.lastAddressVerification.record.status == "IN_PROGRESS")) ||
        (result.lastVerification &&
          (result.lastVerification.record.status === "pending" || result.lastVerification.record.status == "IN_PROGRESS")) ||
        (result.lastContractVerification &&
          (result.lastContractVerification.record.status === "PENDING" || result.lastContractVerification.record.status == "IN_PROGRESS")) ||
        (result.lastApsVerification &&
          (result.lastApsVerification.record.status === "PENDING" || result.lastApsVerification.record.status == "IN_PROGRESS"))
      ) {
        // add cancel verification to the actions menu
        actionsMenu.push({
          name: "Cancel all verifications",
          class: ["alternate"],
          method: "OnCancelVerification",
          src: "/assets/images/cancel-action.svg",
        });
      } else {
        // add a disabled cancel verifiation to the actions menu
        actionsMenu.push({
          name: "Cancel all verifications",
          class: ["disabled", "alternate"],
          src: "/assets/images/cancel-action-grey.svg",
        });
      }

      // add the delete button to the actions menu
      actionsMenu.push({
        name: "Delete person",
        method: "onDeleteVerification",
        class:
          "alternate " +
          (this.idVerificationService.isMember() &&
          result.verificationSubjectStatus &&
          result.verificationSubjectStatus != "NOT_STARTED"
            ? "disabled"
            : ""),
        src: "/assets/images/delete-document-action.svg",
        tooltip:
          this.idVerificationService.isMember() &&
          result.verificationSubjectStatus &&
          result.verificationSubjectStatus != "NOT_STARTED"
            ? "You need to be a project owner to take this action"
            : "",
      });
      // PUSH IN THE DELETE RECORD BUTTON
      _ret.elements.push({
        type: "diagnostics_button",
        class: "mt-0",
      });
      _ret["actionsMenu"] = actionsMenu;
      // add in the tabs
      _ret.elements.unshift({
        type: "dashboardTabs",
        tabGroup: personKey,
        tabs: [
          {
            name: "Checks performed",
            activates: personKey + "_checks",
            active: true,
          },
          {
            name: "Person details",
            activates: personKey + "_personDetails",
          },
        ],
      });
      if (result.url) {
        _ret.elements[0].tabs.push({
          name: "Verification link",
          activates: personKey + "_url",
        });
      }

      _ret.elements[0].tabs.push({
        name: "Tags",
        activates: personKey + "_tags",
      });
      // DONT CREATE ANY PERSON HTML ELEMENTS IS THE PERSON IS NOT ACTIVE,
      // CAN BE FURTHER OPTIMIZED BY NOT DOING ANY CALCULATIONS IF THE PERSON isNew
      if (isNew) {
        _ret.elements = [];
        _ret["actionsMenu"] = undefined;
      }
    }
    return _ret;
  }

  generateApsLightbox(event) {
    this.displayLoader = false;
    if (
      this.idVerificationService.isIdVerification() &&
      this.idVerificationService.getActivePersonDetails() === undefined
    ) {
      // alert('This person cannot be edited!');
      return;
    }
    const target = event.target;
    let person;
    let firstName;
    let lastName;
    let dateOfBirth;
    let lightboxClass;
    let rpKey;
    if (this.idVerificationService.isIdVerification()) {
      person = this.idVerificationService.getActivePersonDetails()["record"];
      firstName = person.firstName;
      lastName = person.lastName;
      dateOfBirth = person.dateOfBirth;
      lightboxClass = "";
    } else if ($(event.target).attr("isrelatedparty")) {
      person =
        this.idVerificationService.getActiveInvestorDetaisl().activeRelatedParty
          .record.investorFields;
      firstName = person.FirstName;
      lastName = person.LastName;
      rpKey =
        this.idVerificationService.getActiveInvestorDetaisl().activeRelatedParty
          .key;
      lightboxClass = {
        reloadfundAlternate: this.idVerificationService.getActiveInvestor(),
      };
    } else {
      person =
        this.idVerificationService.getActiveInvestorDetaisl().investorFields;
      if (!person) {
        person =
          this.idVerificationService.getActiveInvestorDetaisl().record
            .investorFields;
      }
      firstName = person.FirstName;
      lastName = person.LastName;
      lightboxClass = {
        reloadfundAlternate: this.idVerificationService.getActiveInvestor(),
      };
    }
    // console.log(this.helper.formatISODate(person.dateOfBirth));

    this.formType = "Compliance checks";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = lightboxClass;
    // build the content for the Create new person lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "inputText",
              params: {
                label: "First name",
                id: "lb-representative-fname-aps",
                name: "lbRepresentativeName",
                required: true,
                content: firstName,
                maxLength: 100,
              },
            },
            {
              type: "inputText",
              params: {
                label: "Last name ",
                id: "lb-representative-lname-aps",
                name: "lbRepresentativeName",
                required: true,
                content: lastName,
                maxLength: 100,
              },
            },
            {
              type: "input_date",
              params: {
                label: "Date of birth (optional)",
                id: "aps-Check-DOB",
                name: "apsCheckDOB",
                startDate: undefined,
                minDate: this.helper.getAsStartDate("01.01.1915"),
                maxDate: this.helper.getAdultAsMaxDate(),
                required: false,
                button:
                {
                  content: "Use date of birth from identity document",
                  class: "form-button form-button-link-2 p-0 use-birth-date",
                  display: dateOfBirth ? true : false,
                  action: "prepopulateDOB",
                  dateOfBirth: dateOfBirth,
                }
              },
            },
            {
              type: "range",
              class: "fuziness-level-wrapper",
              params: {
                id: "FuzinessLevel",
                name: "FuzinessLevel",
                label: "Screening scope",
                readOnly: false,
                optionselected: "0.2",
                options: this.idVerificationService.getFuzinessLevels(),
                min: "1",
                max: "10",
                defaultValue: "3",
                labels: ["Narrow", "Broad"],
                changemethod: "onChangeScreeningRange",
                defaultIndicator: "30%",
              },
            },
            {
              type: "checkbox",
              params: {
                id: "exactMatch",
                name: "exactMatch",
                label: "Exact match",
                value: "exactMatch",
                changemethod: "exactMatchClicked",
              },
            },
            {
              type: "toggle",
              class:
                "mt-3 " +
                (this.idVerificationService.isIdVerification()
                  ? "d-none"
                  : ""),
              toggleWrapperClass : "d-flex align-items-center",
              params: {
                id: "screening-monitoring-toggle",
                toggleOn:
                  this.idVerificationService.getfundsOrganizationDetails()
                    ?.MonitorAPSByDefault,
                toggleClass: "monitoring-toggle",
                monitoringText: "Enable monitoring of this screening",
                toggleDisable:
                  !this.idVerificationService.getfundsOrganizationDetails()
                    ?.MonitorAPSByDefault,
                disableToggleTooltip:
                  !this.idVerificationService.getfundsOrganizationDetails()
                    ?.MonitorAPSByDefault
                    ? "Your organisation does not subscribe to monitoring. Please contact us for information on how to enable ongoing monitoring."
                    : "",
              },
            },
            {
              type: "button",
              params: {
                specKey: rpKey,
                content: "Request screening",
                type: "submit",
                display: true,
                action: "initiateAPS",
              },
            },
          ],
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  generateApsLightboxLegal(event) {
    this.displayLoader = false;
    if (
      this.idVerificationService.isIdVerification() &&
      this.idVerificationService.getActivePersonDetails() === undefined
    ) {
      // alert('This person cannot be edited!');
      return;
    }
    const target = event.target;
    let person;
    let registeredName;
    let lightboxClass;
    let rpKey;
    if (this.idVerificationService.isIdVerification()) {
      person = this.idVerificationService.getActivePersonDetails()["record"];
      registeredName = person.registeredName;
      lightboxClass = "";
    } else if ($(event.target).attr("isrelatedparty")) {
      person =
        this.idVerificationService.getActiveInvestorDetaisl().activeRelatedParty
          .record.investorFields;
      registeredName = person.RegisteredName;
      rpKey =
        this.idVerificationService.getActiveInvestorDetaisl().activeRelatedParty
          .key;
      lightboxClass = {
        reloadfundAlternate: this.idVerificationService.getActiveInvestor(),
      };
    } else {
      person =
        this.idVerificationService.getActiveInvestorDetaisl().investorFields;
      if (!person) {
        person =
          this.idVerificationService.getActiveInvestorDetaisl().record
            .investorFields;
      }
      registeredName = person.RegisteredName;
      lightboxClass = {
        reloadfundAlternate: this.idVerificationService.getActiveInvestor(),
      };
    }
    // console.log(this.helper.formatISODate(person.dateOfBirth));

    this.formType = "Compliance checks";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = lightboxClass;
    // build the content for the Create new person lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "inputText",
              params: {
                label: "Registration name",
                id: "lb-representative-rname-aps",
                name: "registrationName",
                required: true,
                content: registeredName,
                maxLength: 100,
              },
            },
            {
              type: "range",
              class: "fuziness-level-wrapper",
              params: {
                id: "FuzinessLevel",
                name: "FuzinessLevel",
                label: "Screening scope",
                readOnly: false,
                optionselected: "0.2",
                options: this.idVerificationService.getFuzinessLevels(),
                min: "1",
                max: "10",
                defaultValue: "3",
                labels: ["Narrow", "Broad"],
                changemethod: "onChangeScreeningRange",
                defaultIndicator: "30%",
              },
            },
            {
              type: "checkbox",
              params: {
                id: "exactMatch",
                name: "exactMatch",
                label: "Exact match",
                value: "exactMatch",
                changemethod: "exactMatchClicked",
              },
            },
            {
              type: "toggle",
              class:
                "mt-3 " +
                (this.idVerificationService.isIdVerification()
                  ? "d-none"
                  : ""),
              toggleWrapperClass : "d-flex align-items-center",
              params: {
                id: "screening-monitoring-toggle",
                toggleOn:
                  this.idVerificationService.getfundsOrganizationDetails()
                    ?.MonitorAPSByDefault,
                toggleClass: "monitoring-toggle",
                monitoringText: "Enable monitoring of this screening",
                toggleDisable:
                  !this.idVerificationService.getfundsOrganizationDetails()
                    ?.MonitorAPSByDefault,
                disableToggleTooltip:
                  !this.idVerificationService.getfundsOrganizationDetails()
                    ?.MonitorAPSByDefault
                    ? "Your organisation does not subscribe to monitoring. Please contact us for information on how to enable ongoing monitoring."
                    : "",
              },
            },
            {
              type: "button",
              params: {
                specKey: rpKey,
                content: "Request screening",
                type: "submit",
                display: true,
                action: "initiateAPSLegal",
              },
            },
          ],
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  exactMatchClicked() {
    if ($(".fuziness-level-wrapper input").prop("disabled")) {
      $(".fuziness-level-wrapper input").prop("disabled", false);
    } else {
      $(".fuziness-level-wrapper input").prop("disabled", "disabled");
    }
  }

  onChangeScreeningRange(event) {
    const value = $(event.target).val();
    let parsedValue;
    let cssValue;
    parsedValue = 10 * value;
    parsedValue = parsedValue + "%";
    cssValue = 10 * (value - 1);
    cssValue = cssValue + "%";

    $(event.target).siblings(".range-input-indicator").text(parsedValue);
    $(event.target).siblings(".range-input-indicator").css("left", cssValue);
  }

  initiateAPS(event) {
    this.displayLoader = true;
    // get fuziness level value
    const fuzinessLEvel = $("#FuzinessLevel").val();
    const firstName = $("#lb-representative-fname-aps").val();
    const lastName = $("#lb-representative-lname-aps").val();
    const exactMatch = $("#exactMatch:checked").length;
    const dateOfBirth =
      $("#apsCheckDOB > input").val() !== ""
        ? this.helper.prepareDateForApi($("#apsCheckDOB > input").val())
        : undefined;
    const isMonitored = $("#screening-monitoring-toggle").is(":checked");

    let payload;
    if (this.idVerificationService.isIdVerification()) {
      payload = { firstName: firstName, lastName: lastName, initiate: true };
      if (dateOfBirth) {
        payload.dateOfBirth = dateOfBirth;
      }
    } else {
      payload = {
        searchSubject: { firstName: firstName, lastName: lastName },
        initiate: true,
        isMonitored: isMonitored,
      };
      if (dateOfBirth) {
        payload.searchSubject.dateOfBirth = dateOfBirth;
      }
    }

    if (exactMatch) {
      payload.exactMatch = true;
    } else {
      payload.fuzziness = fuzinessLEvel / 10;
    }

    if (this.idVerificationService.isIdVerification()) {
      this.idVerificationService.initiateAps(
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActivePerson(),
        payload,
      );
    } else {
      this.idVerificationService.initiateApsCDD(
        this.idVerificationService.getActiveProject(),
        $(event.target).attr("specKey")
          ? $(event.target).attr("specKey")
          : this.idVerificationService.getActiveInvestor(),
        payload,
      );
    }
  }

  initiateAPSLegal(event) {
    this.displayLoader = true;
    // get fuziness level value
    const fuzinessLEvel = $("#FuzinessLevel").val();
    const registrationName = $("#lb-representative-rname-aps").val();
    const exactMatch = $("#exactMatch:checked").length;
    const isMonitored = $("#screening-monitoring-toggle").is(":checked");

    let payload;
    payload = {
      searchSubject: { registrationName: registrationName },
      initiate: true,
      isMonitored: isMonitored,
    };

    if (exactMatch) {
      payload.exactMatch = true;
    } else {
      payload.fuzziness = fuzinessLEvel / 10;
    }

    if (this.idVerificationService.isIdVerification()) {
      this.idVerificationService.initiateAps(
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActivePerson(),
        payload,
      );
    } else {
      this.idVerificationService.initiateApsCDD(
        this.idVerificationService.getActiveProject(),
        $(event.target).attr("specKey")
          ? $(event.target).attr("specKey")
          : this.idVerificationService.getActiveInvestor(),
        payload,
      );
    }
  }

  prepopulateDOB(event)
  {
    let dob = $(event.target).attr("dob");
    let elementId = $(event.target).attr("inputId");
    if(elementId && dob)
    {
      let element = "#" + elementId + " > input";
      dob = this.helper.formatISODate(dob, "dd.MM.yyyy"); 
      $(element).val(dob);
    }
  }

  getAps() {
    this.idVerificationService.getAps(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      this.idVerificationService.getActivePersonDetails()["record"][
        "lastApsVerification"
      ]["key"],
    );
  }

  generateTypesOfVerification(event) {
    // if (event) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    // close any windows
    $(".close-form-button,#lightBoxCloseImage").trigger("click");

    const activePerson = this.idVerificationService
      .getActivePersons()
      .find(
        (person) => person.key === this.idVerificationService.getActivePerson(),
      );

    // CHECK IF THERES ACTIVE VERIFICATION
    const lastVerification = activePerson["record"]["lastVerification"];
    const lastAVerification = activePerson["record"]["lastAddressVerification"];
    const lastApsVerification = activePerson["record"]["lastApsVerification"];
    const lastContractVerification =
      activePerson["record"]["lastContractVerification"];

    // // IF USER DOESNT HAVE PERMISSION FOR ADDRESS VERIFICATION GO DIRECTLY TO ID VERIFICATION
    // if (!this.permissionAddressVerification) {
    //   this.loadAndParseTypeCountries();
    // }
    // //IF USER DOESNT HAVE PERMISSION FOR ID VERIFICAITON GO DIRECTLY TO ADDRESS VERIFICATION
    // else if (!this.permissionIDVerification) {
    //   this.generateAVerificationLink();
    // }
    const elements = [
      {
        type: "subtitle-alternate",
        class: "input-label-large increased-margin-top",
        params: {
          content: "Choose which verifications to add",
        },
      },
      {
        type: "paragraph",
        params: {
          content:
            "The person will be required to complete all added verifications. You are not able to add a verification which is currently in progress.",
        },
      },
      {
        type: "counter_group",
        class: "checkbox-borderless counter-group-single",
        params: {
          groupTitle: "",
          checkboxes: [],
        },
      },
      {
        type: "button",
        params: {
          content: "Next",
          type: "submit",
          display: true,
          action: "onFinishSelectionVerificationTypes",
          errorMessage: "*at least one verification type must be selected.",
        },
      },
    ];
    // if there is ID Verification Permission add in the checkbox
    if (this.permissionIDVerification) {
      elements[2].params.checkboxes.push({
        type: "checkbox",
        params: {
          id: "idVerificationSelect",
          name: "idVerificationSelect",
          label: "Identity verification",
          value: "IDVerification",
          oneDocument: true,
          oneDocumentDisabled:
            lastVerification && lastVerification.record.status === "pending"
              ? true
              : false,
          counter:
            lastVerification && lastVerification.record.status === "pending"
              ? 1
              : 1,
          class: "counter-alternate",
        },
      });
    }
    // if there is Address Verification Permission add in the checkbox
    if (this.permissionAddressVerification) {
      elements[2].params.checkboxes.push({
        type: "checkbox",
        params: {
          id: "AddressVerificationSelect",
          name: "AddressVerificationSelect",
          label: "Address verification",
          value: "Addressverification",
          oneDocument: true,
          oneDocumentDisabled:
            lastAVerification &&
            (lastAVerification.record.status === "PENDING" ||
              lastAVerification.record.status === "ACTION_REQUIRED")
              ? true
              : false,
          counter:
            lastAVerification && lastAVerification.record.status === "PENDING"
              ? 1
              : 1,
          class: "counter-alternate",
        },
      });
    }
    // add in contract checkbox (no permission required YET)
    if (this.permissionContract) {
      elements[2].params.checkboxes.push({
        type: "checkbox",
        params: {
          id: "ContractSigningSelect",
          name: "ContractSigningSelect",
          label: "Smart form",
          value: "contractSigning",
          oneDocument: true,
          oneDocumentDisabled:
            lastContractVerification &&
            lastContractVerification.record.status === "PENDING"
              ? true
              : false,
          counter:
            lastContractVerification &&
            lastContractVerification.record.status === "PENDING"
              ? 1
              : 1,
          class: "counter-alternate",
        },
      });
    }
    if (this.permissionAPS) {
      elements[2].params.checkboxes.push({
        type: "checkbox",
        class: "aps-checkbox-class",
        params: {
          id: "APSSelect",
          name: "APSSelect",
          label: "Compliance check",
          value: "APS",
          oneDocument: true,
          oneDocumentDisabled:
            lastApsVerification &&
            (lastApsVerification.record.status === "PENDING" ||
              lastApsVerification.record.status === "ACTION_REQUIRED")
              ? true
              : false,
          counter:
            lastApsVerification &&
            lastApsVerification.record.status === "PENDING"
              ? true
              : false
              ? 1
              : 1,
          class: "counter-alternate",
        },
      });
    }
    this.idVerificationService.setActivePerson(
      $(event.target).closest(".result-container").attr("key"),
    );
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "verification-configuration-screen";
      this.formType = "";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = {
        class:
          "form-lightbox-fullscreen form-lightbox-breadcrumbs border-top-right-fix border-top-left-fix",
        marginclass: "generate-verification",
        stepsSubtitle: "",
        activateFirstEvidenceBox: true,
        routeManipulation: true,
        stepsTitle: "Create Verification",
        steps: [
          { name: "Choose type", class: "form-step-active", count: 1 },
          { name: "Configure", count: 2 },
        ],
      };
      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: elements,
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayLoader = false;
      this.displayModal = true;
    }
  }

  prepareIdConfigurationdata(idv, contracts) {
    const observableBatch = [];
    if (idv) {
      observableBatch.push(
        this.userService.getEndPoint(
          "api/proxy/supportedIdTypes",
          { responseType: "json" },
          { observe: "body" },
          "verification",
        ),
      );
    }
    if (contracts) {
      observableBatch.push(
        this.userService.getEndPoint(
          "api/pdf/templates/organizations",
          { responseType: "json" },
          { observe: "body" },
          "verification",
        ),
      );
    }
    if (observableBatch.length === 0) {
      this.resolveNextVerificationConfigurationStep(
        this.selectedVerifications,
        undefined,
      );
    } else {
      forkJoin(observableBatch).subscribe(
        async (response) => {
          if (idv && contracts) {
            this.idVerificationService.parseTypeCountries(
              response[0],
              "id-verification",
            );
            this.idVerificationService.parseContractTemplates(
              response[1],
              "id-verification",
            );
          } else if (idv) {
            this.idVerificationService.parseTypeCountries(
              response[0],
              "id-verification",
            );
          } else if (contracts) {
            this.idVerificationService.parseContractTemplates(
              response[0],
              "id-verification",
            );
          }
          this.displayLoader = false;
          this.resolveNextVerificationConfigurationStep(
            this.selectedVerifications,
            undefined,
          );
        },
        (error) => {
          console.log(error);
          $(".close-form-button,#lightBoxCloseImage").trigger("click");
          alert("Error while fetching data for the configuration");
        },
      );
    }
  }

  onChangeTypeCountry(params) {
    const selectedCountry = $(".type-country-wrapper option:selected")
      .text()
      .trim();
    // if the default option is selected hide the types
    if (selectedCountry === "All countries") {
      $(".verification-type-wrapper").hide();
    } else if (
      this.idVerificationService.getActivePersonDetails()["typeCountries"]
    ) {
      const selectedCountryObject = this.idVerificationService
        .getActivePersonDetails()
        ["typeCountries"].find(
          (country) => country.countryName === selectedCountry,
        );
      if (
        selectedCountryObject &&
        selectedCountryObject.idTypes &&
        selectedCountryObject.idTypes.length >= 1
      ) {
        // empty the type list

        $("#IDVerificationType").empty();
        selectedCountryObject.idTypes.forEach((selectedCountryType) => {
          const option = $("<option></option>")
            .attr("value", selectedCountryType.idType)
            .text(this.helper.parseTypeName(selectedCountryType.idType));
          if (params.type === selectedCountryType.idType) {
            option.attr("selected", true);
          }
          $("#IDVerificationType").append(option);
        });
        $(".verification-type-wrapper").show();
      } else {
        // if for some reason we can't find the object hide the types
        $(".verification-type-wrapper").hide();
      }
    }
  }
  generateVerificationLink() {
    // try to recover active person if id is lost
    let showAVLink = false;
    let showContractLink = false;
    let showAPSLink = false;
    if (
      this.selectedVerifications &&
      this.selectedVerifications.AVerification
    ) {
      showAVLink = true;
    }
    if (this.selectedVerifications && this.selectedVerifications.Contract) {
      showContractLink = true;
    }
    if (this.selectedVerifications && this.selectedVerifications.APS) {
      showAPSLink = true;
    }
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = {
        class:
          "form-lightbox-fullscreen form-lightbox-breadcrumbs no-form-padding border-top-left-fix",
        marginclass: "steps-no-margin generate-verification",
        stepsSubtitle: "",
        activateFirstEvidenceBox: true,
        routeManipulation: true,
        stepsTitle: "Create Verification",
        steps: [
          { name: "Choose type", class: "form-step-past", count: 1 },
          { name: "Configure", class: "form-step-active", count: 2 },
        ],
      };
      // build the content for the Create new project lightbox
      let group;
      group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "documentUploaderMainVerification",
                wrapperClass: true,
                isActive: true,
                params: {
                  dataActivates: "identityVerificationSide",
                  clickFunction: "generateVerificationLink",
                  order: 1,
                  loadingFromBe: false,
                  document_category_name: "Identity verification",
                },
              },
              {
                type: "documentUploaderSideVerification",
                isActive: true,
                params: {
                  countryList:
                    this.idVerificationService.getActivePersonDetails()[
                      "typeCountries"
                    ],
                  idTypeList: this.idVerificationService.getIDTypeList(),
                  localeList: this.idVerificationService.getLocaleList(),
                  defaultLocale: "en",
                  workflowList: this.idVerificationService.getWorkFlowList(),
                  dateModel: "NotOlderThanM",
                  lastInLine: showAVLink ? false : true,
                  showCountriesAndTypes:
                    this.idVerificationService.getActivePersonDetails()[
                      "typeCountries"
                    ]
                      ? true
                      : false,
                },
              },
            ],
          },
        ],
      };
      if (showAVLink) {
        group.result_details[0].elements.push({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateAVerificationLink",
            order: 2,
            loadingFromBe: false,
            document_category_name: "Address verification",
            isCompletedt: this.selectedVerifications.AVerification.completed,
          },
        });
      }
      if (showContractLink) {
        group.result_details[0].elements.push({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateContractLink",
            order: 2,
            loadingFromBe: false,
            document_category_name: "Smart form",
            isCompletedt: this.selectedVerifications.Contract.completed,
          },
        });
      }
      if (showAPSLink) {
        group.result_details[0].elements.push({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateAPSLink",
            order: 1,
            loadingFromBe: false,
            document_category_name: "Compliance check",
            isCompletedt: this.selectedVerifications.APS.completed,
          },
        });
      }
      group.result_details[0].elements.push({
        type: "button",
        class: "separate-padding",
        params: {
          content: "Close",
          display: true,
          action: "closeLinkGeneration",
        },
      });

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayLoader = false;
      this.displayModal = true;
    }
  }

  generateAVerificationLink() {
    let showIDLink = false;
    let showContractLink = false;
    let showAPSLink = false;
    if (
      this.selectedVerifications &&
      this.selectedVerifications.IDVerification
    ) {
      showIDLink = true;
    }
    if (this.selectedVerifications && this.selectedVerifications.Contract) {
      showContractLink = true;
    }
    if (this.selectedVerifications && this.selectedVerifications.APS) {
      showAPSLink = true;
    }
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = {
        class:
          "form-lightbox-fullscreen form-lightbox-breadcrumbs no-form-padding border-top-left-fix",
        marginclass: "steps-no-margin generate-verification",
        stepsSubtitle: "",
        activateFirstEvidenceBox: true,
        routeManipulation: true,
        stepsTitle: "Create Verification",
        steps: [
          { name: "Choose type", class: "form-step-past", count: 1 },
          { name: "Configure", count: 2, class: "form-step-active" },
        ],
      };
      // build the content for the Create new project lightbox
      let group;
      group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "documentUploaderMainVerification",
                wrapperClass: true,
                isActive: true,
                params: {
                  dataActivates: "identityVerificationSide",
                  clickFunction: "generateAVerificationLink",
                  order: 2,
                  loadingFromBe: false,
                  document_category_name: "Address verification",
                },
              },
              {
                type: "documentUploaderSideAVerification",
                isActive: true,
                params: {
                  countryList: this.idVerificationService.parseCountryOptions(),
                  idTypeList: this.idVerificationService.getIDTypeList(),
                  allowiDIN: this.permissionIDIN,
                  lastInLine: true,
                  countryListAv: this.idVerificationService.getCountryListAV(),
                  checkboxes: [
                    {
                      type: "checkbox",
                      params: {
                        id: "Other",
                        htpmOnSaveNewRepresentativename: "OtherN",
                        label: "Custom documents",
                        value: "Other",
                        existingCustomTypes: 0,
                        counter: 0,
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      };

      if (showIDLink) {
        group.result_details[0].elements.unshift({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateVerificationLink",
            order: 1,
            loadingFromBe: false,
            document_category_name: "Identity verification",
            isCompletedt: this.selectedVerifications.IDVerification.completed,
          },
        });
      }
      if (showContractLink) {
        group.result_details[0].elements.push({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateContractLink",
            order: 1,
            loadingFromBe: false,
            document_category_name: "Smart form",
            isCompletedt: this.selectedVerifications.Contract.completed,
          },
        });
      }
      if (showAPSLink) {
        group.result_details[0].elements.push({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateAPSLink",
            order: 1,
            loadingFromBe: false,
            document_category_name: "Compliance check",
            isCompletedt: this.selectedVerifications.APS.completed,
          },
        });
      }
      group.result_details[0].elements.push({
        type: "button",
        class: "separate-padding",
        params: {
          content: "Close",
          display: true,
          action: "closeLinkGeneration",
        },
      });
      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayLoader = false;
      this.displayModal = true;
    }
  }

  generateContractLink() {
    let showIDLink = false;
    let showAVLink = false;
    let showAPSLink = false;
    if (
      this.selectedVerifications &&
      this.selectedVerifications.IDVerification
    ) {
      showIDLink = true;
    }
    if (
      this.selectedVerifications &&
      this.selectedVerifications.AVerification
    ) {
      showAVLink = true;
    }
    if (this.selectedVerifications && this.selectedVerifications.APS) {
      showAPSLink = true;
    }
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = {
        class:
          "form-lightbox-fullscreen form-lightbox-breadcrumbs no-form-padding border-top-left-fix",
        marginclass: "steps-no-margin generate-verification",
        stepsSubtitle: "",
        activateFirstEvidenceBox: true,
        routeManipulation: true,
        stepsTitle: "Create Verification",
        steps: [
          { name: "Choose type", class: "form-step-past", count: 1 },
          { name: "Configure", count: 2, class: "form-step-active" },
        ],
      };
      // build the content for the Create new project lightbox

      let group;
      group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "documentUploaderMainVerification",
                wrapperClass: true,
                isActive: true,
                params: {
                  dataActivates: "identityVerificationSide",
                  clickFunction: "generateContractLink",
                  order: 2,
                  loadingFromBe: false,
                  document_category_name: "Smart form",
                },
              },
              {
                type: "documentUploaderSideContract",
                isActive: true,
                params: {
                  selectDisabled:
                    this.idVerificationService.getActivePersonDetails()[
                      "ContractTemplates"
                    ]
                      ? false
                      : true,
                  contractValue:
                    this.idVerificationService.getActivePersonDetails()[
                      "ContractTemplates"
                    ] &&
                    this.idVerificationService.getActivePersonDetails()[
                      "ContractTemplates"
                    ].length > 0
                      ? this.idVerificationService.getActivePersonDetails()[
                          "ContractTemplates"
                        ][0]["key"]
                      : "",
                  contractTemplates:
                    this.idVerificationService.getActivePersonDetails()[
                      "ContractTemplates"
                    ],
                },
              },
            ],
          },
        ],
      };
      if (showAVLink) {
        group.result_details[0].elements.unshift({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateAVerificationLink",
            order: 2,
            loadingFromBe: false,
            document_category_name: "Address verification",
            isCompletedt: this.selectedVerifications.AVerification.completed,
          },
        });
      }
      if (showIDLink) {
        group.result_details[0].elements.unshift({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateVerificationLink",
            order: 1,
            loadingFromBe: false,
            document_category_name: "Identity verification",
            isCompletedt: this.selectedVerifications.IDVerification.completed,
          },
        });
      }
      if (showAPSLink) {
        group.result_details[0].elements.push({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateAPSLink",
            order: 1,
            loadingFromBe: false,
            document_category_name: "Compliance check",
            isCompletedt: this.selectedVerifications.APS.completed,
          },
        });
      }
      group.result_details[0].elements.push({
        type: "button",
        class: "separate-padding",
        params: {
          content: "Close",
          display: true,
          action: "closeLinkGeneration",
        },
      });
      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayLoader = false;
      this.displayModal = true;
    }
  }

  generateAPSLink() {
    let showIDLink = false;
    let showAVLink = false;
    let showContractLink = false;
    if (
      this.selectedVerifications &&
      this.selectedVerifications.IDVerification
    ) {
      showIDLink = true;
    }
    if (
      this.selectedVerifications &&
      this.selectedVerifications.AVerification
    ) {
      showAVLink = true;
    }
    if (this.selectedVerifications && this.selectedVerifications.Contract) {
      showContractLink = true;
    }
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = {
        class:
          "form-lightbox-fullscreen form-lightbox-breadcrumbs no-form-padding border-top-left-fix",
        marginclass: "steps-no-margin generate-verification",
        stepsSubtitle: "",
        activateFirstEvidenceBox: true,
        routeManipulation: true,
        stepsTitle: "Create Verification",
        steps: [
          { name: "Choose type", class: "form-step-past", count: 1 },
          { name: "Configure", count: 2, class: "form-step-active" },
        ],
      };
      // build the content for the Create new project lightbox
      let group;
      group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "documentUploaderMainVerification",
                wrapperClass: true,
                isActive: true,
                params: {
                  dataActivates: "identityVerificationSide",
                  clickFunction: "generateAPSLink",
                  order: 2,
                  loadingFromBe: false,
                  document_category_name: "Compliance check",
                },
              },
              {
                type: "documentUploaderSideAPS",
                isActive: true,
                params: {
                  fuzinessLevel: this.idVerificationService.getFuzinessLevels(),
                },
              },
            ],
          },
        ],
      };
      if (showContractLink) {
        group.result_details[0].elements.unshift({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateContractLink",
            order: 1,
            loadingFromBe: false,
            document_category_name: "Smart form",
            isCompletedt: this.selectedVerifications.Contract.completed,
          },
        });
      }
      if (showAVLink) {
        group.result_details[0].elements.unshift({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateAVerificationLink",
            order: 2,
            loadingFromBe: false,
            document_category_name: "Address verification",
            isCompletedt: this.selectedVerifications.AVerification.completed,
          },
        });
      }
      if (showIDLink) {
        group.result_details[0].elements.unshift({
          type: "documentUploaderMainVerification",
          wrapperClass: true,
          isActive: false,
          params: {
            dataActivates: "identityVerificationSide",
            clickFunction: "generateVerificationLink",
            order: 1,
            loadingFromBe: false,
            document_category_name: "Identity verification",
            isCompletedt: this.selectedVerifications.IDVerification.completed,
          },
        });
      }
      group.result_details[0].elements.push({
        type: "button",
        class: "separate-padding",
        params: {
          content: "Close",
          display: true,
          action: "closeLinkGeneration",
        },
      });
      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayLoader = false;
      this.displayModal = true;
    }
  }

  closeLinkGeneration() {
    $("#lightBoxCloseImage").click();
  }

  idvGenerationStepOne() {
    $(".document-category-main").addClass("uploading-document");
    $(".documentUploaderSide").addClass("uploading-document");
    const payload = this.idVerificationService.parseIDVData("identity");
    const path =
      "/api/projects/" +
      this.idVerificationService.getActiveProject() +
      "/people/" +
      this.idVerificationService.getActivePerson() +
      "/verifications/identity";
    this.userService
      .postEndPoint(
        path,
        payload,
        { responseType: "json" },
        { observe: "body" },
        "verification",
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Unexpected error as we were generating the URL");
            return;
          }
          // update the data at once
          this.selectedVerifications.IDVerification.completed = true;
          if (
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastVerification"
            ]
          ) {
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastVerification"
            ].record.status = "pending";
          } else {
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastVerification"
            ] = { record: { status: "pending" } };
          }
          this.idVerificationService.updateLegalRepresentative(
            this.idVerificationService.getActiveProject(),
             this.idVerificationService.getActivePerson(),
           )
          this.resolveNextVerificationConfigurationStep(
            this.selectedVerifications,
            response["record"].url,
          );
        },
        (error) => {
          console.log("We could not generate URL", error);
        },
      );
  }

  /**
   * generate second vfe - address verification
   */
  idvGenerationStepTwo(event) {
    $(".document-category-main").addClass("uploading-document");
    $(".documentUploaderSide").addClass("uploading-document");
    const payload = this.idVerificationService.parseAVData(event);
    if (payload === undefined) {
      return;
    }
    const path =
      "/api/projects/" +
      this.idVerificationService.getActiveProject() +
      "/people/" +
      this.idVerificationService.getActivePerson() +
      "/verifications/address";
    this.userService
      .postEndPoint(
        path,
        payload,
        { responseType: "json" },
        { observe: "body" },
        "verification",
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (typeof response === undefined) {
            console.log(
              "There was an unexpected error while generating the address verification link",
            );
            $("#lightBoxCloseImage").click();
            return;
          }
          // We add this here to update the data as fast as possible
          this.selectedVerifications.AVerification.completed = true;
          if (
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastAddressVerification"
            ]
          ) {
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastAddressVerification"
            ].record.status = "PENDING";
          } else {
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastAddressVerification"
            ] = { record: { status: "PENDING" } };
          }
          this.idVerificationService.updateLegalRepresentative(
            this.idVerificationService.getActiveProject(),
             this.idVerificationService.getActivePerson(),
           )
          this.resolveNextVerificationConfigurationStep(
            this.selectedVerifications,
            response["record"].url,
          );
        },
        (error) => {
          console.log(
            "The following error occured when generating id verification link",
            error,
          );
        },
      );
  }

  idvGenerationStepThree() {
    $(".document-category-main").addClass("uploading-document");
    $(".documentUploaderSide").addClass("uploading-document");
    const selectedTemplate = $("#ContractTemplate").val();
    let hideConfirmation = $("#hideConfirmation:checked").val() ? true : false;
    let hideSignedDocumentCopy = $("#hideSignedDocumentCopy:checked").val() ? true : false
    const payload = { 
      template: selectedTemplate,
      hideConfirmation : hideConfirmation,
      hideSignedDocumentCopy : hideSignedDocumentCopy,
     };
    const path =
      "/api/projects/" +
      this.idVerificationService.getActiveProject() +
      "/people/" +
      this.idVerificationService.getActivePerson() +
      "/verifications/contract";
    this.userService
      .postEndPoint(
        path,
        payload,
        { responseType: "json" },
        { observe: "body" },
        "verification",
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Unexpected error as we were generating the URL");
            return;
          }
          // update the data at once
          this.selectedVerifications.Contract.completed = true;
          if (
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastContractVerification"
            ]
          ) {
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastContractVerification"
            ].record.status = "PENDING";
          } else {
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastContractVerification"
            ] = { record: { status: "PENDING" } };
          }
          this.idVerificationService.updateLegalRepresentative(
            this.idVerificationService.getActiveProject(),
             this.idVerificationService.getActivePerson(),
           )
          this.resolveNextVerificationConfigurationStep(
            this.selectedVerifications,
            response["record"].url,
          );
        },
        (error) => {
          console.log("We could not generate URL", error);
        },
      );
  }

  idvGenerationStepFour() {
    $(".document-category-main").addClass("uploading-document");
    $(".documentUploaderSide").addClass("uploading-document");
    const exactMatch = $("#exactMatch:checked").length;
    const fuzinessLEvel = $("#FuzinessLevel").val();
    let payload;
    payload = {};
    if (exactMatch) {
      payload.exactMatch = true;
    } else {
      payload.fuzziness = fuzinessLEvel / 10;
    }
    const path =
      "/api/projects/" +
      this.idVerificationService.getActiveProject() +
      "/people/" +
      this.idVerificationService.getActivePerson() +
      "/verifications/aps";
    this.userService
      .postEndPoint(
        path,
        payload,
        { responseType: "json" },
        { observe: "body" },
        "verification",
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Unexpected error as we were generating the URL");
            return;
          }
          // update the data at once
          this.selectedVerifications.APS.completed = true;
          if (
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastApsVerification"
            ]
          ) {
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastApsVerification"
            ].record.status = "PENDING";
          } else {
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastApsVerification"
            ] = { record: { status: "PENDING" } };
          }
          this.idVerificationService.updateLegalRepresentative(
            this.idVerificationService.getActiveProject(),
             this.idVerificationService.getActivePerson(),
           )
          // as this is the last step go directly to last step
          this.resolveNextVerificationConfigurationStep(
            this.selectedVerifications,
            response["record"].url,
          );
        },
        (error) => {
          console.log("We could not generate URL", error);
        },
      );
  }

  resolveNextVerificationConfigurationStep(selectedVerifications, url) {
    // next step is idVerification
    if (
      selectedVerifications.IDVerification &&
      !selectedVerifications.IDVerification.completed
    ) {
      this.generateVerificationLink();
    } else if (
      selectedVerifications.AVerification &&
      !selectedVerifications.AVerification.completed
    ) {
      this.generateAVerificationLink();
    } else if (
      selectedVerifications.Contract &&
      !selectedVerifications.Contract.completed
    ) {
      this.generateContractLink();
    } else if (
      selectedVerifications.APS &&
      !selectedVerifications.APS.completed
    ) {
      this.generateAPSLink();
    } else {
      this.loadLinksAfterConfiguration(url);
    }
  }

  onFinishSelectionVerificationTypes(event) {
    // check what verification types are selected
    this.selectedVerifications = {};
    $(event.target).siblings(".button-error-field").hide();
    const counters = $(event.target)
      .closest("form")
      .find(".custom-counter-count")
      .filter(function () {
        return (
          this.value !== "0" &&
          this.getAttribute("verificationDisabled") !== "true"
        );
      });
    if (counters.length === 0) {
      $(event.target).siblings(".button-error-field").show();
    } else {
      // make the selectedVerificationsArray
      const idvViable =
        this.permissionIDVerification &&
        $("#idVerificationSelect").length &&
        $("#idVerificationSelect").val() !== "0" &&
        $("#idVerificationSelect").attr("verificationDisabled") !== "true";
      const avViable =
        this.permissionAddressVerification &&
        $("#AddressVerificationSelect").length &&
        $("#AddressVerificationSelect").val() !== "0" &&
        $("#AddressVerificationSelect").attr("verificationDisabled") !== "true";
      const contractViable =
        this.permissionContract &&
        $("#ContractSigningSelect").length &&
        $("#ContractSigningSelect").val() !== "0" &&
        $("#ContractSigningSelect").attr("verificationDisabled") !== "true";
      const APSViable =
        this.permissionAPS &&
        $("#APSSelect").length &&
        $("#APSSelect").val() !== "0" &&
        $("#APSSelect").attr("verificationDisabled") !== "true";

      this.selectedVerifications = {};
      // add idv if viable
      if (idvViable) {
        this.selectedVerifications.IDVerification = { completed: false };
      }
      // add address if viable
      if (avViable) {
        this.selectedVerifications.AVerification = { completed: false };
      }
      if (contractViable) {
        this.selectedVerifications.Contract = { completed: false };
      }

      if (APSViable) {
        this.selectedVerifications.APS = { completed: false };
      }
      if (!idvViable && !avViable && !contractViable && !APSViable) {
        $(event.target).siblings(".button-error-field").show();
      } else {
        this.displayLoader = true;
        this.prepareIdConfigurationdata(idvViable, contractViable);
      }
      // end on deciding starting verification
    }
  }
  /**
   * parse the tabbed content
   */
  parseTabbedContent(result) {
    const dateTime = new Date();
    const localOffset = dateTime.getTimezoneOffset().toString();
    const serverOffset = " +0000";
    const res = [
      { key: "E-mail", content: result.email ? result.email : "unknown" },
      {
        key: "Date of birth",
        content: result.dateOfBirth
          ? this.datePipe.transform(
              this.helper.convertToDate(result.dateOfBirth),
              "dd.MM.yyyy",
              serverOffset,
            )
          : "unknown",
      },
      // {'key': 'Relationship', 'content' : (result.relationship) ? result.relationship : 'unknown'},
    ];
    if (
      result.lastVerification &&
      result.lastVerification.record.documentExpiry &&
      this.helper.getTimestamp("current") >
        this.helper.getTimestamp(result.lastVerification.record.documentExpiry)
    ) {
      res.push({
        key: "Document expired",
        content: this.datePipe.transform(
          this.helper.convertToDate(
            result.lastVerification.record.documentExpiry,
          ),
          "dd.MM.yyyy",
          serverOffset,
        ),
      });
    }
    if (result.documentNo) {
      res.push({ key: "Document No", content: result.documentNo });
    }
    if (result.expiryDate) {
      res.push({ key: "Expiry Date", content: result.expiryDate });
    }
    return res;
  }

  /**
   * call this method on attemt to recover from failed auto lazy re-fetch
   * @param params:array
   */
  OnAttemptLazyRefresh(params) {
    console.log("on attempt to do a lazy refresh", params);
    this.idVerificationService.setActiveLazyLoadPerson(params.key);
    this.idVerificationService.setLazyLoadPersons([
      this.idVerificationService.getActiveLazyLoadPersonDetails(),
    ]);
    $(params.eventTarget.target)
      .closest(".result-container")
      .find("h4 > .additional-info")
      .text("refreshing ...");
  }

  /**
   * call this method on click of a Content Builder button (not a submit)
   * @param returnedAction event
   */
  parseContentReturn(returnedAction) {
    // console.log('returned action', returnedAction);
    if (
      typeof returnedAction.method === "string" &&
      returnedAction.method !== ""
    ) {
      // allow only strings as acceptable method name
      let params = "";
      // if (Array.isArray(returnedAction.params)) {
      params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, "is not declared as method!", error);
      }
    } else {
      console.log("**method name not string or empty string");
    }
  }

  /**
   * it will execute all additional methods needed to happen on expanding an active box
   * 1. setActivePerson
   * 2. markOrangeDot as seen if needed
   * 3. loadActivePersonDetails
   */
  onExpandActiveBox(params) {
    if (typeof params.key !== "string" || params.key === null) {
      console.log("We could not mark this request as seen");
      return;
    }
    if (this.projectsType === "id-verification") {
      // console.log('miki verification expanded box');
      this.idVerificationService.setActivePerson(params.key);
      this.idVerificationService.setScrollToKey(params.key);
      this.idVerificationService.loadAdditionalPersonDetails(
        this.idVerificationService.getActiveProject(),
        params.key,
        false,
      );
      // ATTENTION: resolving verification status
      // will be handled by detectAdditionalPersonDetailsStatus
      // as it needs to happen after updating personDetails
      // so it doesn't get overwriten by personDetails update

      // EXECUTE ANY ADDITIONAL METHODS IF NECESSARY
      // clear the notification if it exists
      if (
        $(params.eventTarget.target)
          .closest(".result-container")
          .hasClass("orange-dot")
      ) {
        this.idVerificationService.clearPersonNotification(
          this.idVerificationService.getActiveProject(),
          params.key,
          $(params.eventTarget.target).closest(".result-container").attr("id"),
        );
      }
    }
    if (this.projectsType === "funds") {
      this.idVerificationService.setActiveInvestor(params.key);
      this.idVerificationService.setScrollToKey(params.key);
      this.idVerificationService.setAllowReuseProfile(true);
      this.idVerificationService.loadAdditionalInvestorDetail(
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActiveInvestor(),
        false,
      );
    }
    if (this.projectsType === "investor") {
      if (true) {
        this.isInvestorLoaded = true;
        this.idVerificationService.forceLoadingScreen = true;
        this.idVerificationService.setActiveInvestor(params.key);
        this.idVerificationService.setScrollToKey(params.key);
        this.idVerificationService.loadAdditionalInvestorDetailInvestor(
          this.idVerificationService.getActiveProject(),
          this.idVerificationService.getActiveInvestor(),
          false,
        );
      }
    }
  }

  /**
   * Function fired right after ID person creation
   */
  loadLinksAfterCreation(response?) {
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "Create a new person";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = { class: "organization-lightbox-wrapper" };
      let idVerificationLink = {};
      idVerificationLink = {
        type: "empty",
        params: {},
      };

      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "subtitle-alternate",
                class: " mt-2 subtitle-alternate-20",
                params: {
                  content: "Would you like to start a verification?",
                },
              },
              {
                type: "paragraph",
                class: "mt-1",
                params: {
                  content:
                    "After pressing start below, you will be able to choose which verification you would like to perform.",
                },
              },
              {
                type: "button_group",
                class: "",
                params: [
                  {
                    content: "Start",
                    display: true,
                    action: "generateTypesOfVerification",
                  },
                  {
                    content: "Close",
                    display: true,
                    action: "idonCloseLightbox",
                    class: "form-button-grey",
                  },
                ],
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayLoader = false;
      this.displayModal = true;
    }
  }

  loadLinksAfterConfiguration(url?) {
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      // refresh persons details from activeRepresentativeList since it was updated from BE
      this.idVerificationService.getActivePersonDetails(true);
      this.formClass = "";
      this.formType = "Share the link";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = "organization-lightbox-wrapper";
      let idVerificationLink = {};
      idVerificationLink = {
        type: "empty",
        params: {},
      };

      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "paragraph",
                class: " mt-2 result-paragraph",
                params: {
                  content:
                    "You can either copy the link below or enter an email address to send the link directly to the person being verified.",
                },
              },
              {
                type: "inputTextWithButton",
                class: "mt-3 text-with-button-dash",
                params: {
                  id: "generatedVerificationLink",
                  content: url,
                  classes: "generated-link generated-link-fund-share",
                  button: {
                    display: true,
                    content: "Copy",
                    class: "ml-2 mr-0 form-button-1",
                    action: "CopyLinkToClipboard",
                  },
                },
              },
              {
                type: "inputTextWithButton",
                class: "mt-3 text-with-button-dash",
                params: {
                  id: "sendAddressVerificationLink",
                  content:
                    this.idVerificationService.getActivePersonDetails() &&
                    this.idVerificationService.getActivePersonDetails()[
                      "record"
                    ]["email"]
                      ? this.idVerificationService.getActivePersonDetails()[
                          "record"
                        ]["email"]
                      : "",
                  classes: "generated-link generated-link-fund-share",
                  errorMessage: this.translate.instant(
                    "validation.validation5",
                  ),
                  placeholder: "Type in an email address",
                  button: {
                    display: true,
                    content: "Send",
                    class: "ml-2 mr-0 form-button-1",
                    action: "sendAddressVerificationLink",
                  },
                },
              },
              {
                type: "button",
                params: {
                  content: "Close",
                  display: true,
                  action: "idonCloseLightboxAndRefresh",
                },
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayLoader = false;
      this.displayModal = true;
    }
  }

  /**
   * handle click on request identity verification button
   * display request verification lightbox
   */
  OnRequestVerification(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.setActivePerson(
      $(event.target).closest(".result-container").attr("key"),
    );
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "Request verification";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = "organization-lightbox-wrapper";

      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "title",
                params: {
                  content: "Invite with url",
                },
              },
              {
                type: "paragraph",
                params: {
                  content:
                    'Generate a unique link to be shared with the person being verified. To copy the link, please press "Copy" after generating the link',
                },
              },
              {
                type: "button",
                params: {
                  content: "Generate unique link",
                  display: true,
                  action: "idvOnGenerateInviteUrl",
                },
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);

      this.displayModal = true;
    }
  }

  onRequestVerificationStepTwo(response) {
    let content =
      'Generate a unique link to be shared with the person being verified. To copy the link, please press "Copy" after generating the link.';
    // old version: link is presented as textual field and a copy icon
    // let cta: {} = {
    //   'type': 'paragraphIconRight',
    //   'params': {
    //     'content': response.data.record.url,
    //     'classes': 'generated-link',
    //     'display': true,
    //     'svgIcon': {
    //       'src': '/assets/images/ico-copy.svg',
    //       'class': 'svg-orange',
    //       'width': 24,  // required
    //       'height': 24,  // required
    //       'action': 'CopyLinkToClipboard'
    //     }
    //   }
    // };
    let cta = {};
    if (!response.status) {
      content =
        "We were not able to fetch the verification url for you please try again!";
      cta = {
        type: "button",
        params: {
          content: "Try again",
          display: true,
          action: "idvOnGenerateInviteUrl",
        },
      };
    } else {
      cta = {
        type: "inputTextWithButton",
        params: {
          id: "generatedVerificationLink",
          content: response["data"]["record"].url,
          classes: "generated-link",
          button: {
            display: true,
            content: "Copy",
            class: "ml-2 mr-0 form-button-1",
            action: "CopyLinkToClipboard",
          },
        },
      };
    }
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: <any>[
            {
              type: "title",
              params: {
                content: "Invite with url",
              },
            },
            {
              type: "paragraph",
              class: "mt-1",
              params: {
                paragraphClass: "mt-1",
                content: content,
              },
            },
          ],
        },
      ],
    };
    group.result_details[0].elements.push(cta);
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
  }

  /**
   * handle click on reveal identity verification link button
   * display reveal verification link lightbox and
   * autostart idvOnGenerateInviteUrl
   */
  OnRevealVerificationLink(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.setActivePerson(
      $(event.target).closest(".result-container").attr("key"),
    );
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      // force lazy refresh of expanded person
      this.idVerificationService.setForceLazyImageRefresh();
      this.formClass = "";
      this.formType = "Reveal verification link";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = "";

      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "title",
                params: {
                  content: "Retrieving the verification link",
                },
              },
              {
                type: "paragraph",
                class: "mt-1",
                params: {
                  paragraphClass: "mt-1",
                  content:
                    'Please stand by while we fetch the verification link for you. To copy the link, please press "Copy" after displaying the link',
                },
              },
              {
                type: "inputTextWithButton",
                class: "mt-3",
                params: {
                  id: "generatedVerificationLink",
                  content:
                    this.idVerificationService.getActivePersonDetails()[
                      "record"
                    ]["url"],
                  classes: "generated-link generated-link-fund-share",
                  button: {
                    display: true,
                    content: "Copy",
                    class: "ml-2 mr-0 form-button-1",
                    action: "CopyLinkToClipboard",
                  },
                },
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);

      this.displayModal = true;
      // this.idvOnGenerateInviteUrl();
    }
  }
  /**
   * handle click on cancel identity verification button
   * display confirmation lightbox
   */
  OnCancelVerification(event) {
    if (event.target) {
      event.preventDefault();
      event.stopPropagation();
      this.idVerificationService.setActivePerson(
        $(event.target).closest(".result-container").attr("key"),
      );
      this.activeVerificationKey = $(event.target)
        .closest(".result-container")
        .attr("verificationKey");
    }
    // set active verification key:
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "Cancel all verifications";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = "";
      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "title",
                class: "mb-2",
                params: {
                  titleClass: "mb-2",
                  content: "Are you sure you want to cancel?",
                },
              },
              {
                type: "paragraph",
                class: "mt-1",
                params: {
                  paragraphClass: "mt-1",
                  content:
                    "If you continue, all in progress verifications will be canceled. The link will also be canceled, and anyone visiting the link will see a message that the link has expired.",
                },
              },
              {
                type: "button_group",
                params: [
                  {
                    content: "Yes",
                    action: "OnConfirmCancelVerification",
                    display: true,
                  },
                  {
                    content: "No",
                    action: "onLightboxClose",
                    display: true,
                  },
                ],
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);

      this.displayModal = true;
    }
  }

  onDeleteVerification(event) {
    if (event.target) {
      event.preventDefault();
      this.idVerificationService.setActivePerson(
        $(event.target).closest(".result-container").attr("key"),
      );
    }
    this.displayLoader = false;
    // console.log(this.helper.formatISODate(person.dateOfBirth));
    this.formType = "Delete record";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    // build the content for the Create new person lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "paragraph",
              class: " mt-2 result-paragraph",
              params: {
                paragraphClass: "font-weight-bold",
                content:
                  "You are about to delete this record, and cancel all active verifications. All personal data will be lost. Are you sure that you want to continue?",
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Yes",
                  // 'id': 'accept',
                  action: "onDeletePersonAccept",
                  class: "form-button form-button-red",
                  display: true,
                },
                {
                  content: "No",
                  // 'id': 'rescan',
                  action: "onDeletePersonReject",
                  class: "form-button-1",
                  display: true, // TODO: check if user may edit the information
                },
              ],
            },
          ],
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  // functions do download all CDD images one by one
  onDownloadCDDImages(event, status) {
    let key;
    if (event) {
      console.log($(event.target).attr("key"));
      key = $(event.target).attr("key");
    }
    this.displayLoader = false;
    // console.log(this.helper.formatISODate(person.dateOfBirth));
    this.formType = "Download files";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    let imageSrc;
    let imageClass = "address-confirmation-image";
    let titleText = "Download profile files";
    let paragraphText;
    let showButtons;
    let showCloseButton;
    if (status === "loading") {
      imageSrc = "/assets/images/spinner-green.svg";
      imageClass = "address-confirmation-image ";
      paragraphText =
        "Please wait while the files are prepared for download. This may take a little while.";
      showButtons = false;
      titleText = "Downloading files";
    } else if (status === "success") {
      imageSrc = "/assets/images/request_screening.svg";
      paragraphText =
        "All files have been downloaded successfully. If the files have not downloaded, please check if your browser has blocked the download of multiple files. If so, please try again and allow the download of multiple fils when asked by your browser.";
      titleText = "Download complete";
      showButtons = false;
      showCloseButton = true;
    } else if (status === "error") {
      imageSrc = "/assets/images/icon-triangle-orange.svg";
      paragraphText =
        "There was an error while downloading the files. Please contact Mesh ID support for further help.";
      showButtons = false;
      showCloseButton = true;
    } else if (status === "empty") {
      imageSrc = "/assets/images/request_screening.svg";
      titleText = "No files";
      paragraphText = "There are currently no available files to download";
      showButtons = false;
      showCloseButton = true;
    } else {
      (imageSrc = "/assets/images/request_screening.svg"),
        (paragraphText =
          "You are about to download all the files related to this profile and all related profiles. This may take several minutes to complete. Your browser may ask you to allow the download of multiple files, please press Allow.");
      showButtons = true;
    }
    // build the content for the Create new person lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "image",
              params: {
                src: imageSrc,
                class: imageClass,
              },
            },
            {
              type: "h3",
              params: {
                content: titleText,
              },
            },
            {
              type: "paragraph",
              class: " mt-3",
              paragraphClass: "mt-3",
              params: {
                content: paragraphText,
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Download",
                  // 'id': 'accept',
                  action: "onDownloadProfileImages",
                  id: key,
                  class: "form-button-1",
                  display: showButtons,
                },
                {
                  content: "Close",
                  // 'id': 'rescan',
                  action: "onDeletePersonReject",
                  class: "form-button-1 form-button-grey",
                  display: showButtons,
                },
                {
                  content: "Close",
                  // 'id': 'rescan',
                  action: "onDeletePersonReject",
                  class: "form-button-1",
                  display: showCloseButton,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }
  onDownloadProfileImages(event) {
    this.onDownloadCDDImages(undefined, "loading");
    let key = $(event.target).attr("id");
    if (!key) {
      key = this.idVerificationService.getActiveInvestor();
    }

    this.idVerificationService.DownloadCDDFiles(key);
  }
  // functions do download all CDD images one by one
  onDeletePersonAccept(event) {
    this.displayLoader = true;
    this.idVerificationService.deletePerson(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
    );
  }

  onDeletePersonReject(event) {
    $(".close-form-button,#lightBoxCloseImage").trigger("click");
  }

  closeLightBox()
  {
    $(".close-form-button,#lightBoxCloseImage").trigger("click");
  }
  /**
   * handle click on cancel identity verification button
   * display confirmation lightbox
   */
  OnConfirmCancelVerification(event) {
    event.preventDefault();
    event.stopPropagation();
    this.displayLoader = true;
    this.idVerificationService.initiateCancelVerification(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      this.idVerificationService.getActivePersonDetails()["key"],
    );
    // reset the active verification key
    this.activeVerificationKey = undefined;
  }

  idonCloseLightbox() {
    $(
      ".form-container .close-form-button, .form-container .close-form-button img",
    ).trigger("click");
  }

  idonCloseLightboxAndRefresh() {
    // this.idVerificationService.retrieveActiveLegalRepresentatives(this.idVerificationService.getActiveProject(), true);
    this.idonCloseLightbox();
  }

  idvOnGenerateInviteUrl() {
    // send request to BE to generate an URL
    // use that url to replace either GENERATE button
    // or content of the GENERATE lightbox
    this.displayLoader = true;
    this.removeIdvImage = this.idVerificationService.getActivePerson();
    this.idVerificationService.generateVerificationUrl(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      false,
    );
  }

  idvOnGenerateInviteUrlAfterCreation() {
    // send request to BE to generate an URL
    // use that url to replace either GENERATE button
    // or content of the GENERATE lightbox
    this.displayLoader = true;
    this.removeIdvImage = this.idVerificationService.getActivePerson();
    this.idVerificationService.generateVerificationUrl(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      true,
    );
  }
  /**
   * handle click on request address verification button
   * display request verification lightbox
   */
  OnRequestAddressVerification(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.setActivePerson(
      $(event.target).closest(".result-container").attr("key"),
    );
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = {
        class:
          "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list",
        stepsTitle: "Provide address",
        stepsSubtitle: "",
        steps: [
          { name: "Choose type", count: 1, class: "form-step-active" },
          { name: "Configure", count: 2 },
        ],
        routeManipulation: true,
      };
      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "subtitle-alternate",
                class: "input-label-large increased-margin-top",
                params: {
                  content: "Provide address",
                },
              },
              // {
              //   'type': 'inputText',
              //   'params': {
              //     'label': 'Address to be verified',
              //     'id': 'AddressInputField'
              //   }
              // }
              {
                type: "radio",
                class: "font-weight-bold",
                params: {
                  id: "idAddressVerificationManual",
                  name: "idAddressVerificationRadio",
                  label: "I will provide the address",
                  value: "manualAddressVerificaiton",
                  changemethod: "addressVerificationTypeChange",
                  disabled: false,
                  selected: true,
                },
              },
              {
                type: "inputGoogleAutocomplete",
                class: "addressVerificationManualField ml-4",
                params: {
                  label: "Address to be verified",
                  id: "AddressInputField",
                  required: true,
                  placeholder: this.translate.instant(
                    "evidencePreview.addressLabel2",
                  ),
                },
              },
              {
                type: "inputText",
                class: "addressVerificationManualField ml-4",
                params: {
                  label: "Zip code",
                  id: "ZipInputField",
                  required: true,
                },
              },
              {
                type: "inputText",
                class: "addressVerificationManualField ml-4",
                params: {
                  label: "City",
                  id: "CityInputField",
                  required: true,
                },
              },
              {
                type: "inputText",
                class: "addressVerificationManualField ml-4",
                params: {
                  label: "Country",
                  id: "CountryInputField",
                  required: true,
                },
              },
              {
                type: "inputHidden",
                params: {
                  id: "LocationInputField",
                },
              },
              {
                type: "radio",
                class: "font-weight-bold",
                params: {
                  id: "idAddressVerificationInvestor",
                  class: "font-weight-bold",
                  name: "idAddressVerificationRadio",
                  label: "Address input by resident",
                  value: "investorAddressVerificaiton",
                  changemethod: "addressVerificationTypeChange",
                  disabled: false,
                },
              },
              {
                type: "subtitle-alternate",
                class: "input-label-large increased-margin-top ",
                params: {
                  content: "Verification letter",
                },
              },
              {
                type: "checkbox",
                class: "mt-3 ",
                params: {
                  id: "addressVerificaitonLetter",
                  name: "addressVerificaitonLetter",
                  label: "Verification letter",
                  value: "Include verification letter",
                },
              },
              {
                type: "subtitle-alternate",
                class: "input-label-large increased-margin-top",
                params: {
                  content: "Supporting document",
                },
              },
              {
                type: "checkbox",
                class: "mt-3",
                params: {
                  id: "addressSupportingDocument",
                  name: "addressSupportingDocument",
                  label: "Supporting document",
                  value: "Include supporting document",
                  changemethod: "addressVerificationDocumentChange",
                },
              },
              {
                type: "counter_group",
                class: "checkbox-borderless  mt-3 d-none",
                params: {
                  groupTitle: "",
                  checkboxes: [
                    {
                      type: "checkbox",
                      params: {
                        id: "UtilityBill",
                        name: "UtilityBill",
                        label: "Utility bill",
                        value: "UtilityBill",
                        oneDocument: true,
                      },
                    },
                    {
                      type: "checkbox",
                      params: {
                        id: "BankStatement",
                        name: "BankStatement",
                        label: "BankStatement",
                        value: "BankStatement",
                        oneDocument: true,
                      },
                    },
                    {
                      type: "checkbox",
                      params: {
                        id: "CreditCardStatement",
                        name: "CreditCardStatementN",
                        label: "Credit card statement",
                        value: "CreditCardStatement",
                        oneDocument: true,
                      },
                    },
                    {
                      type: "checkbox",
                      params: {
                        id: "InsuranceAgreement",
                        name: "InsuranceAgreementN",
                        label: "Insurance agreement",
                        value: "InsuranceAgreement",
                        oneDocument: true,
                      },
                    },
                    {
                      type: "checkbox",
                      params: {
                        id: "DriversLicence",
                        name: "DriversLicence",
                        label: "Driver's licence",
                        value: "DriversLicence",
                        oneDocument: true,
                      },
                    },
                  ],
                },
              },
              {
                type: "custom_evidecne_type_select",
                class: "counter-group d-none",
                params: {
                  checkboxes: [
                    {
                      type: "checkbox",
                      params: {
                        id: "Other",
                        htpmOnSaveNewRepresentativename: "OtherN",
                        label: "Custom documents",
                        value: "Other",
                        existingCustomTypes: 0,
                        counter: 0,
                      },
                    },
                  ],
                },
              },
              {
                type: "button",
                class: "mt-5",
                params: {
                  content: "Next",
                  display: true,
                  action: "idvGenerationStepTwo",
                  type: "submit",
                },
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);

      this.displayModal = true;
    }
  }
  addressVerificationTypeChange(event) {
    if ($(event.target).attr("id") === "idAddressVerificationInvestor") {
      $(".addressVerificationManualField").addClass("d-none");
      $(
        ".addressVerificationManualField input,.addressVerificationManualField select",
      ).attr("isrequired", false);
      $(
        ".addressVerificationManualField input,.addressVerificationManualField select",
      ).removeClass("invalid-input");
    } else {
      $(".addressVerificationManualField").removeClass("d-none");
      $(
        ".addressVerificationManualField input,.addressVerificationManualField select",
      ).attr("isrequired", true);
    }
  }
  addressVerificationDocumentChange(event) {
    $(".counter-group").toggleClass("d-none");
  }

  sendAddressVerificationLink(event) {
    let payload;
    payload = $(event.target)
      .closest(".input-text-button-wrapper")
      .find("#sendAddressVerificationLink")
      .val();
    $(event.target)
      .closest(".element-wrapper")
      .find(".button-error-field")
      .hide();
    if (
      payload !== "" &&
      payload.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      $(event.target)
        .closest(".element-wrapper")
        .find(".input-action-status")
        .text("Link sent!")
        .fadeIn();
      setTimeout(function () {
        $(event.target)
          .closest(".element-wrapper")
          .find(".input-action-status")
          .text("Link sent!")
          .fadeOut();
      }, 2500);
      // $('#sendAddressVerificationLink').val('');
      const path =
        "/api/projects/" +
        this.idVerificationService.getActiveProject() +
        "/people/" +
        this.idVerificationService.getActivePerson() +
        "/verifications/email";
      this.userService
        .postEndPoint(
          path,
          { email: payload },
          { responseType: "json" },
          { observe: "body" },
          "verification",
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response) => {
            if (typeof response === undefined) {
              return;
            }
            // update the address on the dashboard
            $('button[clickmethod="sendAddressVerificationLink"]')
              .siblings("input")
              .val(payload);
          },
          (error) => {
            console.log(
              "The following error occured when generating id verification link",
              error,
            );
          },
        );
    } else {
      $(event.target)
        .closest(".element-wrapper")
        .find(".button-error-field")
        .show();
    }
  }

  sendVerificationLink(event) {
    let payload;
    payload = $(event.target)
      .closest(".input-text-button-wrapper")
      .find("input")
      .val();
    let requestKey = $(event.target).attr("requestKey");
    if (!requestKey) {
      requestKey = this.idVerificationService.getActiveInvestor();
    }
    $(event.target)
      .closest(".element-wrapper")
      .find(".button-error-field")
      .hide();
    if (
      payload !== "" &&
      payload.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      $(event.target)
        .closest(".element-wrapper")
        .find(".input-action-status")
        .text("Link sent!")
        .fadeIn();
      setTimeout(function () {
        $(event.target)
          .closest(".element-wrapper")
          .find(".input-action-status")
          .text("Link sent!")
          .fadeOut();
      }, 2500);
      // $('#sendAddressVerificationLink').val('');
      const path =
        "/api/projects/" +
        this.idVerificationService.getActiveProject() +
        "/information/requests/" +
        requestKey +
        (this.idVerificationService.isInvestorType()
          ? "/verifications/email"
          : "/email");
      this.userService
        .postEndPoint(
          path,
          { email: payload },
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response) => {
            if (typeof response === undefined) {
              return;
            }
          },
          (error) => {
            console.log(
              "The following error occured when generating id verification link",
              error,
            );
          },
        );
    } else {
      $(event.target)
        .closest(".element-wrapper")
        .find(".button-error-field")
        .show();
    }
  }

  idvOnCloseLinkGeneration(event) {
    $("#lightBoxCloseImage").click();
  }
  /**
   * handle click on reveal address verification link button
   * display reveal verification link lightbox and
   * autostart idvOnGenerateInviteUrl
   */
  OnRevealAddressVerificationLink(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.setActivePerson(
      $(event.target).closest(".result-container").attr("key"),
    );
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      // force lazy refresh of expanded person
      // this.idVerificationService.setForceLazyImageRefresh();
      this.formClass = "";
      this.formType = "Reveal address verification link";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = "";

      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_address_verification",
        result_details: [
          {
            group_result_title: "request_new_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "title",
                params: {
                  content: "Retrieving the address verification link",
                },
              },
              {
                type: "paragraph",
                class: "mt-1",
                params: {
                  paragraphClass: "mt-1",
                  content:
                    'Please stand by while we fetch the address verification link for you. To copy the link, please press "Copy" after displaying the link',
                },
              },
              {
                type: "inputTextWithButton",
                class: "mt-3",
                params: {
                  id: "generatedVerificationLink",
                  content:
                    this.idVerificationService.getActivePersonDetails()[
                      "record"
                    ]["url"],
                  classes: "generated-link generated-link-fund-share",
                  button: {
                    display: true,
                    content: "Copy",
                    class: "ml-2 mr-0 form-button-1",
                    action: "CopyLinkToClipboard",
                  },
                },
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);

      this.displayModal = true;
    }
  }
  /**
   * handle click on cancel address verification button
   * display confirmation lightbox
   */
  OnCancelAddressVerification(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.setActivePerson(
      $(event.target).closest(".result-container").attr("key"),
    );
    // set active verification key:
    this.activeVerificationKey = $(event.target)
      .closest(".result-container")
      .attr("verificationKey");
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "Request verification";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = "";
      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "title",
                params: {
                  content: "Confirm action",
                },
              },
              {
                type: "paragraph",
                class: "mt-1",
                params: {
                  paragraphClass: "mt-1",
                  content: "Are you sure you want to cancel this verification?",
                },
              },
              {
                type: "button_group",
                params: [
                  {
                    content: "Yes",
                    action: "OnConfirmCancelAddressVerification",
                    display: true,
                  },
                  {
                    content: "No",
                    action: "onLightboxClose",
                    display: true,
                  },
                ],
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);

      this.displayModal = true;
    }
  }
  OnConfirmCancelAddressVerification(event) {
    event.preventDefault();
    event.stopPropagation();
    if (
      this.activePersonFrontImage !== undefined &&
      this.activePersonFrontImage !== ""
    ) {
      this.keepIdvImage = this.activePersonFrontImage;
    } else {
      this.removeIdvImage = this.idVerificationService.getActivePerson();
    }
    this.idVerificationService.initiateCancelAVerification(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      this.idVerificationService.getActivePersonDetails()["record"][
        "lastAddressVerification"
      ]["key"],
    );
    this.displayLoader = true;
  }
  /**
   * handle click on confirm cancel address verification button
   * display confirmation lightbox
   */
  // OnConfirmCancelAddressVerification(event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   this.cancelingVerification =  this.activeVerificationKey;
  //   this.idVerificationService.initiateCancelVerification(this.idVerificationService.getActiveProject(),
  //                                                         this.idVerificationService.getActivePerson(),
  //                                                         this.activeVerificationKey);
  //   this.displayLoader = true;
  //   // reset the active verification key
  //   this.activeVerificationKey = undefined;
  // }
  idvOnGenerateAddressVerificationUrl() {
    // if ($('.google-autocomplete').val() === ''
    //     // || $('#ZipInputField').val() === ''
    //     || $('#CityInputField').val() === ''
    //     || $('#CountryInputField').val() === ''
    // ) {
    //   alert('At the moment we only support verification of known addresses. Please fill all address information!');
    //   return;
    // }
    // send request to BE to generate an URL
    // use that url to replace either GENERATE button
    // or content of the GENERATE lightbox
    this.displayLoader = true;
    this.removeIdvImage = this.idVerificationService.getActivePerson();
    this.idVerificationService.generateAddressVerification(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      $(".google-autocomplete").val(),
      $("#ZipInputField").val(),
      $("#CityInputField").val(),
      $("#CountryInputField").val(),
      $("#LocationInputField").val(),
    );
  }
  idOnStartSAcreening() {
    // this.removeIdvImage = this.idVerificationService.getActivePerson();
    // this.idVerificationService.initiateScreening(
    //   this.idVerificationService.getActiveProject(),
    //   this.idVerificationService.getActivePerson());
    this.onScreenPersonInProgress();
  }
  /**
   * On edit existing person
   * @param event event
   */
  OnEditPerson(event) {
    if (event.target) {
      event.preventDefault();
      this.idVerificationService.setActivePerson(
        $(event.target).closest(".result-container").attr("key"),
      );
    }
    this.displayLoader = false;
    if (this.idVerificationService.getActivePersonDetails() === undefined) {
      alert("This person cannot be edited!");
      return;
    }
    const person =
      this.idVerificationService.getActivePersonDetails()["record"];
    // console.log(this.helper.formatISODate(person.dateOfBirth));

    this.formType = "Edit person";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    // build the content for the Create new person lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            // {
            //   'type': 'inputText',
            //   'params': {
            //     'label': 'Name of person',
            //     'id': 'lb-representative-name',
            //     'name': 'lbRepresentativeName',
            //     'required': true,
            //     'content': person.name,
            //     'minLength': 3,
            //   }
            // },
            {
              type: "inputText",
              params: {
                label: "First name",
                id: "lb-representative-fname",
                name: "lbRepresentativeName",
                required: true,
                content: person.firstName,
                maxLength: 100,
              },
            },
            {
              type: "inputText",
              params: {
                label: "Last name ",
                id: "lb-representative-lname",
                name: "lbRepresentativeName",
                required: true,
                content: person.lastName,
                maxLength: 100,
              },
            },
            {
              type: "inputText",
              params: {
                label: "E-mail",
                id: "lb-representative-email",
                name: "lbRepresentativeEmail",
                patternMatch:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                validatorMessage: this.translate.instant(
                  "validation.validation5",
                ),
                content: person.email ? person.email : "",
                required: false,
              },
            },
            {
              type: "input_date",
              params: {
                label: "Date of birth",
                id: "lb-representative-dob",
                name: "lbRepresentativeDob",
                startDate:
                  person.dateOfBirth !== undefined
                    ? this.helper.getAsStartDate(person.dateOfBirth)
                    : undefined,
                minDate: this.helper.getAsStartDate("01.01.1915"),
                maxDate: this.helper.getAdultAsMaxDate(),
                required: false,
                content: this.helper.getAsStartDate(person.dateOfBirth),
              },
            },
            {
              type: "inputText",
              params: {
                label: "Internal reference",
                id: "lb-internal-reference",
                name: "lbInternalReference",
                content: person.internalReference,
                required: false,
                maxLength: 100,
              },
            },
            {
              type: "button",
              params: {
                content: "Save",
                type: "submit",
                display: true,
                action: "htpmOnSaveExistingRepresentative",
              },
            },
          ],
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  /**
   * On adding tags person
   * @param event event
   */
  idOnAddTags(event?, tagInputValue?, focusAgain?) {
    this.displayLoader = false;

    if (event) {
      event.preventDefault();
    }
    if (this.idVerificationService.getActivePersonDetails() === undefined) {
      return;
    }
    const person =
      this.idVerificationService.getActivePersonDetails()["record"];

    this.formType = "Add tags";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    // build the content for the Create new person lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "inputText",
              class: "mt-2",
              params: {
                label:
                  "Type the tags that you want to add to " +
                  this.idVerificationService.getActivePersonDetails()["record"]
                    .name +
                  " below. You can add multiple tags separated by commas:",
                id: "addingTagInputText",
                name: "addingTagInputText",
                content: tagInputValue,
                onEnterChangeAction: "checkForNewTag",
                validatorMessage: "At least one tag is required",
                autofocus: true,
                maxLength: 20,
              },
            },
            {
              type: "person_tags",
              class: "mt-1 mb-4",
              params: {
                tags: this.idVerificationService.getActivePersonDetails()[
                  "record"
                ].tags,
                unaddedTags:
                  this.idVerificationService.getActivePersonDetails()["record"]
                    .unaddedTags,
                showAddButton: false,
                showTitle: false,
                suggestedTags: this.helper.filterSuggestedTags(
                  this.idVerificationService.getActiveLegalRepresentativesTopTags(),
                  this.idVerificationService.getActivePersonDetails()["record"]
                    .tags,
                  this.idVerificationService.getActivePersonDetails()["record"]
                    .unaddedTags,
                ),
                onAddPerson: true,
                allowDelete: true,
              },
            },
            {
              type: "button",
              class: "mt-2",
              params: {
                content: "Save",
                type: "submit",
                display: true,
                action: "htpmOnSaveTags",
              },
            },
          ],
        },
      ],
    };

    if (focusAgain) {
      setTimeout(() => {
        document.getElementById("addingTagInputText").focus();
      }, 250);
    }
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  idOnAddTagsInvestor(event?, tagInputValue?, focusAgain?) {


    this.displayLoader = false;

    if (event) {
      event.preventDefault();
    }
    if (this.idVerificationService.getActiveInvestorDetaisl() === undefined) {
      return;
    }
    const investor =
      this.idVerificationService.getActiveInvestorDetaisl();

    this.formType = "Add tags";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = {
      reloadfundAlternate: this.getActiveInvestor(),
    };
    // build the content for the Create new person lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "inputText",
              class: "mt-2",
              params: {
                label:
                  "Type the tags that you want to add below. You can add multiple tags separated by commas:",
                id: "addingTagInputText",
                name: "addingTagInputText",
                content: tagInputValue,
                onEnterChangeAction: "checkForNewTagCDD",
                validatorMessage: "At least one tag is required",
                autofocus: true,
                maxLength: 20,
              },
            },
            {
              type: "person_tags",
              class: "mt-1 mb-4",
              params: {
                tags: investor.tags,
                unaddedTags: this.idVerificationService.getActiveLegalRepresentativesUnadedTags(),
                showAddButton: false,
                showTitle: false,
                suggestedTags: this.helper.filterSuggestedTags(
                  this.idVerificationService.getActiveLegalRepresentativesTopTags(),
                  investor
                    .tags,
                    this.idVerificationService.getActiveLegalRepresentativesUnadedTags(),
                ),
                investor: true,
                allowDelete: true,
              },
            },
            {
              type: "button",
              class: "mt-2",
              params: {
                content: "Save",
                type: "submit",
                display: true,
                action: "htpmOnSaveTagsInvestor",
              },
            },
          ],
        },
      ],
    };

    if (focusAgain) {
      setTimeout(() => {
        document.getElementById("addingTagInputText").focus();
      }, 250);
    }
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  // smaller functionf or adding tags
  idOnAddSuggestedTag(event) {
    const tagInputValue = $("#addingTagInputText").val();
    const tagValue = event.target.getAttribute("tagValue");
    if (
      !this.idVerificationService.getActivePersonDetails()["record"].unaddedTags
    ) {
      if (
        !this.idVerificationService
          .getActivePersonDetails()
          ["record"].tags.includes(tagValue)
      ) {
        this.idVerificationService.getActivePersonDetails()[
          "record"
        ].unaddedTags = [tagValue];
      }
    } else {
      if (
        !this.idVerificationService
          .getActivePersonDetails()
          ["record"].tags.includes(tagValue)
      ) {
        this.idVerificationService
        .getActivePersonDetails()
          ["record"].unaddedTags.push(tagValue);
      }
    }
    this.idOnAddTags(undefined, tagInputValue);
  }

  // smaller functionf or adding tags
  idOnAddSuggestedTagCDD(event) {
    const tagInputValue = $("#addingTagInputText").val();
    const tagValue = event.target.getAttribute("tagValue");
    let unaded = this.idVerificationService.getActiveLegalRepresentativesUnadedTags();
    if (!unaded || unaded.length == 0) {
      if (
        !this.idVerificationService.getActiveInvestorDetaisl().tags?.includes(tagValue)
      ) {
        this.idVerificationService.setActiveLegalRepresentativesUnadedTags([tagValue]);
      }
    } else {
      if (
        !this.idVerificationService.getActiveInvestorDetaisl().tags?.includes(tagValue)
      ) {
        let unadded = this.idVerificationService.getActiveLegalRepresentativesUnadedTags();
        unadded.push(tagValue);
      }
    }
    this.idOnAddTagsInvestor(undefined, tagInputValue);
  }


  checkForNewTag(event) {
    let tagValue = event.target.value;
    const splitArray = tagValue.split(",");

    if (splitArray[splitArray.length - 1] === "") {
      splitArray.pop();
    }
    if (splitArray.length > 1) {
      splitArray.forEach((splitTag) => {
        splitTag = splitTag.trim();
        if (splitTag !== "") {
          if (
            !this.idVerificationService.getActivePersonDetails()["record"]
              .unaddedTags
          ) {
            if (
              !this.idVerificationService
                .getActivePersonDetails()
                ["record"].tags.includes(splitTag)
            ) {
              this.idVerificationService.getActivePersonDetails()[
                "record"
              ].unaddedTags = [splitTag];
            }
          } else {
            if (
              !this.idVerificationService
                .getActivePersonDetails()
                ["record"].tags.includes(splitTag) &&
              !this.idVerificationService
                .getActivePersonDetails()
                ["record"].unaddedTags.includes(splitTag)
            ) {
              this.idVerificationService
                .getActivePersonDetails()
                ["record"].unaddedTags.push(splitTag);
            }
          }
          this.idOnAddTags(undefined, undefined, true);
        }
      });
      event.target.value = "";
    } else {
      if (tagValue.endsWith(",")) {
        tagValue = tagValue.slice(0, -1);
        tagValue = tagValue.trim();
        if (this.helper.validateTag(tagValue)) {

          if (tagValue !== "") {
            if (
              !this.idVerificationService.getActivePersonDetails()["record"]
                .unaddedTags
            ) {
              if (
                !this.idVerificationService
                  .getActivePersonDetails()
                  ["record"].tags.includes(tagValue)
              ) {
                this.idVerificationService.getActivePersonDetails()[
                  "record"
                ].unaddedTags = [tagValue];
              }
            } else {
              if (
                !this.idVerificationService
                  .getActivePersonDetails()
                  ["record"].tags.includes(tagValue) &&
                !this.idVerificationService
                  .getActivePersonDetails()
                  ["record"].unaddedTags.includes(tagValue)
              ) {
                this.idVerificationService
                  .getActivePersonDetails()
                  ["record"].unaddedTags.push(tagValue);
              }
            }
            event.target.value = "";
            this.idOnAddTags(undefined, undefined, true);
          } else {
            event.target.value = "";
          }
        } else {
          event.target.value = ""
        }
      }
    }
  }

  checkForNewTagCDD(event) {
    let tagValue = event.target.value;
    const splitArray = tagValue.split(",");
    if (splitArray[splitArray.length - 1] === "") {
      splitArray.pop();
    }

    if (splitArray.length > 1) {
      splitArray.forEach((splitTag) => {
        splitTag = splitTag.trim();
        if (splitTag !== "") {
          const unadedArray =
            this.idVerificationService.getActiveLegalRepresentativesUnadedTags();
          if (!unadedArray) {
            this.idVerificationService.setActiveLegalRepresentativesUnadedTags([
              splitTag,
            ]);
          } else {
            if (!unadedArray.includes(splitTag)) {
              unadedArray.push(splitTag);
              this.idVerificationService.setActiveLegalRepresentativesUnadedTags(
                unadedArray,
              );
            }
          }
        }
      });
      event.target.value = "";
    } else {
      if (tagValue.endsWith(",")) {
        tagValue = tagValue.slice(0, -1);
        tagValue = tagValue.trim();
        if (this.helper.validateTag(tagValue)) {
          const unadedArray =
            this.idVerificationService.getActiveLegalRepresentativesUnadedTags();
          if (tagValue !== "") {
            if (!unadedArray) {
              this.idVerificationService.setActiveLegalRepresentativesUnadedTags([
                tagValue,
              ]);
            } else {
              if (!unadedArray.includes(tagValue)) {
                unadedArray.push(tagValue);
                this.idVerificationService.setActiveLegalRepresentativesUnadedTags(
                  unadedArray,
                );
              }
            }
            event.target.value = "";

            this.idOnAddTagsInvestor(undefined, undefined, true)
          } else {
            event.target.value = "";
          }
        } else {
          event.target.value = ""
        }
      }
    }
  }

  onOpenDiagnostics(event) {
    this.formType = "Diagnostics window";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    // build the content for the Create new person lightbox
    const verificationKey =
      this.idVerificationService.getActivePersonDetails()["key"];
    const verificationDetails =
      this.idVerificationService.getActivePersonDetails()["record"];
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "diagnostics_detail",
              class: "mt-2 scroll-bar",
              params: {
                name: verificationDetails.name
                  ? verificationDetails.name
                  : "Name not available",
                pairings: [
                  {
                    label: "User",
                    value: this.userService.getUserInfo()["username"],
                  },
                  { label: "VFS ID", value: verificationKey },
                  {
                    label: "VFE ID",
                    value: verificationDetails.lastVerification
                      ? verificationDetails.lastVerification.key
                      : undefined,
                  },
                  {
                    label: "SCE ID",
                    value: verificationDetails.lastScreening
                      ? verificationDetails.lastScreening.key
                      : undefined,
                  },
                  {
                    label: "Created on",
                    value: this.helper.formatISODate(
                      verificationDetails.createdOn,
                    ),
                  },
                  {
                    label: "Last modified",
                    value: this.helper.formatISODate(
                      verificationDetails.lastModified,
                    ),
                  },
                  {
                    label: "Last verification status",
                    value: verificationDetails.lastVerification
                      ? this.helper.parseTagNameForFE(
                          this.helper.parseBackendName(
                            verificationDetails.lastVerification.record.status,
                          ),
                        )
                      : "",
                  },
                ],
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Copy to clipboard",
                  // 'id': 'ReVerifyBtn',
                  action: "onCopyPairingsToClipboard",
                  class: "form-button-1",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  generateEvidenceStatus(investorDetails): string{
    let evidenceStatusString = '';
    let evidences = this.idVerificationService.generateAllEvidenceForProfile(investorDetails);
    let evidenceStatus = this.idVerificationService.generateProfileStatusInformationObject(evidences);
    let evidenceStatusArray = Object.entries(evidenceStatus)

    evidenceStatusArray.forEach( ([key, value], index) => {
      if( index > 0 )
        {
          if(index == evidenceStatusArray.length-1)
            {
              evidenceStatusString+= this.translate.instant("common.and") + " ";
            }
            else
            {
               evidenceStatusString+= ", "
            }
        }
        evidenceStatusString += value + " " + this.helper.translateEvidenceStatus(key).toLowerCase() + " ";
        if(index == evidenceStatusArray.length-1)
          {
            if(evidenceStatusArray.length > 1)
              {
                evidenceStatusString += this.translate.instant("common.items")
              }
              else
              {
                evidenceStatusString += this.translate.instant("common.item")
              }

          }
    })

    return evidenceStatusString;
  }

  generateEvidence(investorDetails):string
  {
    let evidenceString = '';
    let investorProfileDeepCopy = JSON.parse(
      JSON.stringify(investorDetails),
    );
    if(investorProfileDeepCopy["relatedParties"])
      {
        delete investorProfileDeepCopy["relatedParties"]
      }
    let evidence = this.idVerificationService.generateAllEvidenceForProfile(investorProfileDeepCopy);
    evidenceString = this.generateEvidenceText(evidence);

    return evidenceString;
  }
  generateEvidenceText(evidence): string
  {
    let evidenceString = '';
    evidence.forEach(e =>
      {
        if(e.type == "DigitalVerification")
          {
            evidenceString += this.helper.translateVerificationType(e.verificationType)
          }
          else if(e.type == "BASIC_REQUEST_INFO")
          {
            evidenceString += this.translate.instant("ARPCS.title6")
          }
          else
          {
            evidenceString += this.helper.translateEvidenceType(e.type);
          }
          evidenceString += " - " + e.key + " (" + e.status +")</br>" 
      }
    )
    return evidenceString; 
  }
  onOpenDiagnosticsOnBoard(event) {
    this.formType = "Diagnostics window";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const requestKey = this.getActiveInvestor();
    const projectKey = this.getActiveProject();
    let investorDetails = this.idVerificationService.getActiveInvestorDetaisl();
    if(investorDetails?.record)
      {
        investorDetails = investorDetails.record
      }
    const RPNo = investorDetails?.relatedParties?.length > 0 ? investorDetails.relatedParties.length : 0;
    const investorName = this.idVerificationService.generateEntityName(investorDetails.typeOfInvestor, investorDetails.investorFields);
    
    let evidenceStatusString = this.generateEvidenceStatus(investorDetails);
    let MainProfileEvidence = this.generateEvidence(investorDetails);

    let group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "diagnostics_detail",
              class: "mt-2 scroll-bar",
              params: {
                name: investorName
                  ? investorName
                  : "Name not available",
                pairings: [
                  {
                    label: "User",
                    value: this.userService.getUserInfo()["username"],
                  },
                  { label: "Project key", value: projectKey },
                  { label: "Request key", value: requestKey },
                  {
                    label: "Created on",
                    value: this.helper.formatISODate(
                      investorDetails.createOn,
                    ),
                  },
                  {
                    label: "Last modified",
                    value: this.helper.formatISODate(
                      investorDetails.updatedOn,
                    ),
                  },

                  {
                    label: "Profile overall status",
                    value: investorDetails.verificationSubjectStatus
                      ? this.helper.parseTagNameForFE(
                          this.helper.parseBackendName(
                            investorDetails.verificationSubjectStatus,
                          ),
                        )
                      : "",
                  },
                  { label: "Number of related parties", value: RPNo },
                  {
                    label: "Evidence status", value: evidenceStatusString, 
                  },
                  {
                    label: "Evidence", value: MainProfileEvidence, 
                  },
                ],
              },
            },
            
            {
              type: "button_group",
              params: [
                {
                  content: "Copy to clipboard",
                  action: "onCopyPairingsToClipboard",
                  class: "form-button-1",
                  display: true,
                },
                {
                  content: "Email support",
                  action: "sendEmailtoSupport",
                  class: "form-button-1",
                  display: true,
                },
                {
                  content: "Close",
                  action: "closeLightBox",
                  class: "form-button form-button-6",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };

    investorDetails["relatedParties"]?.forEach(rp =>
      {
        let evidence = this.idVerificationService.generateAllEvidenceForProfile(rp["record"]);
        let evidenceString = this.generateEvidenceText(evidence);
        group.result_details[0].elements[0].params["pairings"].push({
          label: rp.key,
          value: evidenceString,
        })

      }
    )

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  sendEmailtoSupport(event) {

    const diagnosticInfo = this.onCopyPairingsToClipboard(event);
    const requestKey = this.getActiveInvestor();
    const email = 'support@meshid.com'; 
    const subject = `I am facing an issue with or have question about one of my profiles (<${requestKey}>)`;
    const body = `
Hi Mesh ID Support,

Here is a short summary of my issue/question:

<type your issue or question here - keep it brief, and please do not include any personal information about individuals>

Thanks!

This is the diagnostic information to help you investigate:

${diagnosticInfo}`; 
  
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    window.location.href = mailtoLink;
  }


  onCopyPairingsToClipboard(event) {
    let returnValue = " Diagnostics";
    returnValue += "\n";
    returnValue += "\n";
    const pairings = $(".diagnostics-pairing");
    pairings.each(function () {
      returnValue += $(this).find(".diagnostics-label").html();
      returnValue += "\n";
      returnValue += $(this).find(".diagnostics-value").html();
      returnValue += "\n";
      returnValue += "\n";
    });
    this.helper.copyToClipboard(returnValue);
    $(event.target).html("Copied to clipboard");
    return returnValue;
  }

  /**
   * Initiate a screening
   * @param event event
   */
  onScreenPerson(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.setActivePerson(
      $(event.target).closest(".result-container").attr("key"),
    );
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "Request screening";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = "";
      // build the content for the Create new project lightbox
      const group = {
        section_class:
          "results d-flex flex-column justify-items-center p-0 custom_list_icons",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "image",
                params: {
                  src: "/assets/images/request_screening.svg",
                  class: "full-max-size",
                },
              },
              {
                type: "h3",
                params: {
                  content: "PEP and Sanctions screening",
                },
              },
              {
                type: "paragraph",
                params: {
                  content:
                    "The PEP screening will check against the C6 Acuris Risk Intelligent database.",
                },
              },
              {
                type: "paragraph",
                params: {
                  content:
                    "The sanctions screening will check against the following lists:",
                },
              },
              {
                type: "unordered_list_icons",
                listClass: "custom-list-icons",
                params: [
                  {
                    content: "OFAC",
                  },
                  {
                    content: "CFSP",
                  },
                  {
                    content: "Nationale Terrorismelijst (BKR)",
                  },
                  {
                    content: "UN Sanctions List",
                  },
                ],
              },
              {
                type: "button",
                params: {
                  content: "Start",
                  display: true,
                  action: "idOnStartSAcreening",
                },
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);

      this.displayModal = true;
    }
  }

  onScreenPersonInProgress() {
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "Request screening";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = "";

      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "form-screening-container",
        result_details: [
          {
            elements: [
              {
                type: "image",
                params: {
                  src: "/assets/images/loading_screen.gif",
                  class: "full-max-size",
                },
              },
              {
                type: "h3",
                params: {
                  content: "Please wait...",
                },
              },
              {
                type: "paragraph",
                params: {
                  content:
                    "We are performing the check. This usually takes less than a minute.",
                },
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayModal = true;
      this.removeIdvImage = this.idVerificationService.getActivePerson();
      this.idVerificationService.initiateScreening(
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActivePerson(),
      );
    }
  }

  onScreenPersonTimeout() {
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "Request screening";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = "";

      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "form-screening-container",
        result_details: [
          {
            elements: [
              {
                type: "h3",
                params: {
                  content: "This might take a while",
                },
              },
              {
                type: "paragraph",
                params: {
                  content:
                    "We will do the check and show the results in the legal representatives list.",
                },
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayModal = true;
      this.removeIdvImage = this.idVerificationService.getActivePerson();
      this.idVerificationService.initiateScreening(
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActivePerson(),
      );
    }
  }

  onScreenPersonResultsIn(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.setActivePerson(
      $(event.target).closest(".result-container").attr("key"),
    );
    if (
      this.idVerificationService.getActivePerson() !== undefined &&
      this.idVerificationService.getActivePerson() !== null
    ) {
      this.formClass = "";
      this.formType = "Request screening";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = "";

      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        result_details: [
          {
            group_result_title: "request_new_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "h3",
                class: "mt-0",
                params: {
                  content: "The results are in.",
                },
              },
              {
                type: "tabbed_content",
                class: "hide-tabs",
                params: {
                  tabs: [
                    // (!result.company.partial) ?
                    {
                      id: "shareholders",
                      status: "active",
                      content: [
                        { key: "Last name", content: "mik" }, // '22517301',
                        { key: "Date of Birth", content: "22 02 10" }, // 'Rotterdam',
                      ],
                    },
                  ],
                },
              },
              {
                type: "unordered_list_icons",
                listClass: "custom-list-icons-results",
                params: [
                  {
                    content: "PEP",
                  },
                  {
                    content: "Sanctions",
                  },
                ],
              },
              {
                type: "paragraph",
                params: {
                  content: "The check did not produce any hits.",
                },
              },
              {
                type: "button",
                params: {
                  content: "Close",
                  display: true,
                  action: "idOnStartSAcreening",
                },
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayModal = true;
    }
  }

  setMinEndDate() {}
  htpmOnGenerateReport() {
    $(".form-loading-screen").removeClass("d-none");
    $(".form-loading-screen").addClass("d-flex");
    $(".button-error-field").hide();

    this.displayLoader = true;
    const activeProject = this.idVerificationService.getActiveProjectSettings()
      ? this.idVerificationService.getActiveProjectSettings()
      : this.idVerificationService.getActiveProject();
    const dateFrom =
      $("#reportGenerationDateFrom > input").val() !== ""
        ? this.helper.getTimestamp($("#reportGenerationDateFrom > input").val())
        : undefined;
    const dateTo =
      $("#reportGenerationDateTo > input").val() !== ""
        ? this.helper.getTimestampEndDay(
            $("#reportGenerationDateTo > input").val(),
          )
        : undefined;
    const tags = [];
    $(".purposes-checkbox-list .purpose-checkbox:checked").each(function (tag) {
      tags.push($(this).attr("data-purposeid"));
    });
    this.idVerificationService.generateUsageReport(
      activeProject,
      dateFrom,
      dateTo,
      tags,
    );
  }

  htpmOnGenerateStatusReport() {
    $(".form-loading-screen").removeClass("d-none");
    $(".form-loading-screen").addClass("d-flex");
    $(".button-error-field").hide();
    this.displayLoader = true;
    const activeProject = this.idVerificationService.getActiveProjectSettings()
      ? this.idVerificationService.getActiveProjectSettings()
      : this.idVerificationService.getActiveProject();
    this.idVerificationService.generateStatusReport(activeProject);
  }

  // actually save the edited information about a person
  htpmOnSaveExistingRepresentative() {
    if ($("#lb-representative-name").val() === "") {
      alert("You must enter a representative name!");
      return;
    }
    if (
      $("#lb-representative-email").val() !== "" &&
      !this.helper.validateEmail($("#lb-representative-email").val())
    ) {
      alert(
        "You must use a valid email address! If you don't know person's email address leave this field blank.",
      );
      return;
    }

    this.displayLoader = true;
    this.removeIdvImage = this.idVerificationService.getActivePerson();
    // console.log(); return;
    this.idVerificationService.editLegalRepresentatives(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      {
        firstName: $("#lb-representative-fname").val(),
        lastName: $("#lb-representative-lname").val(),
        email: this.helper.validateEmail($("#lb-representative-email").val())
          ? $("#lb-representative-email").val()
          : undefined,
        internalReference: $("#lb-internal-reference").val(),
        dateOfBirth:
          $("#lbRepresentativeDob > input").val() !== ""
            ? this.helper.prepareDateForApi(
                $("#lbRepresentativeDob > input").val(),
              )
            : undefined,
        relationship:
          $("#lb-representative-relationship").find(":selected").val() !== -1
            ? $("#lb-representative-relationship").find(":selected").val()
            : undefined,
      },
    );
  }

  htpmOnSaveTags() {
    // get the value in the input
    const tagFromInput = $("#addingTagInputText").val()?.trim();
    // get the value from the unadded tags
    let tagsArray = [];
    if (
      this.idVerificationService.getActivePersonDetails()["record"].unaddedTags
    ) {
      tagsArray = this.idVerificationService
        .getActivePersonDetails()
        ["record"].unaddedTags.slice();
    }
    tagsArray = tagsArray.map((tag) => tag.trim());
    // add the value from the input
    if (tagFromInput !== "") {
      tagsArray.push(tagFromInput);
    }
    this.displayLoader = true;
    const tagsPayload = { tags: tagsArray };
    this.idVerificationService.getActivePersonDetails()["record"].unaddedTags =
      undefined;
    // format tags so empty spaces are replaced with underscore
    this.idVerificationService.addPersonTags(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      this.idVerificationService.getActivePersonDetails(),
      tagsPayload,
      false,
    );
  }

  async htpmOnSaveTagsInvestor() {
    // get the value in the input
    const tagFromInput = $("#addingTagInputText").val()?.trim();
    // get the value from the unadded tags
    if(tagFromInput  && !this.helper.validateTag(tagFromInput)) {
      $("#addingTagInputText").val('');
      return;
    }
    let tagsArray = [];
    if (
      this.idVerificationService.getActiveLegalRepresentativesUnadedTags()
    ) {
      tagsArray = this.idVerificationService.getActiveLegalRepresentativesUnadedTags();
    }
    tagsArray = tagsArray.map((tag) => tag.trim());
    // add the value from the input
    if (tagFromInput !== "") {
      tagsArray.push(tagFromInput);
    }
    const tagsPayload = { tags: tagsArray };

    if (!tagsPayload || !tagsPayload.tags || tagsPayload.tags.length === 0) {
      // this.editPerson.next({ status: true, data: personDetails });
      alert('Cannot add an empty tag');
      return;
    }
    this.displayLoader = true;

    this.idVerificationService.setActiveLegalRepresentativesUnadedTags([]);
    // format tags so empty spaces are replaced with underscore
    try{
      await this.idVerificationService.addPersonTagsInvestor(
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActiveInvestor(),
        this.idVerificationService.getActivePersonDetails(),
        tagsPayload,
        false,
      );
      this.displayLoader = false;

    } catch(error)
    {
      console.log(error)
    }
  }

  /**
   * generate and download PDF
   */
  OnGeneratePDF(event, replaceLightboxLoader = true, DV = false) {
    event.preventDefault();
    // set the loading animation:
    this.idVerificationService.setActiveVerification(
      $(".result-container.expanded").attr("verificationKey"),
    );
    if (!replaceLightboxLoader) {
      $(
        '.result-container[verificationkey="' +
          this.idVerificationService.getActiveVerification() +
          '"] .pdf-gen-button',
      ).html(
        '<img src="/assets/images/ajax-loader-white.gif" class="pdf-gen-icon" style="padding: 0 26px;">',
      );
    } else {
      $(event.target)
        .closest("a")
        .addClass("pdf-gen-loading")
        .find("img")
        .attr("src", "/assets/images/loader-small.gif");
      $(".pdf-gen-loading span").text("Generating PDF Report ...");
    }
    if (this.idVerificationService.getPDFStatus().status !== "IN_PROGRESS") {
      if (!DV) {
        // this.idVerificationService.generateIdVerificationPDF();
        this.idVerificationService.generatePDFBackend(
          this.idVerificationService.getActiveVerification()
        );
      } else {
        this.idVerificationService.generateDVIdVerificationPDF();
      }
    }
  }

  downloadPreviewFiles(event, isCustomEvidenceType) {
    const fileHtmlElements = $(".downloadble-preview-file");
    for (let i = 0; i < fileHtmlElements.length; i++) {
      this.helper.downloadingDocument(
        fileHtmlElements[i].src,
        $(fileHtmlElements[i]).attr("filename"),
      );
    }
  }

  OnGenerateDVAddressVerificationPDF(event, replaceLightboxLoader = true) {
    // check if files are finished parsing and if we can proceed with download
    // if there are files that need to be downloaded but aren't finished parsing
    // prevent the pdf generation
    if (
      !this.idVerificationService.getActiveDigitalVerificationDetails()
        .filesFinishedParsing
    ) {
      return;
    }
    this.OnGenerateAddressVerificationPDF(event, true, true);
  }

  /**
   * generate and download ID PDF on CCD Sharing fund side
   */
  OnGenerateDVIdentityPDF(event, replaceLightboxLoader = true) {
    this.OnGeneratePDF(event, true, true);
  }

  /**
   * generate and download address verification PDF
   */
  OnGenerateAddressVerificationPDF(
    event,
    replaceLightboxLoader = true,
    DV = false,
  ) {
    event.preventDefault();
    // set the loading animation:
    this.idVerificationService.setActiveAddressVerification(
      $(".result-container.expanded").attr("addressVerificationKey"),
    );
    // console.log('event', event.target);
    // return;
    if (!replaceLightboxLoader) {
      $(
        '.result-container[addressVerificationKey="' +
          this.idVerificationService.getActiveAddressVerification() +
          '"] .pdf-gen-button.address-verification',
      ).html(
        '<img src="/assets/images/ajax-loader-white.gif" class="pdf-gen-icon" style="padding: 0 26px;">',
      );
    } else {
      $(event.target)
        .closest("a")
        .addClass("pdf-gen-loading")
        .find("img")
        .attr("src", "/assets/images/loader-small.gif");
      $(".pdf-gen-loading span").text("Generating PDF Report ...");
    }
    // this.idVerificationService.setAddressPdfGenerationStatus(true);
    if (
      this.idVerificationService.getPDFStatus().status !== "IN_PROGRESS" &&
      this.idVerificationService.getPDFStatus().status !== "ERROR"
    ) {
      if (!DV) {
        // this.idVerificationService.generateAddressVerificationPDF();
        this.idVerificationService.generatePDFBackend
        (this.idVerificationService.getActiveAddressVerification());
      } else {
        this.idVerificationService.generateDVAddressVerificationPDF();
      }
    }
  }

  onConfirmAddress() {
    this.idVerificationService.previewWindowPopUp.next("acceptAddressIDV");
    $(".expire-lightbox").removeClass("d-none");
  }

  OnConfirmAddressConfirm(event) {
    $(".expire-lightbox .loading-icon").removeClass("d-none");
    const activePerson = this.idVerificationService
      .getActivePersons()
      .find(
        (person) => person.key === this.idVerificationService.getActivePerson(),
      );
    if (event) {
      event.preventDefault();
    }
    // set the loading animation:
    this.idVerificationService.confirmAV(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      activePerson["record"]["lastAddressVerification"]["key"],
    );
  }

  onRejectAddress(event, replaceLightboxLoader = true) {
    this.idVerificationService.previewWindowPopUp.next("rejectAddressIDV");
    $(".expire-lightbox").removeClass("d-none");
  }

  onRejectAddressConfirm(event, replaceLightboxLoader = true) {
    $(".expire-lightbox .loading-icon").removeClass("d-none");
    const activePerson = this.idVerificationService
      .getActivePersons()
      .find(
        (person) => person.key === this.idVerificationService.getActivePerson(),
      );
    if (event) {
      event.preventDefault();
    }
    // set the loading animation:
    this.idVerificationService.rejectAV(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      activePerson["record"]["lastAddressVerification"]["key"],
    );
  }

  OnConfirmAPS(event, replaceLightboxLoader = true) {
    this.idVerificationService.previewWindowPopUp.next("acceptAPSIDV");
    $(".expire-lightbox").removeClass("d-none");
  }

  OnConfirmAPSConfirm(event, replaceLightboxLoader = true) {
    $(".expire-lightbox .loading-icon").removeClass("d-none");
    const activePerson = this.idVerificationService
      .getActivePersons()
      .find(
        (person) => person.key === this.idVerificationService.getActivePerson(),
      );
    if (event) {
      event.preventDefault();
    }
    // set the loading animation:
    this.idVerificationService.confirmAPS(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      activePerson["record"]["lastApsVerification"]["key"],
    );
  }

  onRejectAPS(event, replaceLightboxLoader = true) {
    this.idVerificationService.previewWindowPopUp.next("rejectAPSIDV");
    $(".expire-lightbox").removeClass("d-none");
  }

  onRejectAPSConfirm(event, replaceLightboxLoader = true) {
    $(".expire-lightbox .loading-icon").removeClass("d-none");
    const activePerson = this.idVerificationService
      .getActivePersons()
      .find(
        (person) => person.key === this.idVerificationService.getActivePerson(),
      );
    if (event) {
      event.preventDefault();
    }
    this.idVerificationService.rejectAPS(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      activePerson["record"]["lastApsVerification"]["key"],
    );
  }

  /**
   * display history of verifications for active legal representative
   */
  OnDisplayHistory() {
    alert(
      "TODO: Design/html+css/angular for displaying history of verifications",
    );
  }

  /**
   * on click copy link
   */
  CopyLinkToClipboard() {
    this.helper.copyToClipboard($("#generatedVerificationLink").val());
    $("#generatedVerificationLink")
      .siblings(".input-action-status")
      .text("Successfully copied!")
      .fadeIn();
    setTimeout(function () {
      $(".input-action-status").fadeOut();
    }, 2500);
  }

  CopyLinkToClipboard2(event) {
    const inputEl = $(event.target).siblings("input");
    const statusEl = inputEl.siblings(".input-action-status");
    this.helper.copyToClipboard(inputEl.val());
    statusEl.text("Successfully copied!").fadeIn();
    setTimeout(function () {
      statusEl.fadeOut();
    }, 2500);
  }

  /**
   * on click copy address link
   */
  CopyAddressLinkToClipboard() {
    this.helper.copyToClipboard($("#generatedAddressVerificationLink").val());
    $(".input-action-status").text("Successfully copied!").fadeIn();
    setTimeout(function () {
      $(".input-action-status").fadeOut();
    }, 2500);
  }

  /**
   * for close button
   */
  OnCloseLightboxWithButton() {
    this.displayModal = false;
  }

  /**
   * for close button
   */
  OnCloseLightboxTopWithButton() {
    this.topDisplayModal = false;
  }

  /**
   * handle click on preview image
   * display image lightbox
   */
  // DISPLAYING ID VERIFICATION IMAGE
  OnDisplayImageInLightbox(
    event,
    frontImage?,
    backImage?,
    verificationDetails?,
  ) {
    this.displayLoader = false;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const imgSrc = this.activePersonFrontImage;
    let isLoading = false;
    this.formType = "Identity verification";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = { class: "document-preview-lightbox" };

    // if we are waiting for the back image to load set loading state
    if (frontImage === undefined && backImage === undefined) {
      isLoading = true;
      // load the back image
      this.idVerificationService.loadPersonImagesAndData(
        this.idVerificationService.getActiveProject(),
        "",
        "",
        this.idVerificationService.getActivePerson(),
        this.idVerificationService.getActivePersonDetails()["record"][
          "lastVerification"
        ]["key"],
        "front",
      );
    }

    const documentImages = [];

    if (frontImage) {
      documentImages.push({
        filename:
          this.idVerificationService.getActivePersonDetails()["record"][
            "name"
          ] + " front.jpg",
        type: "image",
        data: frontImage,
      });
    }
    if (backImage) {
      documentImages.push({
        filename:
          this.idVerificationService.getActivePersonDetails()["record"][
            "name"
          ] + " back.jpg",
        type: "image",
        data: backImage,
      });
    }
    let statuses;
    const isVerified =
      verificationDetails &&
      this.idVerificationService.getActivePersonDetails()["record"]
        .lastVerification &&
      this.idVerificationService.getActivePersonDetails()["record"]
        .lastVerification.record.status === "verified";
    if (isVerified) {
      const completedAt = this.helper.formatISODate(
        verificationDetails["transaction"].updatedAt,
        this.helper.getPdfFormatDate(),
        false,
      );
      const status = `Individual has been successfully verified on <strong>${completedAt}</strong>`;
      statuses = [
        {
          icon: "/assets/images/check.svg",
          color: "",
          title: "",
          status: status,
        },
      ];
    }
    const status =
      verificationDetails &&
      this.idVerificationService.getActivePersonDetails()["record"]
        .lastVerification &&
      this.idVerificationService.getActivePersonDetails()["record"]
        .lastVerification.record.status;
    if (
      this.helper.getSuitableFailedIDVerificationStatuses().includes(status)
    ) {
      const completedAt = this.helper.formatISODate(
        verificationDetails["transaction"].updatedAt,
        this.helper.getPdfFormatDate(),
        false,
      );
      const status = `The verification has failed on ${completedAt}`;
      statuses = [
        {
          icon: "/assets/images/icon-triangle-orange.svg",
          color: "",
          title: "",
          status: status,
        },
      ];
    }
    const group = {
      lightbox_type: "flex-width",
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "preview_image",
      result_details: [
        {
          group_result_title: "preview_image_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number

          elements: [
            {
              type: "evidencePreviewAlternate",
              class: isLoading ? "isLoading" : "",
              params: {
                documentImages: documentImages,
                noImages: !documentImages.length,
                pdfDownloadButton: this.permissionIDVerification,
                imageDownloadButton: this.permissionIDVerification,
                pdfDownloadFuction: "OnGeneratePDF",
                hideArrows: backImage ? false : true,
                fieldTypes: verificationDetails
                  ? this.idVerificationService.parseIDVFields(
                      verificationDetails,
                    )
                  : undefined,
                statsClass: "id-preview-title",
                showStats: true,
                statsTitle: this.idVerificationService.getActivePersonDetails()
                  ? this.idVerificationService.getActivePersonDetails()[
                      "record"
                    ].name
                  : undefined,
                statuses: statuses,
                basicFieldsPreview:
                  this.projectsType === "funds" ? true : false,
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
    if (!isLoading) {
      const that = this;
      setTimeout(function () {
        {
          $(".document-slide-container-imgs").zoom({
            on: "click",
            magnify: "1",
            onZoomIn: that.onZoomIn,
            onZoomOut: that.onZoomOut,
          });
          $(".document-fields-basicFields").niceScroll({
            cursorcolor: "#105f6b",
            cursorwidth: "4px",
            cursoropacitymin: 1,
            railpadding: { top: 0, right: 0, left: 0, bottom: 0 },
          });
        }
      }, 250);
    }
  }

  async OnDisplayImageAddressInLightbox(event, details) {
    this.displayLoader = false;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const avDetails =
      this.idVerificationService.getActivePersonDetails()["record"]
        .lastAddressVerification;
    // this gets updated after accept or reject so we use this data to decide what buttons to display
    const avDetailsPersons = this.idVerificationService
      .getActivePersons()
      .find(
        (person) => person.key === this.idVerificationService.getActivePerson(),
      )["record"].lastAddressVerification;

    // set active address verification
    this.idVerificationService.setActiveAddressVerification(
      $(".result-container.expanded").attr("addressVerificationKey"),
    );
    const imgSrc = this.activePersonFrontImage;
    let isLoading = false;
    const documentImages = [];
    this.formType = this.idVerificationService.getActivePersonDetails()
      ? this.idVerificationService.getActivePersonDetails()["record"].name
      : "Image preview";
    this.formType = avDetails.record.idinResults
      ? "Address verification (iDIN)"
      : avDetails.record.verificationPresets.verificationLetterRequested
      ? "Address verification (Enhanced)"
      : "Address verification (Standard)";
    this.formTypeAlignment = "center";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = { class: "document-preview-lightbox" };
    // see if there are any documents, fetch them
    if (avDetails.record.documents) {
      isLoading = true;
      if (details === undefined) {
        // load the images
        this.idVerificationService.loadPersonAddressImages(
          this.idVerificationService.getActiveProject(),
          "",
          "",
          this.idVerificationService.getActivePerson(),
          this.idVerificationService.getActivePersonDetails()["record"][
            "lastAddressVerification"
          ]["key"],
          avDetails.record.documents,
        );
      } else {
        isLoading = false;
        for (let i = 0; i < details.length - 1; i++) {
          if (details[i].headers.get("content-type") === "application/pdf") {
            documentImages.push({
              type: "pdf",
              data:
                "data:application/pdf;base64," +
                this.helper.toBase64(details[i].body.data),
              filename: details[i].headers.get("filename"),
            });
          } else if (details[i].headers.get("content-type") === "image/png") {
            documentImages.push({
              type: "image",
              data:
                "data:image/png;base64," +
                this.helper.toBase64(details[i].body.data),
              filename: details[i].headers.get("filename"),
            });
          } else if (details[i].headers.get("content-type") === "image/jpeg") {
            documentImages.push({
              type: "image",
              data:
                "data:image/jpg;base64," +
                this.helper.toBase64(details[i].body.data),
              filename: details[i].headers.get("filename"),
            });
          }
        }
      }
    }

    // get the fields
    let fields;
    if (!isLoading) {
      fields = details
        ? await this.idVerificationService.parseAddressFields(
            details[details.length - 1].record,
          )
        : await this.idVerificationService.parseAddressFields(avDetails.record);
    }
    const group = {
      lightbox_type: "flex-width",
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "preview_image",
      result_details: [
        {
          group_result_title: "preview_image_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number

          elements: [
            {
              type: "evidencePreviewAlternate",
              class: isLoading ? "isLoading" : "",
              params: {
                documentImages: documentImages,
                pdfDownloadButton: true,
                pdfDownloadFuction: "OnGenerateAddressVerificationPDF",
                hideArrows: documentImages.length <= 1 ? true : false,
                noImages: documentImages.length === 0 ? true : false,
                statuses:
                  this.idVerificationService.resolveAvDetailsStatuses(
                    avDetailsPersons,
                  ),
                buttons: this.permissionAddressVerification
                  ? this.idVerificationService.resolveAvDetailsButtons(
                      avDetailsPersons,
                    )
                  : undefined,
                fieldTypes: fields,
                statsClass: "id-preview-title",
                basicFieldsPreview:
                  this.projectsType === "funds" ? true : false,
                showStats: true,
                statsTitle: this.idVerificationService.getActivePersonDetails()
                  ? this.idVerificationService.getActivePersonDetails()[
                      "record"
                    ].name
                  : undefined,
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
    if (!isLoading) {
      const that = this;
      setTimeout(function () {
        $(".document-slide-container-imgs").zoom({
          on: "click",
          magnify: "1",
          onZoomIn: that.onZoomIn,
          onZoomOut: that.onZoomOut,
        });
        $(".document-fields-basicFields").niceScroll({
          cursorcolor: "#105f6b",
          cursorwidth: "4px",
          cursoropacitymin: 1,
          railpadding: { top: 0, right: 0, left: 0, bottom: 0 },
        });
      }, 250);
    }
  }

  // helper function just serves to show the loading screen
  // the loading screen doesn't work because the function is async
  OnDisplayImageAddressInLightboxLoading(event, details) {
    this.displayLoader = false;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const avDetails =
      this.idVerificationService.getActivePersonDetails()["record"]
        .lastAddressVerification;
    // this gets updated after accept or reject so we use this data to decide what buttons to display
    const avDetailsPersons = this.idVerificationService
      .getActivePersons()
      .find(
        (person) => person.key === this.idVerificationService.getActivePerson(),
      )["record"].lastAddressVerification;

    // set active address verification
    this.idVerificationService.setActiveAddressVerification(
      $(".result-container.expanded").attr("addressVerificationKey"),
    );
    const imgSrc = this.activePersonFrontImage;
    let isLoading = false;
    const documentImages = [];
    this.formType = this.idVerificationService.getActivePersonDetails()
      ? this.idVerificationService.getActivePersonDetails()["record"].name
      : "Image preview";
    this.formType = avDetails.record.idinResults
      ? "Address verification (iDIN)"
      : avDetails.record.verificationPresets.verificationLetterRequested
      ? "Address verification (Enhanced)"
      : "Address verification (Standard)";
    this.formTypeAlignment = "center";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = { class: "document-preview-lightbox" };
    // see if there are any documents, fetch them
    if (true) {
      isLoading = true;
      // load the images
      this.idVerificationService.loadPersonAddressImages(
        this.idVerificationService.getActiveProject(),
        "",
        "",
        this.idVerificationService.getActivePerson(),
        this.idVerificationService.getActivePersonDetails()["record"][
          "lastAddressVerification"
        ]["key"],
        avDetails.record.documents,
      );
    }
    const group = {
      lightbox_type: "flex-width",
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "preview_image",
      result_details: [
        {
          group_result_title: "preview_image_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number

          elements: [
            {
              type: "evidencePreviewAlternate",
              class: isLoading ? "isLoading" : "",
              params: {
                basicFieldsPreview: true,
                pdfDownloadButton: true,
                documentImages: documentImages,
                pdfDownloadFuction: "OnGenerateAddressVerificationPDF",
                // 'fieldTypes' : fields,
                statsClass: "id-preview-title",
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  OnGenerateEnhancedAvRequest(event, replaceLightboxLoader) {
    console.log(event);
    const activePerson = this.idVerificationService
      .getActivePersons()
      .find(
        (person) => person.key === this.idVerificationService.getActivePerson(),
      );
    if (event) {
      event.preventDefault();
      // set the loading animation:
    }
    $(event.target)
      .closest("a")
      .addClass("pdf-gen-loading")
      .find("img")
      .attr("src", "/assets/images/loader-small.gif");
    $(".pdf-gen-loading span").text("Configuring the enhanced check...");
    this.idVerificationService.enhancedCheck(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActivePerson(),
      activePerson["record"]["lastAddressVerification"]["key"],
    );
  }

  onZoomIn() {
    $(".document-slide-container-imgs").css("cursor", "zoom-out");
  }
  onZoomOut() {
    $(".document-slide-container-imgs").css("cursor", "zoom-in");
  }

  // DISPLAY ADVERSE MEDIA/PEP
  OnDisplayAPSInLightbox(event, apsDetails?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const activePerson = this.idVerificationService
      .getActivePersons()
      .find(
        (person) => person.key === this.idVerificationService.getActivePerson(),
      );
    const imgSrc = this.activePersonFrontImage;
    let isLoading = false;
    this.formType = "Compliance checks";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = {
      class: "document-preview-lightbox",
      reloadAPS: this.idVerificationService.getActivePerson(),
    };

    // if we are waiting for the back image to load set loading state
    if (apsDetails === undefined) {
      isLoading = true;
      this.idVerificationService.getAps(
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActivePerson(),
        activePerson["record"]["lastApsVerification"]["key"],
      );
    }

    this.localApsDetail = apsDetails
      ? this.idVerificationService.parseAPSData(apsDetails)
      : undefined;
    const verificationStatuses = [];
    // parse the verificaiton statuses
    const group = {
      lightbox_type: "flex-width",
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "preview_image",
      result_details: [
        {
          group_result_title: "preview_image_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number

          elements: [
            {
              type: "evidencePreviewAlternate",
              class: isLoading ? "isLoading" : "",
              params: {
                // 'documentImages' : documentImages,
                // 'pdfDownloadButton' : true,
                // 'pdfDownloadFuction' : 'OnGeneratePDF',
                apsFields: this.localApsDetail,
                noImages: true,
                verificationStatuses: this.localApsDetail
                  ? this.localApsDetail.verificationStatuses
                  : undefined,
                showStats: true,
                statsClass: "id-preview-title",
                basicFieldsPreview: true,
                statsTitle: activePerson
                  ? activePerson["record"].name
                  : undefined,
                statuses: this.localApsDetail
                  ? this.localApsDetail.generalStatus
                  : undefined,
                buttons: this.permissionAPS
                  ? this.idVerificationService.resolveApsDetailsButtons(
                      apsDetails,
                      isLoading,
                    )
                  : undefined,
                isMonitored: apsDetails?.record?.isMonitored,
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
    if (!isLoading) {
      const that = this;
      setTimeout(function () {
        {
          $(".document-slide-container-imgs").zoom({
            on: "click",
            magnify: "1",
            onZoomIn: that.onZoomIn,
            onZoomOut: that.onZoomOut,
          });
          $(".document-fields-basicFields").niceScroll({
            cursorcolor: "#105f6b",
            cursorwidth: "4px",
            cursoropacitymin: 1,
            railpadding: { top: 0, right: 0, left: 0, bottom: 0 },
          });
        }
      }, 250);
    }
  }

  // DISPLAY CONTRACTS
  OnDisplayContractInLightbox(event, contractDetails) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.displayLoader = false;
    const imgSrc = this.activePersonFrontImage;
    let isLoading = false;
    this.formType = "Smart form";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = { class: "document-preview-lightbox" };

    const activePersonContract = this.idVerificationService
      .getActivePersons()
      .find(
        (person) => person.key === this.idVerificationService.getActivePerson(),
      )["record"]["lastContractVerification"];
    // if we are waiting for the back image to load set loading state
    if (contractDetails === undefined) {
      isLoading = true;
      this.idVerificationService.generateContractPreview(
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActivePerson(),
        this.idVerificationService.getActivePersonDetails()["record"][
          "lastContractVerification"
        ]["key"],
        this.idVerificationService.getActivePersonDetails()["record"][
          "lastContractVerification"
        ]["record"]["documentKeys"],
      );
    }

    const verificationStatuses = [];
    // parse the verificaiton statuses

    let statusFull;
    if (!isLoading) {
      const completedAt = this.helper.formatISODate(
        activePersonContract.record.completedAt,
        this.helper.getPdfFormatDate(),
        false,
      );
      const icon = "/assets/images/check.svg";
      const status = `Completed on <strong>${completedAt}</strong>`;
      statusFull = [
        {
          icon: icon,
          status: status,
        },
      ];
    }
    const group = {
      lightbox_type: "flex-width",
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "preview_image",
      result_details: [
        {
          group_result_title: "preview_image_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "evidencePreviewAlternate",
              class: isLoading
                ? "isLoading"
                : "document-preview-image-fullscreen",
              params: {
                // 'documentImages' : documentImages,
                // 'pdfDownloadButton' : true,
                // 'pdfDownloadFuction' : 'OnGeneratePDF',
                // 'apsFields' :this.localApsDetail,
                noImages: false,
                showStats: true,
                statsClass: "id-preview-title",
                documentImages: contractDetails,
                hideRight: true,
                statuses: statusFull,
                hideArrows:
                  contractDetails && contractDetails.length === 1
                    ? true
                    : false,
                // 'basicFieldsPreview' : true,
                buttons: this.permissionContract
                  ? [
                      {
                        action: "onDownloadContracts",
                        class: "",
                        icon: "/assets/images/download.svg",
                        title: "Download document",
                        hideButton: !!isLoading,
                      },
                    ]
                  : undefined,
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onDownloadAPS(event, replaceLightboxLoader = true) {
    event.preventDefault();
    // set the loading animation:
    $(event.target)
      .closest("a")
      .addClass("pdf-gen-loading")
      .find("img")
      .attr("src", "/assets/images/loader-small.gif");
    $(".pdf-gen-loading span").text("Generating PDF Report ...");
    this.idVerificationService
      .parseApsDataPdf(this.localApsDetail)
      // temporary disable pdf generation so we could test the parsing
      .then((parsedAPS) =>
        // this.pdfService.generatePDF(
        //   "apsUpdated",
        //   this.helper.formatISODate(
        //     new Date().toISOString(),
        //     "yyyy-MM-dd",
        //     false,
        //   ) +
        //     this.idVerificationService.getActivePersonDetails()["record"][
        //       "lastApsVerification"
        //     ].key,
        //   JSON.stringify(parsedAPS),
        // ),
        {
          this.idVerificationService.generatePDFBackend(
            this.idVerificationService.getActivePersonDetails()["record"][
              "lastApsVerification"
            ].key,

          );
        }
      )
      // .then(parsedAPS => {return})
      .catch((error) => {
        console.error(error);
      });
  }

  onDownloadContracts(event) {
    $(event.target)
      .closest("a")
      .addClass("pdf-gen-loading")
      .find("img")
      .attr("src", "/assets/images/loader-small.gif");
    $(".pdf-gen-loading span").text("Generating PDF Report ...");
    this.pdfService.setPdfGenerationStatus({
      status: "IN_PROGRESS",
      type: "contract",
    });
    const files = $(".downloadble-preview-file");
    const link = document.createElement("a");
    let counter = 0;
    for (let i = 0; i < files.length; i++) {
      document.body.appendChild(link);
      link.href = files[i]["src"];
      link.download =
        this.helper.formatISODate(
          new Date().toISOString(),
          "yyyy-MM-dd",
          false,
        ) +
        this.idVerificationService.getActivePersonDetails()["record"][
          "lastContractVerification"
        ].key +
        "__" +
        counter +
        ".pdf";
      link.style.display = "none";
      const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      counter++;
      link.dispatchEvent(evt);
    }
    this.pdfService.setPdfGenerationStatus({
      status: "DONE",
      type: "contract",
    });
    document.body.removeChild(link);
  }

  onDownloadCompletePDFAccept() {
    this.onDownloadCompletePDF(undefined, "loading");
    // this.idVerificationService.downloadCompletePDF();
    this.idVerificationService.downloadCompletePDFBackend();
  }

  onDownloadCompletePDF(event, status) {
    this.displayLoader = false;
    if (this.idVerificationService.getActivePersonDetails() === undefined) {
      alert("This person cannot be edited!");
      return;
    }

    let imageSrc;
    let imageClass = "address-confirmation-image";
    let titleText = "Download a full summary report";
    let paragraphText;
    let showButtons;
    let showCloseButton;

    if (status === "loading") {
      imageSrc = "/assets/images/spinner-green.svg";
      imageClass = "address-confirmation-image ";
      paragraphText = "Please be patient, this might take a while to complete.";
      showButtons = false;
      titleText = "Generating report";
    } else if (status === "success") {
      imageSrc = "/assets/images/request_screening.svg";
      titleText = "The summary report has been successfully generated.";
      paragraphText =
        "Your download will automatically start within a few seconds.";
      showButtons = false;
      showCloseButton = true;
    } else if (status === "error") {
      imageSrc = "/assets/images/icon-triangle-orange.svg";
      paragraphText = "There was an error while generating the report";
      showButtons = false;
      showCloseButton = true;
    } else {
      (imageSrc = "/assets/images/request_screening.svg"),
        (paragraphText =
          "You are about to download a summary report which includes all available verification reports. This may take some time to complete.");
      showButtons = true;
    }

    this.formType = "Download full report";
    this.formClass = status === "loading" ? "download-report-loading" : "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    // build the content for the Create new person lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "image",
              params: {
                src: imageSrc,
                class: imageClass,
              },
            },
            {
              type: "h3",
              params: {
                content: titleText,
              },
            },
            {
              type: "paragraph",
              class: " mt-3",
              paragraphClass: "mt-3",
              params: {
                content: paragraphText,
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Download",
                  // 'id': 'accept',
                  action: "onDownloadCompletePDFAccept",
                  class: "form-button-1",
                  display: showButtons,
                },
                {
                  content: "Close",
                  // 'id': 'rescan',
                  action: "onDeletePersonReject",
                  class: "form-button-1 form-button-grey",
                  display: showButtons,
                },
                {
                  content: "Close",
                  // 'id': 'rescan',
                  action: "onDeletePersonReject",
                  class: "form-button-1",
                  display: showCloseButton,
                },
              ],
            },
          ],
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  /**
   * actually display the project customization
   * lightbox
   */
  displayCustomizeProjectSettingsDepercated() {
    this.formType = "Customise project";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    // build the content for the Project Settings box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "perform_integrity_check",
      result_details: [
        {
          group_result_title: "perform_flag_a_company", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "paragraph",
              class: "mt-0 ",
              params: {
                classes: "p-0  ",
                content:
                  "Customize the look of the verification page that your client will use.",
              },
            },
            {
              type: "imageDragDropUpload",
              params: {
                label: "Select your logo",
                id: "projectEditLogo",
                uploadmethod: "handleImageUpload",
                display: true,
              },
            },
            {
              type: "inputColorSelector",
              params: {
                label: "Background color",
                id: "projectEditColor",
                // 'content': projectDetails.record.color,
                display: true,
                color:
                  projectDetails["record"].projectBranding.projectColor !==
                  undefined
                    ? projectDetails["record"].projectBranding.projectColor
                    : "#00889c",
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Close",
                  action: "OnCloseProjectCustomization",
                  class: "form-button-dark-gray",
                  display: true,
                },
                {
                  content: "Save",
                  action: "OnSaveProjectCustomization",
                  display: true,
                },
                {
                  content: "Reset branding",
                  action: "OnResetBranding",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  /**
   * actually display the project customization
   * lightbox
   */
  displayCustomizeProjectSettings() {
    this.formType = "Project settings";
    this.formTitle = "";
    this.formClass = "customize-project-settings-form";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    // console.log('display custom project settings, project details', projectDetails);
    // build the content for the Project Settings box
    const editedTypesOfWebhooks: Map<string, string> = new Map(
      projectDetails["record"]?.webhooks?.map((webhook) => {
        return [webhook.type, webhook.url];
      }),
    );
    this.displayLoader = false;
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "customization_settings",
      result_details: [
        {
          group_result_title: "customize_project_settings", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "divided_section",
              left: {
                params: {
                  class: "pr-5",
                  elements: [
                    {
                      type: "paragraph",
                      class: "mt-0",
                      params: {
                        classes: "p-0 mt-0 font-weight-bold ",
                        content:
                          "Customize the look of the verification page that your client will use.",
                      },
                    },
                    {
                      type: "imageDragDropUpload",
                      params: {
                        label: "Select your logo",
                        id: "projectEditLogo",
                        uploadmethod: "handleImageUpload",
                        // 'image': (projectDetails['record'].logo !== '/assets/images/ico-project.svg')
                        //           ? projectDetails['record'].logo
                        //           : '',
                        image:
                          projectDetails["record"].logo === undefined ||
                          (projectDetails["record"].logo !== undefined &&
                            projectDetails["record"].logo.indexOf(
                              "ico-project.svg",
                            ) !== -1)
                            ? projectDetails["record"].logo
                            : "",
                        backgroundColor:
                          projectDetails["record"].projectBranding !==
                            undefined &&
                          projectDetails["record"].projectBranding
                            .projectColor !== undefined
                            ? projectDetails["record"].projectBranding
                                .projectColor
                            : "#00889c",
                        textClass: this.helper.detectTextColor(
                          this.helper.hexToRgb(
                            projectDetails["record"].projectBranding !==
                              undefined &&
                              projectDetails["record"].projectBranding
                                .projectColor
                              ? projectDetails["record"].projectBranding
                                  .projectColor
                              : "#00889c",
                          ),
                        ),
                        display: true,
                      },
                    },
                    {
                      type: "inputColorSelector",
                      params: {
                        label: "Background color",
                        id: "projectEditColor",
                        textClass: this.helper.detectTextColor(
                          this.helper.hexToRgb(
                            projectDetails["record"].projectBranding !==
                              undefined &&
                              projectDetails["record"].projectBranding
                                .projectColor
                              ? projectDetails["record"].projectBranding
                                  .projectColor
                              : "#00889c",
                          ),
                        ),
                        // 'content': projectDetails.record.color,
                        display: true,
                        color:
                          projectDetails["record"].projectBranding !==
                            undefined &&
                          projectDetails["record"].projectBranding.projectColor
                            ? projectDetails["record"].projectBranding
                                .projectColor
                            : "#00889c",
                      },
                    },
                    {
                      type: "projectCustomMessage",
                      params: {
                        label: "Consent text",
                        id: "projectCustomMessage",
                        // 'content': projectDetails.record.color,
                        display: true,
                        backgroundColor: "#ffffff",
                        consentMessage:
                          projectDetails["record"].projectBranding !==
                            undefined &&
                          projectDetails["record"].projectBranding
                            .consentMessage !== undefined
                            ? projectDetails["record"].projectBranding
                                .consentMessage
                            : this.helper.returnDefaultUserVerificationMessage(
                                projectDetails["record"].name,
                              ),
                        textClass: "dark-color",
                      },
                    },
                    {
                      type: "privacyUrlInput",
                      params: {
                        label: "Privacy Policy Url",
                        id: "privacyUrlInput",
                        privacyUrl:
                          projectDetails["record"].projectBranding !==
                            undefined &&
                          projectDetails["record"].projectBranding
                            .privacyUrl !== undefined
                            ? projectDetails["record"].projectBranding
                                .privacyUrl
                            : "",
                        display: true,
                      },
                    },
                    {
                      type: "collapseInputFields",
                      class:
                        "mt-4" +
                        (this.idVerificationService.isIdVerification()
                          ? " d-none"
                          : ""),
                      params: {
                        label: "Webhooks",
                        labelClass: "font-weight-bold",
                        inputFields: [
                          {
                            id: "webhookCompletedProfile",
                            label: "Webhook for completed profile",
                            labelClass: "font-weight-bold",
                            name: "WebhookCompletedProfile",
                            content: editedTypesOfWebhooks.get(
                              "completed_profile",
                            )
                              ? editedTypesOfWebhooks.get("completed_profile")
                              : "",
                            required: false,
                          },
                          {
                            id: "webhookActionRequiredProfile",
                            label: "Webhook for action required profile",
                            labelClass: "font-weight-bold",
                            name: "WebhookActionRequiredProfile",
                            content: editedTypesOfWebhooks.get(
                              "action_required",
                            )
                              ? editedTypesOfWebhooks.get("action_required")
                              : "",
                            required: false,
                          },
                          {
                            id: "webhookClaimedProfile",
                            label: "Webhook for claimed profile",
                            labelClass: "font-weight-bold",
                            name: "WebhookClaimedProfile",
                            content: editedTypesOfWebhooks.get(
                              "claimed_profile",
                            )
                              ? editedTypesOfWebhooks.get("claimed_profile")
                              : "",
                            required: false,
                          },
                        ],
                      },
                    },
                    {
                      type: "button",
                      params: {
                        content: "Create risk assessments for all profiles",
                        type: "submit",
                        display: projectDetails["record"].questionnaireKey ? true : false,
                        action: "initiateProjectRRE",
                        class: "form-button-link-2 px-0 text-left",
                        id: "projectRiskAssessments"
                      },
                    },
                    {
                      type: "button_group",
                      params: [
                        {
                          content: "Close",
                          action: "OnCloseProjectCustomization",
                          class: "form-button-dark-gray",
                          display: true,
                        },
                        {
                          content: "Save",
                          action: "OnSaveProjectCustomization",
                          display: true,
                        },
                        {
                          content: "Reset branding",
                          action: "OnResetBranding",
                          display: true,
                        },
                      ],
                    },
                  ],
                },
              },
              right: {
                params: {
                  class: " border-left preview-project-container",
                  elements: [
                    {
                      type: "h3",
                      class: "mt-0",
                      params: {
                        classes: "p-0",
                        content: "Preview project",
                      },
                    },
                    {
                      type: "projectPreview",
                      params: {
                        // 'logo': (projectDetails['record'].logo !== '/assets/images/ico-project.svg')
                        //           ? projectDetails['record'].logo
                        //           : '',
                        logo:
                          projectDetails["record"].projectBranding ===
                            undefined ||
                          projectDetails["record"].projectBranding
                            .projectLogo === undefined
                            ? ""
                            : projectDetails["record"].projectBranding
                                .projectLogo,
                        projectColor:
                          projectDetails["record"].projectBranding !==
                            undefined &&
                          projectDetails["record"].projectBranding.projectColor
                            ? projectDetails["record"].projectBranding
                                .projectColor
                            : "#00889c",
                        consentMessage:
                          projectDetails["record"].projectBranding !==
                            undefined &&
                          projectDetails["record"].projectBranding
                            .consentMessage !== undefined
                            ? this.helper.returnShortenedUserVerificationMessage(
                                projectDetails["record"].projectBranding
                                  .consentMessage,
                                projectDetails["record"].name,
                              )
                            : this.helper.returnShortenedUserVerificationMessage(
                                "",
                                projectDetails["record"].name,
                              ),
                        textClass: this.helper.detectTextColor(
                          this.helper.hexToRgb(
                            projectDetails["record"].projectBranding !==
                              undefined &&
                              projectDetails["record"].projectBranding
                                .projectColor
                              ? projectDetails["record"].projectBranding
                                  .projectColor
                              : "#00889c",
                          ),
                        ),
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  /**
   * display the Reset Branding confirmation dialog
   */
  OnResetBranding() {
    this.formType = "Project settings";
    this.formTitle = "";
    this.formClass = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    // console.log('display custom project settings, project details', projectDetails);
    // build the content for the Project Settings box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "customization_settings",
      result_details: [
        {
          group_result_title: "customize_project_settings", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "paragraph",
              params: {
                classes: "py-2",
                contentHTML:
                  "You are about to reset the branding for this project. This action cannot be undone. Are you sure you want to continue?",
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Cancel",
                  action: "OnCancelBrandingReset",
                  class: "form-button-dark-gray",
                  display: true,
                },
                {
                  content: "Yes, continue with reset",
                  action: "OnConfirmBrandingReset",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  /**
   * close the confirmation dialog
   */
  OnCancelBrandingReset() {
    this.displayCustomizeProjectSettings();
  }

  /**
   * confirm and execute the branding reset
   */
  OnConfirmBrandingReset() {
    this.displayLoader = true;
    if (this.idVerificationService.getActiveProjectSettings()) {
      this.idVerificationService.doBrandingReset(
        this.idVerificationService.getActiveProjectSettings(),
      );
    }
  }

  /**
   * display the invite contributors lightbox
   */
  displayInviteContributors() {
    this.displayLoader = false;
    this.formType = "Invite contributors";
    this.formTitle = "";
    this.formClass = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    const reinvitedUser = this.idVerificationService.getReinviteUser();
    // once the data for the Reinvite user is fetched
    // we no longer need it so we can reset it
    this.idVerificationService.resetReinviteUser();
    // build the content for the Project Settings box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "invite_contributors",
      result_details: [
        {
          group_result_title: "perform_invite_contributors", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "h3",
              class: "mt-0",
              params: {
                content: "Invite new contributor",
                classes: "pt-0",
                display: true,
              },
            },
            {
              type: "inputText",
              params: {
                id: "contributor-email",
                label: "Contributor Email",
                content:
                  reinvitedUser !== undefined
                    ? reinvitedUser["record"].email
                    : "",
                required: "true",
                patternMatch:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                validatorMessage: this.translate.instant(
                  "validation.validation5",
                ),
              },
            },
            {
              type: "select",
              params: {
                id: "contributor-type",
                label: "Contributor type",
                changemethod: "OnSelectContributorType",
                options:
                  reinvitedUser !== undefined
                    ? [
                        {
                          name: this.helper.toFirstCharUppercase(
                            reinvitedUser["record"].type,
                          ),
                        },
                      ]
                    : [
                        { key: "-1", name: "Please select" },
                        { key: "owner", name: "Owner" },
                        { key: "member", name: "Member" },
                      ],
                optionselected:
                  reinvitedUser !== undefined
                    ? reinvitedUser["record"].type
                    : "-1",
                required: "true",
                validatorMessage: "A contributor type must be selected",
              },
            },
            {
              type: "indentWithBorder",
              class: "indent-left-border force-hide",
              params: {
                elements: [
                  {
                    type: "paragraphIcon",
                    params: {
                      class: "invite-new-contributor-type-disclaimer",
                      // tslint:disable-next-line:max-line-length
                      content:
                        "Keep in mind that after accepting the invite, the owner will have full administrative rights over the project and will be the owner of the project data.",
                      svgIcon: {
                        src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                        class: "svg-orange",
                        width: 20, // required
                        height: 16.9, // required
                      },
                    },
                    displayHistory: false,
                  },
                  // {
                  //   'type': 'title',
                  //   'params': {
                  //     'content': 'Invite by e-mail',
                  //     'display': true,
                  //   }
                  // },
                  // {
                  //   'type': 'inputText',
                  //   'params': {
                  //   }
                  // },
                  // {
                  //   'type': 'button',
                  //   'params': {
                  //     'content': 'Send invite',
                  //     'clickmethod': 'OnSendByEmail',
                  //     'display': true,
                  //   }
                  // },
                  {
                    type: "hr",
                    params: {
                      class: "py-2 invite-new-contributor-type-disclaimer",
                      display: true,
                    },
                  },
                  // {
                  //   'type': 'title',
                  //   'params': {
                  //     'content': 'Invite by link',
                  //     'display': true,
                  //   }
                  // },
                  // {
                  //   'type': 'paragraph',
                  //   'class' : 'mt-1',
                  //   'params': {
                  //     'paragraphClass' : 'mt-1',
                  //     'contentHTML': 'Generate a unique invitation link which can be shared with the contributor.<br>NOTE: The invitee will only be able to join the project if they login with the same email address associated with the invitation',
                  //   }
                  // },
                  // we no longer generate inviite links
                  // the user is added to the list of contributors automatically
                  // popup the list of contributors window instead
                  // {
                  //   'type': 'button',
                  //   'params': {
                  //     'content': 'Generate invite link',
                  //     'action': 'idvOnGenerateConributorInviteUrl',
                  //     'type': 'submit',
                  //     'display': true,
                  //   }
                  // },
                  {
                    type: "button",
                    params: {
                      content: "Send invitation",
                      action: "idvOnGenerateConributorInviteUrl",
                      type: "submit",
                      display: true,
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  displayEditTemplate() {
    this.idVerificationService.hideActionsMenuForce();
    this.displayLoader = false;
    this.formType = "Edit existing template";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    let group;
    group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [],
        },
      ],
    };

    const projectTemplates =
      this.idVerificationService.getfundsOrganizationTemplates();
    // if project templates exist we need to add the option to choose a template
    if (projectTemplates !== undefined && projectTemplates.length) {
      // we need to build the object which will hold the list values
      const templateValues = [];
      projectTemplates.forEach((template) => {
        templateValues.push({
          key: template.key,
          name: template.record.templateName,
        });
      });
      group.result_details[0].elements.push({
        type: "select",
        class: "reduced-margin-top input-transparent",
        length: "60",
        params: {
          labelClass: "font-weight-bold",
          id: "ARTemplate",
          name: "ARTemplateN",
          label: "Choose template",
          options: templateValues,
        },
      });
    }
    group.result_details[0].elements.push({
      type: "button_group",
      class: "mt-2",
      params: [
        {
          content: "Create new template",
          // 'id': 'accept',
          action: "htpmOnCreateTemplate",
          class: "form-button form-button-link-2 pl-0",
          display: true,
        },
      ],
    });

    group.result_details[0].elements.push({
      type: "button",
      params: {
        content: "Edit selected template",
        display: true,
        action: "htpmOnEditTemplate",
        type: "submit",
      },
    });

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }
  // EDIT TEMPLATE HELPER FUNCTION
  htpmOnCreateTemplate(event) {
    event.preventDefault();
    event.stopPropagation();
    this.displayModal = false;
    this.idVerificationService.createNewTemplate();
  }
  // EDIT TEMPLATE HELPER FUNCTION
  htpmOnEditTemplate(event) {
    this.displayLoader = true;
    event.preventDefault();
    event.stopPropagation();
    // get selected template id
    const selectedTemplate = $("#ARTemplate option:selected").val();
    // retreive selected template data
    this.userService
      .getEndPoint(
        "/api/organizations/templates/" + selectedTemplate,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          response["record"]["existingTemplateKey"] = selectedTemplate;
          this.displayLoader = false;
          this.displayModal = false;
          this.idVerificationService.createNewTemplate(response["record"]);
        },
        (error) => {
          return;
        },
      );
  }

  /**
   * display the loader for contributors
   * lightbox
   */
  displayManageContributorsInit() {
    this.formType = "Manage contributors";
    this.formTitle = "";
    this.formClass = "contributor-form";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    console.log("display manage contributors, project details", projectDetails);
    // build the content for the Project Settings box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "manage_contributors",
      result_details: [
        {
          group_result_title: "perform_manage_contributors", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "title",
              class: "mt-0",
              params: {
                content: "Current contributors",
                display: true,
              },
            },
            {
              type: "contributors",
              params: {
                class: "mt-2",
                display: true,
                displayLoader: true,
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  displayGenerateReportStatus() {
    this.displayLoader = false;
    this.formType = "Status report";
    this.formTitle = "";
    this.formClass = "report-generation-form";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    let projectName = "";
    if (projectDetails) {
      projectName = "for " + projectDetails["record"].name;
    }
    // build the content for the Project Settings box
    let group;
    group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "report_generation",
      result_details: [
        {
          group_result_title: "perform_manage_contributors", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "image",
              params: {
                src: "/assets/images/request_screening.svg",
                class: "address-confirmation-image",
              },
            },
            {
              type: "h3",
              params: {
                content: "Create a status report",
              },
            },
            {
              type: "subtitle",
              params: {
                content:
                  "You are about to generate a report containing the names of all profiles within the current project, indicating their current status.",
                display: true,
              },
            },
            {
              type: "subtitle",
              params: {
                content:
                  "PLEASE NOTE: This report will contain personal information such as names of individuals.",
                display: true,
              },
            },
            {
              type: "button",
              params: {
                content: "Download report",
                type: "submit",
                display: true,
                action: "htpmOnGenerateStatusReport",
                errorMessage:
                  "*the report generated is empty and cannot be downloaded",
              },
            },
          ],
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  /**
   * display the loader for contributors
   * lightbox
   */
  displayGenerateReportInit(tags?) {
    this.formType = "Generate usage report";
    this.formTitle = "";
    this.formClass = "report-generation-form";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    let projectName = "";
    if (projectDetails) {
      projectName = "for " + projectDetails["record"].name;
    }
    // build the content for the Project Settings box
    let group;
    group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "report_generation",
      result_details: [
        {
          group_result_title: "perform_manage_contributors", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "subtitle-alternate",
              class: "mt-0",
              params: {
                content: "Usage report " + projectName,
                display: true,
              },
            },
            {
              type: "subtitle",
              params: {
                content: "Generate a report by selecting the properties below",
                display: true,
              },
            },
            {
              type: "input_date",
              params: {
                labelClass: "font-weight-bold",
                label: "Completed from",
                id: "reportGenerationDateFrom",
                name: "reportGenerationDateFrom",
                // 'minDate': this.helper.getAsStartDate('01.01.1915'),
                // 'maxDate': this.helper.getTodayAsMaxDate(),
                required: false,
              },
            },
            {
              type: "input_date",
              params: {
                labelClass: "font-weight-bold",
                label: "Completed to",
                id: "reportGenerationDateTo",
                name: "reportGenerationDateTo",
                // 'minDate': this.helper.getAsStartDate('01.01.1915'),
                // 'maxDate': this.helper.getTodayAsMaxDate(),
                required: false,
              },
            },
            {
              type: "button",
              params: {
                content: "Download report",
                type: "submit",
                display: true,
                action: "htpmOnGenerateReport",
                errorMessage:
                  "*the report generated is empty and cannot be downloaded",
              },
            },
          ],
        },
      ],
    };

    // Add tag options to id-verification product only
    if (this.idVerificationService.getCCType() === "verification") {
      group.result_details[0].elements.splice(2, 0, {
        type: "project_tags_selection",
        params: {
          tags: tags && tags.length > 0 ? tags : undefined,
        },
      });
    }

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    if (tags === undefined) {
      this.displayLoader = true;
    } else {
      this.displayLoader = false;
    }
    this.displayModal = true;
  }

  /**
   * actually display the manage contributors
   * lightbox
   */
  displayManageContributors(
    displayLoader = false,
    highlightEmail: string = "",
  ) {
    this.formType = "Manage contributors";
    this.formTitle = "";
    this.formClass = "contributor-form";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    const contributors =
      this.idVerificationService.getProjectContributorsData();
    const invitations = this.idVerificationService.getProjectInvitationsData();

    // build the content for the Project Settings box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "manage_contributors",
      result_details: [
        {
          group_result_title: "perform_manage_contributors", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "title",
              class: "mt-0",
              params: {
                content: "Current contributors",
                display: true,
              },
            },
            {
              type: "contributors",
              params: {
                class: "mt-2",
                display: true,
                contributors: contributors,
                highlightEmail: highlightEmail,
                displayOverlayLoader: displayLoader,
              },
            },
            {
              type: "hr",
              params: {
                display:
                  invitations !== undefined &&
                  Object.keys(invitations).length > 0
                    ? true
                    : false,
              },
            },
            {
              type: "title",
              params: {
                titleClass: "mb-1",
                content: "Pending invitations",
                display:
                  invitations !== undefined &&
                  Object.keys(invitations).length > 0
                    ? true
                    : false,
              },
            },
            {
              type: "invitations",
              params: {
                class: "mt-2",
                display:
                  invitations !== undefined &&
                  Object.keys(invitations).length > 0
                    ? true
                    : false,
                invitees: invitations,
              },
            },
            {
              type: "button",
              params: {
                content: "Invite contributors",
                type: "submit",
                display: true,
                action: "onInviteContributors",
                errorMessage:
                  "*at least one verification type must be selected.",
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  onInviteContributors() {
    this.idVerificationService.setDisplayInviteContributorsLightbox();
  }

  copyInvitationLink(event) {
    event.preventDefault();
    const target = $(event.target);
    const url = target.attr("url");
    target.text("Link copied...");
    setTimeout(function () {
      target.text("Copy link");
    }, 2000);
    this.helper.copyToClipboard(url);
  }

  /**
   * execute on selection change for contributor
   * type
   * @param params object
   */
  OnSelectContributorType(params) {
    $(".indent-left-border").removeClass("remove-border");
    if ($("#contributor-type").find(":selected").val() !== -1) {
      $(".indent-left-border").removeClass("force-hide");
    } else {
      $(".indent-left-border").addClass("force-hide");
    }
    if ($("#contributor-type").find(":selected").val() === "owner") {
      $(".invite-new-contributor-type-disclaimer").attr(
        "style",
        "display: flex !important",
      );
    } else {
      $(".indent-left-border").addClass("remove-border");
      $(".invite-new-contributor-type-disclaimer").attr("style", "");
    }
  }

  /**
   * execute on making owner
   * @param params object
   */
  OnContributorMakeOwner(params) {
    // console.log(params);
    // console.log(params.target);
    if ($(params.target).length > 0) {
      this.displayManageContributors(true);
      this.idVerificationService.makeContributorOwner(
        this.idVerificationService.getActiveProjectSettings(),
        $(params.target).attr("id"),
      );
      // $(params.target).replace('<img src="/assets/images/loader_small.gif" />');
    }
  }

  /**
   * execute on removing member
   * @param params object
   */
  OnContributorRemove(params) {
    // console.log(params);
    // console.log(params.target);
    if ($(params.target).length > 0) {
      this.displayManageContributors(true);
      this.idVerificationService.removeContributor(
        this.idVerificationService.getActiveProjectSettings(),
        $(params.target).attr("id"),
      );
    }
  }

  /**
   * execute on removing member
   * @param params object
   */
  OnContributorAuthorize(params) {
    if ($(params.target).length > 0) {
      this.displayManageContributors(true);
      this.idVerificationService.authorizeContributor(
        this.idVerificationService.getActiveProjectSettings(),
        $(params.target).attr("id"),
      );
    }
  }

  /**
   * reinvite already invited contributor
   */
  OnReInvite(params) {
    this.displayLoader = true;
    if ($(params.target).length > 0) {
      this.idVerificationService.reinviteContributor(
        $(params.target).attr("id"),
      );
      // $(params.target).replace('<img src="/assets/images/loader_small.gif" />');
    }
  }

  /**
   * generates a link for Inviting a contributor
   * @param params object
   */
  idvOnGenerateConributorInviteUrl(params) {
    if ($("#contributor-email").val().length < 3) {
      alert("Contributor email must be at least 3 characters long!");
      return;
    }
    if ($("#contributor-type").find(":selected").text() === "Please select") {
      alert("Please select a type of contributor!");
      return;
    }
    this.displayLoader = true;
    this.tempContributorEmail = $("#contributor-email").val();
    this.tempContributorType = $("#contributor-type")
      .find(":selected")
      .val()
      .toLowerCase();
    this.idVerificationService.directInviteContributorToProject(
      this.idVerificationService.getActiveProjectSettings(),
      this.tempContributorEmail,
      this.tempContributorType,
    );
  }

  /**
   * handle completion of invite link generation
   * @param event object, holds the status of the event
   * and the response if the event was successful
   */
  idvOnGenerateConributorInviteUrlComplete(response) {
    this.displayLoader = false;
    // let cta: {} = {
    //   'type': 'inputTextWithButton',
    //   'params': {
    //     'id': 'generatedVerificationLink',
    //     'content': response.data.record.url,
    //     'classes': 'generated-link',
    //     'button': {
    //       'display': true,
    //       'content': 'Save',
    //       'class': 'ml-2 mr-0 form-button-1',
    //       'action': 'CopyLinkToClipboard'
    //     }
    //   }
    // };

    // if (!response.status) {
    //   contentHTML = 'We were not able to fetch the verification url for you please try again!';
    //   cta = {
    //     'type': 'button',
    //     'params': {
    //       'content': 'Try again',
    //       'display': true,
    //       'action': 'idvOnGenerateInviteUrl'
    //     }
    //   };
    // }

    this.formType = "Manage contributors";
    this.formTitle = "";
    this.formClass = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    // build the content for the Project Settings box
    let group;
    if (!response.status) {
      group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "manage_contributors",
        result_details: [
          {
            group_result_title: "perform_manage_contributors", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "h3",
                class: "mt-0",
                params: {
                  content: "Invite new contributor",
                  classes: "pt-0",
                  display: true,
                },
              },
              {
                type: "inputText",
                params: {
                  id: "contributor-email",
                  label: "Contributor Email",
                  content: this.tempContributorEmail,
                  required: "true",
                  patternMatch:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  validatorMessage: this.translate.instant(
                    "validation.validation5",
                  ),
                },
              },
              {
                type: "select",
                params: {
                  id: "contributor-type",
                  label: "Contributor type",
                  options: [
                    {
                      name: this.tempContributorType,
                      key: this.tempContributorType,
                    },
                  ],
                  optionselected: this.tempContributorType,
                  required: "true",
                  validatorMessage: "A contributor type must be selected",
                },
              },
              {
                type: "indentWithBorder",
                class: "indent-left-border",
                params: {
                  elements: [
                    {
                      type: "paragraphIcon",
                      params: {
                        // tslint:disable-next-line:max-line-length
                        content:
                          "Keep in mind that after accepting the invite, the owner will have full administrative rights over the project and will be the owner of the project data.",
                        svgIcon: {
                          src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                          class: "svg-orange",
                          width: 20, // required
                          height: 16.9, // required
                        },
                        display:
                          this.tempContributorType === "Owner" ? true : false,
                      },
                      displayHistory: false,
                    },
                    // {
                    //   'type': 'title',
                    //   'params': {
                    //     'content': 'Invite by e-mail',
                    //     'display': true,
                    //   }
                    // },
                    // {
                    //   'type': 'inputText',
                    //   'params': {
                    //   }
                    // },
                    // {
                    //   'type': 'button',
                    //   'params': {
                    //     'content': 'Send invite',
                    //     'clickmethod': 'OnSendByEmail',
                    //     'display': true,
                    //   }
                    // },
                    {
                      type: "hr",
                      params: {
                        class: "py-2",
                        display:
                          this.tempContributorType === "owner" ? true : false,
                      },
                    },
                    {
                      type: "title",
                      params: {
                        content: "Invite by link",
                        display: true,
                      },
                    },
                    {
                      type: "paragraph",
                      params: {
                        contentHTML:
                          "We were not able to fetch the invitation url for you please try again!",
                      },
                    },
                    {
                      type: "button",
                      params: {
                        content: "Generate invite link",
                        action: "idvOnGenerateConributorInviteUrl",
                        display: true,
                        type: "submit",
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      };
    } else {
      group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "manage_contributors",
        result_details: [
          {
            group_result_title: "perform_manage_contributors", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            // 'result_title' : 'What would you like to know?',
            elements: [
              // {
              //   'type': 'contributors',
              //   'params': {
              //     'class': 'mt-2',
              //     'display': true,
              //     'email': 'service.provider@trust&co.com',
              //     'contributors': [
              //       {
              //         'id': 'CON:1',
              //         'email': 'service.provider@trust&co.com',
              //         'role': 'Member', // Member | Owner
              //         'makeOwner': true, // only if current role = Member
              //       },
              //       {
              //         'id': 'CON:2',
              //         'email': 'test@trust&co.com',
              //         'role': 'Owner', // Member | Owner
              //         'makeOwner': false, // only if current role = Member
              //       }
              //     ],
              //   }
              // },
              // {
              //   'type': 'hr',
              //   'params': {
              //     'class': 'py-2',
              //     'display': true
              //   }
              // },
              {
                type: "h3",
                class: "mt-0",
                params: {
                  content: "Invite new contributor",
                  classes: "pt-0",
                  display: true,
                },
              },
              {
                type: "indentWithBorder",
                class: "indent-left-border",
                params: {
                  elements: [
                    {
                      type: "title",
                      params: {
                        content: "Invite by link",
                        display: true,
                      },
                    },
                    {
                      type: "paragraph",
                      params: {
                        contentHTML:
                          "Link successfully generated. Copy the link below using the 'Copy' button and share it with the invitee.<br>NOTE: The invitee will only be able to join the project if they login with the same email address associated with the invitation",
                      },
                    },
                    {
                      type: "inputTextWithButton",
                      params: {
                        id: "generatedVerificationLink",
                        content: response.data.record.url,
                        classes: "generated-link",
                        button: {
                          display: true,
                          content: "Copy",
                          class: "ml-2 mr-0 form-button-1",
                          action: "CopyLinkToClipboard",
                        },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      };
    }
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  /**
   * handle completion of invite link generation
   * @param event object, holds the status of the event
   * and the response if the event was successful
   */
  idvOnAddConributorComplete(response) {
    this.displayLoader = false;

    this.formType = "Manage contributors";
    this.formTitle = "";
    this.formClass = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    // build the content for the Project Settings box
    let group;
    if (!response.status) {
      if (
        (response.errorMessage &&
          response.errorMessage.includes("already a member")) ||
        (response.errorStatus && response.errorStatus === 403)
      ) {
        // display user already added error:
        group = {
          section_class: "results d-flex flex-column justify-items-center p-0",
          result_container_class: "",
          is_collapsable: false,
          form_id: "manage_contributors",
          result_details: [
            {
              group_result_title: "perform_manage_contributors", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
              elements: [
                {
                  type: "h3",
                  class: "mt-0",
                  params: {
                    content: "Invite new contributor",
                    classes: "pt-0",
                    display: true,
                  },
                },
                {
                  type: "paragraphIcon",
                  params: {
                    // tslint:disable-next-line:max-line-length
                    content:
                      "Contributor with that email is already added to this project. Click the button below to see the list of contributors added to the project!",
                    svgIcon: {
                      src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                      class: "svg-orange",
                      width: 20, // required
                      height: 16.9, // required
                    },
                    display: true,
                  },
                  displayHistory: false,
                },
                {
                  type: "button",
                  params: {
                    content: "Manage Contributors",
                    action: "takeActionsToDisplayManageContributors",
                    display: true,
                    type: "submit",
                  },
                },
              ],
            },
          ],
        };
      } else {
        // display fail error and allow them to try again:
        group = {
          section_class: "results d-flex flex-column justify-items-center p-0",
          result_container_class: "",
          is_collapsable: false,
          form_id: "manage_contributors",
          result_details: [
            {
              group_result_title: "perform_manage_contributors", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
              elements: [
                {
                  type: "h3",
                  class: "mt-0",
                  params: {
                    content: "Invite new contributor",
                    classes: "pt-0",
                    display: true,
                  },
                },
                {
                  type: "inputText",
                  params: {
                    id: "contributor-email",
                    label: "Contributor Email",
                    content: this.tempContributorEmail,
                    required: "true",
                    patternMatch:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    validatorMessage: this.translate.instant(
                      "validation.validation5",
                    ),
                  },
                },
                {
                  type: "select",
                  params: {
                    id: "contributor-type",
                    label: "Contributor type",
                    options: [
                      {
                        name: this.tempContributorType,
                        key: this.tempContributorType,
                      },
                    ],
                    optionselected: this.tempContributorType,
                    required: "true",
                    validatorMessage: "A contributor type must be selected",
                  },
                },
                {
                  type: "indentWithBorder",
                  class: "indent-left-border",
                  params: {
                    elements: [
                      {
                        type: "paragraphIcon",
                        params: {
                          // tslint:disable-next-line:max-line-length
                          content:
                            "Keep in mind that after accepting the invite, the owner will have full administrative rights over the project and will be the owner of the project data.",
                          svgIcon: {
                            src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                            class: "svg-orange",
                            width: 20, // required
                            height: 16.9, // required
                          },
                          display:
                            this.tempContributorType === "Owner" ? true : false,
                        },
                        displayHistory: false,
                      },
                      // {
                      //   'type': 'title',
                      //   'params': {
                      //     'content': 'Invite by e-mail',
                      //     'display': true,
                      //   }
                      // },
                      // {
                      //   'type': 'inputText',
                      //   'params': {
                      //   }
                      // },
                      // {
                      //   'type': 'button',
                      //   'params': {
                      //     'content': 'Send invite',
                      //     'clickmethod': 'OnSendByEmail',
                      //     'display': true,
                      //   }
                      // },
                      {
                        type: "hr",
                        params: {
                          class: "py-2",
                          display:
                            this.tempContributorType === "owner" ? true : false,
                        },
                      },
                      // {
                      //   'type': 'title',
                      //   'params': {
                      //     'content': 'Invite by link',
                      //     'display': true,
                      //   }
                      // },
                      {
                        type: "paragraph",
                        params: {
                          contentHTML:
                            "We were not able to add the contributor to your project. Please try again!",
                        },
                      },
                      {
                        type: "button",
                        params: {
                          content: "Send Invitation",
                          action: "idvOnGenerateConributorInviteUrl",
                          display: true,
                          type: "submit",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          ],
        };
      }
      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);

      this.displayModal = true;
    } else {
      this.takeActionsToDisplayManageContributors(this.tempContributorEmail);
    }
  }

  /**
   * actually display the advanced settings
   * lightbox
   */
  displayAdvancedSettings() {
    this.displayLoader = false;
    this.formType = "Advanced Settings";
    this.formTitle = "";
    this.formClass = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    // build the content for the Project Settings box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "advanced_settings",
      result_details: [
        {
          group_result_title: "perform_advanced_settings", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "title",
              class: "mt-0",
              params: {
                content: "Delete project",
                display: true,
              },
            },
            {
              type: "paragraph",
              params: {
                content: "Did you stopped working on this project? ",
              },
            },
            {
              type: "button",
              params: {
                content: "Delete project",
                action: "confirmProjectDelete",
                display: true,
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  displayDeleteProjectDialogue(projectData) {
    let projectName = "";
    let projectId = "";
    if (projectData) {
      projectName = projectData.record ? projectData.record.name : projectName;
      projectId = projectData.key;
    }
    this.displayLoader = false;
    this.formType = "Delete project";
    this.formClass = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = "";
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "edit_new_representative",
      result_details: [
        {
          group_result_title: "edit_new_representative_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "paragraph",
              class: " mt-2 result-paragraph",
              paragraphClass: "mt-1",
              params: {
                content:
                  'You are about to delete "' +
                  projectName +
                  '". This will also delete this project for all project contributors. Once deleted the project cannot be restored.  Are you sure you want to delete the project?',
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Yes, delete the project ",
                  // 'id': 'accept',
                  action: "onDeleteProjectAccept",
                  class: "form-button form-button-red",
                  type: projectId,
                  display: true,
                },
                {
                  content: "Go back",
                  // 'id': 'rescan',
                  action: "onDeleteProjectReject",
                  class: "form-button-6",
                  display: true, // TODO: check if user may edit the information
                },
              ],
            },
          ],
        },
      ],
    };
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onDeleteProjectReject() {
    $(".close-form-button,#lightBoxCloseImage").trigger("click");
  }

  onDeleteProjectAccept(event) {
    this.displayLoader = true;
    this.idVerificationService.deleteProject($(event.target).attr("type"));
  }

  /**
   * handle image upload
   * @param file: file
   */
  handleImageUpload(file) {
    this.idVerificationService.setActiveProjectSettingsLogo(file);
  }

  /**
   * handle close project customization
   * ignore every change and simply
   * close the lightbox
   */
  OnCloseProjectCustomization() {
    // reset the currently selected logo
    this.idVerificationService.resetActiveProjectSettingsLogo();
    this.displayModal = false;
  }
  /**
   * handle save project customization we need
   * to do two things: 1. save ProjectColor and
   * ProjectName (they must be both present as it
   * is a single BE call and both are required) and
   * 2. save the ProjectLogo in a separate call to BE
   * Both calls can be made async of eachother
   */
  OnSaveProjectCustomization() {
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    // let's do #1
    // this.logoUpdateProjectFinished = false;
    // this.idVerificationService.updateProjectName(projectDetails['record'].name);
    // let's do #2
    this.logoUpdateBrandingFinished = false;
    const webhooks = [];
    if ($("#webhookCompletedProfile").val()) {
      webhooks.push({
        url: $("#webhookCompletedProfile").val(),
        type: "completed_profile",
      });
    }
    if ($("#webhookActionRequiredProfile").val()) {
      webhooks.push({
        url: $("#webhookActionRequiredProfile").val(),
        type: "action_required",
      });
    }
    if ($("#webhookClaimedProfile").val()) {
      webhooks.push({
        url: $("#webhookClaimedProfile").val(),
        type: "claimed_profile",
      });
    }
    this.idVerificationService.updateProjectBranding(
      $("#projectEditColor").val(),
      $("#projectCustomMessage").val(),
      $("#privacyUrlInput").val(),
      webhooks,
    );

    // let's do #3
    this.logoUploadFinished = false;
    // console.log('THIS IS THE LOGO', this.idVerificationService.getActiveProjectSettingsLogo());
    if (
      this.idVerificationService.getActiveProjectSettingsLogo() !== undefined
    ) {
      this.idVerificationService.uploadProjectLogo(
        this.idVerificationService.getActiveProjectSettings(),
        this.idVerificationService.getActiveProjectSettingsLogo(),
      );
    } else {
      this.logoUploadFinished = true;
    }
    this.displayLoader = true;
  }

  /**
   * confirm project delete
   */
  confirmProjectDelete() {
    const projectDetails =
      this.idVerificationService.getActiveProjectSettingsDetails();
    this.formType = "Delete a project";
    this.formClass = "";
    this.formTitle = ""; // 'Financial Holdings B.V';
    this.btnOkText = ""; // 'Yes, I am sure';
    this.formValidator = "";
    this.btnCancelText = ""; // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.lightboxClass = "";
    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "perform_project_delete",
      result_details: [
        {
          group_result_title: "perform_project_delete_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "h3",
              firstOfType: true,
              params: {
                classes: "p-0",
                content: "Are you sure?",
              },
            },
            {
              type: "paragraph",
              params: {
                content:
                  'Are you sure you want to delete the project "' +
                  projectDetails["record"].name +
                  '"?',
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Yes, I am sure",
                  action: "OnConfirmDeleteProject",
                  display: true,
                },
                {
                  content: "No, cancel",
                  action: "OnCancelDeleteProject",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  /**
   * handle project delete
   */
  OnConfirmDeleteProject() {
    this.displayLoader = true;
    const projectKey = this.idVerificationService.getActiveProjectSettings();
    this.idVerificationService.deleteProject(projectKey);
  }

  /**
   * cancel project delete
   */
  OnCancelDeleteProject() {
    this.displayModal = false;
  }

  initiateProjectRRE()
  {
    const projectDetails =
    this.idVerificationService.getActiveProjectSettingsDetails();

    this.riskAssessmentService.initiateRREsProjectProfiles(projectDetails["key"]);
    

  }

  /**
   * display confirmation dialog for project leave
   */
  displayConfirmationProjectLeave() {
    this.formType = "Leave a project";
    this.formClass = "";
    this.formTitle = ""; // 'Financial Holdings B.V';
    this.btnOkText = ""; // 'Yes, I am sure';
    this.formValidator = "";
    this.btnCancelText = ""; // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.lightboxClass = "";
    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "perform_project_leave",
      result_details: [
        {
          group_result_title: "perform_project_leave_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "svgImage",
              firstOfType: true,
              class: "text-center",
              params: {
                classes: "pt-0 pb-5 svg-red",
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                width: "100",
                height: "100",
              },
            },
            {
              type: "h3",
              firstOfType: true,
              params: {
                classes: "p-0",
                content: "Are you sure that you want to leave?",
              },
            },
            {
              type: "paragraph",
              params: {
                content: "You won’t have access to the project after leaving.",
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content:
                    this.projectsType === "funds"
                      ? "Leave project"
                      : "Leave project",
                  action: "OnConfirmLeaveProject",
                  display: true,
                },
              ],
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Cancel",
                  action: "OnCancelProjectLeave",
                  class: "form-button-anchor",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  /**
   * display cannot leave dialog for project leave
   * no other owners/but there are other members
   */
  displayProjectLeaveMembersAvailable() {
    this.formType = "Leave a project";
    this.formClass = "";
    this.formTitle = ""; // 'Financial Holdings B.V';
    this.btnOkText = ""; // 'Yes, I am sure';
    this.formValidator = "";
    this.btnCancelText = ""; // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.lightboxClass = "";

    let isSelected = true;
    const members = [];
    this.idVerificationService.getProjectMembers().forEach((element) => {
      // members.push({
      //   'type': 'checkbox',
      //   'params': {
      //     'id': 'current_member_' + element.id,
      //     'name': 'current_member_question_' + element.id,
      //     'label': element.email,
      //     'value': element.id,
      //     'disabled': false
      //   }
      // });
      members.push({
        type: "radio",
        params: {
          id: "current_member_" + element.id,
          name: "current_member",
          label: element.email,
          value: element.id,
          disabled: false,
          selected: isSelected,
        },
      });
      isSelected = false;
    });

    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "perform_project_leave_not_available",
      result_details: [
        {
          group_result_title: "perform_project_leave_not_available_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "svgImage",
              firstOfType: true,
              class: "text-center",
              params: {
                classes: "pt-0 pb-5 svg-red",
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                width: "100",
                height: "100",
              },
            },
            {
              type: "h3",
              firstOfType: true,
              params: {
                classes: "p-0",
                content: "Action not available.",
              },
            },
            {
              type: "paragraph",
              params: {
                content:
                  "Since you are the only contributor to the project you cannot leave it.",
              },
            },
            {
              type: "paragraph",
              params: {
                content:
                  "Before leaving, you will need to assign some of the existing members as owner or invite a new contributor as owner.",
              },
            },
            {
              type: "title",
              class: "p-0",
              params: {
                content: "Assign current member as owner",
                display: true,
              },
            },
            // {
            //   'type': 'checkbox_group',
            //   'params': {
            //     'required': true,
            //     'checkboxes': members
            //   }
            // },
            {
              type: "radiobutton_list",
              params: members,
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Assign and leave",
                  action: "OnAssignAndLeave",
                  type: "submit",
                  display: true,
                },
                {
                  content: "Invite New Owner",
                  class: "form-button-black",
                  action: "OnInviteNewOwner",
                  display: true,
                },
              ],
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Cancel",
                  action: "OnCancelProjectLeave",
                  class: "form-button-anchor",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  /**
   * display cannot leave dialog for project leave
   * no other owners/no other members
   */
  displayProjectLeaveNotAvailable() {
    this.formType = "Leave a project";
    this.formClass = "";
    this.formTitle = ""; // 'Financial Holdings B.V';
    this.btnOkText = ""; // 'Yes, I am sure';
    this.formValidator = "";
    this.btnCancelText = ""; // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.lightboxClass = "";
    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "perform_project_leave_not_available",
      result_details: [
        {
          group_result_title: "perform_project_leave_not_available_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "svgImage",
              firstOfType: true,
              class: "text-center",
              params: {
                classes: "pt-0 pb-5 svg-red",
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                width: "100",
                height: "100",
              },
            },
            {
              type: "h3",
              firstOfType: true,
              params: {
                classes: "p-0",
                content: "Action not available.",
              },
            },
            {
              type: "paragraph",
              params: {
                content:
                  "Since you are the only contributor to the project you cannot leave it.",
              },
            },
            {
              type: "paragraph",
              params: {
                content:
                  "Before leaving, you will need to invite another contributor and assign them as owner.",
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Invite New Owner",
                  action: "OnInviteNewOwner",
                  display: true,
                },
              ],
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Cancel",
                  action: "OnCancelProjectLeave",
                  class: "form-button-anchor",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  /**
   * initiates display for invite new contributor dialog
   */
  OnInviteNewOwner() {
    this.OnCancelProjectLeave();
    this.idVerificationService.setDisplayInviteContributorsLightbox();
  }

  /**
   * assign selected members as owners
   * and initiate display for invite new contributor dialog
   */
  OnAssignAndLeave(event) {
    const _this = this;
    let foundMembers = false;
    $.each($('input[type="radio"]'), function () {
      if ($(this).is(":checked")) {
        foundMembers = true;
        // console.log('THIS IS WHAT I FOUND', $(this).val());
        // mark this member as owner
        _this.displayLoader = true;
        _this.idVerificationService.makeContributorOwner(
          _this.idVerificationService.getActiveProjectLeave(),
          $(this).val(),
          false,
          true,
        );
        return;
      }
    });
    if (!foundMembers) {
      this.displayLeaveProjectError(
        "We could not mark selected members as owners. You have not left the project yet!",
      );
    }
  }

  /**
   * cancel project leave
   */
  OnCancelProjectLeave() {
    this.displayModal = false;
    this.idVerificationService.resetActiveProjectLeave();
  }

  displayLeaveProjectError(message: string) {
    this.formType = "Leave a project";
    this.formClass = "";
    this.formTitle = ""; // 'Financial Holdings B.V';
    this.btnOkText = ""; // 'Yes, I am sure';
    this.formValidator = "";
    this.btnCancelText = ""; // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.displayLoader = false;
    this.lightboxClass = "";
    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "perform_project_leave",
      result_details: [
        {
          group_result_title: "perform_project_leave_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "svgImage",
              firstOfType: true,
              class: "text-center",
              params: {
                classes: "pt-0 pb-5 svg-red",
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                width: "100",
                height: "100",
              },
            },
            {
              type: "h3",
              firstOfType: true,
              params: {
                classes: "p-0",
                content: "Following error occured while leaving a project:",
              },
            },
            {
              type: "paragraph",
              params: {
                content:
                  "Invited owner needs to be authorized before you leave",
              },
            },
            // {
            //   'type': 'button_group',
            //   'params': [
            //     {
            //       'content': 'Leave project',
            //       'action': 'OnConfirmLeaveProject',
            //       'display': true
            //     },
            //   ]
            // },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  displayCancelVerificationError(message: string) {
    this.formType = "Cancel verification";
    this.formClass = "";
    this.formTitle = ""; // 'Financial Holdings B.V';
    this.btnOkText = ""; // 'Yes, I am sure';
    this.formValidator = "";
    this.btnCancelText = ""; // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.displayLoader = false;
    this.lightboxClass = "";

    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "perform_cancel_verification",
      result_details: [
        {
          group_result_title: "perform_cancel_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "svgImage",
              firstOfType: true,
              class: "text-center",
              params: {
                classes: "pt-0 pb-5 svg-red",
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                width: "100",
                height: "100",
              },
            },
            {
              type: "h3",
              firstOfType: true,
              params: {
                classes: "p-0",
                content: "An error occured while canceling a verification:",
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  displayBrandingResetError(message: string) {
    this.formType = "Project settings";
    this.formClass = "";
    this.formTitle = ""; // 'Financial Holdings B.V';
    this.btnOkText = ""; // 'Yes, I am sure';
    this.formValidator = "";
    this.btnCancelText = ""; // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.displayLoader = false;
    this.lightboxClass = "";
    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "perform_cancel_verification",
      result_details: [
        {
          group_result_title: "perform_cancel_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "svgImage",
              firstOfType: true,
              class: "text-center",
              params: {
                classes: "pt-0 pb-5 svg-red",
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                width: "100",
                height: "100",
              },
            },
            {
              type: "h3",
              firstOfType: true,
              params: {
                classes: "p-0",
                content: "Following error occured while reseting branding:",
              },
            },
            {
              type: "paragraph",
              params: {
                content: message,
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }

  OnConfirmLeaveProject() {
    this.displayLoader = true;
    this.idVerificationService.leaveProject(
      this.idVerificationService.getActiveProjectLeave(),
    );
  }

  ngOnDestroy() {
    this.idVerificationService.resetActivePerson();
    this.idVerificationService.resetScrollToKey();
    // this.retrieveActiveLegalRepresentatives.unsubscribe();
    this.retrieveActiveProjects.unsubscribe();
    this.retrieveActiveProjects.unsubscribe();
    this.toggleHeaderStatus.unsubscribe();
    this.detectDisplayCustomizeProjectSettings.unsubscribe();
    this.detectDisplayManageContributorsLightbox.unsubscribe();
    this.detectDisplayGenerateReportLightbox.unsubscribe();
    this.detectDisplayAdvancedSettingsLightbox.unsubscribe();
    this.detectUpdateProjectBrandingStatus.unsubscribe();
    this.detectUploadProjectLogoStatus.unsubscribe();
    this.detectGenerateContributorInviteUrlStatus.unsubscribe();
    this.detectactiveProjectsStatus.unsubscribe();
    this.detectLoadProjectContributors.unsubscribe();
    this.detectLoadProjectInvitations.unsubscribe();
    this.detectReinviteContributorStatus.unsubscribe();
    this.detectLeaveAProjectStatus.unsubscribe();
    this.idVerificationService.resetActiveProjects();
    this.idVerificationService.resetActivePersons();
    this.displayTopModal.unsubscribe();
    this.detectProjectDeleteDialogue.unsubscribe();
    this.detectProjectDelete.unsubscribe();
    if (this.projectsType === "id-verification") {
      this.loadAdditionalPersonDetails.unsubscribe();
      // this.loadPersonImage.unsubscribe();
      this.parseLegalRepresentativesStatus.unsubscribe();
      this.editLegalRepresentativesStatus.unsubscribe();
      this.completePDFStatus.unsubscribe();
      this.activeUserStatus.unsubscribe();
      this.detectTriggerIDPreview.unsubscribe();
    } else if (
      this.projectsType === "funds" ||
      this.projectsType === "investor"
    ) {
      this.parseInvestorsStatus.unsubscribe();
      this.loadAdditionalInvestorDetails.unsubscribe();
      this.getUserPermission.unsubscribe();
      this.displayTopModal.unsubscribe();
      this.displayPreview.unsubscribe();
      this.displayAccessPopUp.unsubscribe();
      this.displayInvestorReviewStatus.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  filterPersonList(listType) {
    this.idVerificationService.retrieveActiveLegalRepresentatives(
      this.idVerificationService.getActiveProject(),
      false,
      false,
      this.idVerificationService.getActiveLegalRepresentativesBookmark(),
      this.idVerificationService.getActiveLegalrepresentativesFilters().sortBy,
      undefined,
      this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags,
      listType.filter,
      15,
    );
  }

  /************************
   * Funds actions
   ************************/
  // UDD2
  documentUploadScreen(params) {
    // {'requestKey' : element.requestKey,'typeofInvestor' : element.typeOfInvestor, 'existingEvidenceTypes' :element.evidenceTypes}
    const activeInvestor =
      this.idVerificationService.getActiveInvestorDetaisl();
    params = {
      requestKey: this.idVerificationService.getActiveInvestor(),
      typeofInvestor: activeInvestor["record"]
        ? activeInvestor["record"]["typeOfInvestor"]
        : activeInvestor["typeOfInvestor"],
      existingEvidenceTypes: activeInvestor.evidenceTypes,
      existingDigitalVerifications: activeInvestor.digitalVerifications,
    };

    this.idVerificationService.skipToStep(6, params);
  }

  assignProfileRiskRating() {
    // display lightbox with form to assign the risk rating
    this.topDisplayLoader = false;
    this.topFormType = "Assign a risk rating";
    this.topFormTitle = "";
    this.topLightboxClass = "top-dialog-box";
    this.topFormClass = "";
    this.topBtnOkText = "";
    this.topFormValidator = "";
    this.topBtnCancelText = "";
    this.topLightboxClass = {
      closeFormClass: "top-dialog-box",
      class: "",
      reloadfundAlternate: this.idVerificationService.getActiveInvestor(),
    };
    const activeInvestor =
      this.idVerificationService.getActiveInvestorDetaisl();

    const requestKey = this.idVerificationService.getActiveInvestor();

    // build the content for the Project Settings box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "confirm_new_information",
      result_details: [
        {
          group_result_title: "perform_confirm_new_information", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "image",
              params: {
                src: "assets/images/flag.png",
                class: "risk-rating",
              },
            },
            {
              type: "title",
              class: "mt-0",
              params: {
                content: "Add the following risk information to this profile:",
                display: true,
              },
            },
            {
              type: "inputText",
              class: "reduced-margin-top",
              length: "60",
              params: {
                label: "Risk rating",
                id: "RiskRating",
                name: "RiskRating",
                content: activeInvestor?.riskReview?.riskRating
                  ? activeInvestor.riskReview.riskRating
                  : "",
                labelClass: "font-weight-bold",
                validatorMessageClass: "mb-1",
                maxLength: 17,
                required: true,
              },
            },
            {
              type: "risk_suggestions",
              params: {
                listOfSuggestions:
                  this.idVerificationService.getRiskRatingsForProject(),
              },
            },
            {
              type: "inputColorSelector",
              class: "reduced-margin-top",
              length: "60",
              params: {
                label: "Risk rating colour",
                id: "RiskRatingColor",
                name: "RiskRatingColor",
                content: "",
                color: activeInvestor?.riskReview?.color
                  ? activeInvestor.riskReview.color
                  : "",
                labelClass: "font-weight-bold",
                standarColors: [
                  "#4f9556",
                  "#3366cc",
                  "#e3b52a",
                  "#da7706",
                  "#b90d0d",
                ],
              },
            },
            {
              type: "input_date",
              class: "reduced-margin-top",
              params: {
                label: "Date of next risk assessment",
                id: "nextRiskReviewDate",
                name: "nextRiskReview",
                startDate: this.helper.getTodayAsMaxDate(),
                minDate: this.helper.getTodayAsMaxDate(),
                content: activeInvestor?.riskReview?.nextReviewOn
                  ? this.helper.getAsStartDate(
                      activeInvestor.riskReview.nextReviewOn,
                    )
                  : "",
                // 'maxDate': this.helper.getAdultAsMaxDate(),
                required: false,
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Assign",
                  // 'id': 'rescan',
                  action: "OnAssignRiskRating",
                  type: "submit",
                  class: "form-button-1",
                  display: true,
                },
                {
                  content: "Cancel",
                  // 'id': 'accept',
                  action: "OnCancelRiskRating",
                  class: "form-button-link-2",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.topLightboxContent = [];
    this.topLightboxContent.push(group);

    this.topDisplayModal = true;
  }

  OnCancelRiskRating() {
    this.topDisplayModal = false;
  }

  OnAssignRiskRating() {
    this.topDisplayLoader = true;
    const regex = /^#[0-9A-F]{6}$/i;
    const payload = {
      riskRating: $("#RiskRating").val(),
    };
    if ($("#nextRiskReview input").val()) {
      payload["nextReviewOn"] = this.helper.prepareDateForApi(
        $("#nextRiskReview input").val(),
      );
    }
    if (
      $("#RiskRatingColor").val() &&
      $("#RiskRatingColor").val() !== "undefined"
    ) {
      payload["color"] = $("#RiskRatingColor").val();
    }
    if (payload["color"] && !payload["color"].match(regex)) {
      this.toastNotification.newEvent({
        mode: "error",
        title: "The value for rating color must be a valid HEX color value",
        fade: true,
      });
    }

    const path =
      "/api/projects/" +
      this.idVerificationService.getActiveProject() +
      "/information/requests/" +
      this.idVerificationService.getActiveInvestor() +
      "/review";
    // this.userService.postEndPoint(path, payload, {responseType: 'json'}, {observe: 'body'}, 'verification')
    this.userService
      .postEndPoint(
        path,
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        async (response) => {
          if (response === undefined) {
            console.error(
              "Unexpected error as we were writing the risk information",
            );
            return;
          }
          // da se dodelat noviot rr tags odnosno da se vikne od BE
          // this is a quick call so let's wait for it instead of updating through observables

          const activeRiskRatingsFilter =
            this.idVerificationService.getActiveLegalrepresentativesFilters()
              ?.activeRRTags
              ? this.idVerificationService.getActiveLegalrepresentativesFilters()
                  .activeRRTags
              : [];

          await this.userService
            .getEndPoint(
              this.idVerificationService.buildEndPoint(
                "getProjectRiskRating",
                this.idVerificationService.getActiveProject(),
              ),
              { responseType: "json" },
              { observe: "body" },
              this.idVerificationService.getCCType(),
            )
            .subscribe((riskResponse) => {
              if (Array.isArray(riskResponse)) {
                this.idVerificationService.setRiskRatingsForProject(
                  riskResponse,
                );

                this.idVerificationService.setActiveInvestor("");

                // get the active RR filter
                if (
                  activeRiskRatingsFilter &&
                  activeRiskRatingsFilter.length > 0
                ) {
                  // filtering only the tag names from RR data from BE
                  const riskRatings = [];
                  riskResponse?.forEach((tag) =>
                    riskRatings.push(tag["riskRating"]),
                  );

                  // remove the tag from filters if the tag do not exists any more
                  const filter = activeRiskRatingsFilter.filter((element) =>
                    riskRatings.includes(element),
                  );

                  // set the new filters
                  this.idVerificationService.getActiveLegalrepresentativesFilters().activeTags =
                    filter;

                  // set up reload
                  $(".group-beyond-wrapper").addClass("loading");
                  $(".filter-element").addClass("verification-main-disabled");

                  this.idVerificationService.retreiveActiveInvestors(
                    this.getActiveProject(),
                    this.getActiveInvestor(),
                    "funds",
                    false,
                    false,
                    false,
                    undefined,
                    this.idVerificationService.getActiveLegalrepresentativesFilters()
                      .type,
                    this.idVerificationService.getActiveLegalrepresentativesFilters()
                      .sortBy,
                    this.idVerificationService.getActiveLegalrepresentativesFilters()
                      .status,
                    this.idVerificationService.getActiveLegalrepresentativesFilters()
                      .activeTags,
                    this.idVerificationService.getActiveLegalrepresentativesFilters()
                      .filterByRiskReview,
                  );
                }
              }
            });

          // reload the data with the RR information
          if (activeRiskRatingsFilter && activeRiskRatingsFilter.length == 0) {
            this.idVerificationService.skipToStep(-1);
          }
          this.topDisplayModal = false;
          this.toastNotification.newEvent({
            mode: "error",
            title: "The Risk Rating has been assigned",
            fade: true,
          });
        },
        (error) => {
          console.log("We could not generate URL", error);
        },
      );
  }

  documentShareFunction(params) {
    params = {
      requestKey: this.idVerificationService.getActiveInvestor(),
      email:
        this.idVerificationService.getActiveInvestorDetaisl().shareData &&
        this.idVerificationService.getActiveInvestorDetaisl().shareData
          .shareEntities.length >= 1
          ? this.idVerificationService.getActiveInvestorDetaisl().shareData
              .shareEntities[0].sharedWith
          : undefined,
      evidenceTypes: this.idVerificationService.getActiveInvestorDetaisl().evidenceTypes,
    };
    this.idVerificationService.skipToStep(10, params);
  }

  documentShareFunctionAR(params) {
    params = {
      requestKey: this.idVerificationService.getActiveInvestor(),
      email:
        this.idVerificationService.getActiveInvestorDetaisl().shareData &&
        this.idVerificationService.getActiveInvestorDetaisl().shareData
          .shareEntities.length >= 1
          ? this.idVerificationService.getActiveInvestorDetaisl().shareData
              .shareEntities[0].sharedWith
          : undefined,
      evidenceTypes:
        this.idVerificationService.getActiveInvestorDetaisl().evidenceTypes,
    };
    this.idVerificationService.skipToStep(20, params);
  }

  updateBasicInformation(params) {
    let activeInvestorDertails =
      this.idVerificationService.getActiveInvestorDetaisl();
    if (activeInvestorDertails.record) {
      activeInvestorDertails = activeInvestorDertails.record;
    }
    const activeInvestorType = activeInvestorDertails["typeOfInvestor"];
    const activeinvestorFields = activeInvestorDertails["investorFields"];
    let activeinvestorFieldSource = activeInvestorDertails["fieldsSource"];
    if (activeinvestorFieldSource && activeinvestorFieldSource[0]) {
      activeinvestorFieldSource = activeinvestorFieldSource[0];
    }
    params = {
      typeOfInvestor: activeInvestorType,
      fieldsSource: activeinvestorFieldSource,
      investorFields: activeinvestorFields,
    };
    this.idVerificationService.skipToStep(16, params);
  }

  OnUpdateActiveRelatedParty() {
    const activeRPDertails =
      this.idVerificationService.getActiveInvestorDetaisl()[
        "activeRelatedParty"
      ];
    const activeRPType = activeRPDertails.record.typeOfInvestor;
    const activeRPFields = activeRPDertails.record.investorFields;
    const activeRPKey = activeRPDertails.key;
    let activeinvestorRPFieldSource = activeRPDertails.record.fieldsSource;
    if (activeinvestorRPFieldSource) {
      activeinvestorRPFieldSource = activeinvestorRPFieldSource[0];
    }
    const params = {
      typeOfInvestor: activeRPType,
      investorFields: activeRPFields,
      key: activeRPKey,
      fieldsSource: activeinvestorRPFieldSource,
    };
    this.idVerificationService.skipToStep(16, params);
  }

  createRelatedParty(params) {
    // element.requestKey
    params = { requestKey: this.idVerificationService.getActiveInvestor() };
    this.idVerificationService.skipToStep(11, params);
  }

  /***************************
   *  REVIEW PROCESS
   ***************************/
  getActiveInvestors() {
    return this.idVerificationService.getActiveInvestors();
  }
  getActiveProject() {
    return this.idVerificationService.getActiveProject();
  }
  getActiveInvestor() {
    return this.idVerificationService.getActiveInvestor();
  }
  startReviewProcess(params) {
    if (!this.getActiveInvestors()) {
      return;
    }
    // object needed for company search feature
    this.idVerificationService.localFundCreationData = {};
    this.idVerificationService.buildEvidenceListForReview(true, true, true);
    this.idVerificationService.setProjectTypeFlow("review");
    const activeInvestor = this.getActiveInvestors()[0];
    const activeInvestorDetails =
      this.idVerificationService.getActiveInvestorDetaisl();
    let activeEvidenceIfNotSelected;

    for (
      let count = 0;
      count < activeInvestorDetails["projectEvidenceType"].length;
      count++
    ) {
      if (!activeInvestorDetails["projectEvidenceType"][count].justLabel) {
        activeEvidenceIfNotSelected =
          activeInvestorDetails["projectEvidenceType"][count];
        break;
      }
    }

    this.idVerificationService.loadEvidenceFieldAndImages(
      this.idVerificationService.getActiveProject(),
      params && params.requestKey
        ? params.requestKey
        : this.idVerificationService.getActiveInvestor(),
      params && params.evidenceKeyClicked
        ? params.evidenceKeyClicked
        : activeEvidenceIfNotSelected.key,
      "Update",
      activeInvestorDetails["projectEvidenceType"],
    );
  }

  pcsReviewFunction(type) {
    if (!this.getActiveInvestors()) {
      return;
    }

    let requestKey = this.idVerificationService.getActiveInvestor();
    // object needed for company search feature
    this.idVerificationService.localFundCreationData = {};
    let isSingleStep;

    if (type === "basicFields") {
      this.idVerificationService.buildEvidenceListForReview(
        false,
        false,
        false,
      );
      isSingleStep = true;
    }
    if (type === "evidenceTypes") {
      this.idVerificationService.buildEvidenceListForReview(
        true,
        false,
        false,
        true,
      );
    }
    if (type === "digitalVerifications") {
      this.idVerificationService.buildEvidenceListForReview(
        false,
        true,
        false,
        true,
        true,
      );
      isSingleStep = true;
    }

    if (type === "digitalVerificationsContract") {
      this.idVerificationService.buildEvidenceListForReview(
        false,
        true,
        false,
        true,
        true,
        false,
        true,
      );
      isSingleStep = true;
    }

    if (type === "digitalVerificationsIdentityGo") {
      this.idVerificationService.buildEvidenceListForReview(
        false,
        true,
        false,
        true,
        true,
        true,
      );
      isSingleStep = true;
    }

    if (type === "relatedParties") {
      const reviewResults =
        this.idVerificationService.buildEvidenceListForReview(
          false,
          false,
          true,
          true,
          true,
        );
      // the first active request key will be the first element in turn to get reviewed..
      if (reviewResults) {
        for (const obj of reviewResults) {
          if (obj.reviewState === true) {
            requestKey = obj["relatedPartyKey"];
            break;
          }
        }
      }
    }

    this.idVerificationService.setProjectTypeFlow("review");
    const activeInvestorDetails =
      this.idVerificationService.getActiveInvestorDetaisl();
    let activeEvidenceIfNotSelected;

    if (activeInvestorDetails["projectEvidenceType"]) {
      for (
        let count = 0;
        count < activeInvestorDetails["projectEvidenceType"].length;
        count++
      ) {
        if (!activeInvestorDetails["projectEvidenceType"][count].justLabel) {
          activeEvidenceIfNotSelected =
            activeInvestorDetails["projectEvidenceType"][count];
          break;
        }
      }
    }

    this.pcsFlowTrough = true;
    this.idVerificationService.loadEvidenceFieldAndImages(
      this.idVerificationService.getActiveProject(),
      requestKey,
      activeEvidenceIfNotSelected ? activeEvidenceIfNotSelected.key : null,
      "Update",
      activeInvestorDetails["projectEvidenceType"],
      false,
      isSingleStep,
    );
  }

  /**
   * this method will be called each time the element changes are completed
   */
  parseElementChangesCompleted() {
    if (this.projectsType === "investor" && !this.getActiveInvestor()) {
      // since this is investor there can be only one investor which is already set as active
      // so trigger the expandBox with the activeInvestor
      // console.log('parseElementChangesCompleted');
      this.onExpandActiveBox(this.getActiveInvestors()[0]);
    }
  }

  /**
   * actually display the confirm use of new information dialog lightbox
   */
  displayConfirmUseOfNewInformationLightbox(
    isLoaded,
    requestKey = "",
    evidence?,
  ) {
    $(".document-category-main").removeClass("uploading-document");
    $(".documentUploaderSide").removeClass("uploading-document");

    this.topFormType = "Use new information";
    this.topFormTitle = "";
    this.topLightboxClass = "top-dialog-box accept-theirs-form";
    this.topFormClass = "";
    this.topBtnOkText = "";
    this.topFormValidator = "";
    this.topBtnCancelText = "";
    this.topLightboxClass = {
      closeFormClass: "top-dialog-box accept-theirs-form",
      noScroll: true,
      class: "accept-theirs-form",
    };
    requestKey !== ""
      ? (requestKey = requestKey)
      : (requestKey = this.idVerificationService.getActiveInvestor());

    // build the content for the Project Settings box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "confirm_new_information",
      result_details: [
        {
          group_result_title: "perform_confirm_new_information", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "image",
              params: {
                src: "assets/images/flag.png",
              },
            },
            {
              type: "title",
              class: "mt-0",
              params: {
                content: "What would you like to do with the new information?",
                display: true,
              },
            },
            {
              type: "loadingScreen",
              params: {
                isLoading: isLoaded,
              },
            },
            {
              type: "button_group_review_group",
              params: [
                //   {
                //   'content': 'Replace existing document',
                //   'action': 'displayReplaceExistingDocument',
                //   'class': 'full-width-button-with-arrow border-bottom',
                //   'display': true,
                // },
                {
                  content: "Replace existing document",
                  action: "displayReplaceExistingDocument",
                  requestKey: requestKey,
                  evidence: evidence,
                  class: "full-width-button-with-arrow",
                  display: true,
                },
                {
                  content: "Create a new document",
                  action: "initiateCreateNewDocument",
                  requestKey: requestKey,
                  evidence: evidence,
                  class: "full-width-button-with-arrow",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.topLightboxContent = [];
    this.topLightboxContent.push(group);

    this.topDisplayModal = true;
  }

  // initiateCreateNewDocument() {
  //   this.idVerificationService.createNewDocumentWithSPData(undefined);
  // }

  testAlertTopProject() {
    alert("from emitted event on top dialog box");
  }

  /**
   * actually display replace existing document dialog lightbox
   */
  displayReplaceExistingDocument(params) {
    this.topFormType = "Use new information";
    this.topFormTitle = "";
    this.topLightboxClass =
      "top-dialog-box customization-form accept-theirs-form";
    this.topFormClass = "";
    this.topBtnOkText = "";
    this.topFormValidator = "true";
    this.topBtnCancelText = "";
    this.topLightboxClass = {
      closeFormClass: "top-dialog-box accept-theirs-form",
      noScroll: true,
      class: "accept-theirs-form",
    };
    this.topDisplayLoader = false;
    const confirmedEvidenceType: any =
      this.idVerificationService.getEvidenceDetails(
        this.idVerificationService.getActiveEvidenceKey(),
      );
    const requestKey = params.requestKey
      ? params.requestKey
      : this.idVerificationService.getActiveInvestor();

    // fetch and build existing documents list
    const existing_documents = [];
    if (
      confirmedEvidenceType.existingDocuments &&
      confirmedEvidenceType.existingDocuments.length > 0
    ) {
      confirmedEvidenceType.existingDocuments.forEach(
        (existingDocument, key) => {
          existing_documents.push({
            type: "radio",
            params: {
              id: existingDocument.evidence.key.replace("EVD:", "replace"),
              name: "replace_existing_documents",
              label: "Replace existing document",
              value: existingDocument.evidence.key,
              elementType: this.helper.parseBackendName(
                existingDocument.evidence.record.customEvidenceType
                  ? existingDocument.evidence.record.customEvidenceType
                  : existingDocument.evidence.record.type,
              ),
              elementTypeUnparsed: existingDocument.evidence.record
                .customEvidenceType
                ? existingDocument.evidence.record.customEvidenceType
                : existingDocument.evidence.record.type,
              disabled: false,
              selected: key === 0 ? true : false,
              documents: existingDocument.evidence.record.documents,
            },
          });
        },
      );
    }

    const accessInformation = [];
    if (
      confirmedEvidenceType &&
      confirmedEvidenceType.otherPartiesAccess &&
      confirmedEvidenceType.otherPartiesAccess.providersWithPreviousAccess
    ) {
      confirmedEvidenceType.otherPartiesAccess.providersWithPreviousAccess.forEach(
        (accessInfo) => {
          accessInformation.push({
            accessKey: accessInfo.accessKey,
            fund: accessInfo.fund,
            // currently BE returns only single provider
            providers: [
              {
                id: accessInfo.providerId,
                name: accessInfo.providerName,
                accessGranted: this.helper.formatISODate(accessInfo.createdOn),
                access: true,
                toglleActionEnabled: true,
              },
            ],
            email: "not-in-use-atm",
          });
        },
      );
    }

    this.idVerificationService.updateEvidenceProperty(
      this.idVerificationService.getActiveEvidenceKey(),
      ["formattedAccess", accessInformation],
    );

    // build the content for the Project Settings box
    const group = {
      section_class:
        "results d-flex flex-column justify-items-center p-0 accept-theirs-form",
      result_container_class: "",
      is_collapsable: false,
      form_id: "confirm_replace_existing_document",
      submit_btn: {
        type: "submit",
        params: {
          form_id: "request_information_form",
          content: "Replace",
          action: "OnReplaceExistingDocumentWithSPData",
          formValidator: "true",
          display: true,
          requestKey: requestKey,
        },
      },
      cancel_btn: {
        type: "submit",
        params: {
          content: "Cancel",
          action: "OnCancelReplaceExistingDocument",
          className: "form-button-2",
          display: true,
        },
      },
      result_details: [
        {
          group_result_title: "perform_confirm_replace_existing_document", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "title",
              class: "mt-0",
              params: {
                content: "Replace existing document",
                display: true,
              },
            },
            {
              type: "paragraph",
              params: {
                content: "Which document would you like to replace?",
              },
            },
            {
              type: "radiobutton_document_list",
              params: existing_documents,
              requestKey: requestKey,
            },
            {
              type: "newEvidenceShare",
              params: {
                accessInformation: accessInformation,
              },
            },
            {
              type: "inputText",
              params: {
                label: "Reason for replacement",
                id: "replacementReason",
                name: "ReplacementReasonNP",
                content: "",
                required: true,
                labelClass: "font-weight-bold",
              },
              length: "60",
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.topLightboxContent = [];
    this.topLightboxContent.push(group);
    this.topDisplayModal = true;
  }

  hideGeneralError() {
    $(".button-error-field ").removeClass("d-none");
  }

  displayAccessPopUpFunc(params) {
    this.topFormType = this.translate.instant("replace.title3");
    this.topFormTitle = "";
    this.topLightboxClass = "top-dialog-box";
    this.topFormClass = "";
    this.topBtnOkText = "";
    this.topFormValidator = "true";
    this.topBtnCancelText = "";
    this.topLightboxClass = {
      closeFormClass: "top-dialog-box",
      noScroll: true,
    };
    this.topDisplayLoader = false;

    const requestKey = params.requestKey
      ? params.requestKey
      : this.idVerificationService.getActiveInvestor();
    const providersWithPreviousAccess = params.providers;

    // fetch and build existing documents list
    const accessInformation = [];
    providersWithPreviousAccess.forEach((accessInfo) => {
      accessInformation.push({
        accessKey: accessInfo.accessKey,
        fund: accessInfo.fund,
        // currently BE returns only single provider
        providers: [
          {
            id: accessInfo.providerId,
            name: accessInfo.providerName,
            accessGranted: this.helper.formatISODate(accessInfo.createdOn),
            access: true,
            toglleActionEnabled: true,
          },
        ],
        email: "not-in-use-atm",
      });
    });
    // build the content for the Project Settings box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "confirm_replace_existing_document",
      submit_btn: {
        type: "submit",
        params: {
          form_id: "request_information_form",
          content: "Replace",
          action: "onGiveAccessToNewVersion",
          formValidator: "true",
          display: true,
          requestKey: requestKey,
        },
      },
      result_details: [
        {
          group_result_title: "perform_confirm_replace_existing_document", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "newEvidenceShare",
              params: {
                accessInformation: accessInformation,
                evidenceKey: params.evidenceKey,
                hidecheckbox: true,
                requestKey: requestKey,
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.topLightboxContent = [];
    this.topLightboxContent.push(group);

    this.topDisplayModal = true;
  }

  onGiveAccessToNewVersion() {
    const requestKey = $(
      'button[clickmethod = "onGiveAccessToNewVersion"]',
    ).attr("data-requestkey");
    const evidenceKey = $(".access-provider input").attr("data-evidenceKey");
    const accessKeys = [];
    const checkedAccess = $(".access-provider input:checked");
    this.topDisplayLoader = true;
    checkedAccess.each(function () {
      accessKeys.push($(this).attr("data-accessKey"));
    });
    this.idVerificationService.postVersionAccess(
      this.idVerificationService.getActiveProject(),
      this.idVerificationService.getActiveInvestor(),
      evidenceKey,
      accessKeys,
    );
  }

  OnReplaceExistingDocumentWithSPData(event) {
    const requestKey = $(
      'button[clickmethod = "OnReplaceExistingDocumentWithSPData"]',
    ).attr("data-requestkey");
    this.topDisplayLoader = true;

    // TODO find out which evidence is selected (from radio buttons) - done
    let toBeReplace = $("input[name=replace_existing_documents]:checked").val();
    if (!toBeReplace) {
      toBeReplace = $("#confirm_replace_existing_document").val();
    }
    const toBeReplaceWith = this.idVerificationService.getActiveEvidenceKey();
    // TODO get funds with revoked access (the other ones I guess should remain unchanged)
    const accessKeys = [];
    if ($("#share_new_document_existing_sp_check").prop("checked")) {
      const confirmedEvidenceType: any =
        this.idVerificationService.getEvidenceDetails(
          this.idVerificationService.getActiveEvidenceKey(),
        );
      confirmedEvidenceType.formattedAccess.forEach((accessParty) => {
        // TODO: at the moment BE ONLY SUPPORTS ONE provider per accessParty
        if (accessParty.providers[0].access === true) {
          // TODO it is unclear at the moment how BE is handling BULK access revoke
          // it looks like it expects only providers with access to be passed
          // since this is a required field it remains unclear what to do if NONE of the existing providers gets access
          // it should still be possible to give access to the current SP
          accessKeys.push(accessParty.accessKey);
        }
      });
    }
    // get the reason for replacement
    const reasonForReplacement = $("#replacementReason").val();
    // pass all the info to BE
    this.idVerificationService.replaceTheirEvidence(
      toBeReplace,
      toBeReplaceWith,
      accessKeys,
      reasonForReplacement,
      requestKey,
    );
  }

  OnCancelReplaceExistingDocument() {
    this.topDisplayModal = false;
  }

  displayCreateNewDocumentConfirmation(isLoaded) {
    (this.topFormType = isLoaded
      ? "Use new information"
      : "New document is being created"),
      (this.topFormTitle = "");
    this.topFormClass = "";
    this.topBtnOkText = "";
    this.topFormValidator = "";
    this.topBtnCancelText = "";
    this.topLightboxClass = {
      closeFormClass: "top-dialog-box accept-theirs-form",
      class: "accept-theirs-form",
    };

    // build the content for the Create New Document dialog
    const confirmedEvidenceType: any =
      this.idVerificationService.getEvidenceDetails(
        this.idVerificationService.getActiveEvidenceKey(),
      );
    const requestKey = this.idVerificationService.getActiveInvestor();
    const evidenceType = [];
    if (confirmedEvidenceType) {
      evidenceType.push({
        key: confirmedEvidenceType.key,
        type: this.helper.parseBackendName(confirmedEvidenceType.type),
        documents: confirmedEvidenceType.documents,
      });
    }
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "confirm_new_information",
      result_details: [
        {
          group_result_title: "perform_confirm_new_information", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "title",
              class: "mt-0",
              params: {
                content: isLoaded
                  ? "New document created"
                  : "New document is being created",
                display: true,
              },
            },
            {
              type: "paragraph",
              params: {
                content:
                  "A new document has been created and has been added to your dashboard. You are now able to use this new document to respond to other requests in the future.",
              },
            },
            {
              type: "evidenceList",
              investorSide: false,
              action: "onEvidencePreviewInvestor",
              evidenceTypes: isLoaded ? evidenceType : undefined,
              requestKey: requestKey,
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Close",
                  action: "OnCloseLightboxTopWithButton",
                  // 'class': ' border-bottom',
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox

    this.topLightboxContent = [];
    this.topLightboxContent.push(group);
    this.topDisplayModal = true;
  }

  // used for document preview for investor side, there is a separate function on the SP side
  onEvidencePreviewInvestor(inputArgs) {
    let historyEntries;
    let parsedHistoryEntries;
    let purposesOfUse;
    let isLoading = false;
    let evidenceType;
    let fieldValues;
    let accessInformation;
    let evidenceKey;
    let dsiableScroll;
    let documentKeys;
    let expired;
    let requestKey;
    let showVersions = false;
    let versionInfo;
    let showVersionsLightbox;
    let hideActions;
    let basicFieldsPreview;
    let documentName;
    let incomplete;
    let reloadInvestor;
    let latestVersionKey;
    let latestVersion;
    let hasNewerVersions;
    let versionNotInUse;
    let evidence;
    let fieldTypesDV;
    let isDigitalVerification;
    let statuses;
    let isAPSPreview;
    let isAPSpreviewEmpty;
    let apsData;
    let vtype;
    // filename to be made active
    let startingFilname;
    // means no data has been loaded yet
    documentName = " ";
    if (inputArgs[1] === undefined) {
      isLoading = true;
    } else {
      // calls to get data has been completed
      dsiableScroll = inputArgs[0].dsiableScroll;
      (startingFilname = inputArgs[0].startingFilename),
        (reloadInvestor = inputArgs[0].reloadInvestor);
      requestKey = inputArgs[0].originRequest
        ? inputArgs[0].originRequest
        : inputArgs[0].requestKey;
      hideActions = inputArgs[0].hideActions;
      showVersionsLightbox = inputArgs[0].showVersionsLightbox;
      historyEntries = inputArgs[1].historyEntries;
      evidence = inputArgs[2];
      fieldValues = inputArgs[2].record.fields;
      evidenceType = inputArgs[2].record.type;
      documentName = this.helper.translateEvidenceType(evidenceType);
      if (evidenceType === "BASIC_REQUEST_INFO") {
        basicFieldsPreview = true;
        evidenceType = inputArgs[2].record.investorType;
        if (evidenceType === "NaturalPerson") {
          documentName =
            inputArgs[2].record.fields.FirstName +
            " " +
            inputArgs[2].record.fields.LastName;
        }
        if (evidenceType === "LegalPerson") {
          documentName = inputArgs[2].record.fields.RegisteredName;
        }
      }
      if (evidenceType === "DigitalVerification") {
        isDigitalVerification = true;
        vtype = evidence.verificationType;
        if (vtype === undefined) {
          if (evidence.record.verificationData) {
            vtype = evidence.record.verificationData.verificationType;
          }
        }
        documentName = this.helper.translateVerificationType(
          evidence.record.verificationData.verificationType,
        );
        if (vtype === "APS") {
          if (inputArgs[0].apsData && inputArgs[0].apsData.hits.length) {
            isAPSPreview = true;
            apsData = inputArgs[0].apsData;
          } else {
            isAPSpreviewEmpty = true;
          }
        }
      }
      versionInfo = inputArgs[4];
      if (versionInfo.newer) {
        versionInfo.newer[versionInfo.newer.length - 1].label =
          this.translate.instant("evidencePreview.documentLatest");
        latestVersionKey = versionInfo.newer[versionInfo.newer.length - 1].key;
        latestVersion = versionInfo.newer[versionInfo.newer.length - 1];
        hasNewerVersions = true;
      }
      versionNotInUse = inputArgs[2].record.access
        ? !inputArgs[2].record.access.inUse
        : undefined;
      if (versionInfo.newer || versionInfo.previous) {
        showVersions = true;
      }
      evidenceKey = inputArgs[2].key;
      documentKeys = inputArgs[2].record.documents;
      expired = inputArgs[2].record.access
        ? inputArgs[2].record.access.expired
        : undefined;
      // if (!inputArgs[2].record.access) {
      //   expired = true;
      // }
      incomplete = inputArgs[2].record.incomplete;
      purposesOfUse = inputArgs[2].record.access
        ? inputArgs[2].record.access.purposesOfUse
        : undefined;
      const evidenceFields = inputArgs[2];
      accessInformation = inputArgs[3]
        ? inputArgs[3]["providersWithPreviousAccess"]
        : undefined;
      if (historyEntries !== undefined) {
        parsedHistoryEntries = this.idVerificationService.parseHistoryEntries(
          historyEntries,
          vtype,
        );
      }
      fieldTypesDV = inputArgs[0].fieldTypesDV;
      statuses = inputArgs[0].statuses;
      documentKeys = inputArgs[0].documentKeys;
    }

    this.foremostFormType = documentName
      ? documentName
      : this.translate.instant("appSpecific.Document");
    this.foremostFormTitle = "";
    this.foremostLightboxClass = "foremost-dialog-box";
    this.foremostFormClass = "";
    this.foremostBtnOkText = "";
    this.foremostFormValidator = "";
    this.foremostBtnCancelText = "";
    this.foremostLightboxClass = {
      class: "document-preview-lightbox",
      closeFormClass: "foremost-dialog-box",
      accessNiceScroll: !isLoading,
      noScroll: dsiableScroll,
      reloadfund: reloadInvestor
        ? this.idVerificationService.getActiveInvestor()
        : undefined,
    };

    this.idVerificationService.setActiveDigitalVerificationDetails({
      response: inputArgs[2],
      files: [],
      filesFinishedParsing: false,
    });

    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,

      // 'formKey' : inputArgs.requestKey,
      result_details: [
        {
          group_result_title: "create_new_representative_group",
          elements: [
            {
              type: "evidencePreview",
              class: isLoading ? "isLoading" : "",
              params: {
                documentPreviewTitle: basicFieldsPreview
                  ? undefined
                  : isDigitalVerification
                  ? this.translate.instant(
                      "evidencePreview.verificationDetails",
                    )
                  : this.translate.instant("evidencePreview.documentDetails"),
                requestKey: requestKey,
                documentKeys: documentKeys,
                expired: expired,
                evidenceType: evidenceType,
                evidence: evidence,
                evidenceKey: evidenceKey,
                historyEntries: parsedHistoryEntries
                  ? parsedHistoryEntries["parsedHistoryEntries"]
                  : [],
                documentImages: "",
                hideArrows:
                  documentKeys && documentKeys.length <= 1 ? true : false,
                fieldTypes: fieldTypesDV
                  ? fieldTypesDV
                  : evidenceType &&
                    this.idVerificationService.parseEvidenceFields(
                      evidenceType,
                      fieldValues,
                      true,
                      true,
                    ),
                fieldTypesDV: fieldTypesDV ? true : false,
                statuses: statuses,
                purposesOfUse: purposesOfUse,
                hasAccessTab: this.idVerificationService.isInvestorType()
                  ? true
                  : false,
                dontShowUpdate: true,
                accessInformation: accessInformation,
                isReviewFlow: true,
                fieldValues: fieldValues,
                ownerEmail: parsedHistoryEntries
                  ? parsedHistoryEntries["owner"]
                  : "",
                hideActions: hideActions,
                basicFieldsPreview: basicFieldsPreview,
                isInvestorSide: this.idVerificationService.isInvestorType(),
                incomplete:
                  this.idVerificationService.isInvestorType() && incomplete,
                startingFilname: startingFilname,
                showVersions: showVersions,
                versionInfo: versionInfo,
                showVersionsLightbox: showVersionsLightbox,
                versionNotInUse: versionNotInUse,
                latestVersionKey: latestVersionKey,
                noDocumentsDisplay:
                  !basicFieldsPreview &&
                  documentKeys &&
                  documentKeys.length === 0
                    ? true
                    : false,
                isDigitalVerification: isDigitalVerification,
                isAPSPreview: isAPSPreview,
                isAPSpreviewEmpty: isAPSpreviewEmpty,
                apsData: apsData,
                status: evidence && evidence.record && evidence.record.status,
                vtype: vtype,
              },
            },
          ],
        },
      ],
    };
    if (
      inputArgs[2] &&
      inputArgs[2].record &&
      inputArgs[2].record.verificationData &&
      inputArgs[2].record.verificationData.isResolved
    ) {
      // const evidence = inputArgs[0].evidence.filter(x => x.key === inputArgs[2].key);
      group.result_details[0].elements[0].params["pdfDownloadButton"] = true;
      if (
        inputArgs[2].record.verificationData.verificationType.toLowerCase() ===
        "identity"
      ) {
        // if (inputArgs[2] && inputArgs[2].record && inputArgs[2].record.status.toLowerCase() !== 'verified') {
        //   group.result_details[0].elements[0].params['pdfDownloadButton'] = false;
        // }
        group.result_details[0].elements[0].params["pdfDownloadFuction"] =
          "OnGenerateDVIdentityPDF";
      }

      if (
        inputArgs[2].record.verificationData.verificationType.toLowerCase() ===
        "identityinstantai"
      ) {
        group.result_details[0].elements[0].params["pdfDownloadButton"] = false;
      }
      if (
        inputArgs[2].record.verificationData.verificationType.toLowerCase() ===
        "identitygo"
      ) {
        group.result_details[0].elements[0].params["pdfDownloadButton"] = false;
      }
      if (
        inputArgs[2].record.verificationData.verificationType.toLowerCase() ===
        "address"
      ) {
        group.result_details[0].elements[0].params["pdfDownloadFuction"] =
          "OnGenerateDVAddressVerificationPDF";
      }
      if (
        inputArgs[2].record.verificationData.verificationType.toLowerCase() ===
        "contract"
      ) {
        group.result_details[0].elements[0].params["noDocumentsDownload"] =
          true;
        group.result_details[0].elements[0].params["pdfDownloadFuction"] =
          "OnGenerateDVContractPDF";
      }
    }

    // build the content for the Create new person lightbox
    this.foremostLightboxContent = [];
    this.foremostLightboxContent.push(group);
    this.foremostDisplayModal = true;
    // setTimeout(function() {
    //   $('.document-slide-container-imgs').zoom({'on' : 'grab', 'magnify' : '1.33'});
    // }, 250);
  }

  onRiskRatingPreview() {
    let riskReviewKey;
    if(this.idVerificationService.getActiveInvestorDetaisl().riskReview?.riskReviewKey)
    {
      riskReviewKey = this.idVerificationService.getActiveInvestorDetaisl().riskReview.riskReviewKey
      this.riskAssessmentService.buildRiskAssessmentLightbox(
        riskReviewKey
      );
    }
  }

  goToRRDashboard()
  {
    this.router.navigate(["/risk-assessment/dashboard"]);
  }

  OnGenerateDVContractPDF(event, replaceLightboxLoader) {
    if (
      !this.idVerificationService.getActiveDigitalVerificationDetails()
        .filesFinishedParsing
    ) {
      return;
    }

    event.preventDefault();
    $(event.target).closest("a").addClass("download-loading");
    $(".download-loading span").text("Generating PDF Report ...");

    setTimeout(() => {
      this.idVerificationService.generateDVContractPdf(
        this.idVerificationService.getActiveDigitalVerificationDetails(),
      );
      $(".download-loading span").text("Download document");
      $(event.target).closest("a").removeClass("download-loading");
    }, 1000);
  }

  parseEvidenceFields(
    evidenceType: string,
    existingFields = {},
    previewMode = false,
    hideEmpty = false,
  ) {
    const evidenceFields = JSON.parse(
      JSON.stringify(
        this.idVerificationService.getDocumentTypeList().evidenceFields,
      ),
    );
    const evidenceFieldsType = evidenceFields[evidenceType];
    const parsedEvidenceFieldsType = [];
    evidenceFieldsType.forEach((element) => {
      if (
        existingFields[element.id] !== "" &&
        existingFields[element.id] !== null &&
        existingFields[element.id] !== undefined
      ) {
        let value;
        if (element.type === "date") {
          if (
            new Date(parseInt(existingFields[element.id], 10)).getTime() >
            100000
          ) {
            if (!previewMode) {
              value = this.helper.getAsStartDateFromTimestamp(
                existingFields[element.id],
              );
            } else {
              value = this.helper.formatISODate(existingFields[element.id]);
            }
          } else {
            value = this.helper.getAsStartDate(existingFields[element.id]);
          }
        } else {
          value = existingFields[element.id];
        }
        parsedEvidenceFieldsType.push({
          name: element.id,
          id: evidenceType + element.id,
          label: this.helper.parseBackendName(element.id),
          type: element.type,
          value: value,
          minLength: element.minLength,
          maxLength: element.maxLength,
          optional: element.optional,
          selectOptionsList: element.selectOptionsList,
          startDate: element.startDate,
          minDate: element.minDate,
          maxDate: element.maxDate,
        });
      } else {
        if (!hideEmpty || element.type === "title") {
          parsedEvidenceFieldsType.push({
            name: element.id,
            id: evidenceType + element.id,
            label: this.helper.parseBackendName(element.id),
            type: element.type,
            minLength: element.minLength,
            maxLength: element.maxLength,
            optional: element.optional,
            selectOptionsList: element.selectOptionsList,
            startDate: element.startDate,
            minDate: element.minDate,
            maxDate: element.maxDate,
          });
        }
      }
    });
    return parsedEvidenceFieldsType;
  }

  onDeleteProfile() {
    this.idVerificationService.skipToStep(23);
  }
  startProfileVerification() {
    this.idVerificationService.skipToStep(
      24,
      this.idVerificationService.getActiveInvestor(),
    );
  }

  startProfileVerificationRP() {
    this.idVerificationService.skipToStep(
      24,
      this.idVerificationService.getActiveInvestorDetaisl()[
        "activeRelatedParty"
      ]["key"],
    );
  }
}
